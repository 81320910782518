const SelectDropdownFallbackPlaceholder = ({
  loading,
  emptyMessage,
  children,
  isLoading,
  isEmpty,
}) => {
  if (isLoading) {
    return loading;
  }

  if (isEmpty) {
    return emptyMessage;
  }

  return children;
};

export default SelectDropdownFallbackPlaceholder;
