import cx from 'classnames';
import styles from './Svg.module.scss';

const Svg = ({ children, viewBox = '0 0 24 24', className, ...rest }) => (
  <svg
    className={cx(styles.root, className)}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {children}
  </svg>
);

export default Svg;
