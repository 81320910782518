import styles from './IconGoogle.module.scss';

const IconGoogle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={styles.root}
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="#FBBB00"
          d="M3.99 10.878l-.627 2.339-2.29.048A8.955 8.955 0 010 9c0-1.492.363-2.9 1.006-4.139l2.04.374.893 2.026a5.35 5.35 0 00.05 3.617z"
        />
        <path
          fill="#518EF8"
          d="M17.843 7.319a9.03 9.03 0 01-.04 3.561 9 9 0 01-3.169 5.139v-.001l-2.568-.131-.363-2.269a5.363 5.363 0 002.307-2.739H9.198V7.32h8.645z"
        />
        <path
          fill="#28B446"
          d="M14.634 16.018A8.953 8.953 0 019 18a8.999 8.999 0 01-7.927-4.735l2.916-2.387a5.353 5.353 0 007.713 2.74l2.932 2.4z"
        />
        <path
          fill="#F14336"
          d="M14.745 2.072l-2.916 2.387a5.352 5.352 0 00-7.89 2.802l-2.932-2.4A8.994 8.994 0 019 0c2.184 0 4.186.778 5.745 2.072z"
        />
      </g>
      <path d="M0 0h18v18H0z" />
    </g>
  </svg>
);

export default IconGoogle;
