@import '../../styles/utils.scss';

.root {
  display: flex;
  padding: 16px 24px;
  border-radius: $primaryBorderRadius;
  background-color: $grey_1;
  overflow: hidden;
}

.element {
  @extend %normalize-input;
  flex: 1 1 auto;
  resize: none;

  &::placeholder {
    color: $grey_3;
  }
}

.appended {
  flex: 0 0 auto;
  margin-right: -8px;
}
