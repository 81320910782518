@import '../../../../styles/utils';

.container {
  position: relative;
}

.input {
  @extend %normalize-input;
  width: 144px;
  padding: 12px;
  border: 1px solid $grey_2;
  border-radius: 2px;
  @include defaultTransition(border);

  &:focus {
    border: 1px solid $grey_3;
  }
}

.label {
  position: absolute;
  left: 16px;
  top: -7px;
  display: inline-block;
  padding: 2px 4px;
  background-color: $white;
  font-size: 12px;
  color: $grey_3;
  pointer-events: none;
}
