import styles from './Appended.module.scss';

const UsersProductivityStatisticPageAppended = ({ select, calendar }) => (
  <div className={styles.root}>
    <div className={styles.select}>{select}</div>

    <div>{calendar}</div>
  </div>
);

export default UsersProductivityStatisticPageAppended;
