import { useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Localization from '../../models/localization';

const formButtonTitleDate = (date, language, isAbbreviated) => {
  if (!date) {
    return '';
  }

  const currentYear = new Date().getFullYear();
  const options = { day: '2-digit', month: isAbbreviated ? '2-digit' : 'long' };
  if (currentYear !== date.getFullYear()) {
    options.year = 'numeric';
  }

  return new Intl.DateTimeFormat(language, options).format(date);
};

const useButtonTitle = ([dateFrom, dateTo], isDisplayAbbreviated) => {
  const intl = useIntl();
  const language = useAtom(Localization.languageAtom);

  if (!dateFrom || !dateTo) {
    return intl.formatMessage({ defaultMessage: 'Choose date' });
  }

  const formattedDateFrom = formButtonTitleDate(
    dateFrom,
    language,
    isDisplayAbbreviated,
  );
  const formattedDateTo = formButtonTitleDate(
    dateTo,
    language,
    isDisplayAbbreviated,
  );

  if (dateFrom.toISOString() === dateTo.toISOString()) {
    return formattedDateFrom;
  }

  return `${formattedDateFrom} - ${formattedDateTo}`;
};

export default useButtonTitle;
