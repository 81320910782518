import { Children, cloneElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import AdPageTableRow from './Row/Row';
import AdPageTableCell from './Cell/Cell';
import AdPageTableHeaderRow from './HeaderRow/HeaderRow';
import AdPageTableHeaderCell from './HeaderCell/HeaderCell';

import styles from './AdPageTable.module.scss';

const formColumnSizeByName = (columns) =>
  columns.reduce(
    (result, { name, width, minWidth, isFlexible }) => ({
      ...result,
      [name]: { width, minWidth, isFlexible },
    }),
    {},
  );

const AdPageTablePlaceholder = ({ children, emptyText, isEmpty }) => {
  if (isEmpty) {
    return (
      <AdPageTableRow>
        <AdPageTableCell isFullWidth>
          <div className={styles.emptyText}>{emptyText}</div>
        </AdPageTableCell>
      </AdPageTableRow>
    );
  }

  return children;
};

const AdPageTable = ({
  columns = [],
  children,
  headerRow,
  emptyText = <FormattedMessage defaultMessage="The table is empty" />,
  isEmpty,
}) => {
  const columnSizeByName = useMemo(
    () => formColumnSizeByName(columns),
    [columns],
  );

  return (
    <div>
      {headerRow && cloneElement(headerRow, { columnSizeByName })}

      <AdPageTablePlaceholder emptyText={emptyText} isEmpty={isEmpty}>
        {Children.map(children, (child) =>
          cloneElement(child, { columnSizeByName }),
        )}
      </AdPageTablePlaceholder>
    </div>
  );
};

AdPageTable.HeaderRow = AdPageTableHeaderRow;
AdPageTable.HeaderCell = AdPageTableHeaderCell;
AdPageTable.Row = AdPageTableRow;
AdPageTable.Cell = AdPageTableCell;
AdPageTable.EmptyCellPlaceholder = () => '—';
export default AdPageTable;
