import { useCallback, useState } from 'react';

const useSearch = (initialValue = '') => {
  const [search, setSearch] = useState(initialValue);

  const handleSearchChange = useCallback((e) => {
    const value = e.target.value;

    if (!value || /^\d+$/.test(value)) {
      setSearch(value);
    }
  }, []);

  return [search, handleSearchChange];
};

export default useSearch;
