import TaskLabel from '../../../../TaskLabel/TaskLabel';
import AutomaticTaskLabel from '../../../../AutomaticTaskLabel/AutomaticTaskLabel';
import ComplaintTaskLabel from '../../../../ComplaintTaskLabel/ComplaintTaskLabel';
import styles from './TitleAppended.module.scss';

const TaskEditingTitleAppended = ({ id, type, isAutomatic, isComplaint }) => (
  <div className={styles.root}>
    <span>#{id}</span>

    <span>
      {isComplaint && (
        <span className={styles.labelItem}>
          <ComplaintTaskLabel />
        </span>
      )}

      {isAutomatic && (
        <span className={styles.labelItem}>
          <AutomaticTaskLabel />
        </span>
      )}

      <span className={styles.labelItem}>
        <TaskLabel type={type} />
      </span>
    </span>
  </div>
);

export default TaskEditingTitleAppended;
