import cx from 'classnames';
import './Loader.scss';

const INNER_SIZE = 44;

const SIZE_BY_NAME = {
  large: 80,
  medium: 64,
  small: 48,
};

const CircularProgress = ({
  disableShrink = false,
  size,
  style,
  thickness = 3.6,
  ...other
}) => {
  const finalSize = SIZE_BY_NAME[size] || size || SIZE_BY_NAME.large;

  return (
    <div
      className={'circular-progress'}
      style={{ width: finalSize, height: finalSize, ...style }}
      role="progressbar"
      {...other}
    >
      <svg
        viewBox={`${INNER_SIZE / 2} ${
          INNER_SIZE / 2
        } ${INNER_SIZE} ${INNER_SIZE}`}
      >
        <circle
          className={cx({
            'circular-progress__circle--no-shrink': disableShrink,
          })}
          cx={INNER_SIZE}
          cy={INNER_SIZE}
          r={(INNER_SIZE - thickness) / 2}
          strokeWidth={thickness}
        />
      </svg>
    </div>
  );
};

export default CircularProgress;
