import Loader from '../../../components/Loader/Loader';
import styles from './Loader.module.scss';

const TasksQueuePageLoader = () => (
  <div className={styles.root}>
    <Loader />
  </div>
);

export default TasksQueuePageLoader;
