import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const checkIsToday = (dateISO) => {
  const today = new Date();
  const incomeDate = new Date(dateISO);

  return (
    incomeDate.getDate() === today.getDate() &&
    incomeDate.getMonth() === today.getMonth() &&
    incomeDate.getFullYear() === today.getFullYear()
  );
};

const checkIsYesterday = (dateISO) => {
  const yesterday = new Date(new Date() - DAY_IN_MS);
  const incomeDate = new Date(dateISO);

  return (
    incomeDate.getDate() === yesterday.getDate() &&
    incomeDate.getMonth() === yesterday.getMonth() &&
    incomeDate.getFullYear() === yesterday.getFullYear()
  );
};

const FormattedDateTime = ({ dateISO }) => {
  if (!dateISO) {
    return '';
  }

  if (checkIsToday(dateISO)) {
    return (
      <FormattedMessage
        defaultMessage="today at {time}"
        values={{
          time: (
            <FormattedTime
              value={dateISO}
              hour="2-digit"
              minute="2-digit"
              hourCycle="h23"
            />
          ),
        }}
      />
    );
  }

  if (checkIsYesterday(dateISO)) {
    return (
      <FormattedMessage
        defaultMessage="yesterday at {time}"
        values={{
          time: (
            <FormattedTime
              value={dateISO}
              hour="2-digit"
              minute="2-digit"
              hourCycle="h23"
            />
          ),
        }}
      />
    );
  }

  const isCurrentYear =
    new Date(dateISO).getFullYear() === new Date().getFullYear();

  return (
    <FormattedMessage
      defaultMessage="{date} at {time}"
      values={{
        date: (
          <FormattedDate
            value={dateISO}
            month="long"
            day="numeric"
            {...(isCurrentYear ? null : { year: 'numeric' })}
          />
        ),
        time: <FormattedTime value={dateISO} />,
      }}
    ></FormattedMessage>
  );
};

export default FormattedDateTime;
