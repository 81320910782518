.grid {
  display: flex;
}

.adPreviewColumn {
  flex: 1 1 auto;
  margin-right: 48px;
}

.buttonGroupColumn {
  flex: 0 0 296px;
}
