@import '../../../../styles/utils';

.root {
  width: 256px;
  position: relative;
  z-index: 1;
}

.trigger {
  position: relative;
  z-index: 1;
  height: 48px;
}

.button {
  @extend %normalize-button;
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  text-align: left;
}

.buttonContent {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
}

.mediaCol {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}

.textCol {
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
}

.name {
  display: inline-block;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 700;
  @include overflowEllipsis;
}

.role {
  font-size: 12px;
  color: $textDarkGrey;
  text-transform: lowercase;
  @include overflowEllipsis;
}

.hoverSpreader::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.dropdown {
  position: absolute;
  left: -4px;
  top: -4px;
  right: -4px;
  padding: 12px;
  padding-top: calc(48px + 12px);
  border: 1px solid $grey_1;
  border-radius: $primaryBorderRadius;
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(34, 34, 34, 0.3);
  text-align: right;
}
