import { useCallback, useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import UserCreationDialog from './UserCreationDialog';

import * as Users from '../../../../models/users';
import { useNotifications } from '../../../../hooks';

const UserCreationDialogContainer = (props) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const allUsersEmails = useAtom(Users.Showing.allUsersEmails);
  const isUserCreatingFailed = useAtom(Users.Creation.isSavedFailedAtom);
  const handleUserAdd = useAction(Users.Creation.saved);

  useEffect(() => {
    if (!isUserCreatingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to add user',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserCreatingFailed, intl.formatMessage, showErrorNotification]);

  const handleSubmit = useCallback(
    (user) => handleUserAdd(user),
    [handleUserAdd],
  );

  return (
    <UserCreationDialog
      allUsersEmails={allUsersEmails}
      onSubmit={handleSubmit}
      {...props}
    />
  );
};

export default UserCreationDialogContainer;
