import { Children, cloneElement, useMemo } from 'react';

import TableRow from './Row/Row';
import TableCell from './Cell/Cell';
import TableLoader from './Loader/Loader';
import TablePagination from './Pagination/Pagination';
import TableHeaderRow from './HeaderRow/HeaderRow';
import TableHeaderCell from './HeaderCell/HeaderCell';
import TableCellLoader from './CellLoader/CellLoader';
import TableFilterSelect from './FilterSelect/FilterSelect';

import { formColumnSizeByName } from './Table.helpers';
import styles from './Table.module.scss';

const TablePlaceholder = ({ children, emptyText, isLoading, isEmpty }) => {
  if (isLoading) {
    return (
      <TableRow>
        <TableCell horizontalAlignment="center" isFullWidth>
          <TableLoader />
        </TableCell>
      </TableRow>
    );
  }

  if (isEmpty) {
    return (
      <TableRow>
        <TableCell isFullWidth>
          <div className={styles.emptyText}>{emptyText}</div>
        </TableCell>
      </TableRow>
    );
  }

  return children;
};

const Table = ({
  columns = [],
  children,
  page,
  perPage,
  amount,
  headerRow,
  sort,
  emptyText = <FormattedMessage defaultMessage="The table is empty" />,
  isLoading,
  isEmpty,
  isActionsShown,
  onPaginationPrevClick,
  onPaginationNextClick,
  onSort,
}) => {
  const columnSizeByName = useMemo(
    () => formColumnSizeByName(columns),
    [columns],
  );

  return (
    <div>
      {headerRow &&
        cloneElement(headerRow, {
          sort,
          columnSizeByName,
          onSort,
          isActionsShown,
        })}

      <TablePlaceholder
        emptyText={emptyText}
        isLoading={isLoading}
        isEmpty={isEmpty}
      >
        {Children.map(children, (child) =>
          cloneElement(child, { columnSizeByName }),
        )}

        {perPage < amount && (
          <div className={styles.pagination}>
            <TablePagination
              page={page}
              perPage={perPage}
              amount={amount}
              onPrevClick={onPaginationPrevClick}
              onNextClick={onPaginationNextClick}
            />
          </div>
        )}
      </TablePlaceholder>
    </div>
  );
};

Table.Row = TableRow;
Table.HeaderRow = TableHeaderRow;
Table.HeaderCell = TableHeaderCell;
Table.Loader = TableLoader;
Table.CellLoader = TableCellLoader;
Table.Cell = TableCell;
Table.FilterSelect = TableFilterSelect;
Table.EmptyCellPlaceholder = () => '—';
export default Table;
