import cx from 'classnames';
import { FormattedDate, FormattedTime } from 'react-intl';

import Media from '../../Media/Media';

import styles from './ChangeMessagePlaceholder.module.scss';

const ChangeMessagePlaceholder = ({
  user,
  timestamp,
  action,
  details = '',
  isHighlighted,
}) => (
  <div
    className={cx(styles.root, {
      [styles.root_highlighted]: isHighlighted,
    })}
  >
    <div className={styles.metaRow}>
      <div className={styles.subjectRow}>
        <div className={styles.userMedia}>
          <Media media={user.media} />
        </div>

        <div className={styles.actionHolder}>
          <div className={styles.action}>
            {user.name} <b>{action}</b> {details}
          </div>
        </div>
      </div>

      <div className={styles.dateTime}>
        <span className={styles.date}>
          <FormattedDate value={timestamp} />
        </span>
        <span>
          <FormattedTime value={timestamp} />
        </span>
      </div>
    </div>
  </div>
);

export default ChangeMessagePlaceholder;
