import { createStore } from '@reatom/core';
import { connectReduxDevtools } from '@reatom/debug';
import rootAtom from './models';

const store = createStore(rootAtom);

if (process.env.NODE_ENV !== 'production') {
  connectReduxDevtools(store);
}

export default store;
