@import '../../styles/utils';

.pagination {
  margin-top: 24px;
}

.emptyText {
  font-size: 16px;
  color: $grey_3;
}
