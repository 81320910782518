import { useCallback } from 'react';

import { checkIsFunction } from '../../utils';
import styles from './Form.module.scss';

const Form = ({ children, onSubmit, ...rest }) => {
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (checkIsFunction(onSubmit)) {
        onSubmit(e);
      }
    },
    [onSubmit],
  );

  return (
    <form onSubmit={handleSubmit} {...rest}>
      {children}
    </form>
  );
};

Form.Row = ({ children }) => <div className={styles.row}>{children}</div>;
export default Form;
