import { TASK_TYPE } from '../../constants';
import { deserializeMedia } from '../common';
import { deserializeCallPreview } from '../calls';

const formCallsHistoryByPhoneList = (messCallsHistoryByPhone) => {
  const table = {};

  messCallsHistoryByPhone.forEach((historyItem) => {
    table[historyItem.phone] = [
      ...(table[historyItem.phone] || []),
      historyItem,
    ];
  });

  return Object.entries(table).map(([phone, phoneCallsHistoryList]) => ({
    phone,
    history: phoneCallsHistoryList.map((call) => deserializeCallPreview(call)),
  }));
};

const deserializeAd = (ad) => ({
  address: ad.address,
  agency: ad.agency,
  medias: ad.images.map(deserializeMedia),
  price: ad.price,
  currency: ad.currency,
  floor: ad.floor,
  floorsAmount: ad.floorCount,
  roomsAmount: ad.roomCount,
  totalSquare: ad.areaTotal,
  kitchenSquare: ad.areaKitchen,
  livingSquare: ad.areaLiving,
  description: ad.text,
});

const deserializeCallTaskOverviewPayload = (payload) => {
  const {
    ad = null,
    phones = [],
    phonesCalls,
    pageCalls,
    originUrls,
  } = payload;

  const deserializedPayload = {
    phones: phones.map((phone) => String(phone)),
    sourceAdUrlByAdId: originUrls,
  };

  if (ad) {
    deserializedPayload.ad = deserializeAd(ad);
  }

  if (phonesCalls?.length > 0) {
    deserializedPayload.callsHistoryByPhoneList =
      formCallsHistoryByPhoneList(phonesCalls);
  }

  if (pageCalls?.length > 0) {
    const list = pageCalls.map((call) => deserializeCallPreview(call));
    deserializedPayload.adCallsHistory = {
      list,
      adId: list[0].adId,
    };
  }

  return deserializedPayload;
};

const deserializeDefaultOverviewPayload = ({ originUrls = {} }) => {
  const deserializedPayload = {
    sourceAdUrlByAdId: originUrls,
  };

  return deserializedPayload;
};

export const deserializeTaskOverviewPayload = (taskType, payload) => {
  if (!payload) {
    return null;
  }

  switch (taskType) {
    case TASK_TYPE.CALL:
      return deserializeCallTaskOverviewPayload(payload);

    default:
      return deserializeDefaultOverviewPayload(payload);
  }
};
