@import '../../../../../styles/utils';

.root {
  display: flex;
}

.label {
  margin-right: 12px;
}

.content {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
