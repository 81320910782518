import { combine } from '@reatom/core';

import showingAtom from './showing';
import creationAtom from './creation';
import editingAtom from './editing';

export * as Showing from './showing';
export * as Creation from './creation';
export * as Editing from './editing';

export default combine({
  showingAtom,
  creationAtom,
  editingAtom,
});
