import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAction } from '@reatom/react';

import TaskListPage from '../../../../pages/TaskListPage/TaskListPage';

import * as Dialog from '../../../../models/dialog';
import { DIALOG_NAME } from '../../../../constants';
import { useQueryParams } from '../../../../hooks';

const useOnModerationRoutParamOpening = () => {
  const [currentParams, { removeQueryParams }] = useQueryParams();
  const { state, pathname } = useLocation();
  const { moderatingAdId } = currentParams;
  const isDialogOpening = currentParams['task-creation'];

  const handleOpen = useAction(
    (moderatingAdId) =>
      Dialog.openDialog({
        name: DIALOG_NAME.TASK_CREATION,
        payload: { moderatingAdId },
      }),
    [],
  );

  useEffect(() => {
    if (pathname !== TaskListPage.RouteParams.path) {
      return;
    }

    if (!isDialogOpening) {
      return;
    }

    removeQueryParams(['task-creation']);
    handleOpen(state.adId);
  }, [
    pathname,
    state,
    moderatingAdId,
    isDialogOpening,
    removeQueryParams,
    handleOpen,
  ]);
};

export default useOnModerationRoutParamOpening;
