import Autosuggest from 'react-autosuggest';

import './Autosuggest.scss';

const GeneralSearchAutosuggest = ({
  inputValue,
  options,
  placeholder,
  inputRef,
  highlightFirstSuggestion,
  renderSuggestion = ({ label }) => label,
  renderInputComponent,
  shouldRenderSuggestions,
  onSelect,
  onInputChange,
}) => {
  const handleInputChange = (e) => {
    if (e.type === 'change') {
      onInputChange(e.target.value);
    }
  };

  const handleOptionSelect = (event, { suggestion }) => onSelect(suggestion);

  return (
    <Autosuggest
      ref={inputRef}
      suggestions={options}
      onSuggestionsFetchRequested={() => {}}
      onSuggestionsClearRequested={() => {}}
      onSuggestionSelected={handleOptionSelect}
      getSuggestionValue={({ value }) => value}
      renderSuggestion={renderSuggestion}
      inputProps={{
        placeholder: placeholder,
        value: inputValue,
        onChange: handleInputChange,
      }}
      renderInputComponent={renderInputComponent}
      highlightFirstSuggestion={highlightFirstSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      focusInputOnSuggestionClick={false}
    />
  );
};

export default GeneralSearchAutosuggest;
