import { FormattedNumber } from 'react-intl';
import styles from './Price.module.scss';

const Price = ({ number, currency }) => (
  <span className={styles.root}>
    <span>
      <FormattedNumber value={number} />
    </span>

    {String(number) !== '0' && (
      <span className={styles.currency}>{currency || 'грн'}</span>
    )}
  </span>
);

export default Price;
