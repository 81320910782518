@import '../../../styles/utils.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 18px;

  &:last-child {
    margin-right: 0;
  }
}

.content {
  @extend %normalize-button;
  display: inline-flex;
  align-items: center;
  padding: 4px 0;
  @include defaultTransition(opacity);

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }

  &_interactive {
    pointer-events: none;
  }
}

.title {
  font-weight: 700;
}

.icon {
  margin-left: 8px;
  line-height: 1;
}

.actionContainer {
  display: flex;
  align-items: center;
  height: 38px;
  margin-top: 4px;
}

.action {
  flex: 1 1 auto;
  max-width: 100%;
}
