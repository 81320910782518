import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl';
import { useMemo } from 'react';

import Table from '../../../components/Table/Table';
import UserPlate from '../../../components/UserPlate/UserPlate';
import ButtonEdit from '../../../components/ButtonEdit/ButtonEdit';
import SearchHighlighter from '../../../components/SearchHighlighter/SearchHighlighter';
import RoundIconButton from '../../../components/RoundIconButton/RoundIconButton';
import { IconDelete } from '../../../components/icons';

import { CALL_STATUS_INFO, CALL_RESULT_INFO } from '../../../constants';
import styles from './Table.module.scss';

const columns = [
  { name: 'taskId', width: '64px' },
  { name: 'adId', width: '96px' },
  { name: 'createTime', width: '88px' },
  { name: 'address', width: '96px', isFlexible: true },
  { name: 'phone', width: '112px' },
  { name: 'executor', width: '116px', minWidth: '100px', isFlexible: true },
  { name: 'callStatus', width: '112px' },
  { name: 'edit', title: '', width: '40px', isFlexible: false },
];

const CALL_RESULT_OPTIONS = Object.values(CALL_RESULT_INFO).map(
  ({ slug, title }) => ({
    value: slug,
    label: <FormattedMessage {...title} />,
  }),
);

const CallHistoryTable = ({
  history = [],
  searchValue = '',
  filters,
  loadingOverviewTaskIdsAtom,
  onFilterChange,
  onTaskEditClick,
  onFiltersReset,
  ...rest
}) => {
  const intl = useIntl();

  const handleEditClick = (taskId, callId) => () =>
    onTaskEditClick(taskId, callId);

  const isResetButtonDisabled = useMemo(
    () => Object.values(filters).filter(Boolean).length === 0,
    [filters],
  );

  return (
    <Table
      columns={columns}
      emptyText={<FormattedMessage defaultMessage="No calls" />}
      headerRow={
        <Table.HeaderRow>
          <Table.HeaderCell
            name="taskId"
            title={<FormattedMessage defaultMessage="Task id" />}
          />
          <Table.HeaderCell
            name="adId"
            title={<FormattedMessage defaultMessage="Ad id" />}
          />
          <Table.HeaderCell
            name="createTime"
            title={<FormattedMessage defaultMessage="Date" />}
          />
          <Table.HeaderCell
            name="address"
            title={<FormattedMessage defaultMessage="Address" />}
          />
          <Table.HeaderCell
            name="phone"
            title={<FormattedMessage defaultMessage="Phone number" />}
          />
          <Table.HeaderCell
            name="executor"
            title={<FormattedMessage defaultMessage="Assignee" />}
          />
          <Table.HeaderCell
            name="callStatus"
            title={<FormattedMessage defaultMessage="Result" />}
            action={
              <Table.FilterSelect
                name="callResult"
                placeholder={<FormattedMessage defaultMessage="Choose" />}
                value={filters.callResult}
                options={CALL_RESULT_OPTIONS}
                onChange={onFilterChange}
              />
            }
          />
          <Table.HeaderCell
            name="edit"
            action={
              <div className={styles.resetFilterButton}>
                <RoundIconButton
                  variant="flat"
                  iconSize="small"
                  icon={<IconDelete />}
                  isDisabled={isResetButtonDisabled}
                  onClick={onFiltersReset}
                  isSmall
                />
              </div>
            }
          />
        </Table.HeaderRow>
      }
      {...rest}
    >
      {history.map(
        ({
          id,
          taskId,
          adId,
          createTime,
          address,
          phone,
          executor,
          callStatus,
          callResult,
        }) => (
          <Table.Row key={id}>
            <Table.Cell name="taskId">{taskId}</Table.Cell>

            <Table.Cell name="adId">{adId}</Table.Cell>

            <Table.Cell name="createTime">
              <FormattedDate value={createTime} />,{' '}
              <FormattedTime value={createTime} />
            </Table.Cell>

            <Table.Cell name="address">
              {address ? (
                <SearchHighlighter search={searchValue} text={address} />
              ) : (
                <Table.EmptyCellPlaceholder />
              )}
            </Table.Cell>

            <Table.Cell name="phone">
              <SearchHighlighter search={searchValue} text={phone} />
            </Table.Cell>

            <Table.Cell name="executor">
              {executor && (
                <UserPlate name={executor.name} media={executor.media} />
              )}
            </Table.Cell>

            <Table.Cell name="callStatus">
              {intl.formatMessage(CALL_STATUS_INFO[callStatus].title)},{' '}
              <b>
                <FormattedMessage {...CALL_RESULT_INFO[callResult].title} />
              </b>
            </Table.Cell>

            <Table.Cell
              name="edit"
              horizontalAlignment="center"
              isVisibleWhenRowTranslucent
            >
              {loadingOverviewTaskIdsAtom.includes(String(taskId)) ? (
                <Table.CellLoader />
              ) : (
                <ButtonEdit onClick={handleEditClick(taskId, id)} />
              )}
            </Table.Cell>
          </Table.Row>
        ),
      )}
    </Table>
  );
};

export default CallHistoryTable;
