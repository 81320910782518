import { FormattedMessage } from 'react-intl';
import styles from './TimelineDisplayConditions.module.scss';

const TimelineDisplayConditions = () => (
  <div className={styles.root}>
    <FormattedMessage defaultMessage="In order for the user timeline to be displayed, you must select a time period of 1 day." />
  </div>
);

export default TimelineDisplayConditions;
