import { useCallback, useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import { useTaskOverviewDialog, useNotifications } from '../../hooks';
import * as UsersStatistic from '../../models/usersStatistic';

const useTimeline = (dateRange) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const timeline = useAtom(UsersStatistic.Timeline.timelineAtom);
  const earliestTimelineDate = useAtom(
    UsersStatistic.Timeline.earliestTimelineDateAtom,
  );
  const latestTimelineDate = useAtom(
    UsersStatistic.Timeline.latestTimelineDateAtom,
  );
  const isTimelineLoading = useAtom(UsersStatistic.Timeline.isDoneLoadingAtom);
  const isTimelineFailed = useAtom(UsersStatistic.Timeline.isDoneFailedAtom);
  const isTimelineIdle = useAtom(UsersStatistic.Timeline.isDoneIdleAtom);

  const onTimelineReset = useAction(UsersStatistic.Timeline.reset);
  const handleTimelineShow = useAction(UsersStatistic.Timeline.done);

  useEffect(() => onTimelineReset(), [onTimelineReset]);

  useEffect(() => {
    const dateInISORange = dateRange
      .filter(Boolean)
      .map((date) => date.toISOString());

    if (dateInISORange[0] !== dateInISORange[1]) {
      return;
    }

    handleTimelineShow({
      dateRange: dateInISORange,
    });
  }, [dateRange, handleTimelineShow]);

  const { open: openTaskOverviewDialog } = useTaskOverviewDialog();

  const handleTimelineBarClick = useCallback(
    (taskId) => openTaskOverviewDialog(taskId),
    [openTaskOverviewDialog],
  );

  useEffect(() => {
    if (!isTimelineFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({ defaultMessage: 'Failed to load timeline' }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimelineFailed, intl.formatMessage, showErrorNotification]);

  return [
    {
      timeline,
      earliestTimelineDate,
      latestTimelineDate,
      isTimelineLoading,
      isTimelineFailed,
      isTimelineIdle,
    },
    { onTimelineBarClick: handleTimelineBarClick, onTimelineReset },
  ];
};

export default useTimeline;
