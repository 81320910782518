import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import AdInfoTileSection from '../TileSection/TileSection';
import AdDetailsTable from '../Table/Table';
import AdGeographicalRecognizedLabel from './RecognizedLabel/RecognizedLabel';

import rowDataShape from '../rowDataShape';

const AdGeographicalInfoTileSection = ({
  data,
  isModerated,
  onHouseEditClick,
}) => {
  const isHighlighted =
    !data.apartmentComplex.recognized && !data.house.recognized;

  return (
    <AdInfoTileSection
      title={<FormattedMessage defaultMessage="Geography" />}
      isModerated={isModerated}
    >
      <AdDetailsTable>
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="region" />}
          {...data.region}
        />
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="raion" />}
          {...data.raion}
        />
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="city" />}
          {...data.city}
        />
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="district" />}
          {...data.district}
        />
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="subdistrict" />}
          {...data.microdistrict}
        />
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="street" />}
          {...data.street}
        />
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="HCA on the map" />}
          raw={data.apartmentComplex.raw}
          recognized={
            data.apartmentComplex.recognized && (
              <AdGeographicalRecognizedLabel>
                {data.apartmentComplex.recognized}
              </AdGeographicalRecognizedLabel>
            )
          }
          isHighlighted={isHighlighted}
        />
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="house on the map" />}
          raw={data.house.raw}
          recognized={
            <AdDetailsTable.EditableField
              isAvailable={!!data.street.id}
              onEditClick={onHouseEditClick}
            >
              {data.house.recognized && (
                <AdGeographicalRecognizedLabel>
                  {data.house.recognized}
                </AdGeographicalRecognizedLabel>
              )}
            </AdDetailsTable.EditableField>
          }
          isHighlighted={isHighlighted}
        />
      </AdDetailsTable>
    </AdInfoTileSection>
  );
};

AdGeographicalInfoTileSection.propTypes = {
  data: PropTypes.shape({
    apartmentComplex: rowDataShape,
    house: rowDataShape,
    region: rowDataShape,
    raion: rowDataShape,
    city: rowDataShape,
    district: rowDataShape,
    microdistrict: rowDataShape,
    street: rowDataShape,
  }).isRequired,
  onHouseEditClick: PropTypes.func.isRequired,
  isModerated: PropTypes.bool,
};

export default AdGeographicalInfoTileSection;
