import Media from '../../../components/Media/Media';
import Price from '../../../components/Price/Price';
import { IconImage } from '../../../components/icons';

import styles from './AdPreview.module.scss';

const prepareMediaList = (medias) => {
  const _medias = [];
  _medias.length = 6;
  _medias.fill(null);

  return _medias.map((el, index) => medias[index] || el);
};

const QueuedTaskAdPreview = ({ ad }) => {
  const {
    address,
    agency,
    medias,
    roomsAmount,
    price,
    currency,
    floor,
    floorsAmount,
    description,
    totalSquare,
    kitchenSquare,
    livingSquare,
  } = ad;

  const preparedMedias = prepareMediaList(medias);

  return (
    <div className={styles.root}>
      <div className={styles.mediaColumn}>
        {preparedMedias.map((media, index) => (
          <div key={index} className={styles.mediaItem}>
            {media ? (
              <div className={styles.media}>
                <Media media={media} isAdjustable />
              </div>
            ) : (
              <div className={styles.imagePlaceholder}>
                <div className={styles.imagePlaceholderContent}>
                  <IconImage />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className={styles.textColumn}>
        <div className={styles.address}>{address}</div>

        {agency && <div className={styles.agency}>{agency}</div>}

        <div className={styles.price}>
          <Price number={price} currency={currency} />
        </div>

        <div>
          {roomsAmount && (
            <div className={styles.parametersItem}>Комнат: {roomsAmount}</div>
          )}

          {floor && (
            <div className={styles.parametersItem}>
              Этаж: {floor}
              {floorsAmount ? `/${floorsAmount}` : ''}
            </div>
          )}

          {totalSquare && (
            <div className={styles.parametersItem}>
              Площадь: {totalSquare}/{livingSquare || '-'}/
              {kitchenSquare || '-'}
            </div>
          )}
        </div>

        {description && (
          <div className={styles.description}>
            {description} {description} {description}
          </div>
        )}
      </div>
    </div>
  );
};

export default QueuedTaskAdPreview;
