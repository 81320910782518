import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useQueryParams, useToggle } from '../../hooks';
import { parseBooleanFromString } from '../../utils';
import { TASK_SUBTYPE, TASK_TYPE_LIST } from '../../constants';
import { AUTOMATICALLY_CREATED_IDENTIFIER } from './useCreatorFilterOptions';

const prepareDateFilter = (dayISO) => {
  if (!dayISO) {
    return null;
  }

  const date = new Date(dayISO);

  // to obtain date equal to '{dateISO}T00:00:00+-{local timezone shift}'
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const useGeneralFilters = () => {
  const [{ isOnlyCompleted }, { setQueryParams, removeQueryParams }] =
    useQueryParams();

  const handleIsOnlyCompletedFilterChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setQueryParams({ isOnlyCompleted: true });
        return;
      }

      removeQueryParams(['isOnlyCompleted']);
    },
    [setQueryParams, removeQueryParams],
  );

  return [
    {
      isOnlyCompleted: parseBooleanFromString(isOnlyCompleted),
    },
    {
      onIsOnlyCompletedFilterChange: handleIsOnlyCompletedFilterChange,
    },
  ];
};

const normalizeCreator = (creator) =>
  Object.values(AUTOMATICALLY_CREATED_IDENTIFIER).find(
    (identifier) => identifier === creator,
  ) || Number(creator);

const TABLE_FILTERS = [
  'taskType',
  'taskSubType',
  'executorId',
  'dateFrom',
  'dateTo',
  'creator',
];
const DATE_FILTER_NAME = 'date';

const useTableFilters = () => {
  const isFirstRenderRef = useRef(true);
  const [params, { setQueryParams, removeQueryParams }] = useQueryParams();
  const { taskType, taskSubType, executorId, creator, dateFrom, dateTo } =
    params;

  const handleTableFilterChange = useCallback(
    (name, value) => {
      switch (name) {
        case DATE_FILTER_NAME:
          setQueryParams({ dateFrom: value[0], dateTo: value[1] });
          return;

        default:
          setQueryParams({ [name]: value });
      }
    },
    [setQueryParams],
  );

  const handleTableFiltersReset = useCallback(
    () => removeQueryParams(TABLE_FILTERS),
    [removeQueryParams],
  );

  const hasTableFilters = useMemo(
    () =>
      TABLE_FILTERS.filter((filter) => Object.keys(params).includes(filter))
        .length > 0,
    [params],
  );
  const { isOn, toggle } = useToggle(hasTableFilters);

  useEffect(() => {
    if (!Object.values(TASK_SUBTYPE).includes(taskSubType)) {
      removeQueryParams(['taskSubType']);
    }
  }, [taskSubType, removeQueryParams]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      return;
    }

    removeQueryParams(['taskSubType', 'executorId']);
  }, [taskType, removeQueryParams]);

  useEffect(() => {
    if (!TASK_TYPE_LIST.includes(taskType)) {
      removeQueryParams(['taskType']);
    }
  }, [taskType, removeQueryParams]);

  useEffect(() => {
    if (!isOn) {
      handleTableFiltersReset();
    }
  }, [isOn, handleTableFiltersReset]);

  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);

  const tableFilters = useMemo(
    () => ({
      taskType,
      taskSubType,
      executorId: Number(executorId),
      date: [prepareDateFilter(dateFrom), prepareDateFilter(dateTo)],
      creator: normalizeCreator(creator),
    }),
    [dateFrom, dateTo, creator, taskType, taskSubType, executorId],
  );

  return [
    {
      tableFilters,
      isTableFiltersShown: isOn,
    },
    {
      onTableFilterChange: handleTableFilterChange,
      onTableFiltersReset: handleTableFiltersReset,
      onIsTableFiltersShownToggle: toggle,
    },
  ];
};

const useFilters = () => {
  const [{ isOnlyCompleted }, { onIsOnlyCompletedFilterChange }] =
    useGeneralFilters();

  const [
    { tableFilters, isTableFiltersShown },
    { onTableFilterChange, onTableFiltersReset, onIsTableFiltersShownToggle },
  ] = useTableFilters();

  const filters = useMemo(
    () => ({ ...tableFilters, isOnlyCompleted }),
    [tableFilters, isOnlyCompleted],
  );

  return [
    { filters, isTableFiltersShown },
    {
      onIsOnlyCompletedFilterChange,
      onTableFilterChange,
      onTableFiltersReset,
      onIsTableFiltersShownToggle,
    },
  ];
};

export default useFilters;
