import { FILTER_TYPE } from '../constants';
import { createFilterShape } from '../utils';
import { deserializeUserPreview } from './common';

export const serializeCallsFilters = (filters) => [
  createFilterShape('isOnlyMy', FILTER_TYPE.EXACT_MATCH, filters.isOnlyMy),
  createFilterShape(
    'pageStatus',
    FILTER_TYPE.EXACT_MATCH,
    filters.callResult || null,
  ),
];

export const deserializeCallPreview = ({
  callId,
  taskId = '',
  pageId,
  pageStatus,
  createdBy,
  address,
  ...rest
}) => ({
  id: callId,
  taskId,
  adId: pageId,
  executor: deserializeUserPreview(createdBy),
  address: address || '',
  callResult: pageStatus,
  ...rest,
});
