import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import * as Account from '../account';
import * as UsersShowing from './showing';

export const handleSaved = declareAction('Users/Editing/handleSaved');
const savedSucceeded = declareAction('Users/Editing/savedSucceeded');
export const savedFailed = declareAction('Users/Editing/savedFailed');

export const saved = declareAction(
  'Users/Editing/saved',
  async (updatedUser, store) => {
    const userId = updatedUser.id;
    const userList = store.getState(UsersShowing.allUsersAtom);
    const originalUser = userList.find((userItem) => userItem.id === userId);
    if (!originalUser) {
      // no user to edit;
      return;
    }
    store.dispatch(handleSaved(updatedUser));

    const { id, name, isActive, role, taskTypes } = updatedUser;
    const authorization = store.getState(Account.authorizationAtom);

    try {
      await api(authorization).users.edit({
        id,
        name,
        isActive,
        role,
        taskTypes,
      });
      store.dispatch(savedSucceeded({ userId }));
    } catch (error) {
      console.error(error);
      store.dispatch(
        savedFailed({
          userId,
          originalUser,
        }),
      );
    }
  },
);

export const resetSavedErrorMessage = declareAction(
  'Users/Editing/resetSavedErrorMessage',
);

export const savedLoadingStateAtom = declareAtom(
  'Users/Editing/savedLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(saved, () => LOADING_STATE.LOADING),
    on(savedSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(savedFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isSavedFailedAtom = map(
  savedLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({ savedLoadingStateAtom });
