import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Dialog from '../../../Dialog/Dialog';
import Form from '../../../Form/Form';
import Field from '../../../Field/Field';
import Fieldset from '../../../Fieldset/Fieldset';
import Select from '../../../Select/Select';
import Radio from '../../../Radio/Radio';
import ZendeskLinkInput from '../../../ZendeskLinkInput/ZendeskLinkInput';
import InputList from '../../../InputList/InputList';
import CommentTextarea from '../../../CommentTextarea/CommentTextarea';
import TaskInteractionHistoryField from '../../../TaskInteractionHistoryField/TaskInteractionHistoryField';
import TaskInteractionHistory from '../../../TaskInteractionHistory/TaskInteractionHistory';
import Button from '../../../Button/Button';
import { IconLink } from '../../../icons';
import TaskEditingTitleAppended from './TitleAppended/TitleAppended';

import { useEventHandler } from '../../../../hooks';
import { checkHasVisibleErrors } from '../../../../utils';
import {
  SORTED_PRIORITIES,
  PRIORITY_INFO,
  TASK_TYPE_INFO,
  TASK_SUBTYPES_BY_TYPE,
  TASK_IDENTIFIER_TYPE_INFO,
  TASK_SUBTYPE_INFO,
  TASK_IDENTIFIER_TYPE,
  PRIORITY,
  TASK_TYPE,
} from '../../../../constants';
import styles from './TaskEditingDialog.module.scss';

const initValues = (task) => ({
  type: task?.type || '',
  subType: task?.subType || '',
  priority: task?.priority || PRIORITY.MEDIUM,
  zendeskUrl: task?.zendeskUrl || '',
  executorId: task?.executor?.id || '',
  additionalResources: task?.additionalResources || [],
  comment: '',
});

const createValidationSchema = (task, formatMessage) =>
  Yup.object().shape({
    type: Yup.string().test(
      'call-task-has-only-one-identifier',
      formatMessage({
        defaultMessage: 'Task type "Call" can have only one identifier - ad id',
      }),
      (taskType) => {
        if (taskType !== TASK_TYPE.CALL) {
          return true;
        }

        return (
          task?.identifier?.type === TASK_IDENTIFIER_TYPE.AD_ID &&
          task?.identifier?.entities?.length === 1
        );
      },
    ),
    zendeskUrl: Yup.string().url(
      formatMessage({ defaultMessage: 'Link is not valid' }),
    ),
  });

const TASK_TYPE_OPTIONS = Object.values(TASK_TYPE).map((type) => ({
  value: type,
  label: <FormattedMessage {...TASK_TYPE_INFO[type].title} />,
}));

const TaskEditingDialog = ({
  task,
  executors = [],
  isEditingLoading,
  isExecutorsLoading,
  isOpened,
  isCommentingSucceeded,
  isCommentingLoading,
  onUpdate,
  onTaskTypeChange,
  onCommentSend,
  onClose,
}) => {
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initValues(),
    validationSchema: createValidationSchema(task, intl.formatMessage),
    onSubmit: (values) => {
      const updatingValues = {
        ...values,
        id: task?.id,
        additionalResources: values.additionalResources.filter(Boolean),
      };
      delete updatingValues.comment;

      onUpdate(updatingValues);
    },
  });

  const subTypeOptions = useMemo(() => {
    if (!TASK_SUBTYPES_BY_TYPE[values.type]) {
      return [];
    }

    return TASK_SUBTYPES_BY_TYPE[values.type]
      .map((slug) => TASK_SUBTYPE_INFO[slug])
      .map(({ slug, title }) => ({
        value: slug,
        label: <FormattedMessage {...title} />,
      }));
  }, [values.type]);

  const executorOptions = executors.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const hasVisibleErrors = useMemo(
    () => checkHasVisibleErrors(errors, touched),
    [errors, touched],
  );

  const handleSelectChange = useCallback(
    (value, name) => setFieldValue(name, value),
    [setFieldValue],
  );

  const handleAdditionalResources = useCallback(
    (list) => setFieldValue('additionalResources', list),
    [setFieldValue],
  );

  const handleTypeChange = useCallback(
    (value, name) => {
      handleSelectChange(value, name);
      setFieldValue('executorId', '');
      setFieldValue('subType', '');
    },
    [handleSelectChange, setFieldValue],
  );

  const handleCommentSubmit = useEventHandler(() => {
    onCommentSend(task?.id, values.comment);
  }, [values.comment, task, onCommentSend]);

  useEffect(() => {
    if (values.type) {
      onTaskTypeChange(values.type);
    }
  }, [values.type, onTaskTypeChange]);

  const taskId = task?.id;

  useEffect(() => {
    resetForm({
      values: initValues(task),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, isOpened, resetForm]);

  useEffect(() => {
    if (isCommentingSucceeded) {
      setFieldValue('comment', '');
    }
  }, [isCommentingSucceeded, setFieldValue]);

  const actions = useMemo(
    () => [
      <Button
        key="cancel"
        title={<FormattedMessage defaultMessage="Cancel" />}
        variant="flat"
        onClick={onClose}
        withLargeSideInnerGaps
      />,
      <Button
        key="save"
        title={<FormattedMessage defaultMessage="Save" />}
        isDisabled={hasVisibleErrors || isEditingLoading}
        onClick={handleSubmit}
        withLargeSideInnerGaps
      />,
    ],
    [hasVisibleErrors, isEditingLoading, handleSubmit, onClose],
  );

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="Task view" />}
      titleAppended={
        <TaskEditingTitleAppended
          taskId={task?.id}
          taskType={values.type}
          isTaskAutomatic={task?.isAutomatic}
          isTaskComplaint={task?.isComplaint}
        />
      }
      onClose={onClose}
      isOpened={isOpened}
      actions={actions}
    >
      <div className={styles.root}>
        <div className={styles.grid}>
          <div className={styles.column}>
            <Form.Row>
              <Field
                label={<FormattedMessage defaultMessage="Task type" />}
                error={
                  touched.type &&
                  errors.type && <FormattedMessage {...errors.type} />
                }
              >
                <Select
                  value={values.type}
                  options={TASK_TYPE_OPTIONS}
                  name="type"
                  placeholder={
                    <FormattedMessage defaultMessage="Choose task type" />
                  }
                  isDisabled={task?.isCompleted}
                  onChange={handleTypeChange}
                  onBlur={handleBlur}
                />
              </Field>
            </Form.Row>

            <Form.Row>
              <Field label={<FormattedMessage defaultMessage="Task subtype" />}>
                <Select
                  value={values.subType}
                  options={subTypeOptions}
                  name="subType"
                  placeholder={
                    <FormattedMessage defaultMessage="Choose task subtype" />
                  }
                  isDisabled={subTypeOptions.length === 0 || task?.isCompleted}
                  onChange={handleSelectChange}
                  onBlur={handleBlur}
                />
              </Field>
            </Form.Row>

            <Form.Row>
              <Field label={<FormattedMessage defaultMessage="Priority" />}>
                <Fieldset>
                  {SORTED_PRIORITIES.map((priority) => (
                    <Fieldset.Item key={priority}>
                      <Radio
                        name="priority"
                        value={priority}
                        label={PRIORITY_INFO[priority].title}
                        checked={priority === values.priority}
                        isDisabled={task?.isCompleted}
                        onChange={handleChange}
                      />
                    </Fieldset.Item>
                  ))}
                </Fieldset>
              </Field>
            </Form.Row>

            <Form.Row>
              <Field
                label={<FormattedMessage defaultMessage="Zendesk link" />}
                error={touched.zendeskUrl && errors.zendeskUrl}
              >
                <ZendeskLinkInput
                  value={values.zendeskUrl}
                  name="zendeskUrl"
                  isDisabled={task?.isCompleted}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Field>
            </Form.Row>

            <Form.Row>
              <Field
                label={
                  task?.identifier?.type && (
                    <FormattedMessage
                      {...TASK_IDENTIFIER_TYPE_INFO[task.identifier.type].title}
                    />
                  )
                }
              >
                <InputList
                  list={task?.identifier?.entities}
                  noAppendedIcon
                  isDisabled
                />
              </Field>
            </Form.Row>

            <Form.Row>
              <Field label={<FormattedMessage defaultMessage="Assignee" />}>
                <Select
                  value={values.executorId}
                  options={executorOptions}
                  name="executorId"
                  placeholder={<FormattedMessage defaultMessage="Assign" />}
                  noOptionsMessage={
                    isExecutorsLoading ? (
                      <FormattedMessage defaultMessage="Loading..." />
                    ) : (
                      <FormattedMessage defaultMessage="No assignees for this task" />
                    )
                  }
                  isDisabled={!values.type || task?.isCompleted}
                  onChange={handleSelectChange}
                />
              </Field>
            </Form.Row>

            <Form.Row>
              <Field
                label={
                  <FormattedMessage defaultMessage="Additional resources" />
                }
                error={
                  touched.additionalResources && errors.additionalResources
                }
              >
                <InputList
                  name="additionalResources"
                  type="url"
                  list={values.additionalResources}
                  prependedIcon={<IconLink />}
                  isDisabled={task?.isCompleted}
                  onChange={handleAdditionalResources}
                />
              </Field>
            </Form.Row>

            <Form.Row>
              <Field label={<FormattedMessage defaultMessage="Comment" />}>
                <CommentTextarea
                  value={values.comment}
                  isSubmitButtonDisabled={
                    isCommentingLoading || !values.comment
                  }
                  onSubmit={handleCommentSubmit}
                  onChange={handleChange}
                />
              </Field>
            </Form.Row>
          </div>

          <div className={styles.column}>
            <TaskInteractionHistoryField
              label={<FormattedMessage defaultMessage="Comment history" />}
            >
              <TaskInteractionHistory history={task?.interactionHistory} />
            </TaskInteractionHistoryField>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TaskEditingDialog;
