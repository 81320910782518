import { FormattedMessage } from 'react-intl';

import Fieldset from '../../../components/Fieldset/Fieldset';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Switch from '../../../components/Switch/Switch';
import { IconPlus } from '../../../components/icons';

import styles from './Subtitle.module.scss';

const TaskListSubtitle = ({
  isCompletedShown,
  isTableFiltersShown,
  onTableFiltersToggle,
  onCompletedShownChange,
  onCreateTaskClick,
}) => (
  <div className={styles.root}>
    <div className={styles.checkboxGroup}>
      <Fieldset>
        <Fieldset.Item>
          <Checkbox
            label={<FormattedMessage defaultMessage="Done" />}
            value={isCompletedShown}
            onChange={onCompletedShownChange}
          />
        </Fieldset.Item>
      </Fieldset>
    </div>

    <div className={styles.filtersSwitch}>
      <Switch
        label={<FormattedMessage defaultMessage="Field filters" />}
        value={isTableFiltersShown}
        onChange={onTableFiltersToggle}
      />
    </div>

    <div>
      <Button
        title={<FormattedMessage defaultMessage="Create task" />}
        prependedIcon={<IconPlus />}
        onClick={onCreateTaskClick}
      />
    </div>
  </div>
);

export default TaskListSubtitle;
