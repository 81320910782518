import { defineMessage } from 'react-intl';

import ROLE_TYPE from './roleType';

const ROLE_INFO_BY_TYPE = {
  [ROLE_TYPE.OWNER]: {
    type: ROLE_TYPE.OWNER,
    title: defineMessage({
      defaultMessage: 'Owner',
    }),
  },

  [ROLE_TYPE.ADMIN]: {
    type: ROLE_TYPE.ADMIN,
    title: defineMessage({
      defaultMessage: 'Moderator',
    }),
  },

  [ROLE_TYPE.MODERATOR]: {
    type: ROLE_TYPE.MODERATOR,
    title: defineMessage({
      defaultMessage: 'Trainee',
    }),
  },
};

export default ROLE_INFO_BY_TYPE;
