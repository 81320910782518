import { FormattedMessage } from 'react-intl';
import Label from '../../../../../components/Label/Label';

const AdStatusMessageProcessingLabel = () => (
  <Label
    title={<FormattedMessage defaultMessage="Processing" />}
    backgroundColor="#f7ca45"
    color="white"
  />
);

export default AdStatusMessageProcessingLabel;
