import { useCallback, useLayoutEffect, useState, useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import { useEventHandler, useNotifications } from '../../hooks';
import * as Tasks from '../../models/tasks';
import * as TaskQueue from '../../models/taskQueue';

const useComment = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const queuedTaskId = useAtom(TaskQueue.EntityId.valueAtom);
  const isCommentLoading = useAtom(Tasks.Comment.isSaveCommentLoadingAtom);
  const isCommentingSucceeded = useAtom(
    Tasks.Comment.isSaveCommentSucceededAtom,
  );
  const isCommentingFailed = useAtom(Tasks.Comment.isSaveCommentFailedAtom);

  const handleCommentSend = useAction((taskId, comment) =>
    Tasks.Comment.save({ taskId, comment }),
  );

  const [comment, setComment] = useState('');

  const handleCommentChange = useCallback((comment) => setComment(comment), []);
  const handleCommentSubmit = useEventHandler(
    () => handleCommentSend(queuedTaskId, comment),
    [queuedTaskId, comment],
  );

  useEffect(() => {
    if (!isCommentingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to add comment. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentingFailed, intl.formatMessage, showErrorNotification]);

  useLayoutEffect(() => {
    if (isCommentingSucceeded) {
      setComment('');
    }
  }, [isCommentingSucceeded]);

  return [
    { comment, isCommentLoading },
    {
      onCommentChange: handleCommentChange,
      onCommentSubmit: handleCommentSubmit,
    },
  ];
};

export default useComment;
