import { useRef } from 'react';

const useDynamicRef = (value) => {
  const ref = useRef(value);
  ref.current = value;

  return ref;
};

export default useDynamicRef;
