import Svg from '../Svg/Svg';

const IconList = () => (
  <Svg>
    <path
      fillRule="nonzero"
      d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.807 9.942l-3.75-3.75L3.15 17.101c-.1.1-.15.22-.15.36zM20.708 7.042a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
    />
    <path fill="none" fillRule="evenodd" d="M0 0h24v24H0z" />
  </Svg>
);

export default IconList;
