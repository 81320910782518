import Loader from '../../Loader/Loader';
import styles from './Loader.module.scss';

const TableLoader = () => (
  <div className={styles.root}>
    <Loader size="medium" />
  </div>
);

export default TableLoader;
