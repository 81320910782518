import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Label from '../../../../../components/Label/Label';
import { IconCheck } from '../../../../../components/icons';

import styles from './RecognizedLabel.module.scss';

const AdGeographicalRecognizedLabel = ({ children }) => (
  <div className={styles.root}>
    <span className={styles.label}>
      <Label
        title={<FormattedMessage defaultMessage="recognized" />}
        icon={<IconCheck />}
        backgroundColor="#3dc746"
      />
    </span>

    <span className={styles.content}>{children}</span>
  </div>
);

AdGeographicalRecognizedLabel.propTypes = {
  children: PropTypes.node,
};

export default AdGeographicalRecognizedLabel;
