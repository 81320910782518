import styles from './TaskInteractionHistoryField.module.scss';

const ChangesHistoryField = ({ label, children }) => (
  <div className={styles.root}>
    <div className={styles.label}>{label}</div>

    <div className={styles.content}>{children}</div>
  </div>
);

export default ChangesHistoryField;
