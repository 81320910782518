import { FormattedMessage } from 'react-intl';

import { AD_STATUS_REASON, BANNED_AD_STATUS } from '../../../../constants';
import styles from './NotInInterfaceDetails.module.scss';

const Item = ({ title, children }) => (
  <div className={styles.item}>
    <div className={styles.itemTitle}>{title}:</div>
    <div className={styles.itemContent}>{children}</div>
  </div>
);

const ReasonExplanation = ({ bannedStatus }) => {
  switch (bannedStatus) {
    case BANNED_AD_STATUS.NOT_ACTIVE:
      return (
        <FormattedMessage defaultMessage="The ad did not pass the activator check." />
      );

    case BANNED_AD_STATUS.NOT_PASS_FILTERS:
      return (
        <FormattedMessage defaultMessage="The ad did not pass the filter check." />
      );

    case BANNED_AD_STATUS.NOT_PASS_POSTFILTERS:
      return (
        <FormattedMessage defaultMessage="The ad didn't pass the builder's check." />
      );

    default:
      return '';
  }
};

const AdStatusNotInInterfaceDetails = ({
  reason,
  bannedInfo,
  bannedStatus,
}) => {
  switch (reason) {
    case AD_STATUS_REASON.BANNED:
      return (
        <>
          <Item title="Причина">
            <FormattedMessage defaultMessage="no longer transferred from the original site." />
          </Item>

          <Item title="Детали">
            <ReasonExplanation bannedStatus={bannedStatus} />
          </Item>

          {bannedInfo && (
            <Item
              title={
                <FormattedMessage defaultMessage="Additional Information" />
              }
            >
              {bannedInfo}
            </Item>
          )}
        </>
      );

    case AD_STATUS_REASON.DELETED:
      return (
        <Item title="Причина">
          <FormattedMessage defaultMessage="no longer transferred from the original site." />
        </Item>
      );

    default:
      return null;
  }
};

export default AdStatusNotInInterfaceDetails;
