@import '../../../styles/utils';

.grid {
  display: flex;
  margin: 0 -24px;
  padding-top: 24px;
}

.column {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 0 24px;
}

.columnTitle {
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 700;
  color: $textDark;
}

.item {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}
