import { FormattedMessage } from 'react-intl';

import Link from './Link/Link';
import {
  IconPrimarySource,
  IconAdGroup,
  IconInterface,
  IconCatalog,
} from '../../../components/icons';

import styles from './RelatedResourcesLinks.module.scss';

const AdRelatedResourceLinks = ({
  adPrimarySourceLink,
  adGroupLink,
  adIdInterfaceLink,
  adInCatalogLink,
}) => {
  if (
    !adPrimarySourceLink &&
    !adGroupLink &&
    !adIdInterfaceLink &&
    !adInCatalogLink
  ) {
    return null;
  }

  return (
    <div className={styles.root}>
      {adPrimarySourceLink && (
        <div className={styles.item}>
          <Link
            title={<FormattedMessage defaultMessage="Source" />}
            icon={<IconPrimarySource />}
            href={adPrimarySourceLink}
          />
        </div>
      )}

      {adGroupLink && (
        <div className={styles.item}>
          <Link
            title={<FormattedMessage defaultMessage="Ad group" />}
            icon={<IconAdGroup />}
            to={adGroupLink}
          />
        </div>
      )}

      {adIdInterfaceLink && (
        <div className={styles.item}>
          <Link
            title={<FormattedMessage defaultMessage="Realty page" />}
            icon={<IconInterface />}
            href={adIdInterfaceLink}
          />
        </div>
      )}

      {adInCatalogLink && (
        <div className={styles.item}>
          <Link
            title={<FormattedMessage defaultMessage="Search results page" />}
            icon={<IconCatalog />}
            href={adInCatalogLink}
          />
        </div>
      )}
    </div>
  );
};

export default AdRelatedResourceLinks;
