import { HISTORY_INTERACTION_TYPE } from '../../constants';
import { deserializeUserPreview } from '../common';

export const serializeTaskComment = (comment) => ({
  text: comment,
  mediaUrls: [],
});

export const deserializeTaskComment = ({ user, author, ...rest }) => ({
  user: deserializeUserPreview(user || author),
  type: HISTORY_INTERACTION_TYPE.COMMENT,
  ...rest,
});
