@import '../../styles/utils';

.root {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: $grey_3;
  line-height: 16px;
  font-size: 12px;
  color: $textDark;
  text-align: center;
  text-transform: lowercase;
  vertical-align: top;
  @include overflowEllipsis;
}

.icon {
  margin-right: 6px;
  line-height: 1;
}
