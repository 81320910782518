@import '../../styles/utils';

.root {
  @extend %normalize-button;
  width: 100%;
  height: 46px;
  padding: 14px 36px;
  box-sizing: border-box;
  border-radius: $primaryBorderRadius;
  background-color: $white;
  line-height: 18px;
  font-size: 16px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  @include defaultTransition(background-color);

  &:hover,
  &:active {
    background-color: $grey_1;
  }
}

.icon {
  display: inline-block;
  height: 18px;
  margin-right: 8px;
}
