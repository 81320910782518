import { FormattedMessage } from 'react-intl';

import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import AdInfoTileSection from '../TileSection/TileSection';
import AdDetailsTable from '../Table/Table';

const IsOwner = ({ is }) => {
  if (!is) {
    return '';
  }

  return is === 'yes' ? (
    <FormattedMessage defaultMessage="yes" />
  ) : (
    <FormattedMessage defaultMessage="no" />
  );
};

const IsExclusive = ({ is }) => {
  if (!is) {
    return '';
  }

  return is === 'unknown' ? (
    <FormattedMessage defaultMessage="no" />
  ) : (
    <FormattedMessage defaultMessage="yes" />
  );
};

const AdMetaInfoTileSection = ({ data, isModerated }) => (
  <AdInfoTileSection
    title={<FormattedMessage defaultMessage="Advertisement" />}
    isModerated={isModerated}
  >
    <AdDetailsTable>
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="title" />}
        {...data.header}
      />

      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="owner" />}
        raw={<IsOwner is={data.isOwner.raw} />}
        recognized={<IsOwner is={data.isOwner.recognized} />}
      />

      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="exclusive rights" />}
        raw={<IsExclusive is={data.isExclusive.raw} />}
        recognized={<IsExclusive is={data.isExclusive.recognized} />}
      />

      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="downloading time" />}
        raw={<FormattedDateTime dateISO={data.insertTime.raw} />}
        recognized={<FormattedDateTime dateISO={data.insertTime.recognized} />}
      />

      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="updating time" />}
        raw={<FormattedDateTime dateISO={data.updateTime.raw} />}
        recognized={<FormattedDateTime dateISO={data.updateTime.recognized} />}
      />

      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="will be removed from DB" />}
        raw={<FormattedDateTime dateISO={data.deleteTime.raw} />}
        recognized={<FormattedDateTime dateISO={data.deleteTime.recognized} />}
      />
    </AdDetailsTable>
  </AdInfoTileSection>
);

export default AdMetaInfoTileSection;
