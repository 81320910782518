.titleAppended {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid {
  display: flex;
  margin: -4px;
}

.column {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 4px;
}
