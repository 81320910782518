import Input from '../Input/Input';
import IconContainer from '../IconContainer/IconContainer';
import { IconZendesk } from '../icons';

import styles from './ZendeskLinkInput.module.scss';

const ZendeskLinkInput = ({ ...rest }) => (
  <Input
    prependedElement={
      <span className={styles.zendeskIcon}>
        <IconContainer size="small">
          <IconZendesk />
        </IconContainer>
      </span>
    }
    isGrey
    {...rest}
  />
);

export default ZendeskLinkInput;
