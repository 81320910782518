import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAtom, useAction } from '@reatom/react';
import { FormattedMessage, useIntl } from 'react-intl';

import Page from '../../components/Page/Page';
import DefaultPageLayout from '../../components/DefaultPageLayout/DefaultPageLayout';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import SearchInput from '../../components/SearchInput/SearchInput';
import UsersPageSubtitleTitleGroup from './SubtitleGroup/SubtitleGroup';
import UsersPageTable from './Table/Table';
import UsersPageStatisticGroup from './StatisticGroup/StatisticGroup';

import * as Dialog from '../../models/dialog';
import * as Users from '../../models/users';
import { useToggle, usePagination, useNotifications } from '../../hooks';
import { DIALOG_NAME, ROLE_TYPE } from '../../constants';
import withRoles from '../../components/PagesScene/withRoles';

const formUsers = (
  activeUsers,
  inactiveUsers,
  isActiveShown,
  isInactiveShown,
) => {
  const users = [];

  if (isActiveShown) users.push(...activeUsers);
  if (isInactiveShown) users.push(...inactiveUsers);

  return users;
};

const UsersPage = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const [search, setSearch] = useState('');
  const { isOn: isActiveShown, toggle: onActiveShownChange } = useToggle(true);
  const { isOn: isInactiveShown, toggle: onInactiveShownChange } = useToggle();

  const activeUsers = useAtom(Users.Showing.activeUsersAtom);
  const inactiveUsers = useAtom(Users.Showing.inactiveUsersAtom);
  const isAllUsersLoading = useAtom(Users.Showing.isUsersLoadingAtom);
  const isAllUsersSucceeded = useAtom(Users.Showing.isUsersSucceededAtom);
  const isAllUserFailed = useAtom(Users.Showing.isUsersFailedAtom);
  const isAllUsersReady = useAtom(Users.Showing.isUsersReadyAtom);

  const users = useMemo(
    () => formUsers(activeUsers, inactiveUsers, isActiveShown, isInactiveShown),
    [activeUsers, inactiveUsers, isActiveShown, isInactiveShown],
  );

  const filteredBySearchUsers = useMemo(
    () =>
      users.filter(
        ({ name, email }) =>
          name.toUpperCase().includes(search.toUpperCase()) ||
          email.toUpperCase().includes(search.toUpperCase()),
      ),
    [users, search],
  );

  const handleSearchChange = useCallback((e) => setSearch(e.target.value), []);

  const handleAddNewUserClick = useAction(() =>
    Dialog.openDialog({ name: DIALOG_NAME.USER_CREATION }),
  );

  const handleUsersShow = useAction(Users.Showing.done);

  useEffect(() => {
    if (!isAllUsersSucceeded) {
      handleUsersShow();
    }
  }, [isAllUsersSucceeded, handleUsersShow]);

  useEffect(() => {
    if (!isAllUserFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load users. Try reloading the page',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllUserFailed, intl.formatMessage, showErrorNotification]);

  const [{ page, perPage, amount }, { onNext, onPrev }] = usePagination({
    amount: filteredBySearchUsers.length,
  });

  const usersByPage = useMemo(
    () => filteredBySearchUsers.slice((page - 1) * perPage, page * perPage),
    [filteredBySearchUsers, page, perPage],
  );

  const handleUserEditingDialogOpen = useAction((userId) =>
    Dialog.openDialog({ name: DIALOG_NAME.USER_EDITING, payload: { userId } }),
  );

  return (
    <FormattedMessage defaultMessage="Users">
      {([helmetTitle]) => (
        <Page helmetTitle={helmetTitle}>
          <DefaultPageLayout>
            <DefaultPageLayout.WideCol>
              <PageMainContentTile
                title={<FormattedMessage defaultMessage="Users" />}
                titleAppended={
                  <SearchInput
                    placeholder={intl.formatMessage({
                      defaultMessage: "user's name or e-mail",
                    })}
                    value={search}
                    onChange={handleSearchChange}
                    isGrey
                  />
                }
                subtitleElement={
                  <UsersPageSubtitleTitleGroup
                    isActiveShown={isActiveShown}
                    isInactiveShown={isInactiveShown}
                    onActiveShownChange={onActiveShownChange}
                    onInactiveShownChange={onInactiveShownChange}
                    onAddNewUserClick={handleAddNewUserClick}
                  />
                }
              >
                <UsersPageTable
                  page={page}
                  perPage={perPage}
                  amount={amount}
                  users={usersByPage}
                  searchValue={search}
                  isLoading={isAllUsersLoading}
                  isEmpty={
                    isAllUserFailed ||
                    (isAllUsersReady && usersByPage?.length < 1)
                  }
                  onUserEditClick={handleUserEditingDialogOpen}
                  onPaginationPrevClick={onPrev}
                  onPaginationNextClick={onNext}
                />
              </PageMainContentTile>
            </DefaultPageLayout.WideCol>

            <DefaultPageLayout.NarrowCol>
              <UsersPageStatisticGroup users={users} />
            </DefaultPageLayout.NarrowCol>
          </DefaultPageLayout>
        </Page>
      )}
    </FormattedMessage>
  );
};

UsersPage.Roles = [ROLE_TYPE.OWNER];
UsersPage.RouteParams = withRoles(UsersPage.Roles)({
  element: <UsersPage />,
  path: '/users',
});

export default UsersPage;
