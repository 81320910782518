const LoadingStatePlaceholder = ({
  isLoading,
  isSucceeded,
  isFailed,
  loading,
  failed,
  children,
}) => {
  if (isLoading) {
    return loading;
  }

  if (isSucceeded) {
    return children;
  }

  if (isFailed) {
    return failed;
  }

  return null;
};

export default LoadingStatePlaceholder;
