import { useLayoutEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { defineMessage, useIntl, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import Form from '../../../Form/Form';
import Field from '../../../Field/Field';
import Button from '../../../Button/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import Dialog from '../../../Dialog/Dialog';
import Fieldset from '../../../Fieldset/Fieldset';
import FormGroup from '../../../FormGroup/FormGroup';
import Input from '../../../Input/Input';
import TaskTypesSwitch from '../../../TaskTypesSwitch/TaskTypesSwitch';
import Radio from '../../../Radio/Radio';
import Media from '../../../Media/Media';

import { ROLE_INFO_BY_TYPE, ROLE_TYPE } from '../../../../constants';
import { checkHasVisibleErrors } from '../../../../utils';
import styles from './UserEditingDialog.module.scss';

const initValues = (user) => {
  const {
    name = '',
    email = '',
    isActive = true,
    role = ROLE_TYPE.MODERATOR,
    taskTypes = [],
  } = user || {};

  return {
    name,
    email,
    isActive,
    role,
    taskTypes,
  };
};

const createValidationSchema = (existingEmails, currentUserEmail) =>
  Yup.object().shape({
    name: Yup.string().required(
      defineMessage({ defaultMessage: 'Name is required' }),
    ),
    email: Yup.string()
      .required(defineMessage({ defaultMessage: 'E-mail is required' }))
      .email(defineMessage({ defaultMessage: 'E-mail is not valid' }))
      .test(
        'check-is-email-already-exist',
        defineMessage({
          defaultMessage: 'User with this e-mail already exists',
        }),
        (email) =>
          !existingEmails
            .filter((email) => email !== currentUserEmail)
            .includes(email),
      ),
  });

const UserCreationDialog = ({
  user,
  allUsersEmails,
  onSubmit,
  onClose,
  isOpened,
}) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: initValues(),
    validationSchema: createValidationSchema(allUsersEmails, user?.email),
    onSubmit: (values) => {
      onClose();
      onSubmit({ ...user, ...values });
    },
  });

  const intl = useIntl();

  const hasVisibleErrors = useMemo(
    () => checkHasVisibleErrors(errors, touched),
    [errors, touched],
  );

  useLayoutEffect(() => {
    resetForm({ values: initValues(user) });
  }, [user, isOpened, resetForm]);

  const actions = useMemo(
    () => [
      <Button
        key="cancel"
        title={<FormattedMessage defaultMessage="Cancel" />}
        variant="flat"
        onClick={onClose}
        withLargeSideInnerGaps
      />,
      <Button
        key="save"
        title={<FormattedMessage defaultMessage="Save" />}
        isDisabled={hasVisibleErrors}
        onClick={handleSubmit}
        withLargeSideInnerGaps
      />,
    ],
    [hasVisibleErrors, handleSubmit, onClose],
  );

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="User editing" />}
      actions={actions}
      isOpened={isOpened}
      onClose={onClose}
    >
      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Name" />}
          error={
            touched.name && errors.name && <FormattedMessage {...errors.name} />
          }
        >
          <Input
            name="name"
            value={values.name}
            prependedElement={
              user?.media && (
                <div className={styles.userAvatar}>
                  <Media media={user?.media} isAdjustable />
                </div>
              )
            }
            onChange={handleChange}
            onBlur={handleBlur}
            isGrey
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="E-mail" />}
          error={
            touched.email &&
            errors.email && <FormattedMessage {...errors.email} />
          }
        >
          <Input
            name="email"
            value={values.email}
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            isGrey
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <FormGroup>
          <FormGroup.HalfItem>
            <Field label={<FormattedMessage defaultMessage="Role" />}>
              <Fieldset>
                {Object.values(ROLE_TYPE).map((type) => (
                  <Fieldset.Item key={type}>
                    <Radio
                      name="role"
                      value={type}
                      label={intl
                        .formatMessage(ROLE_INFO_BY_TYPE[type].title)
                        .toLowerCase()}
                      checked={type === values.role}
                      onChange={handleChange}
                    />
                  </Fieldset.Item>
                ))}
              </Fieldset>
            </Field>
          </FormGroup.HalfItem>

          <FormGroup.HalfItem>
            <Field label={<FormattedMessage defaultMessage="Active user" />}>
              <Checkbox
                name="isActive"
                value={values.isActive}
                onChange={handleChange}
              />
            </Field>
          </FormGroup.HalfItem>
        </FormGroup>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Tasks" />}
          error={touched.taskTypes && errors.taskTypes}
        >
          <TaskTypesSwitch
            name="taskTypes"
            selectedTypes={values.taskTypes}
            onChange={handleChange}
          />
        </Field>
      </Form.Row>
    </Dialog>
  );
};

export default UserCreationDialog;
