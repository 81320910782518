import { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Form from '../../../components/Form/Form';
import Field from '../../../components/Field/Field';
import Select from '../../../components/Select/Select';
import Fieldset from '../../../components/Fieldset/Fieldset';
import InputList from '../../../components/InputList/InputList';
import Radio from '../../../components/Radio/Radio';
import ZendeskLinkInput from '../../../components/ZendeskLinkInput/ZendeskLinkInput';
import CommentTextarea from '../../../components/CommentTextarea/CommentTextarea';
import { IconLink, IconBendedArrowLeft } from '../../../components/icons';

import {
  TASK_TYPE_LIST,
  TASK_TYPE_INFO,
  TASK_SUBTYPES_BY_TYPE,
  SORTED_PRIORITIES,
  PRIORITY_INFO,
  TASK_IDENTIFIER_TYPE_INFO,
  TASK_IDENTIFIER_TYPE,
  TASK_SUBTYPE_INFO,
} from '../../../constants';

const createAdminAdLink = (identifiersType, identifier) => {
  let prefixLink = '';

  switch (identifiersType) {
    case TASK_IDENTIFIER_TYPE.AD_ID:
      prefixLink = 'https://monitor.lun.ua/pages/search?url=';
      break;

    case TASK_IDENTIFIER_TYPE.GROUP_ID:
      prefixLink =
        'https://monitor.lun.ua/moderation/grouper/search?search_type=ann_id&entity_id=';
      break;

    case TASK_IDENTIFIER_TYPE.PHONE_NUMBER:
      prefixLink = 'https://monitor.lun.ua/phones/edit/';
      break;

    default:
      return '';
  }

  return `${prefixLink}${identifier}`;
};

const TASK_TYPE_OPTIONS = TASK_TYPE_LIST.map((type) => ({
  value: type,
  label: <FormattedMessage {...TASK_TYPE_INFO[type].title} />,
}));

const QueuedTaskOverview = ({
  sourceAdUrlByAdId,
  task,
  values,
  touched,
  errors,
  executors = [],
  comment,
  isExecutorsLoading,
  isCommentDisabled,
  setFieldValue,
  onCommentSubmit,
  onCommentChange,
  onChange,
  onSelectChange,
  onBlur,
}) => {
  const subTypeOptions = useMemo(() => {
    if (!TASK_SUBTYPES_BY_TYPE[values.type]) {
      return [];
    }

    return TASK_SUBTYPES_BY_TYPE[values.type]
      .map((slug) => TASK_SUBTYPE_INFO[slug])
      .map(({ slug, title }) => ({
        value: slug,
        label: <FormattedMessage {...title} />,
      }));
  }, [values.type]);

  const executorOptions = executors.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const handleAdditionalResources = useCallback(
    (list) => setFieldValue('additionalResources', list),
    [setFieldValue],
  );

  const handleInputListAppendedButtonClick = useCallback(
    (inputValue) => {
      if (
        task.identifier.type === TASK_IDENTIFIER_TYPE.AD_ID &&
        sourceAdUrlByAdId[inputValue]
      ) {
        window.open(sourceAdUrlByAdId[inputValue]);
      }

      const link = createAdminAdLink(task.identifier.type, inputValue);
      window.open(link);
    },
    [task, sourceAdUrlByAdId],
  );

  const handleCommentChange = useCallback(
    (e) => onCommentChange(e.target.value),
    [onCommentChange],
  );

  const isAdditionalResourcesHighlighted = useMemo(() => {
    if (!task?.additionalResources) {
      return false;
    }

    return task.additionalResources.filter(Boolean).length > 0;
  }, [task]);

  return (
    <>
      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Task type" />}
          error={touched.type && errors.type}
        >
          <Select
            placeholder={<FormattedMessage defaultMessage="Choose task type" />}
            value={values.type}
            options={TASK_TYPE_OPTIONS}
            name="type"
            onChange={onSelectChange}
            onBlur={onBlur}
            theme="blue"
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field label={<FormattedMessage defaultMessage="Task subtype" />}>
          <Select
            value={values.subType}
            options={subTypeOptions}
            name="subType"
            placeholder={
              <FormattedMessage defaultMessage="Choose task subtype" />
            }
            isDisabled={subTypeOptions.length === 0}
            onChange={onSelectChange}
            onBlur={onBlur}
            theme={task?.subType ? 'blue' : 'default'}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field label={<FormattedMessage defaultMessage="Priority" />}>
          <Fieldset>
            {SORTED_PRIORITIES.map((priority) => (
              <Fieldset.Item key={priority}>
                <Radio
                  name="priority"
                  value={priority}
                  label={PRIORITY_INFO[priority].title}
                  checked={priority === values.priority}
                  onChange={onChange}
                />
              </Fieldset.Item>
            ))}
          </Fieldset>
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Zendesk link" />}
          error={touched.zendeskUrl && errors.zendeskUrl}
        >
          <ZendeskLinkInput
            value={values.zendeskUrl}
            name="zendeskUrl"
            onChange={onChange}
            onBlur={onBlur}
            isHighlighted={task?.zendeskUrl}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={
            task?.identifier?.type && (
              <FormattedMessage
                {...TASK_IDENTIFIER_TYPE_INFO[task.identifier.type].title}
              />
            )
          }
        >
          <InputList
            list={task?.identifier?.entities}
            appendedButtonIcon={<IconBendedArrowLeft />}
            isAppendedButtonDisabled={false}
            onAppendedButtonClick={handleInputListAppendedButtonClick}
            isDisabled
            isHighlighted
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Assignee" />}
          error={touched.executorId && errors.executorId}
        >
          <Select
            value={values.executorId}
            options={executorOptions}
            name="executorId"
            placeholder={<FormattedMessage defaultMessage="Assign" />}
            noOptionsMessage={
              isExecutorsLoading ? (
                <FormattedMessage defaultMessage="Loading..." />
              ) : (
                <FormattedMessage defaultMessage="No assignees for this task" />
              )
            }
            isDisabled={!values.type}
            onChange={onSelectChange}
            theme={task?.executor?.id ? 'blue' : 'default'}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Additional resources" />}
          error={touched.additionalResources && errors.additionalResources}
        >
          <InputList
            name="additionalResources"
            type="url"
            list={values.additionalResources}
            prependedIcon={<IconLink />}
            onChange={handleAdditionalResources}
            isHighlighted={isAdditionalResourcesHighlighted}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field label={<FormattedMessage defaultMessage="Comment" />}>
          <CommentTextarea
            value={comment}
            isDisabled={isCommentDisabled}
            onChange={handleCommentChange}
            onSubmit={onCommentSubmit}
          />
        </Field>
      </Form.Row>
    </>
  );
};

export default QueuedTaskOverview;
