@import '../../../../../styles/utils';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  box-sizing: border-box;
  border: 2px solid $grey_2;
  border-radius: $primaryBorderRadius;
  background-color: $white;
  font-size: 16px;
  font-weight: 500;
}
