import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import { deserializeTaskOverview } from '../../normalizers';
import * as Account from '../account';
import * as TaskQueue from '../taskQueue';
import * as TaskComment from './comment';
import * as TaskEditing from './editing';

const shownSucceeded = declareAction('Tasks/Overview/shownSucceeded');
const shownFailed = declareAction('Tasks/Overview/shownFailed');

export const shown = declareAction(
  'Tasks/Overview/shown',
  async ({ taskId }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const taskOverview = await api(authorization).tasks.overview(taskId);

      store.dispatch(
        shownSucceeded({
          task: deserializeTaskOverview(taskOverview),
        }),
      );
    } catch (error) {
      store.dispatch(
        shownFailed({
          taskId,
        }),
      );
      console.error(error);
    }
  },
);

const tableAtom = declareAtom('Tasks/Overview/tableAtom', {}, (on) => [
  on(shown, (state, { taskId }) => ({ ...state, [taskId]: null })),

  on(shownSucceeded, (state, { task }) => ({
    ...state,
    [task?.id]: task,
  })),

  on(TaskQueue.Showing.doneSucceeded, (state, { task }) => {
    if (!task) {
      return state;
    }

    return {
      ...state,
      [task.id]: task,
    };
  }),

  on(TaskQueue.Resolving.doneSucceeded, (state, { finishedTaskId, task }) => {
    const finalState = { ...state };
    delete finalState[finishedTaskId];

    if (task) {
      finalState[task.id] = task;
    }

    return finalState;
  }),

  on(TaskQueue.Resuming.doneSucceeded, (state, { finishedTaskId, task }) => {
    const finalState = { ...state };
    delete finalState[finishedTaskId];

    if (task) {
      finalState[task.id] = task;
    }

    return finalState;
  }),

  on(TaskQueue.Skip.doneSucceeded, (state, { finishedTaskId, task }) => {
    const finalState = { ...state };
    delete finalState[finishedTaskId];

    if (task) {
      finalState[task.id] = task;
    }

    return finalState;
  }),

  on(TaskComment.saveSucceeded, (state, { taskId, comment }) => ({
    ...state,
    [taskId]: {
      ...state[taskId],
      interactionHistory: {
        ...state[taskId].interactionHistory,
        comments: [...state[taskId].interactionHistory.comments, comment],
      },
    },
  })),

  on(TaskEditing.saveSucceeded, (state, { taskId }) => ({
    ...state,
    [taskId]: null,
  })),
]);

export const makeTaskOverviewAtom = (taskId) =>
  map(tableAtom, (overview) => {
    const taskOverview = overview[taskId];

    if (!taskOverview) {
      return null;
    }

    const { creation, comments, fieldChanges, solutions } =
      taskOverview.interactionHistory;
    const historySortedByTimestamp = [
      creation,
      ...comments,
      ...fieldChanges,
      ...solutions,
    ].sort(
      (firstInteraction, secondInteraction) =>
        new Date(firstInteraction.timestamp) -
        new Date(secondInteraction.timestamp),
    );

    return { ...taskOverview, interactionHistory: historySortedByTimestamp };
  });

export const makeCommentsAmount = (taskId) =>
  map(tableAtom, (table) => {
    const overview = table[taskId];

    if (!overview) {
      return 0;
    }

    return overview.interactionHistory.comments.length;
  });

const shownLoadingStateAtom = declareAtom(
  'Tasks/Overview/shownLoadingStateAtom',
  {},
  (on) => [
    on(shown, (state, { taskId }) => ({
      ...state,
      [taskId]: LOADING_STATE.LOADING,
    })),
    on(shownSucceeded, (state, { task }) => ({
      ...state,
      [task.id]: LOADING_STATE.SUCCEEDED,
    })),
    on(shownFailed, (state, { taskId }) => ({
      ...state,
      [taskId]: LOADING_STATE.FAILED,
    })),

    on(TaskQueue.Showing.doneSucceeded, (state, { task }) => {
      if (!task) {
        return state;
      }

      return {
        ...state,
        [task.id]: LOADING_STATE.SUCCEEDED,
      };
    }),

    on(TaskQueue.Resolving.doneSucceeded, (state, { finishedTaskId, task }) => {
      const finalState = { ...state };
      finalState[finishedTaskId] = LOADING_STATE.IDLE;

      if (task) {
        finalState[task.id] = LOADING_STATE.SUCCEEDED;
        return state;
      }

      return finalState;
    }),

    on(TaskQueue.Skip.doneSucceeded, (state, { finishedTaskId, task }) => {
      const finalState = { ...state };
      finalState[finishedTaskId] = LOADING_STATE.IDLE;

      if (task) {
        finalState[task.id] = LOADING_STATE.SUCCEEDED;
        return state;
      }

      return finalState;
    }),

    on(TaskQueue.Resuming.doneSucceeded, (state, { finishedTaskId, task }) => {
      const finalState = { ...state };
      finalState[finishedTaskId] = LOADING_STATE.IDLE;

      if (task) {
        finalState[task.id] = LOADING_STATE.SUCCEEDED;
        return state;
      }

      return finalState;
    }),

    on(TaskEditing.saveSucceeded, (state, { taskId }) => ({
      ...state,
      [taskId]: LOADING_STATE.IDLE,
    })),
  ],
);

export const loadingTaskIdsAtom = map(shownLoadingStateAtom, (loadingTable) =>
  Object.entries(loadingTable)
    .filter(([taskId, value]) => value === LOADING_STATE.LOADING)
    .map(([taskId]) => taskId),
);

export const makeIsShownLoadingAtom = (taskId) =>
  map(
    shownLoadingStateAtom,
    (loadingState) => loadingState[taskId] === LOADING_STATE.LOADING,
  );

export const makeIsShownSucceededAtom = (taskId) =>
  map(
    shownLoadingStateAtom,
    (loadingState) => loadingState[taskId] === LOADING_STATE.SUCCEEDED,
  );

export const makeIsShownFailedAtom = (taskId) =>
  map(
    shownLoadingStateAtom,
    (loadingState) => loadingState[taskId] === LOADING_STATE.FAILED,
  );

export const makeIsShownReadyAtom = (taskId) =>
  map(
    combine([makeIsShownSucceededAtom(taskId), makeIsShownFailedAtom(taskId)]),
    ([isSucceeded, isFailed]) => isSucceeded || isFailed,
  );

export default combine({
  tableAtom,
  shownLoadingStateAtom,
});
