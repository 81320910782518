import { isValidPhoneNumber } from 'libphonenumber-js';
import { FormattedMessage, useIntl } from 'react-intl';

import GeneralSearchAutosuggest from './AdjustedAutosuggest/AdjustedAutosuggest';

import { SEARCH_ENTITY_TYPE, SEARCH_ENTITY_TYPE_INFO } from '../../constants';
import { useEffect, useMemo } from 'react';

const URL_REGEXP =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

const recognizeSearchEntityType = (value) => {
  const trimmedValue = value.trim();

  if (
    /^\d{9,10}$/.test(trimmedValue) ||
    /^https:\/\/flatfy.[a-z]{2,3}\/(realty|redirect)\/\d{9}\/?$/.test(
      trimmedValue,
    )
  ) {
    return SEARCH_ENTITY_TYPE.AD_ID;
  }

  if (URL_REGEXP.test(trimmedValue)) {
    return SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK;
  }

  if (isValidPhoneNumber(trimmedValue, 'UA')) {
    return SEARCH_ENTITY_TYPE.PHONE;
  }

  return '';
};

const hoistRecognizedEntity = (options, recognizedSearchEntityType, hint) => {
  if (!recognizedSearchEntityType) {
    return options;
  }

  const option = options.find(
    ({ value }) => value === recognizedSearchEntityType,
  );
  return [
    { ...option, hint },
    ...options.filter(({ value }) => value !== recognizedSearchEntityType),
  ];
};

const getSearchEntityHint = (searchValue, recognizeSearchEntityType) => {
  switch (recognizeSearchEntityType) {
    case SEARCH_ENTITY_TYPE.AD_ID: {
      return searchValue.match(/\d{9,10}/)[0] || '';
    }

    default:
      return '';
  }
};

const GeneralSearchField = ({
  value = '',
  inputRef,
  onInputChange,
  onEntityTypeSelect,
}) => {
  const intl = useIntl();

  const options = useMemo(
    () => [
      {
        value:
          SEARCH_ENTITY_TYPE_INFO[SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK].slug,
        label: intl.formatMessage(
          SEARCH_ENTITY_TYPE_INFO[SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK].title,
        ),
      },
      {
        value: SEARCH_ENTITY_TYPE_INFO[SEARCH_ENTITY_TYPE.AD_ID].slug,
        label: intl.formatMessage(
          SEARCH_ENTITY_TYPE_INFO[SEARCH_ENTITY_TYPE.AD_ID].title,
        ),
      },
      {
        value: SEARCH_ENTITY_TYPE_INFO[SEARCH_ENTITY_TYPE.PHONE].slug,
        label: intl.formatMessage(
          SEARCH_ENTITY_TYPE_INFO[SEARCH_ENTITY_TYPE.PHONE].title,
        ),
      },
    ],
    [intl],
  );

  const handleOptionSelect = ({ value }) => onEntityTypeSelect(value);

  const recognizedSearchEntityType = recognizeSearchEntityType(value);
  const optionsWithHoistedRecognizedEntity = hoistRecognizedEntity(
    options,
    recognizedSearchEntityType,
    getSearchEntityHint(value, recognizedSearchEntityType),
  );

  const onAutosuggestInputRefSet = (autosuggestRef) => {
    if (!inputRef) {
      return;
    }

    inputRef.current = autosuggestRef?.input;
  };

  return (
    <GeneralSearchAutosuggest
      options={optionsWithHoistedRecognizedEntity}
      inputValue={value}
      inputRef={onAutosuggestInputRefSet}
      highlightFirstSuggestion={Boolean(recognizedSearchEntityType)}
      onInputChange={onInputChange}
      onSelect={handleOptionSelect}
    />
  );
};

export default GeneralSearchField;
