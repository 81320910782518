import { defineMessage } from 'react-intl';

export const CALL_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  INACTION: 'inaction',
};

export const CALL_STATUS_INFO = {
  [CALL_STATUS.SUCCESS]: {
    slug: CALL_STATUS.SUCCESS,
    title: defineMessage({ defaultMessage: 'Successfully dialed' }),
  },

  [CALL_STATUS.FAILURE]: {
    slug: CALL_STATUS.FAILURE,
    title: defineMessage({ defaultMessage: "Didn't get through" }),
  },

  [CALL_STATUS.INACTION]: {
    slug: CALL_STATUS.INACTION,
    title: defineMessage({ defaultMessage: "Don't call" }),
  },
};

export const CALL_RESULT = {
  IRRELEVANT: 'irrelevant',
  RELEVANT: 'relevant',
  CALLBACK: 'callback',
  CALL_IN_ONE_HOUR: 'call_in_one_hour',
  CALL_IN_TWO_HOURS: 'call_in_two_hours',
  CALL_TOMORROW: 'call_tomorrow',
  CALL_DAY_AFTER_TOMORROW: 'call_day_after_tomorrow',
  BLOCK: 'block',
  DROP_WITHOUT_CALL: 'drop_without_call',
  AGENCY_HOTLINE: 'agency_hotline',
  NOT_ACTIVE: 'not_active',
  OTHER: 'other',
};

export const CALL_RESULT_INFO = {
  [CALL_RESULT.IRRELEVANT]: {
    slug: CALL_RESULT.IRRELEVANT,
    title: defineMessage({ defaultMessage: 'Rented/selection of options' }),
  },

  [CALL_RESULT.RELEVANT]: {
    slug: CALL_RESULT.RELEVANT,
    title: defineMessage({ defaultMessage: 'Actual' }),
  },

  [CALL_RESULT.CALLBACK]: {
    slug: CALL_RESULT.CALLBACK,
    title: defineMessage({ defaultMessage: 'Call back' }),
  },

  [CALL_RESULT.CALL_IN_ONE_HOUR]: {
    slug: CALL_RESULT.CALL_IN_ONE_HOUR,
    title: defineMessage({ defaultMessage: 'Call back in an hour' }),
  },

  [CALL_RESULT.CALL_IN_TWO_HOURS]: {
    slug: CALL_RESULT.CALL_IN_TWO_HOURS,
    title: defineMessage({ defaultMessage: 'Call back in two hours' }),
  },

  [CALL_RESULT.CALL_TOMORROW]: {
    slug: CALL_RESULT.CALL_TOMORROW,
    title: defineMessage({ defaultMessage: 'Call back tomorrow' }),
  },

  [CALL_RESULT.CALL_DAY_AFTER_TOMORROW]: {
    slug: CALL_RESULT.CALL_DAY_AFTER_TOMORROW,
    title: defineMessage({
      defaultMessage: 'Call back the day after tomorrow',
    }),
  },

  [CALL_RESULT.BLOCK]: {
    slug: CALL_RESULT.BLOCK,
    title: defineMessage({
      defaultMessage: 'Spammer/block number',
    }),
  },

  [CALL_RESULT.DROP_WITHOUT_CALL]: {
    slug: CALL_RESULT.DROP_WITHOUT_CALL,
    title: defineMessage({
      defaultMessage: 'Can be dropped without calling',
    }),
  },

  [CALL_RESULT.AGENCY_HOTLINE]: {
    slug: CALL_RESULT.AGENCY_HOTLINE,
    title: defineMessage({
      defaultMessage: 'Real estate hotline number',
    }),
  },

  [CALL_RESULT.NOT_ACTIVE]: {
    slug: CALL_RESULT.NOT_ACTIVE,
    title: defineMessage({
      defaultMessage: 'No longer active',
    }),
  },

  [CALL_RESULT.OTHER]: {
    slug: CALL_RESULT.OTHER,
    title: defineMessage({
      defaultMessage: 'Other (leave a comment in the task)',
    }),
  },
};

export const CALL_RESULTS_BY_STATUS = {
  [CALL_STATUS.SUCCESS]: [
    CALL_RESULT.IRRELEVANT,
    CALL_RESULT.RELEVANT,
    CALL_RESULT.CALLBACK,
  ],

  [CALL_STATUS.FAILURE]: [
    CALL_RESULT.CALL_IN_ONE_HOUR,
    CALL_RESULT.CALL_IN_TWO_HOURS,
    CALL_RESULT.CALL_TOMORROW,
    CALL_RESULT.CALL_DAY_AFTER_TOMORROW,
  ],

  [CALL_STATUS.INACTION]: [
    CALL_RESULT.BLOCK,
    CALL_RESULT.DROP_WITHOUT_CALL,
    CALL_RESULT.NOT_ACTIVE,
    CALL_RESULT.AGENCY_HOTLINE,
    CALL_RESULT.OTHER,
  ],
};
