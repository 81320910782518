@import '../../../../styles/utils';

.root {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  border-radius: 0 24px 24px 0;
  padding-left: 16px;
  color: $textDarkGrey;
  text-decoration: none;
  @include defaultTransition(opacity, color, background-color);

  &:hover {
    color: $primaryBlue;
  }

  &_active {
    pointer-events: none;
    background-color: $primaryBlue;
    color: #fff;
  }
}

.icon {
  display: inline-block;
  margin-right: 24px;
}

.title {
  font-size: 16px;
}
