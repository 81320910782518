import { FormattedMessage, useIntl } from 'react-intl';

import Table from '../../../components/Table/Table';
import UserPlate from '../../../components/UserPlate/UserPlate';
import SearchHighlighter from '../../../components/SearchHighlighter/SearchHighlighter';
import ButtonEdit from '../../../components/ButtonEdit/ButtonEdit';
import CheckMark from '../../../components/CheckMark/CheckMark';
import UsersTableTaskTypes from './TaskTypes/TaskTypes';
import UsersTableSimpleText from './SimpleText/SimpleText';

import { ROLE_INFO_BY_TYPE } from '../../../constants';

const columns = [
  { name: 'userPreview', width: 'auto', minWidth: '196px', isFlexible: true },
  { name: 'email', width: '164px', isFlexible: true },
  { name: 'role', width: '96px', isFlexible: true },
  { name: 'taskTypes', width: '180px', isFlexible: true },
  { name: 'isActive', width: '72px', isFlexible: false },
  { name: 'edit', width: '56px', isFlexible: false },
];

const UsersPageTable = ({
  users = [],
  searchValue,
  isReady,
  isFailed,
  onUserEditClick,
  ...rest
}) => {
  const intl = useIntl();

  const handleEditClick = (userId) => () => onUserEditClick(userId);

  return (
    <Table
      columns={columns}
      emptyText={<FormattedMessage defaultMessage="No users" />}
      headerRow={
        <Table.HeaderRow>
          <Table.HeaderCell
            name="userPreview"
            title={<FormattedMessage defaultMessage="Name" />}
          />
          <Table.HeaderCell
            name="email"
            title={<FormattedMessage defaultMessage="E-mail" />}
          />
          <Table.HeaderCell
            name="role"
            title={<FormattedMessage defaultMessage="Role" />}
          />
          <Table.HeaderCell
            name="taskTypes"
            title={<FormattedMessage defaultMessage="Tasks" />}
          />
          <Table.HeaderCell
            name="isActive"
            title={<FormattedMessage defaultMessage="Active user" />}
          />
          <Table.HeaderCell name="edit" />
        </Table.HeaderRow>
      }
      {...rest}
    >
      {users.map(
        ({ id, name, media, email, role, taskTypes, isActive, _isAdding }) => (
          <Table.Row
            key={id}
            isTranslucent={!isActive}
            isVerticallyCenterAligned={false}
          >
            <Table.Cell name="userPreview">
              <UserPlate
                name={<SearchHighlighter search={searchValue} text={name} />}
                media={media}
                search={searchValue}
              />
            </Table.Cell>

            <Table.Cell name="email">
              <UsersTableSimpleText
                text={<SearchHighlighter search={searchValue} text={email} />}
              />
            </Table.Cell>

            <Table.Cell name="role">
              <UsersTableSimpleText
                text={intl
                  .formatMessage(ROLE_INFO_BY_TYPE[role].title)
                  .toLowerCase()}
              />
            </Table.Cell>

            <Table.Cell name="taskTypes">
              {taskTypes.length !== 0 && (
                <UsersTableTaskTypes list={taskTypes} />
              )}
            </Table.Cell>

            <Table.Cell name="isActive">
              <CheckMark isOn={isActive} />
            </Table.Cell>

            <Table.Cell
              name="edit"
              horizontalAlignment="center"
              isVisibleWhenRowTranslucent
            >
              <ButtonEdit
                onClick={handleEditClick(id)}
                isDisabled={_isAdding}
              />
            </Table.Cell>
          </Table.Row>
        ),
      )}
    </Table>
  );
};

export default UsersPageTable;
