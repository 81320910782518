import Svg from '../Svg/Svg';

const IconStatistic = () => (
  <Svg>
    <path d="M4.641 18.258c.147.1.712.381 1.188-.221l3.23-4.72 3.418 3.297c.18.172.427.264.678.235a.868.868 0 00.619-.36l5.668-8.129.205 2.72c.05.69.699.803.916.786a.853.853 0 00.787-.916l-.36-4.782a.85.85 0 00-.916-.786l-4.782.36a.85.85 0 00-.786.916c.033.468.44.82.916.786l2.72-.205-5.197 7.447L9.52 11.38a.835.835 0 00-.677-.234.849.849 0 00-.62.368L4.42 17.07a.854.854 0 00.221 1.188z" />
    <path d="M21.396 20.543H3.457V2.604c0-.473-.38-.853-.853-.853s-.853.38-.853.853v18.792c0 .473.38.853.853.853h18.792c.473 0 .853-.38.853-.853a.852.852 0 00-.853-.853z" />
  </Svg>
);

export default IconStatistic;
