import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import Media from '../../Media/Media';
import LinksHighlighter from '../../LinksHighlighter/LinksHighlighter';
import IconContainer from '../../IconContainer/IconContainer';
import { IconComment } from '../../icons';

import styles from './CommentMessage.module.scss';

const CommentMessage = ({ user, timestamp, text }) => (
  <div className={styles.root}>
    <div className={styles.metaRow}>
      <div className={styles.subjectRow}>
        <div className={styles.userMedia}>
          <Media media={user.media} />
        </div>

        <div className={styles.actionHolder}>
          <div className={styles.action}>
            {user.name}{' '}
            <b>
              <FormattedMessage defaultMessage="added comment" />
            </b>
          </div>
        </div>
      </div>

      <div className={styles.dateRow}>
        <span className={styles.commentIcon}>
          <IconContainer size="xsmall">
            <IconComment />
          </IconContainer>
        </span>

        <span className={styles.date}>
          <FormattedDate value={timestamp} />
        </span>
        <span>
          {' '}
          <FormattedTime value={timestamp} />
        </span>
      </div>
    </div>

    <div className={styles.text}>
      <LinksHighlighter>{text}</LinksHighlighter>
    </div>
  </div>
);

export default CommentMessage;
