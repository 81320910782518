import { FormattedMessage, useIntl } from 'react-intl';

import SearchInput from '../../SearchInput/SearchInput';
import IconContainer from '../../IconContainer/IconContainer';
import { IconId, IconLink, IconPhone } from '../../icons';
import GeneralSearchAutosuggest from './Autosuggest/Autosuggest';

import { SEARCH_ENTITY_TYPE } from '../../../constants';
import styles from './AdjustedAutosuggest.module.scss';

const MIN_VALUE_LENGTH_TO_SHOW_OPTIONS = 5;

const ICON_BY_SEARCH_ENTITY_TYPE = {
  [SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK]: <IconLink />,
  [SEARCH_ENTITY_TYPE.AD_ID]: <IconId />,
  [SEARCH_ENTITY_TYPE.PHONE]: <IconPhone />,
};

const getShouldRenderSuggestionProps = (inputValue) => {
  if (inputValue.length >= MIN_VALUE_LENGTH_TO_SHOW_OPTIONS) {
    return {};
  }

  return {
    shouldRenderSuggestions: () => false,
  };
};

const GeneralSearchAdjustedAutosuggestOption = ({ value, label, hint }) => (
  <span className={styles.option}>
    <span className={styles.optionIcon}>
      <IconContainer size="small" icon={ICON_BY_SEARCH_ENTITY_TYPE[value]} />
    </span>

    <span className={styles.optionText}>
      {label.toLowerCase()}

      {hint && (
        <span>
          {' '}
          — <span className={styles.highlightedOptionText}>{hint}</span>
        </span>
      )}
    </span>
  </span>
);

const GeneralSearchAdjustedAutosuggest = ({ inputValue, ...rest }) => {
  const intl = useIntl();

  const shouldRenderSuggestionsProps =
    getShouldRenderSuggestionProps(inputValue);

  return (
    <GeneralSearchAutosuggest
      placeholder={intl.formatMessage({
        defaultMessage:
          'search by link/ ad id/ phone number/ ad group id - minimum 5 characters',
      })}
      inputValue={inputValue}
      renderInputComponent={({ className, ...rest }) => (
        <SearchInput {...rest} />
      )}
      renderSuggestion={GeneralSearchAdjustedAutosuggestOption}
      {...shouldRenderSuggestionsProps}
      {...rest}
    />
  );
};

export default GeneralSearchAdjustedAutosuggest;
