import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import GeneralSearchField from '../../GeneralSearchField/GeneralSearchField';
import HeaderAccountPlate from './AccountPlate/AccountPlate';

import { getSearchPageLink } from '../../../routes';
import styles from './Header.module.scss';

const Header = ({
  user,
  searchValue,
  onSearchChange,
  onSearchEntityTypeSelect,
  onSignOut,
}) => {
  const inputRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== getSearchPageLink()) {
      return;
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [pathname]);

  return (
    <header className={styles.root}>
      <div className={styles.input}>
        <GeneralSearchField
          inputRef={inputRef}
          value={searchValue}
          onInputChange={onSearchChange}
          onEntityTypeSelect={onSearchEntityTypeSelect}
        />
      </div>

      <div className={styles.userPlate}>
        <HeaderAccountPlate
          name={user.name}
          role={user.role}
          media={user.media}
          onSignOut={onSignOut}
        />
      </div>
    </header>
  );
};

export default Header;
