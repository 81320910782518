.root {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  &_isVerticallyGrowing {
    flex: 1 1 auto;
  }
}

.title {
  margin: 0;
  margin-bottom: 16px;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 700;
}
