import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAtom, useAction } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Dialog from '../models/dialog';
import * as Tasks from '../models/tasks';
import * as Account from '../models/account';
import { useQueryParams, useNotifications } from '../hooks';
import { DIALOG_NAME } from '../constants';

const TaskOverviewDialogsContext = createContext();

const TaskOverviewDialogsProvider = ({ children }) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const [{ taskId }, { setQueryParams, removeQueryParams }] = useQueryParams();
  const [payload, setPayload] = useState(null);

  const isAccountSucceeded = useAtom(Account.isSucceededAtom);

  const taskOverview = useAtom(Tasks.Overview.makeTaskOverviewAtom(taskId));
  const isTaskOverviewLoading = useAtom(
    Tasks.Overview.makeIsShownLoadingAtom(taskId),
  );
  const isTaskOverviewSucceeded = useAtom(
    Tasks.Overview.makeIsShownSucceededAtom(taskId),
  );
  const isTaskOverviewFailed = useAtom(
    Tasks.Overview.makeIsShownFailedAtom(taskId),
  );

  useEffect(() => {
    if (!isTaskOverviewFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load task data',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskOverviewFailed, intl.formatMessage, showErrorNotification]);

  const openedDialog = useAtom(Dialog.openedNameAtom);
  const isOverviewDialogOpened =
    openedDialog === DIALOG_NAME.CLOSED_TASK_OVERVIEW ||
    openedDialog === DIALOG_NAME.TASK_EDITING;

  const handleTaskOverviewShow = useAction(Tasks.Overview.shown);

  const handleTaskEditingDialogOpen = useAction(
    (taskId, payload) =>
      Dialog.openDialog({
        name: DIALOG_NAME.TASK_EDITING,
        payload: { taskId, ...payload },
      }),
    [payload],
  );

  const handleClosedTaskOverviewDialogOpen = useAction(
    (taskId, payload) =>
      Dialog.openDialog({
        name: DIALOG_NAME.CLOSED_TASK_OVERVIEW,
        payload: { taskId, ...payload },
      }),
    [],
  );

  const handleDialogClose = useAction(Dialog.closeDialog);

  const handleDialogOpen = useCallback(
    (taskId, isTaskCompleted, payload) => {
      if (isTaskCompleted) {
        handleClosedTaskOverviewDialogOpen(taskId, payload);
        return;
      }

      handleTaskEditingDialogOpen(taskId, payload);
    },
    [handleClosedTaskOverviewDialogOpen, handleTaskEditingDialogOpen],
  );

  const openDialog = useCallback(
    (taskId, payload = null) => {
      setPayload(payload);
      setQueryParams({ taskId });
    },
    [setQueryParams],
  );

  const closeDialog = useCallback(() => {
    removeQueryParams(['taskId']);
    handleDialogClose();
    setPayload(null);
  }, [handleDialogClose, removeQueryParams]);

  useEffect(() => {
    if (isTaskOverviewFailed) {
      removeQueryParams(['taskId']);
    }
  }, [isTaskOverviewFailed, removeQueryParams]);

  useEffect(() => {
    if (!isAccountSucceeded) {
      return;
    }

    if (!taskId) {
      return;
    }

    if (isTaskOverviewLoading || isTaskOverviewSucceeded) {
      return;
    }

    handleTaskOverviewShow({ taskId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, isAccountSucceeded, handleTaskOverviewShow]);

  useEffect(() => {
    if (!taskOverview || isOverviewDialogOpened || !taskId) {
      return;
    }

    handleDialogOpen(taskOverview.id, taskOverview.isCompleted, payload);
  }, [
    taskId,
    taskOverview,
    payload,
    isAccountSucceeded,
    isOverviewDialogOpened,
    handleDialogOpen,
  ]);

  useEffect(() => {
    if (!taskId && isOverviewDialogOpened) {
      closeDialog();
    }
  }, [taskId, isOverviewDialogOpened, closeDialog]);

  const value = useMemo(
    () => ({ open: openDialog, close: closeDialog }),
    [openDialog, closeDialog],
  );

  return (
    <TaskOverviewDialogsContext.Provider value={value}>
      {children}
    </TaskOverviewDialogsContext.Provider>
  );
};

export const useTaskOverviewDialog = () =>
  useContext(TaskOverviewDialogsContext);
export default TaskOverviewDialogsProvider;
