import { useCallback, useMemo, useRef } from 'react';
import ReactSelect from 'react-select';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { checkIsFunction } from '../../utils';
import './Select.scss';

const useInputChange = (handler) => {
  const valueRef = useRef('');

  if (!handler) {
    return;
  }

  const handleInputChange = (_value) => {
    if (_value === valueRef.current) {
      return;
    }

    valueRef.current = _value;
    handler(_value);
  };

  return handleInputChange;
};

const Select = ({
  value,
  name,
  options,
  className = '',
  classNamePrefix = 'Select',
  noOptionsMessage = <FormattedMessage defaultMessage="No options" />,
  onChange,
  onBlur,
  onInputChange,
  theme = 'default',
  ...rest
}) => {
  const noOptionsMessageGetter = useCallback(
    () => noOptionsMessage,
    [noOptionsMessage],
  );

  const handleChange = useCallback(
    ({ value }, { name }) => onChange(value, name),
    [onChange],
  );

  const handleBlur = useCallback(
    (e) => {
      if (name) {
        e.target.name = name;
      }

      if (checkIsFunction(onBlur)) {
        onBlur(e);
      }
    },
    [name, onBlur],
  );

  const normalizedValue = useMemo(
    () =>
      options.find(({ value: optionValue }) => optionValue === value) || null,
    [options, value],
  );

  const handleInputChange = useInputChange(onInputChange);

  return (
    <ReactSelect
      className={cx(className, 'Select', `Select__theme_${theme}`)}
      classNamePrefix={classNamePrefix}
      name={name}
      value={normalizedValue}
      options={options}
      noOptionsMessage={noOptionsMessageGetter}
      onBlur={handleBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      maxMenuHeight="200px"
      {...rest}
    />
  );
};

export default Select;
