import AreaClosed from '../../../components/AreaClosed/AreaClosed';
import styles from './AreaClosedPlate.module.scss';

const AreaClosedPlate = ({ title, stock }) => (
  <div>
    <div className={styles.title}>{title}</div>

    <div>
      <AreaClosed stock={stock} height={196} />
    </div>
  </div>
);

export default AreaClosedPlate;
