import { declareAtom, declareAction, combine, map } from '@reatom/core';

import api from '../../services/api';
import {
  deserializeCallPreview,
  serializeCallsFilters,
} from '../../normalizers';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';

const shownSucceeded = declareAction('Calls/PreviewList/shownSucceeded');
const shownFailed = declareAction('Calls/PreviewList/shownFailed');

export const shown = declareAction(
  'Calls/PreviewList/shown',
  async ({ page, perPage, searchQuery, filters }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const {
        calls,
        page: requestedPage,
        amount,
      } = await api(authorization).calls.getAll({
        page,
        perPage,
        search: searchQuery,
        filters: serializeCallsFilters(filters),
      });

      store.dispatch(
        shownSucceeded({
          calls: calls.map(deserializeCallPreview),
          page: requestedPage,
          searchQuery,
          filters,
          amount,
        }),
      );
    } catch (error) {
      console.error(error);
      store.dispatch(
        shownFailed({
          page,
        }),
      );
    }
  },
);

export const resetCallPreviewList = declareAction(
  'Calls/PreviewList/resetCallPreviewList',
);

const shownTableInitialState = {
  callsByPage: {},

  selectedOptions: {
    searchQuery: '',
    filters: {},
  },
};

export const shownTableAtom = declareAtom(
  'Calls/PreviewList/shownTableAtom',
  shownTableInitialState,
  (on) => [
    on(shown, (state, { page, searchQuery, filters }) => ({
      callsByPage: {
        ...state.callsByPage,
        [page]: [],
      },
      selectedOptions: { filters, searchQuery },
    })),

    on(shownSucceeded, (state, { page, calls, searchQuery, filters }) => {
      if (
        searchQuery !== state.selectedOptions.searchQuery ||
        filters !== state.selectedOptions.filters
      ) {
        return state;
      }

      return { ...state, callsByPage: { ...state.callsByPage, [page]: calls } };
    }),

    on(resetCallPreviewList, () => shownTableInitialState),
  ],
);

export const searchQueryAtom = map(
  shownTableAtom,
  ({ selectedOptions }) => selectedOptions.searchQuery,
);

export const makeTaskPreviewListAtom = (page) =>
  map(shownTableAtom, ({ callsByPage }) => callsByPage[page] || []);

const shownLoadingStateAtom = declareAtom(
  'Calls/PreviewList/shownLoadingStateAtom',
  {},
  (on) => [
    on(shown, (state, { page }) => ({
      ...state,
      [page]: LOADING_STATE.LOADING,
    })),
    on(shownSucceeded, (state, { page }) => ({
      ...state,
      [page]: LOADING_STATE.SUCCEEDED,
    })),
    on(shownFailed, (state, { page }) => ({
      ...state,
      [page]: LOADING_STATE.FAILED,
    })),
    on(resetCallPreviewList, () => ({})),
  ],
);

export const makeShownLoadingStateAtom = (page) =>
  map(shownLoadingStateAtom, (ls) => ls[page] || LOADING_STATE.IDLE);

export const makeIsShownLoadingAtom = (page) =>
  map(makeShownLoadingStateAtom(page), (ls) => ls === LOADING_STATE.LOADING);

const makeIsShownSucceededAtom = (page) =>
  map(makeShownLoadingStateAtom(page), (ls) => ls === LOADING_STATE.SUCCEEDED);

export const makeIsShownFailedAtom = (page) =>
  map(makeShownLoadingStateAtom(page), (ls) => ls === LOADING_STATE.FAILED);

export const makeIsShownReadyAtom = (page) =>
  map(
    combine([makeIsShownSucceededAtom(page), makeIsShownFailedAtom(page)]),
    ([isSucceeded, isFailed]) => isSucceeded || isFailed,
  );

export const shownTotalAmountAtom = declareAtom(
  'Calls/PreviewList/shownTotalAmountAtom',
  null,
  (on) => [
    on(shown, () => null),
    on(shownSucceeded, (state, { amount }) => amount),
    on(resetCallPreviewList, () => null),
  ],
);

export default combine({
  shownTableAtom,
  shownTotalAmountAtom,
  shownLoadingStateAtom,
});
