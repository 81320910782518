@import '../../../styles/utils';

.warningIcon {
  color: #f7ca45;
}

.count {
  padding: 4px 8px;
  border-radius: $secondaryBorderRadius;
  background-color: $primaryBlue;
  color: $white;
  font-size: 10px;
}
