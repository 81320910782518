@import '../../../styles/utils.scss';

.root {
  padding: 8px;
  padding-right: 12px;
  border-radius: $primaryBorderRadius;
  background-color: $white;
  color: $black;
  font-size: 14px;

  &_highlighted {
    background-color: rgba(62, 126, 255, 0.1);
    border: 1px solid $primaryBlue;
  }
}

.metaRow {
  display: flex;
}

.subjectRow {
  flex: 1 1 auto;
  margin-right: 8px;
  display: flex;
  overflow: hidden;
}

.userMedia {
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 32px;
  overflow: hidden;
}

.actionHolder {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.action {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
}

.dateTime {
  padding-top: 4px;
  flex: 0 0 auto;
  color: $grey_3;
  font-size: 12px;
  letter-spacing: -0.3px;
}

.date {
  position: relative;
  margin-right: 7px;

  &::after {
    content: '';
    position: absolute;
    right: -4px;
    top: 2px;
    bottom: 2px;
    border-right: 1px solid currentColor;
  }
}
