import {
  processResponse,
  getQueryString,
  getUrlWithQueryString,
} from '../../utils';
import createAccountService from './account';
import createUsersService from './users';
import createTasksService from './tasks';
import createCallsService from './calls';
import createUsersStatisticService from './usersStatistic';
import createAdsStatisticService from './adsStatistic';
import createSearchService from './search';
import createAdsService from './ads';

const getHeaders = (authorization) => {
  const headers = { 'Content-Type': 'application/json' };

  if (authorization) {
    headers.authorization = authorization;
  }

  return headers;
};

const initRequest = (
  endpoint,
  { method = 'GET', data, headers, params } = {},
) => {
  const qs = getQueryString(params);
  const endpointWithParams = getUrlWithQueryString(endpoint, qs);
  const resultEndpoint = qs ? endpointWithParams : endpoint;

  return fetch(resultEndpoint, {
    method,
    headers,
    body: JSON.stringify(data),
  }).then(processResponse(resultEndpoint));
};

const makeInitRequest = (baseUrl, authorization) => {
  const headers = getHeaders(authorization);
  return (endpoint, params) =>
    initRequest(baseUrl.concat(endpoint), { ...params, headers });
};

const createApi = (baseUrl) => {
  const api = (authorization) => {
    const initRequest = makeInitRequest(baseUrl, authorization);

    return {
      account: createAccountService(initRequest),
      search: createSearchService(initRequest),
      users: createUsersService(initRequest),
      tasks: createTasksService(initRequest),
      calls: createCallsService(initRequest),
      usersStatistic: createUsersStatisticService(initRequest),
      adsStatistic: createAdsStatisticService(initRequest),
      ads: createAdsService(initRequest),
    };
  };

  return api;
};

const api = createApi('/api');
export default api;
