import Highlighter from 'react-highlight-words';
import styles from './SearchHighlighter.module.scss';

const SearchHighlighter = ({ search, text }) => (
  <Highlighter
    highlightClassName={styles.root}
    searchWords={[search]}
    textToHighlight={text}
  />
);

export default SearchHighlighter;
