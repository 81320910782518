@import '../../styles/utils';

.wrapper {
  position: relative;
  padding: 2px;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  vertical-align: top;

  &_disabled {
    cursor: default;
    opacity: 0.6;
  }
}

.element {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mark {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid $textDark;
  border-radius: 2px;
  box-sizing: border-box;
  @include defaultTransition(border, background-color, opacity);

  &::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 1px;
    display: inline-block;
    width: 4px;
    height: 10px;
    border-radius: 1px;
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotate(45deg);
    opacity: 0;
    @include defaultTransition(opacity);
  }
}

.element:checked + .mark {
  border: 2px solid $primaryBlue;
  background-color: $primaryBlue;

  &::before {
    opacity: 1;
  }
}

.label {
  margin-left: 8px;
  line-height: 20px;
  font-size: 14px;
}
