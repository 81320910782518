import { FormattedMessage } from 'react-intl';

import IconGoogle from './IconGoogle/IconGoogle';

import styles from './GoogleLoginButton.module.scss';

const GoogleLoginButton = ({ ...rest }) => (
  <button className={styles.root} {...rest}>
    <span className={styles.icon}>
      <IconGoogle />
    </span>

    <span>
      <FormattedMessage defaultMessage="Log in" />
    </span>
  </button>
);

export default GoogleLoginButton;
