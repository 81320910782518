import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAtom, useAction } from '@reatom/react';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import PageErrorFallback from './ErrorFallback/ErrorFallback';
import Header from './Header/Header';
import MainNavigation from './MainNavigation/MainNavigation';

import { useAuth } from '../../hooks';
import * as Account from '../../models/account';
import * as Localization from '../../models/localization';
import useSearch from './useSearch';
import styles from './ContentPagesLayout.module.scss';

const ContentPagesLayout = () => {
  const [, { logOut }] = useAuth();
  const account = useAtom(Account.entityAtom);

  const language = useAtom(Localization.languageAtom);
  const proposalLanguage = useAtom(Localization.proposalLanguageAtom);
  const changeLanguage = useAction(Localization.languageChanged);
  const [search, { onSearchChange, onSearchEntityTypeSelect }] = useSearch();

  const onLanguageChange = (language) => changeLanguage({ language });

  return (
    <section className={styles.root}>
      <Helmet>
        <title>Hubble</title>
      </Helmet>

      <div className={styles.navigationCol}>
        <div className={styles.navigation}>
          <MainNavigation />
        </div>

        <div className={styles.settings}>
          <div className={styles.settingItem}>
            <LanguageSelect
              language={proposalLanguage || language}
              onChange={onLanguageChange}
            />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.header}>
          <Header
            user={account}
            searchValue={search}
            onSearchChange={onSearchChange}
            onSearchEntityTypeSelect={onSearchEntityTypeSelect}
            onSignOut={logOut}
          />
        </div>

        <div className={styles.content}>
          <ErrorBoundary fallback={<PageErrorFallback />}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </section>
  );
};

export default ContentPagesLayout;
