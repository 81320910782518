const DIALOG_NAME = {
  USER_CREATION: 'USER_CREATION',
  USER_EDITING: 'USER_EDITING',
  TASK_CREATION: 'TASK_CREATION',
  TASK_EDITING: 'TASK_EDITING',
  CLOSED_TASK_OVERVIEW: 'CLOSED_TASK_OVERVIEW',
  AD_HOUSE_EDITING: 'AD_HOUSE_EDITING',
};

export default DIALOG_NAME;
