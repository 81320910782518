import { useCallback, useMemo } from 'react';

import Input from '../../Input/Input';
import RoundIconButton from '../../RoundIconButton/RoundIconButton';
import IconContainer from '../../IconContainer/IconContainer';
import { IconPlus, IconMinus } from '../../icons';

import styles from './Input.module.scss';
import { checkIsUndefined } from '../../../utils';

const InputListElement = ({
  value,
  prependedIcon,
  isAppendedButtonDisabled,
  isLast,
  isDisabled,
  disabled,
  noAppendedIcon,
  appendedButtonIcon,
  onNewFieldAdd,
  onInputDelete,
  onAppendedButtonClick,
  ...rest
}) => {
  const icon = useMemo(() => {
    if (appendedButtonIcon) {
      return appendedButtonIcon;
    }

    return isLast ? <IconPlus /> : <IconMinus />;
  }, [appendedButtonIcon, isLast]);

  const isAppendedDisabled = useMemo(() => {
    if (!checkIsUndefined(isAppendedButtonDisabled)) {
      return isAppendedButtonDisabled;
    }

    return (isLast && !value) || disabled || isDisabled;
  }, [value, disabled, isDisabled, isLast, isAppendedButtonDisabled]);

  const handleAppendedClick = useCallback(
    (e) => {
      if (onAppendedButtonClick) {
        onAppendedButtonClick(value);
        return;
      }

      if (isLast) {
        onNewFieldAdd(e);
        return;
      }

      onInputDelete(e);
    },
    [value, isLast, onNewFieldAdd, onInputDelete, onAppendedButtonClick],
  );

  return (
    <Input
      value={value}
      prependedElement={
        prependedIcon && (
          <span className={styles.prependedIcon}>
            <IconContainer>{prependedIcon}</IconContainer>
          </span>
        )
      }
      appendedElement={
        !noAppendedIcon && (
          <RoundIconButton
            onClick={handleAppendedClick}
            icon={icon}
            disabled={isAppendedDisabled}
            isSmall
          />
        )
      }
      isGrey
      isDisabled={isDisabled}
      {...rest}
    />
  );
};

export default InputListElement;
