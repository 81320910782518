import { declareAtom, declareAction, combine, map } from '@reatom/core';

import { deserializeTaskOverview } from '../../normalizers';
import api from '../../services/api';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';
import * as TaskQueueIsStopped from './isStopped';
import { TASK_QUEUE_ERROR_TYPE } from './entityId';

export const doneSucceeded = declareAction('TasksQueue/Showing/doneSucceeded');
export const doneFailed = declareAction('TasksQueue/Showing/doneFailed');

export const done = declareAction(
  'TasksQueue/Showing/done',
  async (payload, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const queuedTask = await api(authorization).tasks.getQueuedTask();
      store.dispatch(
        doneSucceeded({ task: deserializeTaskOverview(queuedTask) }),
      );
    } catch (error) {
      switch (error.type) {
        case TASK_QUEUE_ERROR_TYPE.EMPTY_QUEUE:
          store.dispatch(doneSucceeded({ task: null }));
          return;

        case TASK_QUEUE_ERROR_TYPE.STOPPED_QUEUE:
          store.dispatch(doneSucceeded({ task: null }));
          store.dispatch(TaskQueueIsStopped.stopped());
          return;

        default:
          console.error(error);
          store.dispatch(doneFailed());
      }
    }
  },
);

const loadingStateAtom = declareAtom(
  'TaskQueue/Showing/loadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isFailedAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({ loadingStateAtom });
