import { flow } from 'lodash';

const compareDateAsc = (dateA, dateB) => new Date(dateA) - new Date(dateB);

const selectDate = ({ date }) => date;

const isDateOutOfRange = (dateRange) => (date) => {
  return (
    new Date(date) >= new Date(dateRange.from) &&
    new Date(date) <= new Date(dateRange.to)
  );
};

const compareStatisticsByDate = (firstItem, secondItem) =>
  compareDateAsc(selectDate(firstItem), selectDate(secondItem));

const isStatisticsDateInRange = (allowedDateRange) =>
  flow(selectDate, isDateOutOfRange(allowedDateRange));

export const deserializeAdsStatisticOverview = (
  statisticList,
  allowedDateRange,
) => {
  const deserializedStatisticList = Array.from(statisticList)
    .filter(isStatisticsDateInRange(allowedDateRange))
    .sort(compareStatisticsByDate);

  return {
    ads: deserializedStatisticList.map(({ date, pagesCount }) => ({
      date,
      amount: pagesCount,
    })),
    adGroups: deserializedStatisticList.map(({ date, annsCount }) => ({
      date,
      amount: annsCount,
    })),
    notSpecifiedBuildingAds: deserializedStatisticList.map(
      ({ date, emptyBuildingOrHouseFlatPagesCount }) => ({
        date,
        amount: emptyBuildingOrHouseFlatPagesCount,
      }),
    ),
    notSpecifiedBuildingAdGroups: deserializedStatisticList.map(
      ({ date, emptyBuildingOrHouseFlatAnnsCount }) => ({
        date,
        amount: emptyBuildingOrHouseFlatAnnsCount,
      }),
    ),
    notSpecifiedLocalityAds: deserializedStatisticList.map(
      ({ date, emptyCityHousePagesCount }) => ({
        date,
        amount: emptyCityHousePagesCount,
      }),
    ),
    notSpecifiedLocalityAdGroups: deserializedStatisticList.map(
      ({ date, emptyCityHouseAnnsCount }) => ({
        date,
        amount: emptyCityHouseAnnsCount,
      }),
    ),
  };
};
