import queryString from 'query-string';

const filterEmptySearchParams = (params) =>
  Object.keys(params).reduce((result, name) => {
    if (typeof params[name] === 'undefined' || params[name] === null) {
      return result;
    }

    result[name] = params[name];
    return result;
  }, {});

export const processResponse = (endpoint) => async (response) => {
  if (!response.ok) {
    throw new Error(`${endpoint} ${response.status} ${response.statusText}`);
  }

  const { data, errorType, status } = await response.json();

  if (status !== 'OK') {
    const error = new Error(`${endpoint} ${errorType}`);
    error.type = errorType;
    throw error;
  }

  return data;
};

export const getQueryString = (params) => {
  if (!params) {
    return '';
  }
  const filtered = filterEmptySearchParams(params);

  return queryString.stringify(filtered);
};

export const getUrlWithQueryString = (url, qs) => (qs ? `${url}?${qs}` : url);
