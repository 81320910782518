@import '../../../styles/utils';

.option {
  display: inline-flex;
  align-items: center;
}

.optionIcon {
  margin-right: 8px;
  line-height: 1;
  color: $primaryBlue;
}

.optionText {
  margin-top: -2px;
}

.highlightedOptionText {
  color: $primaryBlue;
}
