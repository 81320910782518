.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.select {
  min-width: 224px;
  margin-right: 16px;
}
