import { useMemo } from 'react';
import { useIntl, FormattedNumber } from 'react-intl';

import PieChart from '../../../components/PieChart/PieChart';

import styles from './PieChartPlate.module.scss';

const PieChartPlate = ({ title, coins }) => {
  const intl = useIntl();

  const primaryText = useMemo(
    () => intl.formatNumber(coins.reduce((acc, { amount }) => acc + amount, 0)),
    [intl.formatNumber],
  );

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>

      <div className={styles.grid}>
        <div className={styles.legend}>
          {coins.map(({ id, color, title, amount }) => (
            <div key={id} className={styles.legendItem}>
              <span className={styles.legendItemKey}>
                <span
                  className={styles.legendItemMarker}
                  style={{ backgroundColor: color }}
                />
                <span className={styles.legendItemTitle}>{title}</span>
              </span>

              <span className={styles.legendItemAmount}>
                <FormattedNumber value={amount} />
              </span>
            </div>
          ))}
        </div>

        <div>
          <PieChart
            coins={coins}
            primaryText={primaryText}
            primaryTextCaption={intl.formatMessage({
              defaultMessage: 'Total tasks',
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default PieChartPlate;
