import Svg from '../Svg/Svg';

const IconDashboard = () => (
  <Svg>
    <path
      fillRule="nonzero"
      d="M4.875 13h5.25a.89.89 0 00.875-.9V4.9a.89.89 0 00-.875-.9h-5.25A.89.89 0 004 4.9v7.2c0 .495.394.9.875.9zm0 7h5.25c.481 0 .875-.375.875-.833v-3.334c0-.458-.394-.833-.875-.833h-5.25c-.481 0-.875.375-.875.833v3.334c0 .458.394.833.875.833zm9 0h5.25a.89.89 0 00.875-.9v-7.2a.89.89 0 00-.875-.9h-5.25a.89.89 0 00-.875.9v7.2c0 .495.394.9.875.9zM13 4.833v3.334c0 .458.394.833.875.833h5.25c.481 0 .875-.375.875-.833V4.833c0-.458-.394-.833-.875-.833h-5.25c-.481 0-.875.375-.875.833z"
    />
    <path d="M0 0h24v24H0z" fill="none" fillRule="evenodd" />
  </Svg>
);

export default IconDashboard;
