.root {
  display: inline-block;
  height: 24px;
  min-width: 24px;
  vertical-align: top;

  &_size-- {
    &inline {
      height: 1em;
      min-width: 1em;
      line-height: 1;
    }

    &large {
      height: 32px;
      min-width: 32px;
    }

    &small {
      height: 18px;
      min-width: 18px;
    }

    &xsmall {
      height: 16px;
      min-width: 16px;
    }

    &xxsmall {
      height: 12px;
      min-width: 12px;
    }
  }
}
