import Svg from '../Svg/Svg';

const IconPhone = () => (
  <Svg>
    <path d="M10.368 13.633c-1.814-1.814-2.223-3.628-2.316-4.354a.651.651 0 01.187-.546l1.468-1.467a.655.655 0 00.093-.81l-2.337-3.63a.655.655 0 00-.85-.238l-3.75 1.767a.652.652 0 00-.361.65c.196 1.868 1.01 6.459 5.522 10.97s9.101 5.326 10.97 5.523a.652.652 0 00.65-.36l1.768-3.752a.655.655 0 00-.238-.848l-3.629-2.336a.655.655 0 00-.81.091l-1.468 1.468a.651.651 0 01-.545.187c-.727-.092-2.54-.502-4.354-2.315h0z" />
    <path d="M17.57 12.655a.655.655 0 01-.655-.655 4.92 4.92 0 00-4.914-4.914.655.655 0 110-1.31A6.232 6.232 0 0118.225 12a.655.655 0 01-.655.655z" />
    <path d="M20.846 12.655a.655.655 0 01-.655-.655 8.2 8.2 0 00-8.19-8.19.655.655 0 110-1.311A9.512 9.512 0 0121.5 12a.655.655 0 01-.655.655h0z" />
  </Svg>
);

export default IconPhone;
