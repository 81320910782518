import cx from 'classnames';
import { calcFlex } from './Cell.helpers';
import styles from './Cell.module.scss';

const TableCell = ({
  children,
  horizontalAlignment = 'left',
  width = 'auto',
  minWidth,
  isFlexible,
  isFullWidth,
  isVisibleWhenRowTranslucent,
}) => {
  const flex = calcFlex(width, isFlexible, isFullWidth);

  return (
    <div
      className={cx(
        styles.root,
        styles[`root_horizontalAlignment__${horizontalAlignment}`],
        {
          [styles.root_isVisibleWhenRowTranslucent]:
            isVisibleWhenRowTranslucent,
        },
      )}
      style={{ flex, width: minWidth || width, minWidth }}
    >
      <div className={styles.content}>{children || '—'}</div>
    </div>
  );
};

export default TableCell;
