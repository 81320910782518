import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Page = ({ helmetTitle = '', children }) => (
  <>
    <Helmet>
      <title>Hubble - {helmetTitle}</title>
    </Helmet>

    {children}
  </>
);

Page.propTypes = {
  helmetTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Page;
