import AutomaticTaskLabel from '../../AutomaticTaskLabel/AutomaticTaskLabel';
import ComplaintTaskLabel from '../../ComplaintTaskLabel/ComplaintTaskLabel';
import UserPlate from '../../UserPlate/UserPlate';

const TasksTableTaskCreator = ({ creator, isAutomatic, isComplaint }) => {
  if (isAutomatic) {
    return <AutomaticTaskLabel />;
  }

  if (isComplaint) {
    return <ComplaintTaskLabel />;
  }

  return <UserPlate name={creator.name} media={creator.media} />;
};

export default TasksTableTaskCreator;
