@import '../../../styles/utils';

.row {
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid $grey_2;

  &:first-child {
    border-top: 1px solid $grey_2;
  }

  &:last-child {
    border-bottom: none;
  }

  &_verticallyCenterAligned {
    align-items: center;
  }

  &_highlighted {
    background-color: #ebf2ff;
  }
}

.row_translucent {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.5;
  }
}
