import { useCallback } from 'react';
import cx from 'classnames';

import styles from './Option.module.scss';

const SelectDropdownOption = ({ value, children, isSelected, onClick }) => {
  const handleClick = useCallback(() => onClick(value), [value, onClick]);

  return (
    <button
      className={cx(styles.root, { [styles.root_selected]: isSelected })}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

SelectDropdownOption.DefaultContent = ({ title }) => title;

export default SelectDropdownOption;
