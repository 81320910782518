import {
  AD_PHOTO_DOWNLOAD_STATUS,
  AD_PHOTO_PROCESSING_STAGE,
} from '../constants';

const BROKEN_DOWNLOAD_STATUSES = [
  AD_PHOTO_DOWNLOAD_STATUS.DOWNLOAD_FAILED,
  AD_PHOTO_DOWNLOAD_STATUS.UPLOAD_FAILED,
  AD_PHOTO_DOWNLOAD_STATUS.NOT_EXISTS,
  AD_PHOTO_DOWNLOAD_STATUS.INVALID_TYPE_ERROR,
  AD_PHOTO_DOWNLOAD_STATUS.IMAGE_CLEANER_READY,
];
const BROKEN_PROCESSING_STAGES = [
  AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_FAILED,
  AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_BAD_INPUT,
  AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_FAILED,
  AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_BAD_INPUT,
  AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_FAILED,
  AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_BAD_INPUT,
];
const MIN_SIZE = 600;

const checkIsAdPhotoIsBroken = ({ downloadStatus, processingStage, size }) => {
  if (size.lun.width < MIN_SIZE || size.lun.height < MIN_SIZE) {
    return true;
  }

  return (
    BROKEN_DOWNLOAD_STATUSES.includes(downloadStatus) ||
    BROKEN_PROCESSING_STAGES.includes(processingStage)
  );
};

export default checkIsAdPhotoIsBroken;
