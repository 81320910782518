import { useState, useCallback, useEffect } from 'react';

const useCleave = (value) => {
  const [cleave, setCleave] = useState(null);

  const setValue = useCallback(
    (value) => {
      if (!cleave) {
        return;
      }

      cleave.setRawValue(value);
    },
    [cleave],
  );

  useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  return { setCleaveValue: setValue, setCleave };
};

export default useCleave;
