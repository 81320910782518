import { useMemo } from 'react';

import InputListElement from './Input/Input';

import { useEventHandler } from '../../hooks';
import styles from './InputList.module.scss';

const InputList = ({ list = [''], onChange, ...rest }) => {
  const _linkList = useMemo(() => {
    const isEmptyList = list.length === 0;

    if (isEmptyList) {
      return [''];
    }

    return list;
  }, [list]);

  const handleChange = (index) => (e) =>
    onChange([
      ...list.slice(0, index),
      e.target.value,
      ...list.slice(index + 1),
    ]);

  const handleDelete = (index) => () =>
    onChange([...list.slice(0, index), ...list.slice(index + 1)]);

  const handleNewFieldAdd = useEventHandler(
    () => onChange([...list, '']),
    [list, onChange],
  );

  return (
    <div>
      {_linkList.map((link, index) => (
        <div key={index} className={styles.item}>
          <InputListElement
            value={link}
            isLast={index === Math.max(list.length - 1, 0)}
            onChange={handleChange(index)}
            onNewFieldAdd={handleNewFieldAdd}
            onInputDelete={handleDelete(index)}
            {...rest}
          />
        </div>
      ))}
    </div>
  );
};

export default InputList;
