const TimelineStatePlaceholder = ({
  children,
  loader,
  failed,
  idle,
  empty,
  isLoading,
  isFailed,
  isIdle,
  isEmpty,
}) => {
  if (isLoading) {
    return loader;
  }

  if (isFailed) {
    return failed;
  }

  if (isIdle) {
    return idle;
  }

  if (isEmpty) {
    return empty;
  }

  return children;
};

export default TimelineStatePlaceholder;
