import { declareAction, declareAtom, combine } from '@reatom/core';

export const openDialog = declareAction('Dialog/openDialog'); // { name, payload }
export const closeDialog = declareAction('Dialog/closeDialog'); // nothing

export const openedNameAtom = declareAtom('Dialog/openedNameAtom', '', (on) => [
  on(openDialog, (_, { name = '' }) => name),
  on(closeDialog, () => ''),
]);

export const openedPayloadAtom = declareAtom(
  'Dialog/openedPayloadAtom',
  null,
  (on) => [
    on(openDialog, (_, { payload = null }) => payload),
    on(closeDialog, () => null),
  ],
);

export default combine({ openedNameAtom, openedPayloadAtom });
