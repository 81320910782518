import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as AdsStatistic from '../../models/adsStatistic';
import { useNotifications } from '../../hooks';

const useCities = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const cities = useAtom(AdsStatistic.Cities.listAtom);
  const isCitiesLoading = useAtom(AdsStatistic.Cities.isShownLoadingAtom);
  const isCitiesIdle = useAtom(AdsStatistic.Cities.isShownIdleAtom);
  const isCitiesFailed = useAtom(AdsStatistic.Cities.isShownFailedAtom);
  const onCitiesShown = useAction(AdsStatistic.Cities.shown);

  useEffect(() => {
    if (!isCitiesFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load cities',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCitiesFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isCitiesIdle) {
      return;
    }

    onCitiesShown();
  }, [isCitiesIdle, onCitiesShown]);

  return { cities, isCitiesLoading };
};

export default useCities;
