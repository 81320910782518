import { useAtom } from '@reatom/react';
import { Navigate } from 'react-router-dom';

import LoginPage from '../../../pages/LoginPage/LoginPage';

import * as Account from '../../../models/account';
import { useAuth } from '../../../hooks';

const AuthBarrier = ({ children }) => {
  const [{ isLoading }] = useAuth();
  const isAuthorized = useAtom(Account.isSucceededAtom);

  if (isLoading) {
    return <LoginPage />;
  }

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthBarrier;
