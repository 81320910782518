import { defineMessage } from 'react-intl';

export const AD_PHOTO_DOWNLOAD_STATUS = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  SUCCEEDED: 'succeeded',
  DOWNLOAD_FAILED: 'download_failed',
  UPLOAD_FAILED: 'upload_failed',
  NOT_EXISTS: 'not_exists',
  INVALID_TYPE_ERROR: 'invalid_type_error',
  IMAGE_CLEANER_READY: 'image_cleaner_ready',
};

export const AD_PHOTO_DOWNLOAD_STATUS_INFO = {
  [AD_PHOTO_DOWNLOAD_STATUS.NEW]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.NEW,
    title: defineMessage({ defaultMessage: 'New' }),
  },

  [AD_PHOTO_DOWNLOAD_STATUS.ACCEPTED]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.ACCEPTED,
    title: defineMessage({ defaultMessage: 'Accepted' }),
  },

  [AD_PHOTO_DOWNLOAD_STATUS.SUCCEEDED]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.SUCCEEDED,
    title: defineMessage({ defaultMessage: 'Successfully downloaded' }),
  },

  [AD_PHOTO_DOWNLOAD_STATUS.DOWNLOAD_FAILED]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.DOWNLOAD_FAILED,
    title: defineMessage({ defaultMessage: 'Download failed' }),
  },

  [AD_PHOTO_DOWNLOAD_STATUS.UPLOAD_FAILED]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.UPLOAD_FAILED,
    title: defineMessage({ defaultMessage: 'Uploading failed' }),
  },

  [AD_PHOTO_DOWNLOAD_STATUS.NOT_EXISTS]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.NOT_EXISTS,
    title: defineMessage({ defaultMessage: 'Not exists' }),
  },

  [AD_PHOTO_DOWNLOAD_STATUS.INVALID_TYPE_ERROR]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.INVALID_TYPE_ERROR,
    title: defineMessage({ defaultMessage: 'Wrong type' }),
  },

  [AD_PHOTO_DOWNLOAD_STATUS.IMAGE_CLEANER_READY]: {
    slug: AD_PHOTO_DOWNLOAD_STATUS.IMAGE_CLEANER_READY,
    title: defineMessage({
      defaultMessage: 'image_cleaner_ready',
    }), // TODO: needs translation
  },
};

export const AD_PHOTO_PROCESSING_STAGE = {
  PS_DOWNLOADED: 'ps_downloaded',
  PS_FILE_PROCESSOR_FAILED: 'ps_file_processor_failed',
  PS_FILE_PROCESSOR_SUCCEEDED: 'ps_file_processor_succeeded',
  PS_FILE_PROCESSOR_BAD_INPUT: 'ps_file_processor_bad_input',
  PS_CLASSIFIER_FAILED: 'ps_classifier_failed',
  PS_CLASSIFIER_SUCCEEDED: 'ps_classifier_succeeded',
  PS_CLASSIFIER_MINOR_CLASSES_SUCCEEDED:
    'ps_classifier_minor_classes_succeeded',
  PS_CLASSIFIER_BAD_INPUT: 'ps_classifier_bad_input',
  PS_GROUPER_FAILED: 'ps_grouper_failed',
  PS_GROUPER_SUCCEEDED: 'ps_grouper_succeeded',
  PS_GROUPER_BAD_INPUT: 'ps_grouper_bad_input',
};

export const AD_PHOTO_PROCESSING_STAGE_INFO = {
  [AD_PHOTO_PROCESSING_STAGE.PS_DOWNLOADED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_DOWNLOADED,
    title: defineMessage({ defaultMessage: 'Downloading' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_FAILED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_FAILED,
    title: defineMessage({ defaultMessage: 'Error during download' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_SUCCEEDED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_SUCCEEDED,
    title: defineMessage({ defaultMessage: 'Successfully downloaded' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_BAD_INPUT]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_FILE_PROCESSOR_BAD_INPUT,
    title: defineMessage({
      defaultMessage: 'ps_file_processor_bad_input',
    }), // TODO: needs translation
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_FAILED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_FAILED,
    title: defineMessage({ defaultMessage: 'Classification failed' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_SUCCEEDED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_SUCCEEDED,
    title: defineMessage({ defaultMessage: 'Successfully classified' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_MINOR_CLASSES_SUCCEEDED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_MINOR_CLASSES_SUCCEEDED,
    title: defineMessage({ defaultMessage: 'Successfully classified' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_BAD_INPUT]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_CLASSIFIER_BAD_INPUT,
    title: defineMessage({
      defaultMessage: 'ps_classifier_bad_input',
    }), // TODO: needs translation
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_FAILED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_FAILED,
    title: defineMessage({ defaultMessage: 'Grouping failed' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_SUCCEEDED]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_SUCCEEDED,
    title: defineMessage({ defaultMessage: 'Successfully grouped' }),
  },

  [AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_BAD_INPUT]: {
    slug: AD_PHOTO_PROCESSING_STAGE.PS_GROUPER_BAD_INPUT,
    title: defineMessage({
      defaultMessage: 'ps_grouper_bad_input',
    }), // TODO: needs translation
  },
};

const AD_PHOTO_REPAIR_QUALITY = {
  REPAIR: 'repair',
  GRANDMA: 'grandma',
  EURO: 'euro',
  DESIGNER: 'designer',
};

export const AD_PHOTO_REPAIR_QUALITY_INFO = {
  [AD_PHOTO_REPAIR_QUALITY.REPAIR]: {
    slug: AD_PHOTO_REPAIR_QUALITY.REPAIR,
    title: defineMessage({ defaultMessage: 'Not renovated' }),
  },

  [AD_PHOTO_REPAIR_QUALITY.GRANDMA]: {
    slug: AD_PHOTO_REPAIR_QUALITY.GRANDMA,
    title: defineMessage({ defaultMessage: '"Grandma\'s" repair' }),
  },

  [AD_PHOTO_REPAIR_QUALITY.EURO]: {
    slug: AD_PHOTO_REPAIR_QUALITY.EURO,
    title: defineMessage({ defaultMessage: 'Renovation' }),
  },

  [AD_PHOTO_REPAIR_QUALITY.DESIGNER]: {
    slug: AD_PHOTO_REPAIR_QUALITY.DESIGNER,
    title: defineMessage({ defaultMessage: 'Designer repair' }),
  },
};

const AD_PHOTO_CATEGORY = {
  KITCHEN: 'kitchen',
  ROOM: 'room',
  BEDROOM: 'bedroom',
  CORRIDOR: 'corridor',
  TOILET: 'toilet',
  REPAIR: 'repair',
  STAIRS: 'stairs',
  BUILDING: 'building',
  STAIRWAY: 'stairway',
  HALLWAY: 'hallway',
  ENTRANCE: 'entrance',
  CONSTRUCTION: 'construction',
  FACADE: 'facade',
  HOUSE: 'house',
  VIEW: 'view',
  BIRD_VIEW: 'birdview',
  CHILD_VIEW: 'childview',
  LAYOUT: 'layout',
  MAP: 'map',
  SHARED: 'shared',
  BANNED: 'banned',
  BALCONY: 'balcony',
  ELEVATOR: 'elevator',
  OTHER: 'other',
};

export const AD_PHOTO_CATEGORY_INFO = {
  [AD_PHOTO_CATEGORY.KITCHEN]: {
    slug: AD_PHOTO_CATEGORY.KITCHEN,
    title: defineMessage({ defaultMessage: 'Kitchen' }),
  },

  [AD_PHOTO_CATEGORY.ELEVATOR]: {
    slug: AD_PHOTO_CATEGORY.ELEVATOR,
    title: defineMessage({ defaultMessage: 'Elevator' }),
  },

  [AD_PHOTO_CATEGORY.ROOM]: {
    slug: AD_PHOTO_CATEGORY.ROOM,
    title: defineMessage({ defaultMessage: 'Room' }),
  },

  [AD_PHOTO_CATEGORY.BEDROOM]: {
    slug: AD_PHOTO_CATEGORY.BEDROOM,
    title: defineMessage({ defaultMessage: 'Bedroom' }),
  },

  [AD_PHOTO_CATEGORY.CORRIDOR]: {
    slug: AD_PHOTO_CATEGORY.CORRIDOR,
    title: defineMessage({ defaultMessage: 'Corridor' }),
  },

  [AD_PHOTO_CATEGORY.BALCONY]: {
    slug: AD_PHOTO_CATEGORY.BALCONY,
    title: defineMessage({ defaultMessage: 'Balcony' }),
  },

  [AD_PHOTO_CATEGORY.TOILET]: {
    slug: AD_PHOTO_CATEGORY.TOILET,
    title: defineMessage({ defaultMessage: 'Toilet' }),
  },

  [AD_PHOTO_CATEGORY.REPAIR]: {
    slug: AD_PHOTO_CATEGORY.REPAIR,
    title: defineMessage({ defaultMessage: 'Rough finish' }),
  },

  [AD_PHOTO_CATEGORY.STAIRS]: {
    slug: AD_PHOTO_CATEGORY.STAIRS,
    title: defineMessage({ defaultMessage: 'Stairs' }),
  },

  [AD_PHOTO_CATEGORY.BUILDING]: {
    slug: AD_PHOTO_CATEGORY.BUILDING,
    title: defineMessage({ defaultMessage: 'Building' }),
  },

  [AD_PHOTO_CATEGORY.STAIRWAY]: {
    slug: AD_PHOTO_CATEGORY.STAIRWAY,
    title: defineMessage({ defaultMessage: 'Stairwell' }),
  },

  [AD_PHOTO_CATEGORY.HALLWAY]: {
    slug: AD_PHOTO_CATEGORY.HALLWAY,
    title: defineMessage({ defaultMessage: 'Hall' }),
  },

  [AD_PHOTO_CATEGORY.ENTRANCE]: {
    slug: AD_PHOTO_CATEGORY.ENTRANCE,
    title: defineMessage({ defaultMessage: 'Entrance' }),
  },

  [AD_PHOTO_CATEGORY.CONSTRUCTION]: {
    slug: AD_PHOTO_CATEGORY.CONSTRUCTION,
    title: defineMessage({ defaultMessage: 'Construction' }),
  },

  [AD_PHOTO_CATEGORY.FACADE]: {
    slug: AD_PHOTO_CATEGORY.FACADE,
    title: defineMessage({ defaultMessage: 'Facade' }),
  },

  [AD_PHOTO_CATEGORY.HOUSE]: {
    slug: AD_PHOTO_CATEGORY.HOUSE,
    title: defineMessage({ defaultMessage: 'House' }),
  },

  [AD_PHOTO_CATEGORY.VIEW]: {
    slug: AD_PHOTO_CATEGORY.VIEW,
    title: defineMessage({ defaultMessage: 'View from window' }),
  },

  [AD_PHOTO_CATEGORY.BIRD_VIEW]: {
    slug: AD_PHOTO_CATEGORY.BIRD_VIEW,
    title: defineMessage({ defaultMessage: 'Bird view' }),
  },

  [AD_PHOTO_CATEGORY.CHILD_VIEW]: {
    slug: AD_PHOTO_CATEGORY.CHILD_VIEW,
    title: defineMessage({ defaultMessage: 'View from street' }),
  },

  [AD_PHOTO_CATEGORY.LAYOUT]: {
    slug: AD_PHOTO_CATEGORY.LAYOUT,
    title: defineMessage({ defaultMessage: 'Layout' }),
  },

  [AD_PHOTO_CATEGORY.MAP]: {
    slug: AD_PHOTO_CATEGORY.MAP,
    title: defineMessage({ defaultMessage: 'Map' }),
  },

  [AD_PHOTO_CATEGORY.SHARED]: {
    slug: AD_PHOTO_CATEGORY.SHARED,
    title: defineMessage({ defaultMessage: 'General photo' }),
  },

  [AD_PHOTO_CATEGORY.BANNED]: {
    slug: AD_PHOTO_CATEGORY.BANNED,
    title: defineMessage({ defaultMessage: 'Photo is banned' }),
  },

  [AD_PHOTO_CATEGORY.OTHER]: {
    slug: AD_PHOTO_CATEGORY.OTHER,
    title: defineMessage({ defaultMessage: 'Other' }),
  },
};
