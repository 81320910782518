@import '../../../styles/utils.scss';

.root {
  display: flex;
  align-items: center;
}

.counter {
  margin-right: 16px;
  font-weight: 700;
}

.buttonGroup {
  display: flex;
}

.buttonItem {
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.button {
  @extend %normalize-button;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  box-sizing: border-box;
  border-radius: 18px;
  color: $black;
  border: 1px solid currentColor;
  @include defaultTransition(color, opacity);

  &:hover {
    color: $primaryBlue;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
