import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import CompletedTaskOverviewDialog from './CompletedTaskOverviewDialog';

import * as Tasks from '../../../../models/tasks';
import * as Account from '../../../../models/account';
import { TASK_TYPE } from '../../../../constants';
import { useEventHandler, useNotifications } from '../../../../hooks';
import { getTaskQueuePageLink } from '../../../../routes';

const CompletedTaskOverviewDialogContainer = ({ payload, ...rest }) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const navigate = useNavigate();
  const taskOverview = useAtom(
    Tasks.Overview.makeTaskOverviewAtom(payload?.taskId),
  );
  const accountTaskTypes = useAtom(Account.taskTypesAtom);
  const task = useMemo(() => taskOverview || {}, [taskOverview]);

  const isCommentSucceeded = useAtom(Tasks.Comment.isSaveCommentSucceededAtom);
  const isCommentLoading = useAtom(Tasks.Comment.isSaveCommentLoadingAtom);
  const isCommentingFailed = useAtom(Tasks.Comment.isSaveCommentFailedAtom);
  const handleTaskCommentSend = useAction((taskId, comment) =>
    Tasks.Comment.save({ taskId, comment }),
  );

  useEffect(() => {
    if (!isCommentingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to add comment. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentingFailed, intl.formatMessage, showErrorNotification]);

  const isRecreationLoading = useAtom(Tasks.Recreation.isDoneLoadingAtom);
  const isRecreationSucceeded = useAtom(Tasks.Recreation.isDoneSucceededAtom);
  const isRecreationFailed = useAtom(Tasks.Recreation.isDoneFailedAtom);

  useEffect(() => {
    if (!isRecreationFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to re-create the task. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecreationFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (isRecreationSucceeded) {
      navigate(getTaskQueuePageLink());
    }
  }, [navigate, isRecreationSucceeded]);

  const handleCommentSubmit = useCallback(
    (taskId, comment) => handleTaskCommentSend(taskId, comment),
    [handleTaskCommentSend],
  );

  const handleTaskRecreateClick = useEventHandler(
    () => handleTaskRecreate(payload?.callId),
    [payload?.callId],
  );

  return (
    <CompletedTaskOverviewDialog
      task={task}
      hasRecreationButton={
        payload?.callId && accountTaskTypes.includes(TASK_TYPE.CALL)
      }
      isRecreationLoading={isRecreationLoading}
      isCommentSucceeded={isCommentSucceeded}
      isCommentLoading={isCommentLoading}
      onCommentSubmit={handleCommentSubmit}
      onRecreateClick={handleTaskRecreateClick}
      {...rest}
    />
  );
};

export default CompletedTaskOverviewDialogContainer;
