import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import styles from './Link.module.scss';

const Link = ({
  component: Component = RouterLink,
  title,
  isExpanded,
  ...rest
}) => (
  <Component
    className={cx(styles.root, { [styles.root_expanded]: isExpanded })}
    {...rest}
  >
    {title}
  </Component>
);

export default Link;
