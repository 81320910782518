@import '../../../../../styles/utils';

.row {
  display: flex;
  margin: 0 -12px;
  padding: 12px 24px;
  padding-left: 8px;
  border-bottom: 1px solid $grey_2;

  &_highlighted {
    background-color: #ebd7d6;
  }
}

.titleCell {
  flex: 0 0 240px;
  padding: 0 12px;
  box-sizing: border-box;
  font-weight: 700;
  @include overflowEllipsis;
}

.rowContent {
  flex: 1 1 auto;
  display: flex;
}

.rawCell {
  flex: 0 0 30%;
  padding: 0 12px;
  box-sizing: border-box;
  overflow: hidden;
}

.recognizedCell {
  flex: 0 0 70%;
  padding: 0 12px;
  box-sizing: border-box;
  overflow: hidden;
}
