import IconContainer from '../../../../components/IconContainer/IconContainer';
import AdStatusMessageProcessingLabel from './ProcessingLabel/ProcessingLabel';

import styles from './AdStatusMessageTemplate.module.scss';

const AdStatusMessageTemplate = ({
  icon,
  title,
  children,
  backgroundColor = '#d6dbe6',
  iconColor,
  isReprocessing,
}) => (
  <div
    className={styles.root}
    style={{ backgroundColor: isReprocessing ? '#fcf4b5' : backgroundColor }}
  >
    <div className={styles.header}>
      <div
        className={styles.headerIcon}
        style={{ color: isReprocessing ? '#f7ca45' : iconColor }}
      >
        <IconContainer>{icon}</IconContainer>
      </div>

      <div>{title}</div>

      {isReprocessing && (
        <div className={styles.reprocessingLabel}>
          <AdStatusMessageProcessingLabel />
        </div>
      )}
    </div>

    {children && <div className={styles.content}>{children}</div>}
  </div>
);

export default AdStatusMessageTemplate;
