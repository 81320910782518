import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Select from '../Select/Select';

import { LANGUAGE_INFO } from '../../constants';
import styles from './LanguageSelect.module.scss';

const options = Object.values(LANGUAGE_INFO).map(({ slug, title }) => ({
  value: slug,
  label: <FormattedMessage {...title} />,
}));

const LanguageSelect = ({ language = 'en', onChange }) => (
  <div className={styles.root}>
    <span className={styles.label}>
      <FormattedMessage defaultMessage="Language:" />
    </span>

    <span className={styles.select}>
      <Select
        value={language}
        options={options}
        placeholder={<FormattedMessage defaultMessage="Language" />}
        theme="white"
        onChange={onChange}
      />
    </span>
  </div>
);

LanguageSelect.propTypes = {
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LanguageSelect;
