.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input {
  flex: 1 1 auto;
  margin-right: 24px;
}
