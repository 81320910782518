import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { FormattedMessage, useIntl } from 'react-intl';

import Page from '../../components/Page/Page';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import Loader from '../../components/Loader/Loader';
import NoResultsPlaceholder from '../../components/NoResultsPlaceholder/NoResultsPlaceholder';
import SearchResult from './SearchResult/SearchResult';

import { useNotifications } from '../../hooks';
import * as Search from '../../models/search';
import withRoles from '../../components/PagesScene/withRoles';
import getErrorMessage from './getErrorMessage';
import styles from './SearchPage.module.scss';

const SearchPageLayout = ({ children }) => (
  <FormattedMessage defaultMessage="Search">
    {([helmetTitle]) => (
      <Page helmetTitle={helmetTitle}>
        <PageMainContentTile
          title={<FormattedMessage defaultMessage="Search results" />}
        >
          {children}
        </PageMainContentTile>
      </Page>
    )}
  </FormattedMessage>
);

const SearchPage = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const searchResults = useAtom(Search.resultsAtom);
  const searchErrorType = useAtom(Search.errorTypeAtom);
  const isSearchLoading = useAtom(Search.isLoadingAtom);
  const isSearchSucceeded = useAtom(Search.isSucceededAtom);
  const isSearchFailed = useAtom(Search.isFailedAtom);
  const isSearchReady = isSearchSucceeded || isSearchFailed;
  const hasNoResults = isSearchSucceeded && searchResults.length === 0;

  const resetSearchState = useAction(Search.stateReset);

  useEffect(() => {
    if (!searchErrorType) {
      return;
    }

    showErrorNotification(getErrorMessage(searchErrorType, intl.formatMessage));
  }, [searchErrorType, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    return () => resetSearchState();
  }, [resetSearchState]);

  if (isSearchLoading) {
    return (
      <SearchPageLayout>
        <div className={styles.loader}>
          <Loader />
        </div>
      </SearchPageLayout>
    );
  }

  if (isSearchFailed) {
    return (
      <SearchPageLayout>
        <div className={styles.status}>
          <FormattedMessage defaultMessage="An error occurred during the search. Please try again" />
        </div>
      </SearchPageLayout>
    );
  }

  if (hasNoResults) {
    return (
      <SearchPageLayout>
        <NoResultsPlaceholder
          title={
            <FormattedMessage defaultMessage="Oops! Couldn't find anything for your query :(" />
          }
          description={
            <FormattedMessage defaultMessage="Try changing your search query and you will definitely be lucky 🙂" />
          }
        />
      </SearchPageLayout>
    );
  }

  if (!isSearchReady) {
    return (
      <SearchPageLayout>
        <div className={styles.status}>
          <FormattedMessage defaultMessage="Enter query and choose search type" />
        </div>
      </SearchPageLayout>
    );
  }

  return (
    <SearchPageLayout>
      <div className={styles.status}>
        <FormattedMessage
          defaultMessage="{num, plural, one {# result} other {# results}}"
          values={{ num: searchResults.length }}
        />
      </div>

      <div className={styles.grid}>
        {searchResults.map(({ entityType, payload }, index) => (
          <div key={index} className={styles.gridItem}>
            <SearchResult entityType={entityType} payload={payload} />
          </div>
        ))}
      </div>
    </SearchPageLayout>
  );
};

SearchPage.RouteParams = withRoles()({
  element: <SearchPage />,
  path: '/search',
});

export default SearchPage;
