import { forwardRef } from 'react';
import cx from 'classnames';

import IconContainer from '../IconContainer/IconContainer';
import styles from './Button.module.scss';

const Button = forwardRef(
  (
    {
      component: Component = 'button',
      title,
      prependedIcon,
      appendedIcon,
      variant = 'primary',
      type = 'button',
      iconSize = 'small',
      withLargeSideInnerGaps,
      isExpanded,
      isDisabled,
      isSmall,
      disabled,
      ...rest
    },
    ref,
  ) => (
    <Component
      className={cx(styles.root, styles[`root_variant--${variant}`], {
        [styles.root_disabled]: isDisabled || disabled,
        [styles.root_small]: isSmall,
        [styles.root_withLargeSideInnerGaps]: withLargeSideInnerGaps,
        [styles.root_expanded]: isExpanded,
      })}
      ref={ref}
      type={type}
      disabled={isDisabled || disabled}
      {...rest}
    >
      {prependedIcon && (
        <span className={styles.prependedIcon}>
          <IconContainer icon={prependedIcon} size={iconSize} />
        </span>
      )}

      <span className={styles.title}>{title}</span>

      {appendedIcon && (
        <span className={styles.appendedIcon}>
          <IconContainer icon={appendedIcon} size={iconSize} />
        </span>
      )}
    </Component>
  ),
);

Button.displayName = 'Button';

export default Button;
