import { combine } from '@reatom/core';

import dialogAtom from './dialog';
import searchAtom from './search';
import usersAtom from './users';
import accountAtom from './account';
import tasksAtom from './tasks';
import usersStatisticAtom from './usersStatistic';
import adsStatisticAtom from './adsStatistic';
import callsAtom from './calls';
import adsAtom from './ads';
import taskQueueAtom from './taskQueue';
import localizationAtom from './localization';

const rootAtom = combine('rootAtom', {
  dialogAtom,
  searchAtom,
  usersAtom,
  accountAtom,
  tasksAtom,
  usersStatisticAtom,
  adsStatisticAtom,
  callsAtom,
  adsAtom,
  taskQueueAtom,
  localizationAtom,
});

export default rootAtom;
