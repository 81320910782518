import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Dialog from '../../../Dialog/Dialog';
import Form from '../../../Form/Form';
import FormGroup from '../../../FormGroup/FormGroup';
import Field from '../../../Field/Field';
import Fieldset from '../../../Fieldset/Fieldset';
import Select from '../../../Select/Select';
import Radio from '../../../Radio/Radio';
import ZendeskLinkInput from '../../../ZendeskLinkInput/ZendeskLinkInput';
import Textarea from '../../../Textarea/Textarea';
import InputList from '../../../InputList/InputList';
import Switch from '../../../Switch/Switch';
import Button from '../../../Button/Button';
import { IconLink } from '../../../icons';

import {
  SORTED_PRIORITIES,
  PRIORITY_INFO,
  TASK_TYPE_LIST,
  TASK_TYPE_INFO,
  TASK_SUBTYPES_BY_TYPE,
  TASK_SUBTYPE_INFO,
  TASK_IDENTIFIER_TYPE,
  TASK_IDENTIFIER_TYPE_INFO,
  PRIORITY,
  PHONE_NUMBER_FORMAT,
  PHONE_NUMBER_REG_EXP,
  TASK_TYPE,
} from '../../../../constants';
import { checkHasVisibleErrors } from '../../../../utils';

const IDENTIFIERS_REG_EXP = new RegExp('\\s+', 'g');

const initValues = ({
  type = '',
  identifiersType = '',
  identifiers = [],
} = {}) => ({
  type,
  subType: '',
  priority: PRIORITY.MEDIUM,
  zendeskUrl: '',
  identifiersType,
  identifiers,
  isManyTasks: false,
  executorId: '',
  additionalResources: [],
  comment: '',
});

export const getRegExpByIdentifiersType = (identifiersType) => {
  switch (identifiersType) {
    case TASK_IDENTIFIER_TYPE.AD_ID:
    case TASK_IDENTIFIER_TYPE.GROUP_ID:
      return new RegExp(
        `^[0-9]{${TASK_IDENTIFIER_TYPE_INFO[identifiersType].minNumbersAmount},${TASK_IDENTIFIER_TYPE_INFO[identifiersType].maxNumbersAmount}}$`,
      );

    case TASK_IDENTIFIER_TYPE.PHONE_NUMBER:
      return PHONE_NUMBER_REG_EXP;

    default:
      return false;
  }
};

export const formPlaceholderByIdentifiersType = (
  identifiersType,
  formatMessage,
) => {
  switch (identifiersType) {
    case TASK_IDENTIFIER_TYPE.AD_ID:
    case TASK_IDENTIFIER_TYPE.GROUP_ID:
      return formatMessage(
        {
          defaultMessage:
            '{digits_amount, plural, one {# digit} other {# digits}}',
        },
        {
          digits_amount:
            TASK_IDENTIFIER_TYPE_INFO[identifiersType].maxNumbersAmount,
        },
      );

    case TASK_IDENTIFIER_TYPE.PHONE_NUMBER:
      return formatMessage(
        {
          defaultMessage: 'Phone number in format {phone_number_format}',
        },
        { phone_number_format: PHONE_NUMBER_FORMAT },
      );

    default:
      return '';
  }
};

// By default validation schema doesn't provide us with the ability
// to access to values withing error message form function.
// So it is no possibility to determine what length the identifiers
// should have
const createValidationSchema = (valuesRef, formatMessage) =>
  Yup.object().shape({
    type: Yup.string().required(
      formatMessage({ defaultMessage: 'Task type is required' }),
    ),
    identifiersType: Yup.string().required(
      formatMessage({ defaultMessage: 'Choose identifier type' }),
    ),
    identifiers: Yup.array()
      .compact()
      .min(
        1,
        formatMessage({
          defaultMessage: 'At least one field must be filled',
        }),
      )
      .test(
        'correctness',
        () => {
          const { identifiersType } = valuesRef.current;
          const numbersAmount =
            TASK_IDENTIFIER_TYPE_INFO[identifiersType]?.maxNumbersAmount;

          switch (identifiersType) {
            case TASK_IDENTIFIER_TYPE.AD_ID:
            case TASK_IDENTIFIER_TYPE.GROUP_ID:
              return formatMessage(
                {
                  defaultMessage:
                    'Ad id must consist of {digits_amount, plural, one {# digit} other {# digits}}',
                },
                { digits_amount: numbersAmount },
              );

            case TASK_IDENTIFIER_TYPE.PHONE_NUMBER:
              return formatMessage(
                {
                  defaultMessage:
                    'The number does not match the format {phone_number_format}',
                },
                { phone_number_format: PHONE_NUMBER_FORMAT },
              );

            default:
              return '';
          }
        },
        (value, context) => {
          if (value?.length === 0) {
            return false;
          }

          const { identifiersType } = context.parent;
          const regExp = getRegExpByIdentifiersType(identifiersType);

          return (
            regExp &&
            value
              .map((value) => regExp.test(value))
              .reduce((acc, current) => acc && current, true)
          );
        },
      )
      .test(
        'call-task-has-only-one-identifier',
        formatMessage({
          defaultMessage:
            'Task type "Call" can have only one identifier - ad id',
        }),
        (identifiers, { parent }) => {
          return (
            parent.type !== TASK_TYPE.CALL ||
            parent.isManyTasks ||
            identifiers.length === 1
          );
        },
      ),
    zendeskUrl: Yup.string().url(
      formatMessage({ defaultMessage: 'Link is not valid' }),
    ),
  });

const TASK_TYPE_OPTIONS = TASK_TYPE_LIST.map((type) => ({
  value: type,
  label: <FormattedMessage {...TASK_TYPE_INFO[type].title} />,
}));

const TaskCreationDialog = ({
  moderatingAdId,
  executors = [],
  isExecutorsLoading,
  isOpened,
  isLoading,
  onCreate,
  onTaskTypeChange,
  onClose,
}) => {
  const intl = useIntl();

  const valuesRef = useRef({});

  const handleTaskCreate = useCallback(
    ({ isManyTasks, ...values }) =>
      onCreate({
        ...values,
        additionalResources: values.additionalResources.filter(Boolean),
        isSingleTask: !isManyTasks,
      }),
    [onCreate],
  );

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initValues(),
    validationSchema: createValidationSchema(valuesRef, intl.formatMessage),
    onSubmit: handleTaskCreate,
  });

  valuesRef.current = values;

  const subTypeOptions = useMemo(() => {
    if (!TASK_SUBTYPES_BY_TYPE[values.type]) {
      return [];
    }

    return TASK_SUBTYPES_BY_TYPE[values.type]
      .map((slug) => TASK_SUBTYPE_INFO[slug])
      .map(({ slug, title }) => ({
        value: slug,
        label: intl.formatMessage(title),
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type, intl.formatMessage]);

  const handleSelectChange = useCallback(
    (value, name) => setFieldValue(name, value),
    [setFieldValue],
  );

  const handleIdentifiersChange = useCallback(
    (list) => setFieldValue('identifiers', list),
    [setFieldValue],
  );

  const handleAdditionalResources = useCallback(
    (list) => setFieldValue('additionalResources', list),
    [setFieldValue],
  );

  const handleTypeChange = useCallback(
    (value, name) => {
      handleSelectChange(value, name);
      setFieldValue('executorId', null);
      setFieldValue('subType', '');
      const correspondingIdentifiersType = TASK_TYPE_INFO[value].identifierType;

      if (correspondingIdentifiersType) {
        setFieldValue('identifiersType', correspondingIdentifiersType);
      }
    },
    [handleSelectChange, setFieldValue],
  );

  const executorOptions = executors.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const hasVisibleErrors = useMemo(
    () => checkHasVisibleErrors(errors, touched),
    [errors, touched],
  );

  useEffect(() => {
    resetForm();
  }, [resetForm, isOpened]);

  useEffect(() => {
    resetForm({
      values: initValues({
        type: moderatingAdId ? TASK_TYPE.MODERATION : '',
        identifiersType: moderatingAdId ? TASK_IDENTIFIER_TYPE.AD_ID : '',
        identifiers: moderatingAdId ? [moderatingAdId] : [],
      }),
    });
  }, [moderatingAdId, resetForm]);

  useEffect(() => {
    if (values.type) {
      onTaskTypeChange(values.type);
    }
  }, [values.type, onTaskTypeChange]);

  const handleIdentifiersTextareaChange = useCallback(
    (e) => {
      const { value } = e.target;
      setFieldValue('identifiers', value.split(IDENTIFIERS_REG_EXP));
    },
    [setFieldValue],
  );

  const actions = useMemo(
    () => [
      <Button
        key="cancel"
        title={<FormattedMessage defaultMessage="Cancel" />}
        variant="flat"
        onClick={onClose}
        withLargeSideInnerGaps
      />,
      <Button
        key="save"
        title={<FormattedMessage defaultMessage="Save" />}
        isDisabled={hasVisibleErrors || isLoading}
        onClick={handleSubmit}
        withLargeSideInnerGaps
      />,
    ],
    [hasVisibleErrors, isLoading, handleSubmit, onClose],
  );

  useEffect(() => {
    if (values.zendeskUrl) {
      setFieldValue('priority', PRIORITY.HIGH);
    }
  }, [values.zendeskUrl, setFieldValue]);

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="Task creation" />}
      onClose={onClose}
      isOpened={isOpened}
      actions={actions}
    >
      <Form.Row>
        <FormGroup>
          <FormGroup.HalfItem>
            <Field
              label={<FormattedMessage defaultMessage="Task type" />}
              error={touched.type && errors.type}
              isRequired
            >
              <Select
                value={values.type}
                options={TASK_TYPE_OPTIONS}
                name="type"
                placeholder={
                  <FormattedMessage defaultMessage="Choose task type" />
                }
                onChange={handleTypeChange}
                onBlur={handleBlur}
              />
            </Field>
          </FormGroup.HalfItem>

          <FormGroup.HalfItem>
            <Field label={<FormattedMessage defaultMessage="Task subtype" />}>
              <Select
                value={values.subType}
                options={subTypeOptions}
                name="subType"
                placeholder={
                  <FormattedMessage defaultMessage="Choose task subtype" />
                }
                isDisabled={subTypeOptions.length === 0}
                onChange={handleSelectChange}
                onBlur={handleBlur}
              />
            </Field>
          </FormGroup.HalfItem>
        </FormGroup>
      </Form.Row>

      <Form.Row>
        <FormGroup>
          <FormGroup.NarrowItem>
            <Field
              label={<FormattedMessage defaultMessage="Priority" />}
              hasDefaultHeight
            >
              <Fieldset>
                {SORTED_PRIORITIES.map((priority) => (
                  <Fieldset.Item key={priority}>
                    <Radio
                      name="priority"
                      value={priority}
                      label={PRIORITY_INFO[priority].title}
                      checked={priority === values.priority}
                      onChange={handleChange}
                    />
                  </Fieldset.Item>
                ))}
              </Fieldset>
            </Field>
          </FormGroup.NarrowItem>

          <FormGroup.WideItem>
            <Field
              label={<FormattedMessage defaultMessage="Zendesk link" />}
              error={touched.zendeskUrl && errors.zendeskUrl}
            >
              <ZendeskLinkInput
                value={values.zendeskUrl}
                name="zendeskUrl"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Field>
          </FormGroup.WideItem>
        </FormGroup>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Identifiers" />}
          labelElement={
            <Switch
              name="isManyTasks"
              label={
                <FormattedMessage defaultMessage="Create multiple tasks" />
              }
              value={values.isManyTasks}
              onChange={handleChange}
            />
          }
          hasDefaultHeight
          isRequired
        >
          <Fieldset>
            {Object.values(TASK_IDENTIFIER_TYPE_INFO).map(({ slug, title }) => (
              <Fieldset.Item key={slug}>
                <Radio
                  name="identifiersType"
                  value={slug}
                  label={<FormattedMessage {...title} />}
                  checked={slug === values.identifiersType}
                  isDisabled={values.type === TASK_TYPE.CALL}
                  onChange={handleChange}
                />
              </Fieldset.Item>
            ))}
          </Fieldset>
        </Field>

        <Field
          error={
            (touched.identifiersType && errors.identifiersType) ||
            (touched.identifiers && errors.identifiers)
          }
        >
          {values.isManyTasks ? (
            <Textarea
              name="identifiers"
              minRows={4}
              placeholder={formPlaceholderByIdentifiersType(
                values.identifiersType,
                intl.formatMessage,
              )}
              isDisabled={!values.identifiersType}
              defaultValue={values.identifiers
                .reduce((acc, current) => `${acc}\n${current}`, '')
                .trim()}
              onChange={handleIdentifiersTextareaChange}
              onBlur={handleBlur}
            />
          ) : (
            <InputList
              name="identifiers"
              list={values.identifiers}
              placeholder={formPlaceholderByIdentifiersType(
                values.identifiersType,
                intl.formatMessage,
              )}
              isDisabled={!values.identifiersType}
              onChange={handleIdentifiersChange}
              onBlur={handleBlur}
            />
          )}
        </Field>
      </Form.Row>

      <Form.Row>
        <Field label={<FormattedMessage defaultMessage="Assignee" />}>
          <Select
            value={values.executorId}
            options={executorOptions}
            name="executorId"
            placeholder={<FormattedMessage defaultMessage="Assign" />}
            noOptionsMessage={
              isExecutorsLoading ? (
                <FormattedMessage defaultMessage="Loading..." />
              ) : (
                <FormattedMessage defaultMessage="No assignees for this task" />
              )
            }
            isDisabled={!values.type}
            onChange={handleSelectChange}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Additional resources" />}
          error={touched.additionalResources && errors.additionalResources}
        >
          <InputList
            name="additionalResources"
            type="url"
            list={values.additionalResources}
            prependedIcon={<IconLink />}
            onChange={handleAdditionalResources}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field label={<FormattedMessage defaultMessage="Comment" />}>
          <Textarea name="comment" onChange={handleChange} />
        </Field>
      </Form.Row>
    </Dialog>
  );
};

export default TaskCreationDialog;
