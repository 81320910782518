import Textarea from '../Textarea/Textarea';
import IconContainer from '../IconContainer/IconContainer';
import { IconSend } from '../icons';

import styles from './CommentTextarea.module.scss';

const CommentTextarea = ({ isSubmitButtonDisabled, onSubmit, ...rest }) => (
  <Textarea
    name="comment"
    appendedItem={
      <button
        className={styles.commentButton}
        disabled={isSubmitButtonDisabled}
        onClick={onSubmit}
        type="button"
      >
        <IconContainer>
          <IconSend />
        </IconContainer>
      </button>
    }
    {...rest}
  />
);

export default CommentTextarea;
