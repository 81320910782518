import cx from 'classnames';
import Svg from '../Svg/Svg';
import styles from './Sort.module.scss';

const IconSort = ({ highlighted }) => (
  <Svg>
    <path
      className={cx(styles.root, {
        [styles.root_highlighted]: !highlighted || highlighted === 'top',
      })}
      d="M4.525 9.864h14.95c.289 0 .54-.105.75-.317.212-.211.318-.461.318-.75 0-.29-.106-.54-.317-.751L12.75.57C12.54.36 12.289.254 12 .254s-.54.106-.75.317L3.773 8.046a1.026 1.026 0 00-.317.75c0 .29.106.54.317.751.212.212.462.317.751.317z"
    />
    <path
      className={cx(styles.root, {
        [styles.root_highlighted]: !highlighted || highlighted === 'bottom',
      })}
      d="M19.475 14.136H4.525c-.29 0-.54.106-.75.317a1.026 1.026 0 00-.318.75c0 .29.106.54.317.751l7.475 7.475c.212.211.462.317.751.317s.54-.106.75-.317l7.476-7.475c.21-.21.317-.461.317-.75 0-.29-.106-.54-.317-.751a1.026 1.026 0 00-.751-.317z"
    />
  </Svg>
);

export default IconSort;
