import cx from 'classnames';
import styles from './TileSection.module.scss';

const TileSection = ({ title, titleAppended, children, isGrey }) => (
  <section className={cx(styles.root, { [styles.root_grey]: isGrey })}>
    <header className={styles.header}>
      <h2 className={styles.title}>{title}</h2>

      {titleAppended && (
        <div className={styles.titleAppended}>{titleAppended}</div>
      )}
    </header>

    <div>{children}</div>
  </section>
);

export default TileSection;
