@import '../../styles/utils.scss';

.root {
  @extend %normalize-button;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 46px;
  padding: 14px 24px;
  border: 1px solid $primaryBlue;
  border-radius: $primaryBorderRadius;
  background-color: $primaryBlue;
  color: $white;
  line-height: 18px;
  @include defaultTransition(background-color, border, opacity);

  &:hover {
    background-color: $secondaryBlue;
    border: 1px solid $secondaryBlue;
  }

  &:active {
    background-color: $primaryBlue;
    border: 1px solid $primaryBlue;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &_withLargeSideInnerGaps {
    padding-left: 40px;
    padding-right: 40px;
  }

  &_expanded {
    width: 100%;
  }

  &_small {
    height: 38px;
    padding: 10px 16px;
  }
}

.root_variant--secondary {
  background-color: transparent;
  border: 1px solid $primaryBlue;
  color: $primaryBlue;
  @include defaultTransition(background-color, color, border);

  &:hover {
    border: 1px solid $primaryBlue;
    background-color: $primaryBlue;
    color: $white;
  }

  &:active {
    background-color: transparent;
    color: $primaryBlue;
  }
}

.root_variant--flat {
  background-color: transparent;
  border: 1px solid transparent;
  color: $primaryBlue;
  @include defaultTransition(border, background-color, color, opacity);

  &:hover {
    border: 1px solid $primaryBlue;
    background-color: transparent;
  }

  &:active {
    border: 1px solid $secondaryBlue;
    background-color: $secondaryBlue;
    color: $white;
  }
}

.title {
  flex: 1 1 auto;
  @include overflowEllipsis;
}

.prependedIcon {
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 8px;
}

.appendedIcon {
  flex: 0 0 auto;
  display: inline-block;
  margin-left: 8px;
}
