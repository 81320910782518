.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkboxGroup {
  margin-right: 24px;
}

.filtersSwitch {
  margin-right: auto;
}
