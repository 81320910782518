import { useState, useCallback } from 'react';

const DEFAULT_MIN_DATE = new Date('2021-07-01T00:00:00');

const currentDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
); // create date with no timestamp
const firstDayOfCurrentMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1,
);

const useCalendar = ({
  defaultDateFrom = firstDayOfCurrentMonth,
  defaultDateTo = currentDate,
  minDate = DEFAULT_MIN_DATE,
  maxDate = currentDate,
} = {}) => {
  const [dateRange, setDate] = useState(() => [
    new Date(Math.max(new Date(defaultDateFrom), new Date(minDate))),
    new Date(Math.min(currentDate, new Date(defaultDateTo))),
  ]);

  const handleDateChange = useCallback(
    ([dateFrom, dateTo]) => setDate([dateFrom, dateTo]),
    [],
  );

  return [{ dateRange, minDate, maxDate }, handleDateChange];
};

export default useCalendar;
