import { AD_PAGE_DEFAULT_SECTION } from './constants';

export const getLoginPageLink = () => '/login';
export const getDashboardPageLink = () => '/dashboard';
export const getTaskListPageLink = () => '/task-list';
export const getTaskQueuePageLink = () => '/task-queue';
export const getUsersPageLink = () => '/users';
export const getUsersProductivityStatisticPageLink = () =>
  '/users-productivity-statistic';
export const getSearchPageLink = () => '/search';
export const getAdPageLink = (adId, section = AD_PAGE_DEFAULT_SECTION) =>
  `/ad/${adId}/${section}`;
export const getPhonePageLink = (phone) => `/phone/${phone}`;
