import { useCallback } from 'react';
import Select from '../../Select/Select';
import './FilterSelect.scss';

const TableFilterSelect = ({ onChange, ...rest }) => {
  const handleChange = useCallback(
    (value, name) => onChange(name, value),
    [onChange],
  );

  return (
    <Select
      className="FilterSelect"
      classNamePrefix="FilterSelect"
      onChange={handleChange}
      {...rest}
    />
  );
};

export default TableFilterSelect;
