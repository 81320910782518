import { FormattedMessage } from 'react-intl';
import Label from '../Label/Label';

const ComplaintTaskLabel = () => (
  <Label
    title={<FormattedMessage defaultMessage="Complaint" />}
    backgroundColor="#fbc000"
    color="#fff"
  />
);

export default ComplaintTaskLabel;
