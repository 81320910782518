import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useGeneralOptions = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      locationGeneralOption: {
        value: 'ukraine',
        title: intl.formatMessage({ defaultMessage: 'All Ukraine' }),
      },

      siteGeneralOption: {
        value: 'all',
        title: intl.formatMessage({ defaultMessage: 'All sites' }),
      },

      sectionGeneralOption: {
        value: 'all',
        title: intl.formatMessage({ defaultMessage: 'All property types' }),
      },
    }),
    [intl],
  );
};

export default useGeneralOptions;
