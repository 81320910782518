import { useEffect, useRef, useCallback, useMemo } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'throttle-debounce';

import Page from '../../components/Page/Page';
import DefaultPageLayout from '../../components/DefaultPageLayout/DefaultPageLayout';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import SearchInput from '../../components/SearchInput/SearchInput';
import DefaultStatisticGroup from '../../components/CounterStatisticGroup/CounterStatisticGroup';
import TasksTable from '../../components/TasksTable/TasksTable';
import TaskListSubtitle from './Subtitle/Subtitle';

import {
  useNotifications,
  usePagination,
  useTaskOverviewDialog,
} from '../../hooks';
import { createFilterShape } from '../../utils';
import { DIALOG_NAME, FILTER_TYPE } from '../../constants';
import withRoles from '../../components/PagesScene/withRoles';
import * as Tasks from '../../models/tasks';
import * as Dialog from '../../models/dialog';
import useTaskCountStatistic from './useTaskCountStatistic';
import useSort from './useSort';
import useSearch from './useSearch';
import useFilters from './useFilters';
import useExecutors from './useExecutors';
import useCreatorFilterOptions, {
  AUTOMATICALLY_CREATED_IDENTIFIER,
} from './useCreatorFilterOptions';
import { serializeDateRange } from '../../normalizers';

const SEARCH_DELAY_MS = 300;

const getCreatorFilter = (creator, creatorFilterOptions) => {
  if (creator === AUTOMATICALLY_CREATED_IDENTIFIER.COMPLAINT) {
    return createFilterShape('isComplaint', FILTER_TYPE.EXACT_MATCH, true);
  }

  if (creator === AUTOMATICALLY_CREATED_IDENTIFIER.AUTOMATIC) {
    return createFilterShape('isAutomatic', FILTER_TYPE.EXACT_MATCH, true);
  }

  if (creatorFilterOptions.map(({ value }) => value).includes(creator)) {
    return createFilterShape('createdBy', FILTER_TYPE.EXACT_MATCH, creator);
  }

  return null;
};

const serializeFilters = (filters, creatorFilterOptions) => {
  const { date, executorId, isOnlyCompleted, taskType, taskSubType, creator } =
    filters;
  const filterArray = [
    createFilterShape('isCompleted', FILTER_TYPE.EXACT_MATCH, isOnlyCompleted),
  ];

  const creatorFilter = getCreatorFilter(creator, creatorFilterOptions);

  if (creatorFilter) {
    filterArray.push(creatorFilter);
  }

  if (executorId) {
    filterArray.push(
      createFilterShape('executorId', FILTER_TYPE.EXACT_MATCH, executorId),
    );
  }

  if (taskType) {
    filterArray.push(
      createFilterShape('taskType', FILTER_TYPE.MULTI_MATCH, [taskType]),
    );
  }

  if (taskSubType) {
    filterArray.push(
      createFilterShape('taskSubType', FILTER_TYPE.MULTI_MATCH, [taskSubType]),
    );
  }

  if (date.filter(Boolean).length === 2) {
    filterArray.push(
      createFilterShape(
        'createTime',
        FILTER_TYPE.DATE_MATCH,
        serializeDateRange(date),
      ),
    );
  }

  return filterArray;
};

const TaskListPage = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const isFirstRenderRef = useRef(true);
  const [sort, toggleSort] = useSort();
  const [search, onSearchChange] = useSearch();

  const [
    { filters, isTableFiltersShown },
    {
      onIsOnlyCompletedFilterChange,
      onTableFilterChange,
      onTableFiltersReset,
      onIsTableFiltersShownToggle,
    },
  ] = useFilters();

  const { creatorFilterOptions, isCreatorFilterOptionsLoading } =
    useCreatorFilterOptions();
  const serializedFilters = useMemo(
    () => serializeFilters(filters, creatorFilterOptions),
    [filters, creatorFilterOptions],
  );

  const tableFilters = useMemo(() => {
    const tableFilters = { ...filters };
    delete tableFilters.isOnlyCompleted;
    delete tableFilters.isOnlyMy;

    return tableFilters;
  }, [filters]);

  const tasksAmount = useAtom(Tasks.PreviewList.shownTotalAmountAtom);
  const { open: openTaskEditingDialog } = useTaskOverviewDialog();

  const [{ page, perPage, amount }, { onNext, onPrev, goToPage }] =
    usePagination({
      amount: tasksAmount,
    });

  const statistic = useTaskCountStatistic(
    { search, filters: serializedFilters },
    tasksAmount,
  );
  const { executorsOptions, isExecutorOptionsLoading } = useExecutors(
    filters.taskType,
  );

  const loadingOverviewTaskIdsAtom = useAtom(Tasks.Overview.loadingTaskIdsAtom);
  const tasks = useAtom(Tasks.PreviewList.makeTaskPreviewListAtom(page));

  const isTasksFailed = useAtom(Tasks.PreviewList.makeShownFailedAtom(page));
  const isTasksLoading = useAtom(Tasks.PreviewList.makeShownLoadingAtom(page));
  const isTasksReady = useAtom(Tasks.PreviewList.makeShownReadyAtom(page));

  const handleCreateTaskClick = useAction(() =>
    Dialog.openDialog({ name: DIALOG_NAME.TASK_CREATION }),
  );
  const handleTaskEditClick = useCallback(
    (taskId) => openTaskEditingDialog(taskId),
    [openTaskEditingDialog],
  );

  const handleLoadAllTasks = useAction(Tasks.PreviewList.shown);
  const handleTasksReset = useAction(Tasks.PreviewList.resetTasks);

  useEffect(() => {
    if (!isTasksReady && !isTasksLoading) {
      handleLoadAllTasks({
        page,
        perPage,
        searchQuery: search.trim(),
        sort,
        filters: serializedFilters,
      });
    }
  }, [
    page,
    perPage,
    search,
    sort,
    isTasksReady,
    isTasksLoading,
    serializedFilters,
    handleLoadAllTasks,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResetState = useCallback(
    debounce(SEARCH_DELAY_MS, () => {
      handleTasksReset();
      goToPage(1);
    }),
    [handleTasksReset],
  );

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    debouncedResetState();
  }, [search, sort, filters, debouncedResetState]);

  useEffect(() => {
    if (!isTasksFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load tasks. Try reloading the page',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTasksFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    return handleTasksReset;
  }, [handleTasksReset]);

  return (
    <FormattedMessage defaultMessage="Task list">
      {([helmetTitle]) => (
        <Page helmetTitle={helmetTitle}>
          <DefaultPageLayout>
            <DefaultPageLayout.WideCol>
              <PageMainContentTile
                title={<FormattedMessage defaultMessage="Task list" />}
                titleAppended={
                  <SearchInput
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Task or ad id',
                    })}
                    value={search}
                    onChange={onSearchChange}
                    isGrey
                  />
                }
                subtitleElement={
                  <TaskListSubtitle
                    isCompletedShown={filters.isOnlyCompleted}
                    isTableFiltersShown={isTableFiltersShown}
                    onTableFiltersToggle={onIsTableFiltersShownToggle}
                    onCompletedShownChange={onIsOnlyCompletedFilterChange}
                    onCreateTaskClick={handleCreateTaskClick}
                  />
                }
              >
                <TasksTable
                  page={page}
                  perPage={perPage}
                  amount={amount}
                  tasks={tasks}
                  sort={sort}
                  filters={tableFilters}
                  executorsOptions={executorsOptions}
                  creatorFilterOptions={creatorFilterOptions}
                  isExecutorOptionsLoading={isExecutorOptionsLoading}
                  isCreatorFilterOptionsLoading={isCreatorFilterOptionsLoading}
                  loadingOverviewTaskIdsAtom={loadingOverviewTaskIdsAtom}
                  isLoading={isTasksLoading}
                  isEmpty={isTasksFailed || (isTasksReady && tasks?.length < 1)}
                  isActionsShown={isTableFiltersShown}
                  onTaskEditClick={handleTaskEditClick}
                  onPaginationPrevClick={onPrev}
                  onPaginationNextClick={onNext}
                  onSort={toggleSort}
                  onFilter={onTableFilterChange}
                  onFilterReset={onTableFiltersReset}
                />
              </PageMainContentTile>
            </DefaultPageLayout.WideCol>

            <DefaultPageLayout.NarrowCol>
              <DefaultStatisticGroup
                primary={statistic.primary}
                list={statistic.list}
              />
            </DefaultPageLayout.NarrowCol>
          </DefaultPageLayout>
        </Page>
      )}
    </FormattedMessage>
  );
};

TaskListPage.RouteParams = withRoles()({
  element: <TaskListPage />,
  path: '/task-list',
});

export default TaskListPage;
