.root {
  display: flex;
  margin-right: 18px;
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }

  &_isVisibleWhenRowTranslucent {
    position: relative;
    z-index: 1;
  }

  &_horizontalAlignment__center {
    justify-content: center;
  }
}

.content {
  overflow: hidden;
}
