import { FormattedMessage } from 'react-intl';

import Fieldset from '../../../components/Fieldset/Fieldset';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { IconPlus } from '../../../components/icons';

import styles from './SubtitleGroup.module.scss';

const UsersPageSubtitleGroup = ({
  isActiveShown,
  isInactiveShown,
  onActiveShownChange,
  onInactiveShownChange,
  onAddNewUserClick,
}) => (
  <div className={styles.root}>
    <div className={styles.checkboxGroup}>
      <Fieldset>
        <Fieldset.Item>
          <Checkbox
            label={<FormattedMessage defaultMessage="Active users" />}
            value={isActiveShown}
            onChange={onActiveShownChange}
          />
        </Fieldset.Item>

        <Fieldset.Item>
          <Checkbox
            label={<FormattedMessage defaultMessage="Inactive users" />}
            value={isInactiveShown}
            onChange={onInactiveShownChange}
          />
        </Fieldset.Item>
      </Fieldset>
    </div>

    <div>
      <Button
        title={<FormattedMessage defaultMessage="Add user" />}
        prependedIcon={<IconPlus />}
        onClick={onAddNewUserClick}
      />
    </div>
  </div>
);

export default UsersPageSubtitleGroup;
