import { combine } from '@reatom/core';

import overviewAtom from './overview';
import statusAtom from './status';
import houseEditing from './houseEditing';
import houseOptionsAtom from './houseOptions';
import publishingAtom from './publishing';
import removingAtom from './removing';
import reprocessingAtom from './reprocessing';

export * as Overview from './overview';
export * as Status from './status';
export * as HouseEditing from './houseEditing';
export * as HouseOptions from './houseOptions';
export * as Publishing from './publishing';
export * as Removing from './removing';
export * as Reprocessing from './reprocessing';

export default combine({
  overviewAtom,
  statusAtom,
  houseEditing,
  houseOptionsAtom,
  publishingAtom,
  removingAtom,
  reprocessingAtom,
});
