import cx from 'classnames';
import styles from './Fieldset.module.scss';

const Fieldset = ({ children, isInline }) => (
  <div className={cx(styles.root, { [styles.root_inline]: isInline })}>
    {children}
  </div>
);

Fieldset.Item = ({ children }) => <div className={styles.item}>{children}</div>;
export default Fieldset;
