const NoResultsPlaceholderImage = () => (
  <svg viewBox="0 0 212 221" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h212v221H0z" />
      <g transform="translate(19 10)" fillRule="nonzero">
        <path
          d="M62.647 28 9.537 174.642c-1.609 4.434.543 9.352 4.888 11.17C26.743 190.993 53.17 200 88.005 200c34.833 0 61.253-9.008 73.58-14.187 4.345-1.82 6.496-6.738 4.887-11.171L113.362 28H62.647z"
          fill="#FEA691"
          opacity=".27"
        />
        <circle fill="#3E7EFF" cx="32.004" cy="40" r="5" />
        <circle fill="#3E7EFF" cx="144.004" cy="40" r="5" />
        <circle fill="#3E7EFF" cx="88.004" cy="48" r="5" />
        <path
          d="M155.004 30.999c0 9.39-26.953 17.001-66.786 17.001-39.832 0-66.214-7.61-66.214-17.001C22.004 21.609 48.372 14 88.206 14c39.835 0 66.798 7.61 66.798 16.999z"
          fill="#3E7EFF"
        />
        <path
          d="M155.004 24c0 9.387-26.956 17-66.792 17-39.835 0-66.208-7.613-66.208-17S48.374 7 88.212 7c39.839 0 66.792 7.613 66.792 17z"
          fill="#FFF"
          opacity=".42"
          style={{ mixBlendMode: 'soft-light' }}
        />
        <path
          d="M88.73 30c11.34 0 21.997-1.361 31.274-3.756C117.984 11.448 104.182 0 87.454 0 70.98 0 57.341 11.107 55.004 25.583 64.837 28.386 76.382 30 88.73 30z"
          fill="#3E7EFF"
        />
        <path
          d="M113.985 144.007c.26-2.47-2.12-3.67-6.181-3.954-4.061-.285-6.519.57-6.778 3.039-.093.854.12 1.713.601 2.429.18.279.18.635 0 .915-.383.613-.681 1.245-.463 1.487.315.35 1.06.043 1.916-.534a.86.86 0 0 1 .83-.07c.57.242 1.166.42 1.776.53.346.061.62.322.696.66.146.72.434 1.438 1.01 1.486.727.067 1.103-.513 1.246-1.363a.858.858 0 0 1 .714-.719c.312-.048.622-.114.926-.198a.866.866 0 0 1 .825.227c.694.668 1.337 1.025 1.713.719.242-.204.094-.839-.14-1.437a.856.856 0 0 1 .179-.92 3.766 3.766 0 0 0 1.13-2.297z"
          fill="#FF97C9"
        />
        <path
          d="M61.385 122.373c-2.754 6.159-3.335 18.361-3.743 19.172-.407.81-3.244 2.276-2.52 3.76.667 1.365 5.29 3.224 6.67 2.843 2.144-.578 4.262-6.262 5.565-9.863 4.764 2.843 12.73 3.804 12.73 3.804s-.808 5.907-1.43 7.2c-.621 1.294-2.796 2.874-2.348 4.451.448 1.578 4.74 2.468 6.646 2.712 1.906.244 4.99-10.156 5.703-13.797 12.675 1.334 27.976.365 27.976.365s1.832 6.35.04 9.183c-.817 1.293-1.336 4.027-.895 4.209 2.727 1.092 6.243.474 7.365-.325 1.263-.89 5.698-21.383 3.011-29.119-2.687-7.735-7.994-12.17-17.335-14.514C99.48 110.109 85.382 110 85.382 110l-23.997 12.373z"
          fill="#FFF"
        />
        <g fill="#1C3177">
          <path d="M103.55 111.36s-1.945 7.46 1.994 11.398c3.94 3.938 12.004 3.436 16.382-1.714-4.19-7.72-18.376-9.684-18.376-9.684zm-44.683 20.974s3.431 1.25 4.337 4.59c-2.041 3.263-5.2 2.267-5.2 2.267" />
          <path d="M110.357 122.36c2.51 0 4.333.852 5.426 2.48.832 1.238 3.279 4.245 6.012 2.59 3.578-2.157-1.922-9.662-1.922-9.662l-6.845-.532-2.671 5.123z" />
        </g>
        <path
          d="M61.004 123.81s4.092 9.197 14.362 9.19c8.997 0 14.682-10.043 11.95-17-8.826 3.5-26.312 7.81-26.312 7.81z"
          fill="#F0F4FF"
        />
        <path
          d="M58.415 97s-2.437-6.028-.904-8.55c1.533-2.522 6.493 6.381 6.493 6.381L58.414 97zm27.182 0s2.433-6.028.9-8.55c-1.532-2.522-6.493 6.381-6.493 6.381L85.597 97z"
          fill="#FEC272"
        />
        <path
          d="M88.944 111.106c-.794 9.147-3.893 17.025-18.764 15.76-14.872-1.265-16.82-9.572-16.026-18.716.794-9.145 9.228-15.897 18.834-15.084 9.607.814 16.75 8.893 15.956 18.04z"
          fill="#FFF"
        />
        <path
          d="M69.004 93s-19.671 1.734-21.82 8.708c-1.414 4.593 5.867 4.348 10.567-.533C58.699 99.305 69.004 93 69.004 93z"
          fill="#FFF"
        />
        <path
          d="M74.004 93s19.671 1.734 21.821 8.708c1.415 4.593-5.865 4.348-10.569-.533C84.308 99.305 74.004 93 74.004 93z"
          fill="#1C3177"
        />
        <circle fill="#1C3177" cx="60.504" cy="110.5" r="2.5" />
        <circle fill="#1C3177" cx="82.504" cy="112.5" r="2.5" />
        <path
          d="M78.989 117.122c-.258 3.054-3.53 5.259-8.536 4.823-5.004-.436-7.695-3.167-7.43-6.22.264-3.055 3.26-4.066 8.263-3.628 5.002.438 7.962 1.97 7.703 5.025z"
          fill="#FF97C9"
        />
        <ellipse
          fill="#FF97C9"
          style={{ mixBlendMode: 'multiply' }}
          transform="rotate(-85.09 67.208 115.578)"
          cx="67.208"
          cy="115.578"
          rx="1.117"
          ry="1.166"
        />
        <ellipse
          fill="#FF97C9"
          style={{ mixBlendMode: 'multiply' }}
          transform="rotate(-85.09 75.148 116.262)"
          cx="75.148"
          cy="116.262"
          rx="1.117"
          ry="1.166"
        />
        <path
          d="M72.76 93s-3.44 5.902 4.359 10.258c5.834 3.255 10.885.95 10.885.95S85.857 93.945 72.76 93zm34.377 34.043c2.511.236 3.974 5.005 1.833 6.508-2.14 1.504-5.72-1.076-5.94-3.253-.22-2.176.921-3.564 4.107-3.255zm-26.024 3.574c.814-2.446 6.39-1.939 8.894.327 2.504 2.266 3.759 6.995 2.507 7.886-1.252.89-3.842-1.961-6.154-2.792-2.313-.831-6.038-3.053-5.247-5.42z"
          fill="#1C3177"
        />
        <ellipse
          fill="#FFF"
          opacity=".42"
          style={{ mixBlendMode: 'soft-light' }}
          transform="rotate(-75.84 93.283 10.939)"
          cx="93.283"
          cy="10.939"
          rx="5.565"
          ry="12.751"
        />
        <path
          d="M141.665 60.698a3.05 3.05 0 0 1 3.969 1.69l13.116 32.597a3.052 3.052 0 0 1-5.659 2.28l-13.117-32.596a3.052 3.052 0 0 1 1.691-3.97zM11.972 131.531a3.052 3.052 0 0 1 1.658 3.984l-6.823 16.553a3.05 3.05 0 0 1-3.981 1.657h-.001a3.052 3.052 0 0 1-1.658-3.985l6.822-16.552a3.05 3.05 0 0 1 3.983-1.657z"
          fill="#FEA691"
        />
      </g>
    </g>
  </svg>
);

export default NoResultsPlaceholderImage;
