import cx from 'classnames';
import styles from './Section.module.scss';

const Section = ({ children, title, isVerticallyGrowing }) => (
  <section
    className={cx(styles.root, {
      [styles.root_isVerticallyGrowing]: isVerticallyGrowing,
    })}
  >
    {title && <h2 className={styles.title}>{title}</h2>}

    <div>{children}</div>
  </section>
);

export default Section;
