import TaskLabel from '../../../components/TaskLabel/TaskLabel';
import AutomaticTaskLabel from '../../../components/AutomaticTaskLabel/AutomaticTaskLabel';
import ComplaintTaskLabel from '../../../components/ComplaintTaskLabel/ComplaintTaskLabel';

import styles from './QueuedTaskMetaInfo.module.scss';

const QueuedTaskMetaInfo = ({
  taskId,
  taskType,
  isTaskAutomatic,
  isTaskComplaint,
}) => (
  <div className={styles.root}>
    <div>#{taskId}</div>

    <span>
      {isTaskAutomatic && (
        <span className={styles.labelItem}>
          <AutomaticTaskLabel />
        </span>
      )}

      {isTaskComplaint && (
        <span className={styles.labelItem}>
          <ComplaintTaskLabel />
        </span>
      )}

      <span className={styles.labelItem}>
        <TaskLabel type={taskType} />
      </span>
    </span>
  </div>
);

export default QueuedTaskMetaInfo;
