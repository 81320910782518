import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Loader from '../../../components/Loader/Loader';
import Button from '../../../components/Button/Button';
import Form from '../../../components/Form/Form';
import FinishTaskDropdown from './FinishTaskDropdown/FinishTaskDropdown';
import CommonTaskResolvePlateFieldsGroup from './CommonFieldsGroup/CommonFieldsGroup';
import CallTaskResolvePlateFieldsGroup from './CallFieldsGroup/CallFieldsGroup';

import {
  TASK_SUBTYPE_INFO,
  TASK_TYPE,
  TASK_TYPE_INFO,
} from '../../../constants';
import styles from './TaskResolvePlate.module.scss';

const TaskResolvePlateFieldsGroupSwitch = ({
  values,
  errors,
  touched,
  phoneList,
  onBlur,
  onChange,
  onFieldValueSet,
  onFieldTouchedSet,
  onSelectChange,
}) => {
  if (values.type === TASK_TYPE.CALL) {
    return (
      <CallTaskResolvePlateFieldsGroup
        phoneList={phoneList}
        callStatus={values.callStatus}
        callResult={values.callResult}
        callComment={values.callComment}
        callPhoneError={touched.callPhone && errors.callPhone}
        callStatusError={touched.callStatus && errors.callStatus}
        callResultError={touched.callResult && errors.callResult}
        onBlur={onBlur}
        onChange={onChange}
        onFieldValueSet={onFieldValueSet}
        onFieldTouchedSet={onFieldTouchedSet}
        onSelectChange={onSelectChange}
      />
    );
  }

  return (
    <CommonTaskResolvePlateFieldsGroup
      taskType={values.type}
      solution={values.solution}
      solutionError={touched.solution && errors.solution}
      onSelectChange={onSelectChange}
    />
  );
};

const TaskResolvePlate = ({
  values,
  errors,
  touched,
  phoneList,
  isLoading,
  isFailed,
  isQueueEmpty,
  isQueueStopped,
  onBlur,
  onChange,
  onFieldValueSet,
  onFieldTouchedSet,
  onSkip,
  onSubmit,
  onSelectChange,
}) => {
  const handleSubmit = useCallback(() => onSubmit(false), [onSubmit]);
  const handleSkip = useCallback(() => onSkip(false), [onSkip]);
  const handleSubmitAndStopQueue = useCallback(
    () => onSubmit(true),
    [onSubmit],
  );
  const handleSkipAndStopQueue = useCallback(() => onSkip(true), [onSkip]);

  if (isLoading) {
    return (
      <div className={cx(styles.root, styles.root_centerAligned)}>
        <Loader size="small" />
      </div>
    );
  }

  if (isFailed || isQueueEmpty || isQueueStopped) {
    return (
      <div className={cx(styles.root, styles.root_centerAligned)}>
        <FormattedMessage defaultMessage="Information is unavailable" />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Form.Row>
        <div className={styles.taskTypeRow}>
          {values.type && (
            <FormattedMessage {...TASK_TYPE_INFO[values.type].title} />
          )}

          {values.subType && (
            <FormattedMessage {...TASK_SUBTYPE_INFO[values.subType].title} />
          )}
        </div>
      </Form.Row>

      <TaskResolvePlateFieldsGroupSwitch
        values={values}
        errors={errors}
        touched={touched}
        phoneList={phoneList}
        onBlur={onBlur}
        onChange={onChange}
        onFieldValueSet={onFieldValueSet}
        onFieldTouchedSet={onFieldTouchedSet}
        onSelectChange={onSelectChange}
      />

      <Form.Row>
        <div className={styles.buttonGroup}>
          <div className={styles.buttonItem}>
            <Button
              title={<FormattedMessage defaultMessage="Skip" />}
              variant="flat"
              onClick={handleSkip}
              isExpanded
            />
          </div>

          <div className={styles.buttonItem}>
            <Button
              title={<FormattedMessage defaultMessage="Resolve" />}
              onClick={handleSubmit}
              isExpanded
            />
          </div>

          <div>
            <FinishTaskDropdown
              onSubmit={handleSubmitAndStopQueue}
              onSkip={handleSkipAndStopQueue}
            />
          </div>
        </div>
      </Form.Row>
    </div>
  );
};

export default TaskResolvePlate;
