import { forwardRef, useCallback, useMemo } from 'react';
import { useAtom } from '@reatom/react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import Flatpickr from 'react-flatpickr';
import locales from 'flatpickr/dist/l10n';

import { IconAngleArrowRight, IconAngleArrowLeft } from '../../../icons';

import * as Localization from '../../../../models/localization';
import './DatePicker.scss';

const DatePicker = forwardRef(
  ({ defaultDate, maxDate, minDate, onChange }, ref) => {
    const language = useAtom(Localization.languageAtom);

    const handleChange = useCallback(
      (dateRange) => {
        if (dateRange.length !== 2) {
          return;
        }

        onChange(dateRange);
      },
      [onChange],
    );

    const handleSeasonChange = useCallback(
      (...args) => {
        const range = args[0];

        if (range.length === 1) {
          return;
        }

        const { currentMonth, currentYear } = args[2]; // Flatpickr options
        const firstDayOfCurrentMonthDate = new Date(
          currentYear,
          currentMonth,
          1,
        );
        const lastDayOfCurrentMonthDate = new Date(
          currentYear,
          currentMonth + 1,
          0,
        );

        onChange([firstDayOfCurrentMonthDate, lastDayOfCurrentMonthDate]);
      },
      [onChange],
    );

    const options = useMemo(
      () => ({
        defaultDate,
        maxDate,
        minDate,
        locale: locales[language],
        inline: true,
        mode: 'range',
        nextArrow: ReactDOMServer.renderToStaticMarkup(<IconAngleArrowRight />),
        prevArrow: ReactDOMServer.renderToStaticMarkup(<IconAngleArrowLeft />),
        onChange: handleChange,
        onMonthChange: handleSeasonChange,
        onYearChange: handleSeasonChange,
      }),
      [
        defaultDate,
        maxDate,
        minDate,
        language,
        handleChange,
        handleSeasonChange,
      ],
    );

    return <Flatpickr ref={ref} options={options} />;
  },
);

DatePicker.propTypes = {
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};
DatePicker.displayName = 'Search';
export default DatePicker;
