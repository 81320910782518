import { FormattedMessage } from 'react-intl';

import IconContainer from '../../IconContainer/IconContainer';
import { IconAngleArrowLeft, IconAngleArrowRight } from '../../icons';

import styles from './Pagination.module.scss';

const TablePaginationButton = ({ isPrev, isDisabled, onClick }) => (
  <button className={styles.button} disabled={isDisabled} onClick={onClick}>
    <IconContainer size="xsmall">
      {isPrev ? <IconAngleArrowLeft /> : <IconAngleArrowRight />}
    </IconContainer>
  </button>
);

const TablePagination = ({
  page,
  perPage,
  amount,
  onNextClick,
  onPrevClick,
}) => {
  const countFrom = (page - 1) * perPage + 1;
  const countTo = Math.min(amount, page * perPage);
  const countString =
    countFrom === countTo ? countFrom : `${countFrom}-${countTo}`;

  const isPrevDisabled = page === 1;
  const isNextDisabled = perPage * page >= amount;

  return (
    <div className={styles.root}>
      <div className={styles.counter}>
        <FormattedMessage
          defaultMessage="{count_string} of {amount}"
          values={{ count_string: countString, amount }}
        />
      </div>

      <div className={styles.buttonGroup}>
        <div className={styles.buttonItem}>
          <TablePaginationButton
            onClick={onPrevClick}
            isDisabled={isPrevDisabled}
            isPrev
          />
        </div>

        <div className={styles.buttonItem}>
          <TablePaginationButton
            onClick={onNextClick}
            isDisabled={isNextDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
