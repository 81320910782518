@import '../../styles/utils';

.root {
  display: flex;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 24px;
}

.navigationCol {
  flex: 0 0 264px;
}

@media (min-width: 1560px) {
  .navigationCol {
    flex: 0 0 320px;
  }
}

.navigation {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 24px;

  &::before {
    content: '';
    position: absolute;
    left: 16px;
    right: 24px;
    bottom: 0;
    border-top: 1px solid $grey_2;
  }
}

.settings {
  padding-left: 16px;
  padding-right: 24px;
}

.settingItem {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 264px);
  box-sizing: border-box;
  padding-right: 24px;
}

@media (min-width: 1560px) {
  .body {
    max-width: calc(100% - 320px);
  }
}

.header {
  flex: 0 0 auto;
  padding: 24px 0;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
