import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import { deserializeAdOverview, deserializeAdStatus } from '../../normalizers';
import { checkIsAdPhotoBroken } from '../../utils';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';
import * as HouseEditing from './houseEditing';

export const shownSucceeded = declareAction('Ads/Overview/shownSucceeded');
const shownFailed = declareAction('Ads/Overview/shownFailed');

export const shown = declareAction(
  'Ads/Overview/shown',
  async ({ adId }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const adOverview = await api(authorization).ads.getAd(adId);
      store.dispatch(
        shownSucceeded({
          adId,
          adStatus: deserializeAdStatus(adOverview.general.pageStatusInfo),
          adOverview: deserializeAdOverview(adOverview),
        }),
      );
    } catch (e) {
      console.error(e);

      store.dispatch(
        shownFailed({
          adId,
        }),
      );
    }
  },
);

const tableAtom = declareAtom('Ads/Overview/tableAtom', {}, (on) => [
  on(shown, (state, { adId }) => ({ ...state, [adId]: null })),
  on(shownSucceeded, (state, { adId, adOverview }) => ({
    ...state,
    [adId]: adOverview,
  })),
  on(HouseEditing.saved, (state, { adId, house }) => ({
    ...state,
    [adId]: {
      ...state[adId],
      details: {
        ...state[adId].details,
        geo: {
          ...state[adId].details.geo,
          house: {
            ...state[adId].details.geo.house,
            recognized: house.title,
            id: house.id,
          },
          _previousHouse: state[adId].details.geo.house,
        },
      },
    },
  })),
  on(HouseEditing.savedSucceeded, (state, { adId }) => {
    const geo = { ...state[adId].details.geo };
    delete geo._previousHouse;

    return {
      ...state,
      [adId]: {
        ...state[adId],
        details: {
          ...state[adId].details,
          geo,
        },
      },
    };
  }),
  on(HouseEditing.savedFailed, (state, { adId }) => {
    const geo = { ...state[adId].details.geo };
    geo.house = geo._previousHouse;
    delete geo._previousHouse;

    return {
      ...state,
      [adId]: {
        ...state[adId],
        details: {
          ...state[adId].details,
          geo,
        },
      },
    };
  }),
]);

export const makeAdAtom = (adId) =>
  map(tableAtom, (table) => table[adId] || null);

export const makeAdDetailsAtom = (adId) =>
  map(makeAdAtom(adId), (adOverview) => adOverview?.details || null);

export const makeAdCallsAtom = (adId) =>
  map(makeAdAtom(adId), (adOverview) => adOverview?.calls || []);

export const makeAdStatusAtom = (adId) =>
  map(makeAdAtom(adId), (adOverview) => adOverview?.status || null);

export const makeAdPhotosAtom = (adId) =>
  map(makeAdAtom(adId), (adOverview) => adOverview?.photos || []);

export const makeAdPreviewAtom = (adId) =>
  map(makeAdAtom(adId), (adOverview) => {
    if (!adOverview) {
      return null;
    }

    const { object, house, meta } = adOverview.details;
    const validPhoto = adOverview.photos.find(
      (photo) => !checkIsAdPhotoBroken(photo),
    );

    return {
      media: validPhoto
        ? { title: 'Ad photo', source: validPhoto.url.lun }
        : null,
      price: {
        number: object.price.recognized,
        currency: object.currency.recognized,
      },
      title: object.title,
      apartmentComplex: house.apartmentComplex,
      description: object.description.recognized,
      updateTime: meta.updateTime.raw,
      totalArea: object.totalArea.recognized,
      livingArea: object.livingArea.recognized,
      kitchenArea: object.kitchenArea.recognized,
      floor: object.floor.recognized,
      floorCount: house.floorCount.recognized,
      roomCount: object.roomCount.recognized,
      houseType: house.houseType.recognized,
      yearOfBuilding: house.yearOfBuilding.recognized,
      wallType: house.wallType.recognized,
    };
  });

const makeAdTasksAtom = (adId) =>
  map(makeAdAtom(adId), (adOverview) => adOverview?.tasks || []);

export const makeAdTasksWithoutComplaintsAtom = (adId) =>
  map(makeAdTasksAtom(adId), (adTasks) =>
    adTasks.filter(({ isComplaint }) => !isComplaint),
  );

export const makeAdComplaintTasksAtom = (adId) =>
  map(makeAdTasksAtom(adId), (adTasks) =>
    adTasks.filter(({ isComplaint }) => isComplaint),
  );

const loadingStateAtom = declareAtom(
  'Ads/Overview/loadingStateAtom',
  {},
  (on) => [
    on(shown, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.LOADING,
    })),
    on(shownSucceeded, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.SUCCEEDED,
    })),
    on(shownFailed, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.FAILED,
    })),
  ],
);

export const makeAdLoadingStateAtom = (adId) =>
  map(loadingStateAtom, (ls) => ls[adId] || LOADING_STATE.IDLE);

export const makeIsAdIdleAtom = (adId) =>
  map(makeAdLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.IDLE);

export const makeIsAdLoadingAtom = (adId) =>
  map(makeAdLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.LOADING);

export const makeIsAdFailedAtom = (adId) =>
  map(makeAdLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.FAILED);

export const makeIsAdSucceededAtom = (adId) =>
  map(makeAdLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.SUCCEEDED);

export default combine({ tableAtom, loadingStateAtom });
