@import '../../../styles/utils';

.root {
  fill: $grey_3;
  @include defaultTransition(fill);

  &_highlighted {
    fill: $black;
  }
}
