import { defineMessage } from 'react-intl';
import { TASK_TYPE } from './taskType';

const TASK_SOLUTION = {
  REMOVED_FROM_PUBLICATION: 'removed_from_publication',
  AD_NOT_ACTIVE: 'ad_not_active',
  AUTHOR_UNBLOCKED: 'author_unblocked',
  AUTHOR_REMAINED_BLOCKED: 'author_remained_blocked',
  AUTHOR_BLOCKED: 'author_blocked',
  GROUPED: 'grouped',
  UNGROUPED: 'ungrouped',
  AD_REMOVED: 'ad_removed',
  GEOGRAPHY_EDITED: 'geography_edited',
  OTHER_FIELDS_EDITED: 'other_fields_edited',
  NOTHING: 'nothing',
  OTHER: 'other',
};

export const TASK_SOLUTION_INFO = {
  [TASK_SOLUTION.GEOGRAPHY_EDITED]: {
    slug: TASK_SOLUTION.GEOGRAPHY_EDITED,
    title: defineMessage({ defaultMessage: 'Geography was edited' }),
  },

  [TASK_SOLUTION.OTHER_FIELDS_EDITED]: {
    slug: TASK_SOLUTION.OTHER_FIELDS_EDITED,
    title: defineMessage({ defaultMessage: 'Other fields were edited' }),
  },

  [TASK_SOLUTION.REMOVED_FROM_PUBLICATION]: {
    slug: TASK_SOLUTION.REMOVED_FROM_PUBLICATION,
    title: defineMessage({ defaultMessage: 'Removed from publication' }),
  },

  [TASK_SOLUTION.AD_NOT_ACTIVE]: {
    slug: TASK_SOLUTION.AD_NOT_ACTIVE,
    title: defineMessage({ defaultMessage: 'Ad is no longer active' }),
  },

  [TASK_SOLUTION.AUTHOR_UNBLOCKED]: {
    slug: TASK_SOLUTION.AUTHOR_UNBLOCKED,
    title: defineMessage({ defaultMessage: 'Author needs to be unblocked' }),
  },

  [TASK_SOLUTION.AUTHOR_REMAINED_BLOCKED]: {
    slug: TASK_SOLUTION.AUTHOR_REMAINED_BLOCKED,
    title: defineMessage({
      defaultMessage: "Author shouldn't be unblocked",
    }),
  },

  [TASK_SOLUTION.AUTHOR_BLOCKED]: {
    slug: TASK_SOLUTION.AUTHOR_BLOCKED,
    title: defineMessage({ defaultMessage: 'Blocked' }),
  },

  [TASK_SOLUTION.GROUPED]: {
    slug: TASK_SOLUTION.GROUPED,
    title: defineMessage({ defaultMessage: 'Grouped' }),
  },

  [TASK_SOLUTION.UNGROUPED]: {
    slug: TASK_SOLUTION.UNGROUPED,
    title: defineMessage({ defaultMessage: 'Ungrouped' }),
  },

  [TASK_SOLUTION.AD_REMOVED]: {
    slug: TASK_SOLUTION.AD_REMOVED,
    title: defineMessage({ defaultMessage: 'Dropped ad' }),
  },

  [TASK_SOLUTION.NOTHING]: {
    slug: TASK_SOLUTION.NOTHING,
    title: defineMessage({ defaultMessage: 'Nothing was done' }),
  },

  [TASK_SOLUTION.OTHER]: {
    slug: TASK_SOLUTION.OTHER,
    title: defineMessage({
      defaultMessage: 'Other (leave a comment in the task)',
    }),
  },
};

export const TASK_SKIPPED_SOLUTION = 'skipped';

export const TASK_SOLUTIONS_BY_TYPE = {
  [TASK_TYPE.MODERATION]: [
    TASK_SOLUTION_INFO[TASK_SOLUTION.GEOGRAPHY_EDITED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER_FIELDS_EDITED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.REMOVED_FROM_PUBLICATION],
    TASK_SOLUTION_INFO[TASK_SOLUTION.AD_NOT_ACTIVE],
    TASK_SOLUTION_INFO[TASK_SOLUTION.NOTHING],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER],
  ],

  [TASK_TYPE.AUTHOR_VERIFICATION]: [
    TASK_SOLUTION_INFO[TASK_SOLUTION.AUTHOR_UNBLOCKED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.AUTHOR_REMAINED_BLOCKED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.AUTHOR_BLOCKED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.NOTHING],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER],
  ],

  [TASK_TYPE.GROUP_VERIFICATION]: [
    TASK_SOLUTION_INFO[TASK_SOLUTION.GROUPED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.UNGROUPED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.NOTHING],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER],
  ],

  [TASK_TYPE.USER_COMPLAINT]: [
    TASK_SOLUTION_INFO[TASK_SOLUTION.AD_REMOVED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.GEOGRAPHY_EDITED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER_FIELDS_EDITED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.NOTHING],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER],
  ],

  [TASK_TYPE.OTHER]: [
    TASK_SOLUTION_INFO[TASK_SOLUTION.AD_REMOVED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.GEOGRAPHY_EDITED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER_FIELDS_EDITED],
    TASK_SOLUTION_INFO[TASK_SOLUTION.NOTHING],
    TASK_SOLUTION_INFO[TASK_SOLUTION.OTHER],
  ],
};
