import DefaultPageLayout from '../../DefaultPageLayout/DefaultPageLayout';
import PageMainContentTile from '../../PageMainContentTile/PageMainContentTile';
import DefaultErrorFallback from '../../DefaultErrorFallback/DefaultErrorFallback';

const PageErrorFallback = () => (
  <DefaultPageLayout>
    <DefaultPageLayout.WideCol>
      <PageMainContentTile>
        <DefaultErrorFallback />
      </PageMainContentTile>
    </DefaultPageLayout.WideCol>
  </DefaultPageLayout>
);

export default PageErrorFallback;
