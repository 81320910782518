import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as AdsStatistic from '../../models/adsStatistic';
import { useNotifications } from '../../hooks';

const useSites = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const sites = useAtom(AdsStatistic.Sites.listAtom);
  const isSitesLoading = useAtom(AdsStatistic.Sites.isShownLoadingAtom);
  const isSitesIdle = useAtom(AdsStatistic.Sites.isShownIdleAtom);
  const isSitesFailed = useAtom(AdsStatistic.Sites.isShownFailedAtom);
  const onSitesShown = useAction(AdsStatistic.Sites.shown);

  useEffect(() => {
    if (!isSitesFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load sites',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSitesFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isSitesIdle) {
      return;
    }

    onSitesShown();
  }, [isSitesIdle, onSitesShown]);

  return { sites, isSitesLoading };
};

export default useSites;
