import cx from 'classnames';
import { FormattedNumber } from 'react-intl';

import { checkIsNumber } from '../../../utils';
import styles from './Item.module.scss';

const SYMBOLS_AMOUNT_IN_NUMBER_LESS_TEN_THOUSAND = 4;

const CounterStatisticGroupItem = ({ title, number, color = '#afb5c4' }) => (
  <div className={styles.root}>
    <div
      className={cx(styles.number, {
        [styles.number_minimized]:
          checkIsNumber(number) &&
          String(number).length > SYMBOLS_AMOUNT_IN_NUMBER_LESS_TEN_THOUSAND,
      })}
    >
      {checkIsNumber(number) ? <FormattedNumber value={number} /> : '-'}
    </div>

    <div className={styles.line} style={{ backgroundColor: color }} />

    <div className={styles.title}>{title}</div>
  </div>
);

export default CounterStatisticGroupItem;
