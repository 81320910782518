@import '../../styles/utils';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: $textDarkGrey;
}

.label {
  margin-right: 12px;
}

.select {
  flex: 1 1 auto;
}
