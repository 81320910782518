import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { IconAngleArrowDown, IconAngleArrowUp } from '../icons';
import SelectDropdownGeneralOption from './GeneralOption/GeneralOption';
import SelectDropdownOption from './Option/Option';
import SelectDropdownFallbackPlaceholder from './FallbackPlaceholder/FallbackPlaceholder';

import { useToggle } from '../../hooks';
import styles from './SelectDropdown.module.scss';

const DefaultTriggerButtonContent = ({
  generalOption,
  options,
  selectedValue,
}) =>
  [generalOption, ...options]
    .filter(Boolean)
    .find(({ value }) => value === selectedValue)?.title || '';

const SelectDropdown = ({
  generalOption,
  options = [],
  selectedValue,
  triggerButtonContent: TriggerButtonContent = DefaultTriggerButtonContent,
  emptyMessage = <FormattedMessage defaultMessage="No options" />,
  optionContent: OptionContent = SelectDropdownOption.DefaultContent,
  isLoading,
  onChange,
}) => {
  const { isOn, on, off } = useToggle();

  const handleChange = useCallback(
    (value) => {
      onChange(value);
      off();
    },
    [off, onChange],
  );

  const handleTriggerButtonClick = useCallback(() => on(), [on]);
  const handleDropdownClose = useCallback(() => off(), [off]);

  return (
    <div className={styles.root}>
      <Dropdown
        trigger={
          <div className={styles.triggerButton}>
            <Button
              title={
                <TriggerButtonContent
                  generalOption={generalOption}
                  options={options}
                  selectedValue={selectedValue}
                />
              }
              variant="secondary"
              appendedIcon={
                isOn ? <IconAngleArrowUp /> : <IconAngleArrowDown />
              }
              iconSize="xsmall"
              onClick={handleTriggerButtonClick}
              isExpanded
            />
          </div>
        }
        isOpened={isOn}
        onClose={handleDropdownClose}
      >
        <div className={styles.dropdown}>
          <SelectDropdownFallbackPlaceholder
            emptyMessage={emptyMessage}
            loading={
              <div className={styles.loader}>
                <Loader size="small" />
              </div>
            }
            isEmpty={!generalOption && options.length === 0}
            isLoading={isLoading}
          >
            {generalOption && (
              <SelectDropdownGeneralOption
                option={generalOption}
                isSelected={generalOption.value === selectedValue}
                onClick={handleChange}
              />
            )}

            {options.map(({ value, title, payload }) => (
              <SelectDropdownOption
                key={value}
                value={value}
                isSelected={value === selectedValue}
                onClick={handleChange}
              >
                <OptionContent value={value} title={title} payload={payload} />
              </SelectDropdownOption>
            ))}
          </SelectDropdownFallbackPlaceholder>
        </div>
      </Dropdown>
    </div>
  );
};

export default SelectDropdown;
