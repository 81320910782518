import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../../components/Button/Button';
import {
  IconRoundedCheck,
  IconCrossedRoundedCheck,
  IconReloadingCheck,
} from '../../../components/icons';

import { AD_STATUS } from '../../../constants';
import styles from './AdInteractionButtonGroup.module.scss';

const formButtonsProps = (adStatus) => {
  const removeProps = { variant: 'secondary' };
  const publishProps = { variant: 'secondary' };
  const reprocessProps = { variant: 'secondary' };

  switch (adStatus) {
    case AD_STATUS.IN_INTERFACE:
      removeProps.variant = 'primary';
      publishProps.isDisabled = true;
      break;

    case AD_STATUS.NOT_IN_INTERFACE:
      publishProps.variant = 'primary';
      removeProps.isDisabled = true;
      break;

    default:
      break;
  }

  return { removeProps, publishProps, reprocessProps };
};

const AdInteractionButtonGroup = ({
  adStatus,
  isLoading,
  onRemoveClick,
  onPublishClick,
  onReprocessClick,
}) => {
  const { removeProps, publishProps, reprocessProps } = useMemo(
    () => formButtonsProps(adStatus),
    [adStatus],
  );

  const isLoadingProps = isLoading ? { isDisabled: true } : {};

  return (
    <div>
      <div className={styles.item}>
        <Button
          title={<FormattedMessage defaultMessage="Unpublish" />}
          prependedIcon={<IconCrossedRoundedCheck />}
          iconSize="medium"
          onClick={onRemoveClick}
          isExpanded
          {...removeProps}
          {...isLoadingProps}
        />
      </div>

      <div className={styles.item}>
        <Button
          title={<FormattedMessage defaultMessage="Publish" />}
          prependedIcon={<IconRoundedCheck />}
          iconSize="medium"
          onClick={onPublishClick}
          isExpanded
          {...publishProps}
          {...isLoadingProps}
        />
      </div>

      <div className={styles.item}>
        <Button
          title={<FormattedMessage defaultMessage="Reprocess" />}
          prependedIcon={<IconReloadingCheck />}
          iconSize="medium"
          onClick={onReprocessClick}
          isExpanded
          {...reprocessProps}
          {...isLoadingProps}
        />
      </div>
    </div>
  );
};

export default AdInteractionButtonGroup;
