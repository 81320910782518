import Svg from '../Svg/Svg';

const IconAlien = () => (
  <Svg>
    <g stroke="null">
      <path
        fill="#27536B"
        d="M23.923 11.961c0 4.24-2.206 7.964-5.532 10.089a11.904 11.904 0 01-6.43 1.873 11.995 11.995 0 01-2.194-.201 11.975 11.975 0 01-9.602-9.768A12.063 12.063 0 010 11.96C0 5.355 5.356 0 11.962 0s11.961 5.355 11.961 11.961z"
      />
      <path
        fill="#FFF"
        d="M21.229 12.206l2.211-.245-2.211-.244-.245-2.212-.244 2.212-2.212.244 2.212.245.244 2.211z"
      />
      <path
        fill="#35BC58"
        d="M18.391 22.05a11.904 11.904 0 01-6.43 1.873 11.995 11.995 0 01-4.26-.782 11.934 11.934 0 01-2.17-1.092c.433-1.306 1.443-2.417 2.791-3.14.419-.223.87-.41 1.347-.553a7.986 7.986 0 012.292-.331c1.343 0 2.593.325 3.64.885 1.348.722 2.358 1.833 2.79 3.14z"
      />
      <path
        fill="#2DAA4A"
        d="M15.6 18.91l-1.152 1.613a3.024 3.024 0 01-1.8 1.201 3.05 3.05 0 01-3.173-1.201l-1.153-1.614c1.047-.559 2.297-.884 3.64-.884s2.592.325 3.638.885z"
      />
      <path
        fill="#35BC58"
        d="M18.042 10.615a2.971 2.971 0 00-.302 1.947c.02.112.045.224.076.334.109.245.158.509.136.775a1.67 1.67 0 01-.318.836l-3.186 4.46a3.024 3.024 0 01-1.8 1.201 3.05 3.05 0 01-3.173-1.201l-3.186-4.46c-.377-.527-.406-1.108-.183-1.61.217-.765.157-1.585-.225-2.282A4.614 4.614 0 015.3 8.384c0-3.013 2.983-5.455 6.661-5.455s6.662 2.442 6.662 5.455a4.62 4.62 0 01-.581 2.23z"
      />
      <g fill="#25A042">
        <path d="M13.313 18.567a.208.208 0 01-.073-.013c-.353-.132-.808-.205-1.28-.205-.47 0-.924.073-1.277.205a.207.207 0 11-.146-.388c.398-.15.904-.232 1.424-.232.52 0 1.026.082 1.425.232a.207.207 0 01-.073.401zM12.543 19.124a4.495 4.495 0 00-1.138-.002.208.208 0 01-.052-.411c.402-.051.83-.05 1.216 0a.207.207 0 01-.026.413z" />
      </g>
      <path
        fill="#2DAA4A"
        d="M12.648 20.168a3.05 3.05 0 01-3.173-1.201l-3.186-4.46c-.377-.527-.406-1.108-.183-1.61.217-.765.157-1.585-.225-2.283a4.614 4.614 0 01-.581-2.23c0-3.014 2.983-5.455 6.661-5.455 0 0-5.018 1.23-4.258 5.83.761 4.6.353 3.907.208 4.6-.145.692.778 3.58 1.833 4.634 1.055 1.055.898 2.175 2.904 2.175z"
      />
      <path
        fill="#25A042"
        d="M11.04 16.331c.183.295.64.173.647-.175v-.02c0-.344-.172-.622-.384-.622-.212 0-.384.278-.384.621l.122.196z"
      />
      <g fill="#5BD377">
        <path d="M17.952 13.671c-.953 1.8-3.492 1.054-3.855.69s-1.072-.712-1.072-.712l3.005-.965s1.096-.31 1.71-.123c.02.113.045.225.076.335.109.245.158.509.136.775zM5.983 13.671c.953 1.8 3.491 1.054 3.854.69s1.072-.712 1.072-.712l-3.004-.965s-1.096-.31-1.71-.123c-.02.113-.046.225-.077.335a1.581 1.581 0 00-.135.775z" />
      </g>
      <path
        fill="#25A042"
        d="M12.882 16.331c-.182.295-.64.173-.646-.175v-.02c0-.344.171-.622.383-.622s.384.278.384.621l-.121.196z"
      />
      <g fill="#E6E6E6">
        <path d="M10.91 13.65c-.341-.163-.621-.075-.955.067 0 0 0 0 0 0-.493.208-1.101.53-2.19.334a1.443 1.443 0 01-.629-.291s0 0 0 0c-.62-.483-.905-1.353-.905-1.353s.163-1.272 1.605-1.278h0c.234 0 .502.032.807.108.67.168 1.142.459 1.475.781.756.733.791 1.631.791 1.631zM17.704 12.407s-.295.9-.937 1.376l-.044.032a1.41 1.41 0 01-.554.236c-1.1.199-1.71-.133-2.206-.34v-.001c-.327-.137-.604-.22-.938-.06 0 0 .035-.881.768-1.61h.001c.334-.332.812-.632 1.497-.803.3-.075.564-.107.794-.108 1.455-.002 1.619 1.278 1.619 1.278z" />
      </g>
      <path
        fill="#333"
        d="M10.267 12.723c0 .37-.116.712-.312.994 0 0 0 0 0 0-.297.126-.635.292-1.094.361-.304.047-.662.051-1.095-.026a1.443 1.443 0 01-.63-.292s0 0 0 0a1.738 1.738 0 01.7-2.63h.038c.224.002.48.035.77.107.67.168 1.142.459 1.474.781.097.215.15.454.15.705z"
      />
      <path
        fill="#4D4D4D"
        d="M10.267 12.723c0 .37-.116.712-.312.994 0 0 0 0 0 0-.297.126-.635.292-1.094.361a1.993 1.993 0 01-.987-2.948c.224.002.48.035.77.107.67.168 1.142.459 1.474.781.097.215.15.454.15.705z"
      />
      <path
        fill="#333"
        d="M17.13 12.723c0 .399-.135.767-.363 1.06l-.044.032a1.41 1.41 0 01-.554.236 3.575 3.575 0 01-.684.06c-.69-.007-1.14-.24-1.522-.4v-.001a1.746 1.746 0 01-.17-1.669v-.001h.001c.203-.201.458-.39.78-.547a3.71 3.71 0 01.717-.256c.3-.075.564-.107.794-.108.615.267 1.044.88 1.044 1.594z"
      />
      <path
        fill="#4D4D4D"
        d="M17.13 12.723c0 .399-.135.767-.363 1.06l-.044.032a1.41 1.41 0 01-.554.236 3.575 3.575 0 01-.684.06 1.923 1.923 0 01-.911-2.618 3.71 3.71 0 01.717-.256c.3-.075.564-.107.794-.108.615.267 1.044.88 1.044 1.594z"
      />
      <g fill="gray">
        <circle r=".476" cy="12.209" cx="16.03" />
        <circle r=".476" cy="12.209" cx="9.294" />
      </g>
      <g fill="#2DAA4A">
        <path d="M9.669 18.356l-.194 2.167s-1.146.96-1.774 2.618a11.934 11.934 0 01-2.17-1.092c.433-1.306 1.443-2.417 2.791-3.14.419-.223.87-.41 1.347-.553zM13.025 12.612s-.657-1.951 1.037-2.677c1.695-.727 3.2-.57 4.29.519 1.089 1.089-.648.915-.648.915h0a8.091 8.091 0 00-4.132.95l-.547.293zM10.91 12.612s.657-1.951-1.038-2.677-3.199-.57-4.288.519.647.915.647.915h0a8.091 8.091 0 014.132.95l.546.293z" />
      </g>
      <ellipse
        fill="#5BD377"
        ry="21.466"
        rx="31.83"
        cy="688.775"
        cx="-3024.78"
        transform="scale(-.04673) rotate(54.261 -876.623 2890)"
      />
      <g fill="#FFF">
        <path d="M4.499 16.678l2.211-.244-2.211-.245-.245-2.211-.245 2.211-2.211.245 2.211.244.245 2.212z" />
        <path
          d="M20.684 16.912l-.343-.343.343-.342.342.342zM19.937 6.983l-.343-.343.343-.342.342.342zM15.502 2.469l-.343-.342.343-.343.342.343zM17.105 18.073l-.343-.342.343-.342.342.342zM3.674 6.905l-.343-.342.343-.343.342.343zM2.087 8.85l-.342-.342.342-.343.342.343z"
          strokeWidth=".04672"
        />
      </g>
    </g>
  </Svg>
);

export default IconAlien;
