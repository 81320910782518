import { useCallback, useLayoutEffect, useRef } from 'react';

const useEventHandler = (handler, dependencies) => {
  const handlerRef = useRef();

  useLayoutEffect(() => {
    handlerRef.current = handler;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, ...dependencies]);

  return useCallback(
    (...args) => {
      const handler = handlerRef.current;

      if (typeof handler !== 'function') {
        return;
      }

      handler(...args);
    },
    [handlerRef],
  );
};

export default useEventHandler;
