import { FormattedMessage } from 'react-intl';

import {
  IconRoundedCheck,
  IconReloadingCheck,
  IconCrossedRoundedCheck,
} from '../../../components/icons';
import AdStatusMessageTemplate from './AdStatusMessageTemplate/AdStatusMessageTemplate';
import AdStatusNotInInterfaceDetails from './NotInInterfaceDetails/NotInInterfaceDetails';

import { AD_STATUS } from '../../../constants';

const AdStatusMessage = ({ adStatus }) => {
  const { status, reason, bannedStatus, bannedInfo, isReprocessing } = adStatus;

  switch (status) {
    case AD_STATUS.IN_INTERFACE: {
      return (
        <AdStatusMessageTemplate
          title={<FormattedMessage defaultMessage="In interface" />}
          icon={<IconRoundedCheck />}
          backgroundColor="#d8f4da"
          iconColor="#3dc746"
          isReprocessing={isReprocessing}
        />
      );
    }

    case AD_STATUS.FIRST_IN_INTERFACE: {
      return (
        <AdStatusMessageTemplate
          title={
            <FormattedMessage defaultMessage="Adding to the interface for the first time" />
          }
          icon={<IconReloadingCheck />}
          backgroundColor="#fae9d0"
          iconColor="#e89115"
          isReprocessing={isReprocessing}
        />
      );
    }

    case AD_STATUS.NOT_IN_INTERFACE: {
      return (
        <AdStatusMessageTemplate
          title={<FormattedMessage defaultMessage="Not in interface" />}
          icon={<IconCrossedRoundedCheck />}
          backgroundColor="#f6dcd9"
          iconColor="#d45143"
          isReprocessing={isReprocessing}
        >
          <AdStatusNotInInterfaceDetails
            reason={reason}
            bannedStatus={bannedStatus}
            bannedInfo={bannedInfo}
          />
        </AdStatusMessageTemplate>
      );
    }

    default:
      return null;
  }
};

export default AdStatusMessage;
