const makeAdChangesRequest = (initRequest) => (adId, changes) =>
  initRequest(`/realties/${adId}`, {
    method: 'PUT',
    data: { changes },
  });

const makeGeoOptionsRequest =
  (initRequest) =>
  (fieldname, parentId, value = '') =>
    initRequest('/realties/moderation/geo_options', {
      method: 'POST',
      data: { fieldname, parentId, value },
    });

const createAdsService = (initRequest) => ({
  getAd: (adId) => initRequest(`/realties/${adId}`),

  removeAd: (adId, reason) =>
    initRequest(`/realties/${adId}/set_inactive`, {
      method: 'POST',
      data: { reason },
    }),

  publishAd: (adId, reason) =>
    initRequest(`/realties/${adId}/set_active`, {
      method: 'POST',
      data: { reason },
    }),

  reprocessAd: (adId) =>
    initRequest(`/realties/${adId}/reprocess`, { method: 'POST', data: {} }),

  editAdHouse: (adId, houseId) =>
    makeAdChangesRequest(initRequest)(adId, { house: houseId }),

  getHousesByStreet: (streetId, query) =>
    makeGeoOptionsRequest(initRequest)('house', streetId, query),
});

export default createAdsService;
