import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { DIALOG_NAME, LOADING_STATE } from '../../constants';
import api from '../../services/api';
import { serializeUpdatingTask } from '../../normalizers';
import * as Account from '../account';
import * as Dialog from '../dialog';

export const savedSucceeded = declareAction('Tasks/Creation/savedSucceeded');
const savedFailed = declareAction('Tasks/Creation/savedFailed');

export const saved = declareAction(
  'Tasks/Creation/saved',
  async ({ task }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const { taskIds } = await api(authorization).tasks.create(
        serializeUpdatingTask(task),
      );

      const openedName = store.getState(Dialog.openedNameAtom);
      const isTaskCreationDialogOpened =
        openedName === DIALOG_NAME.TASK_CREATION;

      if (isTaskCreationDialogOpened) {
        store.dispatch(Dialog.closeDialog());
      }

      store.dispatch(savedSucceeded({ taskIds }));
    } catch (error) {
      console.error(error);

      store.dispatch(savedFailed());
    }
  },
);

export const createdTaskIdsAtom = declareAtom(
  'Tasks/Creation/createdTaskIdsAtom',
  [],
  (on) => [
    on(saved, () => []),
    on(savedSucceeded, (state, { taskIds = [] }) => taskIds),
  ],
);

const savedLoadingState = declareAtom(
  'Tasks/Creation/savedLoadingState',
  LOADING_STATE.IDLE,
  (on) => [
    on(saved, () => LOADING_STATE.LOADING),
    on(savedSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(savedFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isSavedLoadingAtom = map(
  savedLoadingState,
  (ls) => ls === LOADING_STATE.LOADING,
);

export const isSavedSucceededAtom = map(
  savedLoadingState,
  (ls) => ls === LOADING_STATE.SUCCEEDED,
);

export const isSavedFailedAtom = map(
  savedLoadingState,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({
  createdTaskIdsAtom,
  savedLoadingState,
});
