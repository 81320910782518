.root {
  display: flex;
  height: 100%;
}

.wideCol {
  flex: 1 1 auto;
  margin-right: 16px;
  overflow: hidden;
}

.narrowCol {
  flex: 0 0 auto;
}
