@import '../../styles/utils';

.root {
}

.element {
  @extend %normalize-button;
  @include defaultTransition(color);
  position: relative;
  padding: 4px;

  &:hover {
    color: $primaryBlue;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
  }
}

.counter {
  position: absolute;
  left: 18px;
  top: 18px;
  font-size: 11px;
}
