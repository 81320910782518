import styles from './HeaderCell.module.scss';

const AdPageTableTitleCell = ({
  title,
  width = 'auto',
  minWidth,
  isFlexible,
}) => (
  <div
    style={{
      flex: isFlexible ? `1 1 ${width}` : `0 0 ${width}`,
      width: minWidth || width,
      minWidth,
    }}
    className={styles.root}
  >
    {title}
  </div>
);

export default AdPageTableTitleCell;
