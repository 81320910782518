import RoleBarrier from './RoleBarrier/RoleBarrier';
import { ROLE_TYPE } from '../../constants';

const withRoles =
  (roles = Object.values(ROLE_TYPE)) =>
  (route) => ({
    ...route,
    element: <RoleBarrier roles={roles}>{route.element}</RoleBarrier>,
  });

export default withRoles;
