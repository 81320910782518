import CounterStatisticGroupItem from './Item/Item';
import styles from './CounterStatisticGroup.module.scss';

const CounterStatisticGroup = ({ primary, list = [] }) => (
  <div className={styles.root}>
    <div className={styles.primary}>
      <CounterStatisticGroupItem
        number={primary.number}
        title={primary.title}
        color={primary.color}
      />
    </div>

    <div className={styles.list}>
      {list.map(({ id, number, title, color }) => (
        <div key={id} className={styles.item}>
          <CounterStatisticGroupItem
            number={number}
            title={title}
            color={color}
          />
        </div>
      ))}
    </div>
  </div>
);

export default CounterStatisticGroup;
