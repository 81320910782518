import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import contains from 'dom-helpers/contains';
import { useTransition, animated } from 'react-spring';

import Media from '../../../Media/Media';
import Button from '../../../Button/Button';
import RoundIconButton from '../../../RoundIconButton/RoundIconButton';
import { IconAngleArrowDown, IconAngleArrowUp } from '../../../icons';

import { useToggle, useEventHandler } from '../../../../hooks';
import { ROLE_INFO_BY_TYPE } from '../../../../constants';
import styles from './AccountPlate.module.scss';

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 25,
  duration: 250,
};

const HeaderAccountPlate = ({ name, role, media, onSignOut }) => {
  const { isOn, toggle, off } = useToggle(false);
  const rootNodeRef = useRef();

  const handleWindowClick = useEventHandler(
    (e) => {
      if (!isOn) {
        return;
      }

      if (
        !contains(document.body, e.target) ||
        contains(rootNodeRef.current, e.target)
      ) {
        return;
      }

      off();
    },
    [isOn, off],
  );

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);

    return () => window.removeEventListener('click', handleWindowClick);
  }, [handleWindowClick]);

  const transitions = useTransition(isOn, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: springConfig,
  });

  return (
    <div
      className={cx(styles.root, { [styles.root_on]: isOn })}
      ref={rootNodeRef}
    >
      <div className={styles.trigger}>
        <button className={styles.button} onClick={toggle}>
          <span className={styles.buttonContent}>
            <span className={styles.mediaCol}>
              <Media media={media} />
            </span>

            <span className={styles.textCol}>
              <span className={styles.name}>{name}</span>
              <span className={styles.role}>
                <FormattedMessage {...ROLE_INFO_BY_TYPE[role].title} />
              </span>
            </span>
          </span>

          <span>
            <RoundIconButton
              component="span"
              variant="flat"
              icon={
                <span className={styles.hoverSpreader}>
                  {isOn ? <IconAngleArrowUp /> : <IconAngleArrowDown />}
                </span>
              }
              isSmall
            />
          </span>
        </button>
      </div>

      {transitions.map(
        ({ item: opened, key, props }) =>
          opened && (
            <animated.div
              key={key}
              className={styles.dropdown}
              style={{ opacity: props.opacity }}
            >
              <Button
                title={<FormattedMessage defaultMessage="Log out" />}
                variant="secondary"
                onClick={onSignOut}
              />
            </animated.div>
          ),
      )}
    </div>
  );
};

export default HeaderAccountPlate;
