const toTwoDigits = (number) => (number < 10 ? `0${number}` : number);

const getTimezoneStandard = () => {
  const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
  let timezoneHoursOffset = toTwoDigits(
    parseInt(Math.abs(timezoneOffsetInMinutes / 60)),
  );
  let timezoneMinutesOffset = toTwoDigits(
    Math.abs(timezoneOffsetInMinutes % 60),
  );

  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezoneOffsetInMinutes < 0) {
    return `+${timezoneHoursOffset}:${timezoneMinutesOffset}`;
  }

  if (timezoneOffsetInMinutes > 0) {
    return `-${timezoneHoursOffset}:${timezoneMinutesOffset}`;
  }

  return 'Z';
};

const getCurrentDatetime = (date) => {
  const year = date.getFullYear();
  const month = toTwoDigits(date.getMonth() + 1);
  const day = toTwoDigits(date.getDate());
  const hours = toTwoDigits(date.getHours());
  const minutes = toTwoDigits(date.getMinutes());
  const seconds = toTwoDigits(date.getSeconds());

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`; // YYYY-mm-ddThh:mm:ss
};

const dateToISOInCurrentTimezone = (date) =>
  `${getCurrentDatetime(date)}${getTimezoneStandard()}`;

export default dateToISOInCurrentTimezone;
