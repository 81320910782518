import Loader from '../../../components/Loader/Loader';
import styles from './FullScreenLoader.module.scss';

const FullScreenLoader = () => (
  <div className={styles.root}>
    <Loader size={120} />
  </div>
);

export default FullScreenLoader;
