import { useCallback } from 'react';

import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import Calendar from './Calendar/Calendar';

import { useToggle } from '../../hooks';
import useButtonTitle from './useButtonTitle';

const CalendarDropdown = ({
  dateRange,
  onDateChange,
  minDate,
  maxDate,
  buttonProps = null,
  dropdownProps = null,
  isDisplayAbbreviated,
}) => {
  const buttonTitle = useButtonTitle(dateRange, isDisplayAbbreviated);

  const { isOn, on, off } = useToggle();

  const handleDateChange = useCallback(
    (date) => {
      off();
      onDateChange(date);
    },
    [onDateChange, off],
  );

  const handleTriggerButtonClick = useCallback(() => on(), [on]);
  const handleDropdownClose = useCallback(() => off(), [off]);

  return (
    <Dropdown
      trigger={
        <Button
          title={buttonTitle}
          type="primary"
          onClick={handleTriggerButtonClick}
          withLargeSideInnerGaps
          {...buttonProps}
        />
      }
      isOpened={isOn}
      onClose={handleDropdownClose}
      {...dropdownProps}
    >
      <Calendar
        dateRange={dateRange}
        minDate={minDate}
        maxDate={maxDate}
        onDateChange={handleDateChange}
      />
    </Dropdown>
  );
};

export default CalendarDropdown;
