import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAction, useAtom } from '@reatom/react';
import { debounce } from 'throttle-debounce';

import Page from '../../components/Page/Page';
import DefaultPageLayout from '../../components/DefaultPageLayout/DefaultPageLayout';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import SearchInput from '../../components/SearchInput/SearchInput';
import CallHistoryTable from './Table/Table';
import CallHistoryPageSubtitle from './Subtitle/Subtitle';

import * as Calls from '../../models/calls';
import * as Tasks from '../../models/tasks';
import {
  useNotifications,
  usePagination,
  useTaskOverviewDialog,
} from '../../hooks';
import withRoles from '../../components/PagesScene/withRoles';
import useFilters from './useFilters';

const SEARCH_DELAY_MS = 300;

const CallHistoryPage = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const isFirstRenderRef = useRef(true);
  const [search, setSearch] = useState('');
  const { open: openTaskEditingDialog } = useTaskOverviewDialog();

  const [
    { tableFilters, isTableFiltersShown, isOnlyMy },
    {
      onTableFilterChange,
      onTableFiltersReset,
      onIsTableFiltersShownToggle,
      onIsOnlyMyChange,
    },
  ] = useFilters();

  const filters = useMemo(
    () => ({ ...tableFilters, isOnlyMy }),
    [tableFilters, isOnlyMy],
  );

  const callsAmount = useAtom(Calls.PreviewList.shownTotalAmountAtom);

  const [{ page, perPage }, { goToPage, onPrev, onNext }] = usePagination({
    amount: callsAmount,
  });

  const loadingOverviewTaskIdsAtom = useAtom(Tasks.Overview.loadingTaskIdsAtom);
  const calls = useAtom(Calls.PreviewList.makeTaskPreviewListAtom(page));
  const callsSearchQuery = useAtom(Calls.PreviewList.searchQueryAtom);
  const isCallsLoading = useAtom(
    Calls.PreviewList.makeIsShownLoadingAtom(page),
  );
  const isCallsFailed = useAtom(Calls.PreviewList.makeIsShownFailedAtom(page));
  const isCallsReady = useAtom(Calls.PreviewList.makeIsShownReadyAtom(page));

  const handleLoadAllCalls = useAction(Calls.PreviewList.shown);
  const handleResetCalls = useAction(Calls.PreviewList.resetCallPreviewList);

  useEffect(() => {
    if (isCallsLoading || isCallsReady) {
      return;
    }

    handleLoadAllCalls({
      page,
      perPage,
      searchQuery: search.trim(),
      filters,
    });
  }, [
    page,
    perPage,
    search,
    filters,
    isCallsLoading,
    isCallsReady,
    handleLoadAllCalls,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedResetState = useCallback(
    debounce(SEARCH_DELAY_MS, () => {
      handleResetCalls();
      goToPage(1);
    }),
    [handleResetCalls, goToPage],
  );

  useEffect(() => {
    if (!isCallsFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load tasks. Try reloading the page',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallsFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    debouncedResetState();
  }, [search, filters, debouncedResetState]);

  useEffect(() => {
    return () => handleResetCalls();
  }, [handleResetCalls]);

  const handleSearchChange = useCallback((e) => setSearch(e.target.value), []);

  const handleTaskEditClick = useCallback(
    (taskId, callId) => openTaskEditingDialog(taskId, { callId }),
    [openTaskEditingDialog],
  );

  const handlePaginationPrevClick = useCallback(() => onPrev(), [onPrev]);

  const handlePaginationNextClick = useCallback(() => onNext(), [onNext]);

  return (
    <FormattedMessage defaultMessage="Call history">
      {([helmetTitle]) => (
        <Page helmetTitle={helmetTitle}>
          <DefaultPageLayout>
            <DefaultPageLayout.WideCol>
              <PageMainContentTile
                title={<FormattedMessage defaultMessage="Call history" />}
                titleAppended={
                  <SearchInput
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Phone number or address',
                    })}
                    value={search}
                    onChange={handleSearchChange}
                    isGrey
                  />
                }
                subtitleElement={
                  <CallHistoryPageSubtitle
                    isOnlyMy={isOnlyMy}
                    isTableFiltersShown={isTableFiltersShown}
                    onIsOnlyMyChange={onIsOnlyMyChange}
                    onIsTableFiltersShownToggle={onIsTableFiltersShownToggle}
                  />
                }
              >
                <CallHistoryTable
                  history={calls}
                  loadingOverviewTaskIdsAtom={loadingOverviewTaskIdsAtom}
                  page={page}
                  amount={callsAmount}
                  perPage={perPage}
                  searchValue={callsSearchQuery}
                  filters={tableFilters}
                  isActionsShown={isTableFiltersShown}
                  onFilterChange={onTableFilterChange}
                  onFiltersReset={onTableFiltersReset}
                  isLoading={isCallsLoading}
                  isEmpty={isCallsFailed || (isCallsReady && calls?.length < 1)}
                  onTaskEditClick={handleTaskEditClick}
                  onPaginationPrevClick={handlePaginationPrevClick}
                  onPaginationNextClick={handlePaginationNextClick}
                />
              </PageMainContentTile>
            </DefaultPageLayout.WideCol>
          </DefaultPageLayout>
        </Page>
      )}
    </FormattedMessage>
  );
};

CallHistoryPage.RouteParams = withRoles()({
  element: <CallHistoryPage />,
  path: '/call-history',
});

export default CallHistoryPage;
