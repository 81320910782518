@import '../../styles/utils';

.wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  &_disabled {
    cursor: default;
    opacity: 0.6;
  }
}

.element {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mark {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid $textDark;
  box-sizing: border-box;
  @include defaultTransition(border);

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    @include defaultTransition(background-color);
  }
}

.element:checked + .mark {
  border: 2px solid $primaryBlue;

  &::before {
    background-color: $primaryBlue;
  }
}

.label {
  flex: 1 1 auto;
  margin-left: 8px;
  line-height: 18px;
  font-size: 14px;
}
