@import '../../styles/utils';

$labelBottom: 8px;

.root {
  position: relative;
}

.labelRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $labelBottom;
}

.label {
  flex: 0 0 auto;
  margin-right: 16px;
  color: $grey_3;
}

.content {
  &_withDefaultHeight {
    display: flex;
    align-items: center;
    min-height: 46px;
  }
}

.hint {
  position: absolute;
  left: 16px;
  bottom: -2px;
  transform: translateY(100%);
  line-height: 0.9;
  font-size: 10px;
  color: $grey_3;

  &_failed {
    color: $errorRed;
  }
}
