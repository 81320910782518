import { FormattedMessage, FormattedNumber } from 'react-intl';

import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import IconContainer from '../../../components/IconContainer/IconContainer';
import { IconImage } from '../../../components/icons';
import Price from '../../../components/Price/Price';
import Media from '../../../components/Media/Media';

import styles from './AdPreview.module.scss';

const AdPreviewParams = ({ adPreview }) => {
  const floor = `${adPreview.floor} ${
    adPreview.floorCount ? `/${adPreview.floorCount}` : ''
  }`;
  const area = `${adPreview.totalArea || '-'}/${adPreview.livingArea || '-'}/${
    adPreview.kitchenArea || '-'
  }`;
  const isAreaShown =
    adPreview.totalArea || adPreview.livingArea || adPreview.kitchenArea;
  const isLeftColumnShown =
    adPreview.roomCount || adPreview.floor || isAreaShown;

  return (
    <div className={styles.params}>
      <div className={styles.paramsGrid}>
        {isLeftColumnShown && (
          <div className={styles.paramsColumn}>
            {adPreview.roomCount && (
              <div className={styles.paramsItem}>
                <FormattedMessage defaultMessage="Room count:" />{' '}
                {adPreview.roomCount}
              </div>
            )}

            {adPreview.floor && (
              <div className={styles.paramsItem}>
                <FormattedMessage defaultMessage="Floor:" /> {floor}
              </div>
            )}

            {isAreaShown && (
              <div className={styles.paramsItem}>
                <FormattedMessage defaultMessage="Area:" /> {area}
              </div>
            )}
          </div>
        )}

        <div className={styles.paramsColumn}>
          {adPreview.houseType && (
            <div className={styles.paramsItem}>{adPreview.houseType}</div>
          )}

          {adPreview.yearOfBuilding && (
            <div className={styles.paramsItem}>{adPreview.yearOfBuilding}</div>
          )}

          {adPreview.wallType && (
            <div className={styles.paramsItem}>{adPreview.wallType}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const AdPreview = ({ adPreview }) => {
  const isPriceShown = adPreview.price.number && adPreview.price.currency;
  const isPricePerMeterShown = isPriceShown && adPreview.totalArea;
  const isParamsGridShown =
    adPreview.roomCount ||
    adPreview.floor ||
    adPreview.totalArea ||
    adPreview.livingArea ||
    adPreview.kitchenArea ||
    adPreview.houseType ||
    adPreview.yearOfBuilding ||
    adPreview.wallType;

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        <div className={styles.imageColumn}>
          <div className={styles.image}>
            {adPreview.media ? (
              <Media media={adPreview.media} isAdjustable />
            ) : (
              <IconContainer size="inline">
                <IconImage />
              </IconContainer>
            )}
          </div>
        </div>

        <div className={styles.contentColumn}>
          <div className={styles.content}>
            {isPriceShown && (
              <div className={styles.price}>
                <Price
                  number={adPreview.price.number}
                  currency={adPreview.price.currency}
                />
              </div>
            )}

            {isPricePerMeterShown && (
              <div className={styles.pricePerMeter}>
                <FormattedNumber
                  value={Math.ceil(
                    adPreview.price.number / adPreview.totalArea,
                  )}
                />{' '}
                {adPreview.price.currency}{' '}
                <FormattedMessage defaultMessage="for m²" />
              </div>
            )}

            {adPreview.title && (
              <div className={styles.title}>{adPreview.title}</div>
            )}

            {adPreview.apartmentComplex && (
              <div className={styles.apartmentComplex}>
                {adPreview.apartmentComplex}
              </div>
            )}

            {adPreview.description && (
              <div className={styles.description}>{adPreview.description}</div>
            )}

            {isParamsGridShown && <AdPreviewParams adPreview={adPreview} />}

            {adPreview.updateTime && (
              <div className={styles.updateTime}>
                <FormattedMessage defaultMessage="Updated:" />{' '}
                <FormattedDateTime dateISO={adPreview.updateTime} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdPreview;
