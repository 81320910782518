import { createContext, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { useEventHandler, useDynamicRef } from '../hooks';

// Creating of the separate context instead of a regular hook, and using useRef
// are necessary for the ability to change the query parameters from two places simultaneously
//
// e.g. closeTaskEditingDialog and goToPage in the component TaskEditingDialogContainer

const QueryParamsContext = createContext();

const QueryParamsProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }) || {},
    [search],
  );

  const latestPathname = useDynamicRef(pathname);
  const lastCurrentParams = useDynamicRef(currentParams);

  const setQueryParams = useEventHandler(
    (params, { replaceCurrentParams, replaceHistory } = {}) => {
      const finalParams = replaceCurrentParams
        ? params
        : { ...lastCurrentParams.current, ...params };
      lastCurrentParams.current = finalParams;

      const queryString = qs.stringify(finalParams, {
        addQueryPrefix: false,
      });

      navigate(
        { pathname: latestPathname.current, search: queryString },
        { replace: replaceHistory },
      );
    },
    [navigate],
  );

  const removeQueryParams = useEventHandler(
    (paramKeyList, { replaceHistory } = {}) => {
      if (paramKeyList.length === 0) {
        return;
      }

      const currentParamsDuplicate = { ...lastCurrentParams.current };
      paramKeyList.forEach((key) => delete currentParamsDuplicate[key]);

      setQueryParams(currentParamsDuplicate, {
        replaceHistory,
        replaceCurrentParams: true,
      });
    },
    [setQueryParams],
  );

  const value = useMemo(
    () => [currentParams, { setQueryParams, removeQueryParams }],
    [currentParams, setQueryParams, removeQueryParams],
  );

  return (
    <QueryParamsContext.Provider value={value}>
      {children}
    </QueryParamsContext.Provider>
  );
};

export const useQueryParams = () => useContext(QueryParamsContext);
export default QueryParamsProvider;
