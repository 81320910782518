import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAtom } from '@reatom/react';

import { useNotifications } from '../../hooks';
import { TASK_QUEUE_ERROR_TYPE } from '../../models/taskQueue/entityId';
import * as TaskQueue from '../../models/taskQueue';

const getSkipErrorMessage = (errorType, formatMessage) => {
  switch (errorType) {
    case TASK_QUEUE_ERROR_TYPE.NEED_TASK_TYPE_OR_EXECUTOR_CHANGES:
      return formatMessage({
        defaultMessage:
          'An error has occurred! You need to assign a task to another user',
      });

    default:
      return formatMessage({
        defaultMessage: 'Failed to load task from queue. Please try again',
      });
  }
};

const useTaskQueueLoadingErrorMessage = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const skipErrorType = useAtom(TaskQueue.Skip.errorTypeAtom);
  const isResolvingFailed = useAtom(TaskQueue.Resolving.isFailedAtom);
  const isResumingFailed = useAtom(TaskQueue.Resuming.isFailedAtom);
  const isShowingFailed = useAtom(TaskQueue.Showing.isFailedAtom);

  useEffect(() => {
    if (!skipErrorType) {
      return;
    }

    showErrorNotification(
      getSkipErrorMessage(skipErrorType, intl.formatMessage),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipErrorType, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isResolvingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load task from queue. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResolvingFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isResumingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to resume queue. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResumingFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isShowingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load task from queue. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingFailed, intl.formatMessage, showErrorNotification]);
};

export default useTaskQueueLoadingErrorMessage;
