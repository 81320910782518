import { Children, cloneElement } from 'react';
import cx from 'classnames';

import styles from './Row.module.scss';

const TableRow = ({
  columnSizeByName = {},
  children,
  isTranslucent,
  isVerticallyCenterAligned = true,
  isHighlighted,
}) => (
  <div
    className={cx(styles.row, {
      [styles.row_translucent]: isTranslucent,
      [styles.row_verticallyCenterAligned]: isVerticallyCenterAligned,
      [styles.row_highlighted]: isHighlighted,
    })}
  >
    {Children.map(children, (child) => {
      const { width, minWidth, isFlexible } =
        columnSizeByName[child.props.name] || {};

      return cloneElement(child, {
        width,
        minWidth,
        isFlexible,
      });
    })}
  </div>
);

export default TableRow;
