import Svg from '../Svg/Svg';

const IconZendesk = () => (
  <Svg>
    <path d="M11.238 7.961v11.038H2l9.238-11.038zm0-3.961c0 2.525-2.068 4.571-4.619 4.571C4.068 8.571 2 6.525 2 4h9.238zm1.522 15c0-2.525 2.068-4.571 4.619-4.571 2.551 0 4.619 2.046 4.619 4.571H12.76zm0-3.961V4H22l-9.24 11.038v.001z" />
    <path fill="none" d="M0 0h24v24H0z" fillRule="evenodd" />
  </Svg>
);

export default IconZendesk;
