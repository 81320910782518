@import '../../../styles/utils';

.root {
  @extend %normalize-button;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  text-align: left;
  @include defaultTransition(background-color);

  &:hover {
    background-color: $blue_1;
  }

  &_selected {
    background-color: $blue_1;
  }
}

.mark {
  width: 32px;
  text-align: center;
  margin-right: 8px;
  line-height: 1;
}
