import { Navigate, useLocation } from 'react-router-dom';
import { useAction, useAtom } from '@reatom/react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/Page/Page';
import Logo from '../../components/Logo/Logo';
import GoogleLoginButton from '../../components/GoogleLoginButton/GoogleLoginButton';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';
import FullScreenLoader from './FullScreenLoader/FullScreenLoader';

import * as Account from '../../models/account';
import * as Localization from '../../models/localization';
import { useAuth } from '../../hooks';
import { DEFAULT_PAGE_PATH } from '../../constants';
import { getLoginPageLink } from '../../routes';
import styles from './LoginPage.module.scss';

const LoginPage = () => {
  const [{ isLoading }, { logInWithGoogle }] = useAuth();
  const { pathname } = useLocation();
  const isAuthorized = useAtom(Account.isSucceededAtom);
  const language = useAtom(Localization.languageAtom);
  const proposalLanguage = useAtom(Localization.proposalLanguageAtom);
  const changeLanguage = useAction(Localization.languageChanged);

  const onLanguageChange = (language) => changeLanguage({ language });

  if (isAuthorized && pathname === getLoginPageLink()) {
    return <Navigate to={DEFAULT_PAGE_PATH} replace />;
  }

  return (
    <FormattedMessage defaultMessage="Login">
      {([helmetTitle]) => (
        <Page helmetTitle={helmetTitle}>
          <section className={styles.root}>
            <div className={styles.contentHolder}>
              <div className={styles.content}>
                <div className={styles.logo}>
                  <Logo />
                </div>

                <div className={styles.caption}>
                  <FormattedMessage defaultMessage="Log in with your Google account" />
                </div>

                <div className={styles.button}>
                  <GoogleLoginButton onClick={logInWithGoogle} />
                </div>

                <div>
                  <LanguageSelect
                    language={proposalLanguage || language}
                    onChange={onLanguageChange}
                  />
                </div>
              </div>
            </div>

            {isLoading && <FullScreenLoader />}
          </section>
        </Page>
      )}
    </FormattedMessage>
  );
};

export default LoginPage;
