@import '../../../../../styles/utils';

.row {
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid $grey_2;

  &:first-child {
    border-top: 1px solid $grey_2;
  }

  &:last-child {
    border-bottom: none;
  }
}
