import { Children, cloneElement } from 'react';
import styles from './HeaderRow.module.scss';

const TableHeaderRow = ({
  children,
  sort,
  columnSizeByName,
  isActionsShown,
  onSort,
}) => (
  <div className={styles.root}>
    {Children.map(children, (child) => {
      const { width, minWidth, isFlexible } =
        columnSizeByName[child.props.name] || {};

      return cloneElement(child, {
        sort,
        width,
        minWidth,
        isFlexible,
        isActionShown: isActionsShown,
        onSort,
      });
    })}
  </div>
);

export default TableHeaderRow;
