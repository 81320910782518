import { FormattedMessage } from 'react-intl';

import AdInfoTileSection from '../TileSection/TileSection';
import AdDetailsTable from '../Table/Table';

const AdHouseDetailsSection = ({ data, isModerated }) => (
  <AdInfoTileSection
    title={<FormattedMessage defaultMessage="House" />}
    isModerated={isModerated}
  >
    <AdDetailsTable>
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="floor count" />}
        {...data.floorCount}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="walls type" />}
        {...data.wallType}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="construction type" />}
        {...data.houseType}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="heating type" />}
        {...data.heatingSystem}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="insulation type" />}
        {...data.insulationType}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="building year" />}
        {...data.yearOfBuilding}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="ceiling height" />}
        {...data.ceilingHeight}
      />
    </AdDetailsTable>
  </AdInfoTileSection>
);

export default AdHouseDetailsSection;
