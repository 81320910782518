import Svg from '../Svg/Svg';

const IconRoundedCheck = () => (
  <Svg>
    <path d="M12 2.043c5.642 0 10.222 4.46 10.222 9.957S17.642 21.957 12 21.957A10.225 9.96 0 0 1 1.778 12c0-5.496 4.58-9.957 10.222-9.957zM8.606 7.065a1.174 1.143 0 1 0-1.662 1.618L10.338 12l-3.404 3.317a1.174 1.143 0 1 0 1.66 1.618L12 13.62l3.394 3.316a1.174 1.143 0 1 0 1.662-1.618L13.662 12l3.404-3.317a1.174 1.143 0 1 0-1.66-1.618L12 10.38 8.606 7.065z" />
    <path d="M1.778 2.043h20.444v19.914H1.778V2.043z" fill="none" />
  </Svg>
);

export default IconRoundedCheck;
