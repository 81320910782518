import { useEffect } from 'react';
import { useAtom, useAction } from '@reatom/react';
import { useIntl } from 'react-intl';

import { TASK_TYPE_INFO } from '../constants';
import * as Tasks from '../models/tasks';
import { useNotifications } from '.';

const useExecutorsByTaskType = (taskType) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const executors = useAtom(Tasks.Executors.makeExecutorsAtom(taskType));

  const isExecutorsLoading = useAtom(
    Tasks.Executors.makeIsShownLoadingAtom(taskType),
  );

  const isExecutorsSucceeded = useAtom(
    Tasks.Executors.makeIsShownSucceededAtom(taskType),
  );

  const isExecutorsFailed = useAtom(
    Tasks.Executors.makeIsShownFailedAtom(taskType),
  );

  const handleExecutorsShow = useAction(Tasks.Executors.shown);

  useEffect(() => {
    if (taskType && !isExecutorsLoading && !isExecutorsSucceeded) {
      handleExecutorsShow({ taskType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskType]);

  useEffect(() => {
    if (!isExecutorsFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage(
        {
          defaultMessage:
            'Failed to load assignees of task type {task_type_title}. Please try again',
        },
        { task_type_title: intl.formatMessage(TASK_TYPE_INFO[taskType].title) },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskType, isExecutorsFailed, intl.formatMessage, showErrorNotification]);

  return { executors, isExecutorsLoading };
};

export default useExecutorsByTaskType;
