import { declareAtom, declareAction, combine, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import { deserializeUserPreview } from '../../normalizers';
import * as Account from '../account';

const shownSucceeded = declareAction('Tasks/Executors/shownSucceeded'); // { taskType, list }
const shownFailed = declareAction('Tasks/Executors/shownFailed'); // { taskType }

export const shown = declareAction(
  'Tasks/Executors/declareAction',
  async ({ taskType }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const executors = await api(authorization).tasks.getExecutors(taskType);
      store.dispatch(
        shownSucceeded({
          taskType,
          list: executors.map(deserializeUserPreview),
        }),
      );
    } catch (error) {
      console.error(error);

      store.dispatch(
        shownFailed({
          taskType,
        }),
      );
    }
  },
);

const executorsAtom = declareAtom('Tasks/Executors/executorsAtom', {}, (on) => [
  on(shown, (state, { taskType }) => ({ ...state, [taskType]: [] })),
  on(shownSucceeded, (state, { taskType, list }) => ({
    ...state,
    [taskType]: list,
  })),
]);

export const makeExecutorsAtom = (taskType) =>
  map(executorsAtom, (listsTable) => listsTable[taskType] || []);

const shownLoadingStateAtom = declareAtom(
  'Tasks/Executors/shownLoadingStateAtom',
  {},
  (on) => [
    on(shown, (state, { taskType }) => ({
      ...state,
      [taskType]: LOADING_STATE.LOADING,
    })),
    on(shownSucceeded, (state, { taskType }) => ({
      ...state,
      [taskType]: LOADING_STATE.SUCCEEDED,
    })),
    on(shownFailed, (state, { taskType }) => ({
      ...state,
      [taskType]: LOADING_STATE.FAILED,
    })),
  ],
);

const makeShownLoadingStateAtom = (taskType) =>
  map(
    shownLoadingStateAtom,
    (loadingStatesTable) => loadingStatesTable[taskType] || LOADING_STATE.IDLE,
  );

export const makeIsShownLoadingAtom = (taskType) =>
  map(
    makeShownLoadingStateAtom(taskType),
    (loadingState) => loadingState === LOADING_STATE.LOADING,
  );

export const makeIsShownSucceededAtom = (taskType) =>
  map(
    makeShownLoadingStateAtom(taskType),
    (loadingState) => loadingState === LOADING_STATE.SUCCEEDED,
  );

export const makeIsShownFailedAtom = (taskType) =>
  map(
    makeShownLoadingStateAtom(taskType),
    (loadingState) => loadingState === LOADING_STATE.FAILED,
  );

export default combine({
  executorsAtom,
  shownLoadingStateAtom,
});
