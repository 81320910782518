import cx from 'classnames';
import styles from './Checkbox.module.scss';

const Checkbox = ({ label, value, disabled, isDisabled, ...rest }) => {
  const handleMouseDown = (e) => {
    e.preventDefault(); // disable text selection on dblclick;
  };

  return (
    <div>
      <label
        className={cx(styles.wrapper, {
          [styles.wrapper_disabled]: disabled || isDisabled,
        })}
      >
        <input
          className={styles.element}
          type="checkbox"
          value={value}
          checked={value}
          disabled={disabled || isDisabled}
          {...rest}
        />

        <span className={styles.mark} />

        {label && (
          <span className={styles.label} onMouseDown={handleMouseDown}>
            {label}
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
