import styles from './ContentGrid.module.scss';

const DashboardPageContentGridItem = ({ children }) => (
  <div className={styles.item}>{children}</div>
);

const DashboardPageContentGridColumn = ({ title, children }) => (
  <div className={styles.column}>
    <div className={styles.columnTitle}>{title}</div>

    <div className={styles.columnContent}>{children}</div>
  </div>
);

const DashboardPageContentGrid = ({ children }) => (
  <div className={styles.grid}>{children}</div>
);

DashboardPageContentGrid.Column = DashboardPageContentGridColumn;
DashboardPageContentGrid.Item = DashboardPageContentGridItem;
export default DashboardPageContentGrid;
