@import '../../styles/utils';

.root {
  display: inline-flex;
  flex-direction: column;
}

.primary {
  margin-bottom: 16px;
}

.list {
  padding-bottom: 4px;
  border-radius: $primaryBorderRadius;
  background-color: $white;
  overflow: hidden;
}

.item:not(:last-child) {
  margin-bottom: -16px;
}
