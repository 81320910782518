import Tabs from '../../../components/Tabs/Tabs';
import { IconWarning } from '../../../components/icons';

import styles from './TabsControlItem.module.scss';

const AdPageTabsControlItemTrailingElement = ({ count, hasWarning }) => {
  if (Boolean(count)) {
    return <span className={styles.count}>{count}</span>;
  }

  if (hasWarning) {
    return (
      <span className={styles.warningIcon}>
        <Tabs.ControlItemIcon icon={<IconWarning />} />
      </span>
    );
  }

  return null;
};

const AdPageTabsControlItem = ({ count, hasWarning, ...rest }) => (
  <Tabs.ControlItem
    trailingElement={
      <AdPageTabsControlItemTrailingElement
        count={count}
        hasWarning={hasWarning}
      />
    }
    {...rest}
  />
);

export default AdPageTabsControlItem;
