import { FormattedMessage } from 'react-intl';

import Checkbox from '../../../components/Checkbox/Checkbox';
import Switch from '../../../components/Switch/Switch';

import styles from './Subtitle.module.scss';

const CallHistoryPageSubtitle = ({
  isOnlyMy,
  isTableFiltersShown,
  onIsOnlyMyChange,
  onIsTableFiltersShownToggle,
}) => (
  <div className={styles.root}>
    <div className={styles.item}>
      <Checkbox
        label={<FormattedMessage defaultMessage="Only mine" />}
        value={isOnlyMy}
        onChange={onIsOnlyMyChange}
      />
    </div>

    <div className={styles.item}>
      <Switch
        label={<FormattedMessage defaultMessage="Field filters" />}
        value={isTableFiltersShown}
        onChange={onIsTableFiltersShownToggle}
      />
    </div>
  </div>
);

export default CallHistoryPageSubtitle;
