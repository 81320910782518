import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import { checkIsNumber } from '../../utils';
import { deserializeUserOverview } from '../../normalizers';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';
import * as UsersCreation from './creation';
import * as UsersEditing from './editing';

const doneSucceeded = declareAction('Users/Showing/doneSucceeded');
const doneFailed = declareAction('Users/Showing/doneFailed');

export const done = declareAction('Users/Showing/done', async (_, store) => {
  const authorization = store.getState(Account.authorizationAtom);

  try {
    const users = await api(authorization).users.getAll();

    store.dispatch(
      doneSucceeded({ users: users.map(deserializeUserOverview) }),
    );
  } catch (error) {
    console.error(error);

    store.dispatch(doneFailed());
  }
});

const usersLoadingStateAtom = declareAtom(
  'Users/Showing/usersLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
  ],
);

export const allUsersAtom = declareAtom(
  'Users/Showing/allUsersAtom',
  [],
  (on) => [
    on(done, () => []),
    on(doneSucceeded, (state, { users }) => users),
    on(UsersCreation.handleSaved, (state, addedUser) => {
      const finalUser = {
        ...addedUser,
        id: addedUser.dummyId,
        _isAdding: true,
      };

      delete finalUser.dummyId;

      return [finalUser, ...state];
    }),
    on(UsersCreation.savedSucceeded, (state, { userId, dummyId }) =>
      state.map((userItem) => {
        if (userItem.id !== dummyId) {
          return userItem;
        }
        const addedUser = { ...userItem, id: userId };
        delete addedUser._isAdding;
        return addedUser;
      }),
    ),
    on(UsersCreation.savedFailed, (state, { dummyId }) =>
      state.filter(({ id }) => id !== dummyId),
    ),
    on(UsersEditing.handleSaved, (state, updatedUser) =>
      state.map((userItem) => {
        if (userItem.id !== updatedUser.id) {
          return userItem;
        }
        return updatedUser;
      }),
    ),
    on(UsersEditing.savedFailed, (state, { userId, originalUser }) =>
      state.map((userItem) => {
        if (userItem.id === userId) {
          return originalUser;
        }

        return userItem;
      }),
    ),
  ],
);

const not = (expression) => !expression;
const isActive = (user) => user.isActive;

export const allUsersEmails = map(allUsersAtom, (users) =>
  users.map(({ email }) => email),
);

export const activeUsersAtom = map(allUsersAtom, (state) =>
  state.filter(isActive),
);
export const inactiveUsersAtom = map(allUsersAtom, (state) =>
  state.filter((user) => not(isActive(user))),
);

export const makeUserByIdAtom = (userId) =>
  map(allUsersAtom, (state) => {
    if (!checkIsNumber(userId)) {
      return null;
    }
    return state.find(({ id }) => id === userId) || null;
  });

const doneLoadingStateAtom = declareAtom(
  'Users/Showing/doneLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isUsersLoadingAtom = map(
  doneLoadingStateAtom,
  (state) => state === LOADING_STATE.LOADING,
);

export const isUsersSucceededAtom = map(
  doneLoadingStateAtom,
  (state) => state === LOADING_STATE.SUCCEEDED,
);

export const isUsersFailedAtom = map(
  doneLoadingStateAtom,
  (state) => state === LOADING_STATE.FAILED,
);

export const isUsersReadyAtom = map(
  combine([isUsersSucceededAtom, isUsersFailedAtom]),
  ([isSucceeded, isFailed]) => isSucceeded || isFailed,
);

export default combine({
  usersLoadingStateAtom,
  doneLoadingStateAtom,
});
