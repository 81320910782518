import { forwardRef } from 'react';
import Input from '../Input/Input';
import IconContainer from '../IconContainer/IconContainer';
import { IconSearch } from '../icons';

const SearchInput = forwardRef(({ ...rest }, ref) => (
  <Input
    prependedElement={
      <IconContainer size="small">
        <IconSearch />
      </IconContainer>
    }
    ref={ref}
    {...rest}
  />
));

SearchInput.displayName = 'SearchInput';

export default SearchInput;
