import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import {
  deserializeTaskComment,
  serializeTaskComment,
} from '../../normalizers';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';

export const saveSucceeded = declareAction('Tasks/Comment/saveSucceeded');
const saveFailed = declareAction('Tasks/Comment/saveFailed');

export const save = declareAction(
  'Tasks/Comment/save',
  async ({ taskId, comment }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const fullComment = await api(authorization).tasks.comment({
        taskId,
        ...serializeTaskComment(comment),
      });

      store.dispatch(
        saveSucceeded({ taskId, comment: deserializeTaskComment(fullComment) }),
      );
    } catch (error) {
      console.error(error);

      store.dispatch(saveFailed());
    }
  },
);

const saveLoadingStateAtom = declareAtom(
  'Tasks/Comment/saveLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(save, () => LOADING_STATE.LOADING),
    on(saveSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(saveFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isSaveCommentLoadingAtom = map(
  saveLoadingStateAtom,
  (loadingState) => loadingState === LOADING_STATE.LOADING,
);

export const isSaveCommentSucceededAtom = map(
  saveLoadingStateAtom,
  (loadingState) => loadingState === LOADING_STATE.SUCCEEDED,
);

export const isSaveCommentFailedAtom = map(
  saveLoadingStateAtom,
  (loadingState) => loadingState === LOADING_STATE.FAILED,
);

export default combine({ saveLoadingStateAtom });
