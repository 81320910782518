import { declareAction, declareAtom, combine } from '@reatom/core';

import {
  serializeUpdatingTask,
  deserializeTaskOverview,
} from '../../normalizers';
import api from '../../services/api';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';
import TaskQueueIsStopped from './isStopped';
import { TASK_QUEUE_ERROR_TYPE } from './entityId';

export const doneSucceeded = declareAction('TaskQueue/Skip/doneSucceeded');
export const doneFailed = declareAction('TaskQueue/Skip/doneFailed');

export const done = declareAction(
  'TaskQueue/Skip/done',
  async ({ task, shouldQueueStop }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const queuedTask = await api(authorization).tasks.skipQueuedTask(
        serializeUpdatingTask(task),
        task.id,
        shouldQueueStop,
      );

      store.dispatch(
        doneSucceeded({
          finishedTaskId: task.id,
          task: deserializeTaskOverview(queuedTask),
        }),
      );
    } catch (error) {
      const dispatchDoneSucceeded = () =>
        store.dispatch(
          doneSucceeded({
            finishedTaskId: task.id,
            task: null,
          }),
        );

      switch (error.type) {
        case TASK_QUEUE_ERROR_TYPE.EMPTY_QUEUE:
          dispatchDoneSucceeded();
          return;

        case TASK_QUEUE_ERROR_TYPE.STOPPED_QUEUE:
          dispatchDoneSucceeded();
          store.dispatch(TaskQueueIsStopped.stopped());
          return;

        default:
          console.error(error);
          store.dispatch(
            doneFailed({
              errorType:
                TASK_QUEUE_ERROR_TYPE[error.type] ||
                TASK_QUEUE_ERROR_TYPE.DEFAULT,
            }),
          );
      }
    }
  },
);

const loadingStateAtom = declareAtom(
  'TaskQueue/Skip/loadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
  ],
);

export const errorTypeAtom = declareAtom(
  'TaskQueue/Skip/errorTypeAtom',
  '',
  (on) => [on(done, () => ''), on(doneFailed, ({ errorType }) => errorType)],
);

export default combine({ loadingStateAtom, errorTypeAtom });
