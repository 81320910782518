@import '../../../../../styles/utils';

.root {
  display: flex;
  align-items: center;
}

.field {
  flex: 1 1 auto;
  margin-right: 12px;
}

.buttonHolder {
  flex: 0 0 auto;
}

.button {
  @extend %normalize-button;
  @include defaultTransition(color);

  &:hover,
  &:focus {
    color: $secondaryBlue;
  }

  &:active {
    color: $primaryBlue;
  }
}
