import PropTypes from 'prop-types';

import IconContainer from '../../../../../components/IconContainer/IconContainer';
import { IconEdit } from '../../../../../components/icons';

import styles from './EditableField.module.scss';

const AdDetailsTableEditableField = ({
  children,
  isAvailable = true,
  onEditClick,
}) => (
  <div className={styles.root}>
    {children && <div className={styles.field}>{children}</div>}

    {isAvailable && (
      <div className={styles.buttonHolder}>
        <button className={styles.button} onClick={onEditClick}>
          <IconContainer size="medium">
            <IconEdit />
          </IconContainer>
        </button>
      </div>
    )}
  </div>
);

AdDetailsTableEditableField.propTypes = {
  children: PropTypes.element,
  isAvailable: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
};

export default AdDetailsTableEditableField;
