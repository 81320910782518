import Linkify from 'react-linkify';

const LinksHighlighter = ({ children }) => (
  <Linkify
    componentDecorator={(href, text, key) => (
      <a target="_blank" rel="noreferrer" href={href} key={key}>
        {text}
      </a>
    )}
  >
    {children}
  </Linkify>
);

export default LinksHighlighter;
