import { useState, useCallback } from 'react';

const useToggle = (defaultIsOn = false) => {
  const [isOn, setIsOn] = useState(defaultIsOn);

  const on = useCallback(() => setIsOn(true), []);
  const off = useCallback(() => setIsOn(false), []);
  const toggle = useCallback(() => setIsOn((isOn) => !isOn), []);

  return { isOn, on, off, toggle };
};

export default useToggle;
