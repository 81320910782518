import { SORT_TYPE } from '../constants';

const toggleSortType = (sortType) => {
  switch (sortType) {
    case SORT_TYPE.ASC:
      return SORT_TYPE.DESC;

    case SORT_TYPE.DESC:
      return SORT_TYPE.ASC;

    default:
      return '';
  }
};

export default toggleSortType;
