import { deserializeCallPreview } from './calls';
import { deserializeTaskPreview } from './tasks';

export const deserializeAdStatus = ({
  bannedInfo = '',
  bannedStatus = '',
  isReprocessing,
  reason = '',
  status,
}) => ({ status, reason, bannedInfo, bannedStatus, isReprocessing });

const deserializeAdOverviewDetails = ({
  general_info,
  about_object,
  about_house,
  geo,
  about_add,
  about_author,
}) => ({
  general: {
    contractType: general_info.contract_type,
    realtyType: general_info.realty_type,
  },

  geo: {
    region: geo.region,
    raion: geo.raion,
    city: geo.city,
    district: geo.district,
    microdistrict: geo.microdistrict,
    street: geo.street,
    apartmentComplex: {
      raw: geo.building.raw,
      recognized:
        geo.building.recognized === 0 ? null : geo.building.recognized,
    },
    house: geo.house,
  },

  object: {
    title: [geo.street.recognized, geo.house.recognized]
      .filter(Boolean)
      .join(', '),
    description: about_object.text,
    price: about_object.price,
    currency: about_object.currency,
    roomCount: about_object.room_count,
    roomType: about_object.room_type,
    floor: about_object.floor,
    totalArea: about_object.area_total,
    livingArea: about_object.area_living,
    kitchenArea: about_object.area_kitchen,
    landArea: about_object.area_land,
  },

  house: {
    floorCount: about_house.floor_count,
    type: about_house.house_type.recognized,
    wallType: about_house.wall_type,
    houseType: about_house.house_type,
    heatingSystem: about_house.heating_system,
    insulationType: about_house.insulation_type,
    yearOfBuilding: about_house.year_of_building,
    ceilingHeight: about_house.ceiling_height,
    apartmentComplex: [geo.building.recognized, geo.microdistrict.recognized]
      .filter(Boolean)
      .join(', '),
  },

  author: {
    phoneNumbers: about_author.contacts,
    name: about_author.contact_name,
    agency: about_author.agency,
  },

  meta: {
    header: about_add.header,
    isOwner: about_add.is_owner,
    isExclusive: about_add.exclusive_rights,
    updateTime: about_add.lun_update_time,
    insertTime: about_add.lun_insert_time,
    deleteTime: about_add.lun_delete_time,
  },
});

const deserializePhoto = ({
  id,
  url,
  size,
  category,
  repair_quality,
  processing_stage,
  download_status,
}) => ({
  id,
  url,
  size,
  category,
  repairQuality: repair_quality,
  processingStage: processing_stage,
  downloadStatus: download_status,
});

export const deserializeAdOverview = ({ general, calls, tasks }) => ({
  status: deserializeAdStatus(general.pageStatusInfo),
  details: deserializeAdOverviewDetails(general),
  calls: calls.map((call) => deserializeCallPreview(call)),
  photos: general.aboutImages.map((photo) => deserializePhoto(photo)),
  tasks: tasks.map((task) => deserializeTaskPreview(task)),
});

export const deserializeAdHouseOptions = (houses) =>
  houses.map(({ id, value }) => ({ id, title: value }));
