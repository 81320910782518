import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Row.module.scss';

const AdDetailsTableRow = ({ title, raw, recognized, isHighlighted }) => (
  <div className={cx(styles.row, { [styles.row_highlighted]: isHighlighted })}>
    <div className={styles.titleCell}>{title}</div>

    <div className={styles.rowContent}>
      <div className={styles.rawCell}>{raw}</div>
      <div className={styles.recognizedCell}>{recognized}</div>
    </div>
  </div>
);

AdDetailsTableRow.propTypes = {
  title: PropTypes.node.isRequired,
  raw: PropTypes.node,
  recognized: PropTypes.node,
  isHighlighted: PropTypes.bool,
};

export default AdDetailsTableRow;
