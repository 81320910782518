@import '../../styles/utils.scss';

.root {
  @extend %normalize-button;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background-color: $primaryBlue;
  color: $white;
  @include defaultTransition(background-color);

  &:hover,
  &:focus {
    background-color: $secondaryBlue;
  }

  &_small {
    height: 36px;
    width: 36px;
  }
}

.root_variant--flat {
  background-color: transparent;
  border: 1px solid transparent;
  color: $black;
  @include defaultTransition(border, background-color, color, opacity);

  &:focus {
    border: 1px solid $grey_3;
    background-color: transparent;
    color: $black;
  }

  &:hover {
    border: 1px solid $grey_3;
    background-color: transparent;
    color: $black;
  }

  &:focus {
    color: $white;
  }

  &:active {
    border: 1px solid $secondaryBlue;
    background-color: $secondaryBlue;
    color: $white;
  }
}
