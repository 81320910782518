import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as AdsStatistic from '../../models/adsStatistic';
import { useNotifications } from '../../hooks';
import useGeneralOptions from './useGeneralOptions';

const useStatistic = ({ dateRange, siteId, cityId, section }) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const { locationGeneralOption, siteGeneralOption, sectionGeneralOption } =
    useGeneralOptions();

  const statistic = useAtom(AdsStatistic.Overview.tableAtom);
  const isStatisticLoading = useAtom(AdsStatistic.Overview.isShownLoadingAtom);
  const isStatisticSucceeded = useAtom(
    AdsStatistic.Overview.isShownSucceededAtom,
  );
  const isStatisticFailed = useAtom(AdsStatistic.Overview.isShownFailedAtom);

  const onStatisticShown = useAction(AdsStatistic.Overview.shown);

  useEffect(() => {
    if (!isStatisticFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load statistics',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatisticFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (dateRange.filter(Boolean).length !== 2) {
      return;
    }

    onStatisticShown({
      dateRange,
      cityId: cityId === locationGeneralOption.value ? null : cityId,
      section: section === sectionGeneralOption.value ? null : section,
      siteId: siteId === siteGeneralOption.value ? null : siteId,
    });
  }, [
    dateRange,
    siteId,
    cityId,
    section,
    locationGeneralOption,
    siteGeneralOption,
    sectionGeneralOption,
    onStatisticShown,
  ]);

  return {
    statistic,
    isStatisticSucceeded,
    isStatisticLoading,
    isStatisticFailed,
  };
};

export default useStatistic;
