import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import * as Account from '../account';

const shownSucceeded = declareAction('AdsStatistic/Cities/shownSucceeded');
const shownFailed = declareAction('AdsStatistic/Cities/shownFailed');

export const shown = declareAction(
  'AdsStatistic/Cities/shown',
  async (payload, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const list = await api(authorization).adsStatistic.cities();
      store.dispatch(shownSucceeded({ list }));
    } catch (error) {
      console.error(error);

      store.dispatch(shownFailed());
    }
  },
);

export const listAtom = declareAtom(
  'AdsStatistic/Cities/listAtom',
  [],
  (on) => [on(shown, () => []), on(shownSucceeded, (state, { list }) => list)],
);

const shownLoadingStateAtom = declareAtom(
  'AdsStatistic/Cities/shownLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(shown, () => LOADING_STATE.LOADING),
    on(shownSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(shownFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isShownLoadingAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);
export const isShownIdleAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.IDLE,
);

export const isShownFailedAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({
  listAtom,
  shownLoadingStateAtom,
});
