const createAdsStatisticService = (initRequest) => ({
  overview: ({ siteId, date, cityId, section }) =>
    initRequest('/data-statistic/overview', {
      method: 'POST',
      data: { siteId, date, cityId, section },
    }),

  cities: () => initRequest('/data-statistic/cities'),

  sites: () => initRequest('/data-statistic/sites'),
});

export default createAdsStatisticService;
