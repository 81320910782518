import styles from './FormGroup.module.scss';

const FormGroup = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

FormGroup.NarrowItem = ({ children }) => (
  <div className={styles.narrowItem}>{children}</div>
);
FormGroup.WideItem = ({ children }) => (
  <div className={styles.wideItem}>{children}</div>
);
FormGroup.HalfItem = ({ children }) => (
  <div className={styles.halfItem}>{children}</div>
);
export default FormGroup;
