const AstronautImage = () => (
  <svg width="220" height="225" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path
        d="M41.395 65.705S31.4 69.655 29.93 79.25c-1.47 9.596 21.243 37.705 21.243 37.705l70.04-37.71-22.144-38.83-57.674 25.289z"
        fill="#C7DCF9"
      />
      <path
        d="M56.881 74.775s-7.37 7.56-9.63 15.925c-2.26 8.365-2.552 23.304-2.175 28.9 10.994 10.165 54.451 1.647 54.451 1.647l41.879-49.221s-7.1-13.468-10.703-17.16c-3.604-3.692-15.287-8.283-28.765-2.863C94.028 63.22 56.88 74.775 56.88 74.775z"
        fill="#E1ECFF"
      />
      <path
        d="M53.592 78.684s16.26 5.214 34.562-1.85c18.302-7.064 21.618-26.96 21.618-26.96l-7.843 2.132-45.048 22.769-3.29 3.91z"
        fill="#E1ECFF"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M119.782 139.716s20.557.133 26.333 1.607c5.777 1.475 21.066 36.048 27.392 39.359 6.326 3.31 11.7-2.189 18.67-6.271 6.97-4.083 13.358-9.023 13.558-11.085.2-2.062-2.76-10.301-5.32-11.964-2.561-1.662-9.996-1.78-13.961.758-5.827-15.86-14.11-38.991-21.707-42.175-7.596-3.184-30.617-4.7-30.617-4.7l-14.348 34.471z"
        fill="#E1ECFF"
      />
      <path
        d="M141.406 124.578s16.738 1.83 21.363 8.054c4.624 6.224 13.787 44.308 24.043 45.21.165 12.336-21.077 16.791-21.077 16.791l-50.918-56.53 26.589-13.525z"
        fill="#E1ECFF"
        opacity=".51"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M64.551 136.597s12.64 26.49 24.105 33.716c11.465 7.226 32.237 6.641 37.966 11.373 5.73 4.732 4.519 27.463 10.245 33.484 5.727 6.022 13.784 9.71 21.316 9.032 7.531-.678 13.558-4.288 13.634-7.675.076-3.386-.226-6.324-3.766-8.13-3.54-1.806-8.525-3.31-8.74-5.416-.214-2.106-4.218-34.169-5.879-42.883-1.66-8.715-14.56-20.837-31.184-26.943-15.742-13.17-39.92-7.528-39.92-7.528l-17.777 10.97z"
        fill="#E1ECFF"
      />
      <path
        d="M68.229 143.685s14.507.74 27.536-4.979c-2.49-13.247-10.172-17.16-10.172-17.16l-21.042 15.051 3.678 7.088z"
        fill="#E1ECFF"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M88.304 5.345C76.942-.732 53.13-2.782 38.432 15.431c-14.698 18.214-7.373 37.103-1.196 45.897 6.467 9.229 28.697 23.333 52.423 10.087 23.726-13.247 26.213-51.32-1.355-66.07z"
        fill="#E1ECFF"
      />
      <path
        d="M86.349 16.483C96.44 18.89 102.996 33.04 99.607 46.586c-3.39 13.547-15.066 22.655-28.171 22.729-13.105.073-26.137-8.868-26.067-25.587.071-16.066 19.064-32.473 40.98-27.245z"
        fill="#1952C5"
      />
      <path
        d="M57.272 122.299c13.15-6.098 3.313-20.02 16.421-23.187 17.283 2.456 22.063 1.575 22.063 1.575S62.693 129.7 57.272 122.299z"
        fill="#E1ECFF"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fill="#FFAD4B"
        d="m68.229 85.06 21.883 56.263 30.957-.737 28.321-17.911 8.437-25.59-20.487-61.41-9.945 28.749-25.76 20.47z"
      />
      <path
        d="M57.272 122.299c7.255-.588 10.95-5.308 13.15-7.402 1.12-1.07.896-2.006.332-5.09-.565-3.084 1.393-6.248 4.068-7.602 1.47 3.95 3.275 4.7 4.668 4.515 1.394-.185 2.825-8.503 7.382-8.43 4.556.073 8.21 6.098 8.819 9.105.609 3.008-2.334 11.588-6.026 17.609-3.693 6.021-8.902 13.476-20.628 15.808-11.726 2.332-18.394-3.651-22.236-11.214-1.296-2.553-1.764-6.08-1.722-10.01 0 0 2.625 3.463 12.193 2.71zM144.396 56.825s1.396 4.538.455 6.307c-.94 1.768-2.222 3.23-3.163 5.495-.94 2.265.453 10.8 4.595 12.266 4.142 1.466 7.57-2.37 8.663-4.59 1.094-2.221.488-8.052-1.132-12.117-1.62-4.065-4.665-7.678-9.418-7.36z"
        fill="#E1ECFF"
      />
      <path
        d="m149.158.443.929 1.88a.779.779 0 0 0 .588.428l2.078.294a.785.785 0 0 1 .435 1.34l-1.502 1.468a.78.78 0 0 0-.226.693l.352 2.056a.784.784 0 0 1-1.137.829l-1.861-.975a.78.78 0 0 0-.73 0l-1.86.975a.785.785 0 0 1-1.14-.829l.355-2.056a.78.78 0 0 0-.226-.693l-1.502-1.468a.784.784 0 0 1 .435-1.34l2.078-.294a.779.779 0 0 0 .588-.428l.929-1.88a.788.788 0 0 1 1.417 0zM49.703 199.377l.779 1.577c.094.195.28.33.494.359l1.743.252a.658.658 0 0 1 .364 1.122l-1.26 1.228a.652.652 0 0 0-.189.587l.294 1.733a.66.66 0 0 1-.955.696l-1.558-.82a.662.662 0 0 0-.612 0l-1.552.815a.659.659 0 0 1-.955-.697l.294-1.733a.663.663 0 0 0-.192-.587l-1.258-1.228a.658.658 0 0 1 .365-1.122l1.74-.252c.215-.03.4-.164.497-.359l.779-1.577a.659.659 0 0 1 1.182.006z"
        fill="#FEC272"
      />
      <ellipse
        fill="#FFF"
        opacity=".48"
        style={{ mixBlendMode: 'soft-light' }}
        transform="rotate(-12.43 76.518 28.742)"
        cx="76.518"
        cy="28.742"
        rx="13.413"
        ry="4.741"
      />
      <path
        d="M94.015 115.831s-8.322-7.431-9.83-7.431c-1.508 0-5.524 5.874-4.618 7.431.905 1.557 5.556 6.656 10.662 8.224 1.655-3.154 3.786-8.224 3.786-8.224z"
        fill="#E1ECFF"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fill="#FF97C9"
        opacity=".5"
        style={{ mixBlendMode: 'multiply' }}
        d="m101.635 84.893 19.434 55.693 28.321-17.911-21.995-58.251z"
      />
      <path
        d="M155.099 170.736a36.165 36.165 0 0 1-9.701 5.748c-3.295 1.27-4.41 3.866-3.413 8.63.997 4.764 1.555 12.192 3.613 14.148 2.058 1.957 9.136.917 12.805-3.63-1.15-8.958-3.304-24.896-3.304-24.896zM174.28 122.164s-4.115 1.791-3.724 4.65c.39 2.857 4.595 16.67 6.253 17.916 1.657 1.246 6.564-.963 6.564-.963-5.039-14.263-9.093-21.603-9.093-21.603z"
        fill="#E1ECFF"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M.604 155.219c-3.272-6.78 7.39-14.034 16.462-13.511 9.072.523 12.885 8.89 16.098 12.013 6.711 6.515 6.5 12.888 1.852 15.629-4.648 2.74-30.082-5.164-34.412-14.131zM183.617 50.2c-2.646-2.315.076-13.195 6.326-15.741 6.25-2.547 14.01.238 15.668 4.602 1.658 4.365-16.57 15.879-21.994 11.138zM214.242 56.02c-1.355 1.93-2.257 7.426.603 8.404 2.86.978 5.024-4.676 4.019-7.62-1.006-2.942-3.716-2.073-4.622-.783z"
        fill="#75A3FF"
      />
      <path
        d="M216.3 11.425c-2.134 1.005-5.653 5.322-3.654 7.593 2 2.27 6.682-1.566 7.273-4.618.59-3.051-2.193-3.645-3.619-2.975zM15.614 183.742c-1.846-1.468-7.276-2.717-8.434.077-1.158 2.793 4.357 5.287 7.35 4.482 2.992-.805 2.313-3.578 1.084-4.559z"
        fill="#75A3FF"
        opacity=".44"
      />
      <path
        d="M31.556 151.862c-3.284.617-2.754 3.55-1.702 6.209 1.052 2.658 4.756 3.416 7.37.987-1.696-4.033-5.668-7.196-5.668-7.196zM12.68 147.435c-2.351.262-4.268 3.663-2.61 6.421 1.658 2.758 4.818 4.767 6.626 4.015 1.808-.752 2.11-4.817.805-7.425-1.305-2.608-2.56-3.263-4.82-3.01zM198.274 36.803c-1.29.41-2.599 1.806-2.484 3.313.115 1.506 1.432 2.822 2.86 2.643 1.429-.179 2.646-2.485 2.352-4.141-.294-1.657-1.743-2.127-2.728-1.815zM184.607 39.74s1.764 1.762 1.47 3.762c-.294 2-3.333 1.815-3.333 1.815s.452-3.41 1.863-5.577z"
        fill="#99ADF9"
        opacity=".64"
        style={{ mixBlendMode: 'multiply' }}
      />
    </g>
  </svg>
);

export default AstronautImage;
