import styles from './Cell.module.scss';

export const calcFlex = (width, isFlexible, isFullWidth) => {
  if (isFullWidth) {
    return '0 0 100%';
  }

  return isFlexible ? `1 1 ${width}` : `0 0 ${width}`;
};

const AdPageTableCell = ({
  children,
  width = 'auto',
  minWidth,
  isFlexible,
  isFullWidth,
}) => {
  const flex = calcFlex(width, isFlexible, isFullWidth);

  return (
    <div
      className={styles.root}
      style={{ flex, width: minWidth || width, minWidth }}
    >
      {children || '—'}
    </div>
  );
};

export default AdPageTableCell;
