@import '../../styles/utils';

.root {
  position: relative;
}

.element {
  vertical-align: top;
}

.amountTooltip {
  height: 18px !important;
  box-sizing: border-box;
  padding: 4px 12px !important;
  border-radius: 9px !important;
  background-color: $primaryBlue !important;
  color: $white !important;
  font-size: 10px !important;
  transform: translate(calc(-50% - 10px), -44px);
}

.dateTooltip {
  height: 16px;
  box-sizing: border-box;
  padding: 4px 16px !important;
  border-radius: 8px !important;
  background-color: $dark !important;
  color: $white !important;
  font-size: 9px !important;
  transform: translate(calc(-50% - 10px), -18px);
  white-space: nowrap;
}
