import { useCallback, useRef } from 'react';

const useDatePicker = () => {
  const rootRef = useRef();

  const selectRange = useCallback((dateRange) => {
    if (!rootRef.current) {
      return;
    }

    if (!dateRange[0] || !dateRange[1]) {
      return;
    }

    rootRef.current.flatpickr.setDate(dateRange.map((date) => new Date(date)));
  }, []);

  const jumpToDate = useCallback((date) => {
    if (!rootRef.current) {
      return;
    }

    if (!date) {
      return;
    }

    rootRef.current.flatpickr.jumpToDate(date);
  }, []);

  return [rootRef, { selectRange, jumpToDate }];
};

export default useDatePicker;
