import cx from 'classnames';
import styles from './Field.module.scss';

const Field = ({
  label,
  labelElement,
  error,
  hint,
  children,
  hasDefaultHeight,
  isRequired,
}) => (
  <div className={styles.root}>
    <div className={styles.labelRow}>
      <div className={styles.label}>
        {label} {isRequired && '*'}
      </div>

      {labelElement && <div>{labelElement}</div>}
    </div>

    <div
      className={cx(styles.content, {
        [styles.content_withDefaultHeight]: hasDefaultHeight,
      })}
    >
      {children}
    </div>

    {(error || hint) && (
      <div className={cx(styles.hint, { [styles.hint_failed]: error })}>
        {error || hint}
      </div>
    )}
  </div>
);

export default Field;
