import { combine, declareAtom, map } from '@reatom/core';

import * as AdOverview from './overview';
import * as AdPublishing from './publishing';
import * as AdRemoving from './removing';
import * as AdReprocessing from './reprocessing';

const tableAtom = declareAtom('Ads/Status/tableAtom', {}, (on) => [
  on(AdPublishing.doneSucceeded, (state, { adId, adStatus }) => ({
    ...state,
    [adId]: adStatus,
  })),
  on(AdRemoving.doneSucceeded, (state, { adId, adStatus }) => ({
    ...state,
    [adId]: adStatus,
  })),
  on(AdReprocessing.doneSucceeded, (state, { adId, adStatus }) => ({
    ...state,
    [adId]: adStatus,
  })),
  on(AdOverview.shownSucceeded, (state, { adId, adStatus }) => ({
    ...state,
    [adId]: adStatus,
  })),
]);

export const makeAdStatusAtom = (adId) =>
  map(tableAtom, (table) => table[adId] || null);

export const makeIsLoadingAtom = (adId) =>
  map(
    combine([
      AdPublishing.makeIsLoadingAtom(adId),
      AdRemoving.makeIsLoadingAtom(adId),
      AdReprocessing.makeIsLoadingAtom(adId),
    ]),
    ([isPublishingLoading, isRemovingLoading, isReprocessingLoading]) =>
      isPublishingLoading || isRemovingLoading || isReprocessingLoading,
  );

export default combine({ tableAtom });
