import { forwardRef } from 'react';
import cx from 'classnames';
import styles from './Input.module.scss';

const Input = forwardRef(
  (
    {
      prependedElement,
      appendedElement,
      isGrey,
      isHighlighted,
      isDisabled,
      ...rest
    },
    ref,
  ) => (
    <div
      className={cx(styles.root, {
        [styles.root_disabled]: isDisabled,
        [styles.root_grey]: isGrey,
        [styles.root_highlighted]: isHighlighted,
      })}
    >
      {prependedElement && (
        <span className={styles.prepended}>{prependedElement}</span>
      )}

      <input
        className={styles.element}
        ref={ref}
        {...rest}
        disabled={isDisabled}
      />

      {appendedElement && (
        <span className={styles.appended}>{appendedElement}</span>
      )}
    </div>
  ),
);

export default Input;
