import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import Page from '../../components/Page/Page';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';

import withRoles from '../../components/PagesScene/withRoles';
import styles from './PhonePage.module.scss';

const PhonePageLayout = ({ phone, children }) => (
  <FormattedMessage defaultMessage="Phone number {phone}" values={{ phone }}>
    {([helmetTitle]) => (
      <Page helmetTitle={helmetTitle}>
        <PageMainContentTile
          title={<FormattedMessage defaultMessage="Phone number" />}
          titleAppended={<div className={styles.phone}>{phone}</div>}
        >
          {children}
        </PageMainContentTile>
      </Page>
    )}
  </FormattedMessage>
);

const PhonePage = () => {
  const { phone } = useParams();

  return (
    <PhonePageLayout phone={phone}>
      <FormattedMessage defaultMessage="Page is under development :)" />
    </PhonePageLayout>
  );
};

PhonePage.RouteParams = withRoles()({
  element: <PhonePage />,
  path: '/phone/:phone',
});

export default PhonePage;
