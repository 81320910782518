import { useCallback, useLayoutEffect } from 'react';
import { useQueryParams, useEventHandler } from '../hooks';

const usePagination = ({
  amount = 0,
  defaultPage = 1,
  perPage = 10,
  initializePagination = true,
} = {}) => {
  const [queryParams, { setQueryParams }] = useQueryParams();
  const page = Number(queryParams.page);
  const maxPage = Math.ceil(amount / perPage);

  const setPage = useCallback(
    (page, replaceHistory) =>
      setQueryParams({ page: Math.max(page, 1) }, { replaceHistory }),
    [setQueryParams],
  );

  useLayoutEffect(() => {
    if (!initializePagination) {
      return;
    }

    const hasAmount = amount > 0;
    const isMaxPageExceeded = hasAmount && page > maxPage;

    if (!page || isMaxPageExceeded) {
      setPage(defaultPage, true);
    }
  }, [
    page,
    defaultPage,
    perPage,
    amount,
    maxPage,
    initializePagination,
    setPage,
  ]);

  const onPrev = useEventHandler(
    () => setPage(page > 1 ? page - 1 : page),
    [page, setPage],
  );

  const onNext = useEventHandler(
    () => setPage((page + 1) * perPage > amount + perPage ? page : page + 1),
    [page, amount, perPage, setPage],
  );

  const goToPage = useEventHandler(
    (page) => setPage(Math.min(page, maxPage)),
    [maxPage, setPage],
  );

  return [
    { page: page || defaultPage, amount, perPage },
    { onPrev, onNext, goToPage },
  ];
};

export default usePagination;
