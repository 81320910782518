import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';

export const doneSucceeded = declareAction('Tasks/Recreation/doneSucceeded');
const doneFailed = declareAction('Tasks/Recreation/doneFailed');

export const done = declareAction(
  'Tasks/Recreation/done',
  async ({ callId }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      await api(authorization).tasks.recreate(callId);
      store.dispatch(doneSucceeded());
    } catch (error) {
      console.error(error);

      store.dispatch(doneFailed());
    }
  },
);

const doneLoadingStateAtom = declareAtom(
  'Tasks/Recreation/doneLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isDoneLoadingAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);

export const isDoneSucceededAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.SUCCEEDED,
);

export const isDoneFailedAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({ doneLoadingStateAtom });
