import { useIntl } from 'react-intl';

import { TASK_TYPE_LIST, TASK_TYPE_INFO } from '../../constants';
import styles from './TaskTypesSwitch.module.scss';

const TaskTypesSwitch = ({ selectedTypes = [], onChange, ...rest }) => {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {TASK_TYPE_LIST.map((type) => (
          <div key={type} className={styles.item}>
            <label className={styles.label} key={type}>
              <input
                className={styles.element}
                type="checkbox"
                value={type}
                checked={selectedTypes.includes(type)}
                onChange={onChange}
                {...rest}
              />

              <span
                className={styles.mark}
                style={{ '--color': TASK_TYPE_INFO[type].color }}
              >
                {intl.formatMessage(TASK_TYPE_INFO[type].title).toLowerCase()}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskTypesSwitch;
