import { useEffect, useMemo } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Users from '../../models/users';
import { useNotifications } from '../../hooks';

export const AUTOMATICALLY_CREATED_IDENTIFIER = {
  COMPLAINT: 'complaint',
  AUTOMATIC: 'automatic',
};

const useCreatorFilterOptions = (taskType) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const activeUsers = useAtom(Users.Showing.activeUsersAtom);
  const isAllUsersSucceeded = useAtom(Users.Showing.isUsersSucceededAtom);
  const isAllUsersLoading = useAtom(Users.Showing.isUsersLoadingAtom);
  const isAllUsersFailed = useAtom(Users.Showing.isUsersFailedAtom);
  const handleUsersShow = useAction(Users.Showing.done);

  useEffect(() => {
    if (!isAllUsersFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load users. Try reloading the page',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllUsersFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isAllUsersSucceeded && !isAllUsersLoading) {
      handleUsersShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUsersShow]);

  const creatorFilterOptions = useMemo(
    () => [
      {
        value: AUTOMATICALLY_CREATED_IDENTIFIER.COMPLAINT,
        label: intl.formatMessage({ defaultMessage: 'Complaint' }),
      },
      {
        value: AUTOMATICALLY_CREATED_IDENTIFIER.AUTOMATIC,
        label: intl.formatMessage({ defaultMessage: 'Automatic' }),
      },
      ...activeUsers.map(({ id, name }) => ({ value: id, label: name })),
    ],
    [intl, activeUsers],
  );

  return {
    creatorFilterOptions,
    isCreatorFilterOptionsLoading: isAllUsersLoading,
  };
};

export default useCreatorFilterOptions;
