@import '../../../styles/utils';

.root {
  color: $black;
  @include defaultTransition(color);

  &:hover,
  &:focus {
    color: $primaryBlue;
  }
}
