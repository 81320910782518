import { declareAction, declareAtom, map, combine } from '@reatom/core';

import { LOADING_STATE } from '../constants';
import api from '../services/api';
import { deserializeUserOverview } from '../normalizers';

export const ACCOUNT_ERROR_TYPE = {
  INACTIVE_USER: 'INACTIVE_USER',
  DEFAULT: 'DEFAULT',
};

const receiveLogInSucceeded = declareAction('Account/receiveLogInSucceeded'); // { entity }
const receiveLogInFailed = declareAction('Account/receiveLogInFailed'); // { errorType }

export const logIn = declareAction(
  'Account/logIn',
  async ({ firebaseToken, email }, store) => {
    try {
      const user = await api().account.logIn({
        firebaseToken,
        email,
      });

      const { id, name, media, role, taskTypes, isActive } =
        deserializeUserOverview(user);

      const entity = {
        id,
        name,
        email,
        media,
        role,
        taskTypes,
        isActive,
        authorization: firebaseToken,
      };

      store.dispatch(receiveLogInSucceeded({ entity }));
    } catch (error) {
      console.error(error);

      store.dispatch(
        receiveLogInFailed({
          errorType:
            ACCOUNT_ERROR_TYPE[error.type] || ACCOUNT_ERROR_TYPE.DEFAULT,
        }),
      );
    }
  },
);

export const logOut = declareAction('Account/logOut'); // nothing

export const tokenUpdated = declareAction('Account/tokenUpdated');

export const entityAtom = declareAtom('Account/entityAtom', null, (on) => [
  on(logIn, () => null),
  on(receiveLogInSucceeded, (_, { entity }) => entity || null),
  on(logOut, () => null),
  on(tokenUpdated, (state, { firebaseToken }) => ({
    ...state,
    authorization: firebaseToken,
  })),
]);

export const authorizationAtom = map(
  entityAtom,
  (entity) => entity?.authorization || '',
);
export const currentUserId = map(entityAtom, (entity) => entity?.id || '');
export const roleAtom = map(entityAtom, (entity) => entity?.role || '');
export const taskTypesAtom = map(
  entityAtom,
  (entity) => entity?.taskTypes || [],
);

const loadingStateAtom = declareAtom(
  'Account/loadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(logIn, () => LOADING_STATE.LOADING),
    on(receiveLogInSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(receiveLogInFailed, () => LOADING_STATE.FAILED),
    on(logOut, () => LOADING_STATE.IDLE),
  ],
);

export const isLoadingAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);

export const isSucceededAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.SUCCEEDED,
);

export const errorTypeAtom = declareAtom('Account/errorTypeAtom', '', (on) => [
  on(logIn, () => ''),
  on(receiveLogInFailed, (state, { errorType }) => errorType),
  on(logOut, () => ''),
]);

export default combine({
  entityAtom,
  errorTypeAtom,
  loadingStateAtom,
});
