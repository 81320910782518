import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from './config';

const initSentry = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  Sentry.init({
    dsn: config.sentryClientDns,
    integrations: [new Integrations.BrowserTracing()],
  });
};

export default initSentry;
