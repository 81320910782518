import { deserializeUserPreview } from './common';

export const deserializeTimeline = ({ userTimelines, ...rest }) => ({
  timeline: userTimelines.map(({ timeline, user }) => ({
    user: deserializeUserPreview(user),
    timeline: timeline.map(
      ({ startTime, finishTime, taskId, taskType, status }) => ({
        startTime,
        finishTime,
        taskId,
        taskType,
        wasSkipped: status === 'touched',
      }),
    ),
  })),
  ...rest,
});

export const deserializeUsersStatisticOverview = ({ created, moderated }) => ({
  created: created.map(({ taskType, count }) => ({ taskType, amount: count })),
  moderated: moderated.map(({ taskType, count }) => ({
    taskType,
    amount: count,
  })),
});
