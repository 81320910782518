import { useCallback, useRef, useState } from 'react';

import Input from '../../../../../components/Input/Input';
import Radio from '../../../../../components/Radio/Radio';

const CustomPhoneRadio = ({
  name,
  onFieldValueSet,
  onFieldTouchedSet,
  ...rest
}) => {
  const [phone, setPhone] = useState('');
  const radioRef = useRef();

  const handlePhoneFocus = useCallback(
    (e) => {
      if (radioRef.current) {
        radioRef.current.checked = true;
      }

      onFieldValueSet(e.target.name, e.target.value);
    },
    [onFieldValueSet],
  );

  const handlePhoneBlur = useCallback(
    (e) => onFieldTouchedSet(e.target.name, true),
    [onFieldTouchedSet],
  );

  const handlePhoneChange = useCallback(
    (e) => {
      onFieldValueSet(e.target.name, e.target.value);
      setPhone(e.target.value);
    },
    [onFieldValueSet],
  );

  return (
    <Radio
      name={name}
      label={
        <Input
          name={name}
          value={phone}
          placeholder="80XXXXXXXXX"
          onChange={handlePhoneChange}
          onFocus={handlePhoneFocus}
          onBlur={handlePhoneBlur}
          isGrey
        />
      }
      value={phone}
      ref={radioRef}
      hasTextSelectionPrevented={false}
      {...rest}
    />
  );
};

export default CustomPhoneRadio;
