import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Form from '../../../../components/Form/Form';
import Field from '../../../../components/Field/Field';
import Select from '../../../../components/Select/Select';

import { TASK_SOLUTIONS_BY_TYPE } from '../../../../constants';

const CommonTaskResolvePlateFieldsGroup = ({
  taskType,
  solution,
  solutionError,
  onSelectChange,
}) => {
  const intl = useIntl();

  const solutionOptions = useMemo(() => {
    if (!taskType) {
      return [];
    }

    return TASK_SOLUTIONS_BY_TYPE[taskType].map(({ slug, title }) => ({
      value: slug,
      label: intl.formatMessage(title),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskType, intl.formatMessage]);

  return (
    <Form.Row>
      <Field
        label={<FormattedMessage defaultMessage="Resolved task:" />}
        error={solutionError}
      >
        <Select
          placeholder={
            <FormattedMessage defaultMessage="Choose solution type" />
          }
          options={solutionOptions}
          value={solution}
          name="solution"
          onChange={onSelectChange}
        />
      </Field>
    </Form.Row>
  );
};

export default CommonTaskResolvePlateFieldsGroup;
