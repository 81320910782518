import { useEffect, useRef, useCallback } from 'react';
import { useAtom, useAction } from '@reatom/react';
import { useIntl } from 'react-intl';
import { debounce } from 'throttle-debounce';

import * as Ads from '../../../../models/ads';
import { checkIsNumber } from '../../../../utils';
import { useNotifications } from '../../../../hooks';

const SEARCH_DELAY_MS = 300;

const useHouseOptions = (streetId, isDialogOpened) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications;

  const isFirstRenderRef = useRef(true);

  const list = useAtom(Ads.HouseOptions.listAtom);
  const isIdle = useAtom(Ads.HouseOptions.isIdleAtom);
  const isLoading = useAtom(Ads.HouseOptions.isLoadingAtom);
  const isFailed = useAtom(Ads.HouseOptions.isFailedAtom);

  const show = useAction(Ads.HouseOptions.shown);
  const stateReset = useAction(Ads.HouseOptions.stateReset);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleQueryChange = useCallback(
    debounce(SEARCH_DELAY_MS, (query) => show({ streetId, query })),
    [streetId, show],
  );

  useEffect(() => {
    if (!isFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load house options',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isDialogOpened) {
      return;
    }

    if (!checkIsNumber(streetId)) {
      return;
    }

    if (!isIdle) {
      return;
    }

    show({ streetId });
  }, [isDialogOpened, streetId, isIdle, show]);

  useEffect(() => {
    if (!isDialogOpened && !isFirstRenderRef.current) {
      stateReset();
    }
  }, [isDialogOpened, stateReset]);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      stateReset();
    }
  }, [streetId, stateReset]);

  useEffect(() => (isFirstRenderRef.current = false), []);

  return [{ list, isLoading }, { onQueryChange: handleQueryChange }];
};

export default useHouseOptions;
