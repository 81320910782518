import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import { serializeUpdatingTask } from '../../normalizers';
import * as Account from '../account';

export const saveSucceeded = declareAction('Tasks/Editing/saveSucceeded');
const saveFailed = declareAction('Tasks/Editing/saveFailed');

export const saved = declareAction(
  'Tasks/Editing/saved',
  async ({ task }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      await api(authorization).tasks.update(
        serializeUpdatingTask(task),
        task.id,
      );

      store.dispatch(saveSucceeded({ taskId: task.id }));
    } catch (error) {
      console.error(error);

      store.dispatch(saveFailed());
    }
  },
);

const saveLoadingStateAtom = declareAtom(
  'Tasks/Editing/saveLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(saved, () => LOADING_STATE.LOADING),
    on(saveSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(saveFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isSaveLoadingAtom = map(
  saveLoadingStateAtom,
  (loadingState) => loadingState === LOADING_STATE.LOADING,
);

export const isSaveSucceededAtom = map(
  saveLoadingStateAtom,
  (loadingState) => loadingState === LOADING_STATE.SUCCEEDED,
);

export const isSaveFailedAtom = map(
  saveLoadingStateAtom,
  (loadingState) => loadingState === LOADING_STATE.FAILED,
);

export default combine({
  saveLoadingStateAtom,
});
