@import '../../styles/utils.scss';

$color: var(--color, $white);

.root {
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -6px;
}

.item {
  padding: 6px;
}

.label {
  cursor: pointer;
}

.element {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mark {
  display: inline-block;
  padding: 12px 16px;
  border: 1px solid $color;
  border-radius: $primaryBorderRadius;
  color: $color;
  font-size: 16px;
  @include defaultTransition(background-color, color, opacity);

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
  }
}

.element:checked + .mark {
  background-color: $color;
  color: $white;

  &:hover {
    opacity: 0.8;
  }
}
