import { useCallback, useLayoutEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';

import Link from '../../../../components/Link/Link';
import AdInfoTileSection from '../TileSection/TileSection';
import AdDetailsTable from '../Table/Table';

import { useToggle } from '../../../../hooks';

const MIN_COLLAPSING_HEIGHT = 240;

const AdObjectInfoTileSection = ({ data, isModerated }) => {
  const rawDescriptionRef = useRef();
  const recognizedDescriptionRef = useRef();
  const { isOn: isCollapsed, toggle: onIsCollapsedToggle } = useToggle(false);
  const collapsingHeightRef = useRef(null);

  useLayoutEffect(() => {
    const rawHeight = rawDescriptionRef.current.offsetHeight;
    const recognizedHeight = recognizedDescriptionRef.current.offsetHeight;

    if (rawHeight <= recognizedHeight || rawHeight < MIN_COLLAPSING_HEIGHT) {
      return;
    }

    collapsingHeightRef.current = recognizedHeight;
    onIsCollapsedToggle();
  }, [onIsCollapsedToggle]);

  const handleIsCollapsedToggle = useCallback(
    () => onIsCollapsedToggle(),
    [onIsCollapsedToggle],
  );

  return (
    <AdInfoTileSection
      title={<FormattedMessage defaultMessage="Object" />}
      isModerated={isModerated}
    >
      <AdDetailsTable>
        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="room count" />}
          {...data.roomCount}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="layout" />}
          {...data.roomType}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="floor" />}
          {...data.floor}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="full area" />}
          {...data.totalArea}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="living area" />}
          {...data.livingArea}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="kitchen area" />}
          {...data.kitchenArea}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="land area" />}
          {...data.landArea}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="price" />}
          {...data.price}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="currency" />}
          {...data.currency}
        />

        <AdDetailsTable.Row
          title={<FormattedMessage defaultMessage="description" />}
          raw={
            <div>
              <div ref={rawDescriptionRef}>
                {data.description.raw && (
                  <AnimateHeight
                    height={isCollapsed ? collapsingHeightRef.current : 'auto'}
                  >
                    {data.description.raw}
                  </AnimateHeight>
                )}
              </div>

              {collapsingHeightRef.current && (
                <div onClick={handleIsCollapsedToggle}>
                  <Link
                    component="button"
                    title={
                      isCollapsed ? (
                        <FormattedMessage defaultMessage="show more" />
                      ) : (
                        <FormattedMessage defaultMessage="show less" />
                      )
                    }
                    isExpanded
                  />
                </div>
              )}
            </div>
          }
          recognized={
            <div ref={recognizedDescriptionRef}>
              {data.description.recognized}
            </div>
          }
        />
      </AdDetailsTable>
    </AdInfoTileSection>
  );
};

export default AdObjectInfoTileSection;
