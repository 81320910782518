import { SEARCH_ERROR_TYPE } from '../../models/search';

const getErrorMessage = (errorType, formatMessage) => {
  switch (errorType) {
    case SEARCH_ERROR_TYPE.SEARCH_RESULT_EXCEEDS_MAX_LIMIT:
      return formatMessage({
        defaultMessage:
          'Too many results were found for your query. Please specify your request',
      });

    default:
      return formatMessage({
        defaultMessage: 'An error has occurred. Please try again',
      });
  }
};

export default getErrorMessage;
