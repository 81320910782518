import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import {
  deserializeUsersStatisticOverview,
  serializeDateRange,
} from '../../normalizers';
import * as Account from '../account';

const formOverviewPayload = (date, userId) => {
  const payload = {
    date,
  };

  if (userId) {
    payload.userId = userId;
  }

  return payload;
};

const checkIsParamsRelevant = (stateParams, requestParams) =>
  stateParams.userId === requestParams.userId &&
  stateParams.dateRange
    .map((date, index) => date === requestParams.dateRange[index])
    .reduce((cur, acc) => cur && acc, true);

const doneSucceeded = declareAction('UsersStatistic/Overview/doneSucceeded');
const doneFailed = declareAction('UsersStatistic/Overview/doneFailed');

export const done = declareAction(
  'UsersStatistic/Overview/done',
  async (payload, store) => {
    const { dateRange, userId } = payload;
    const authorization = store.getState(Account.authorizationAtom);
    const overviewPayload = formOverviewPayload(
      serializeDateRange(dateRange),
      userId,
    );

    try {
      const overview = await api(authorization).usersStatistic.overview(
        overviewPayload,
      );

      if (checkIsParamsRelevant(store.getState(getParamsAtom()), payload)) {
        store.dispatch(
          doneSucceeded({
            overview: deserializeUsersStatisticOverview(overview),
          }),
        );
      }
    } catch (error) {
      if (!checkIsParamsRelevant(store.getState(getParamsAtom()), payload)) {
        return;
      }

      console.error(error);
      store.dispatch(doneFailed());
    }
  },
);

const paramsAtom = declareAtom(
  'UsersStatistic/Overview/paramsAtom',
  { dateRange: [], userId: null },
  (on) => [on(done, (state, payload) => payload)],
);

function getParamsAtom() {
  return paramsAtom;
}

const overviewInitialState = { moderated: [], created: [] };
export const overviewAtom = declareAtom(
  'UsersStatistic/Overview/overviewAtom',
  overviewInitialState,
  (on) => [
    on(done, () => overviewInitialState),
    on(doneSucceeded, (state, { overview }) => overview),
  ],
);

const doneLoadingStateAtom = declareAtom(
  'UsersStatistic/Overview/doneLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isDoneLoadingAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);
export const isDoneFailedAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);
export const isDoneSucceededAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.SUCCEEDED,
);

export default combine({
  overviewAtom,
  doneLoadingStateAtom,
  paramsAtom,
});
