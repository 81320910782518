import Svg from '../Svg/Svg';

const IconImage = () => (
  <Svg>
    <g stroke="null">
      <path d="M.609 20.989a2.405 2.405 0 002.402 2.402h17.978a2.405 2.405 0 002.402-2.402V3.011A2.405 2.405 0 0020.989.609H3.011A2.405 2.405 0 00.609 3.011v17.978zm20.38 1.262H3.011a1.266 1.266 0 01-1.262-1.262v-3.003l4.32-4.32 3.692 3.692a.568.568 0 00.805 0l6.666-6.666 5.019 5.018v5.279c0 .694-.568 1.262-1.262 1.262zM3.011 1.749h17.978c.694 0 1.262.568 1.262 1.262v11.084l-4.618-4.613a.568.568 0 00-.806 0l-6.666 6.666-3.691-3.692a.568.568 0 00-.806 0L1.75 16.371V3.011c0-.694.568-1.262 1.262-1.262z" />
      <path d="M7.67 9.738c1.602 0 2.9-1.304 2.9-2.9s-1.303-2.9-2.9-2.9-2.9 1.303-2.9 2.9 1.3 2.9 2.9 2.9zm0-4.66c.974 0 1.76.791 1.76 1.76s-.791 1.76-1.76 1.76-1.759-.792-1.759-1.76.787-1.76 1.76-1.76z" />
    </g>
  </Svg>
);

export default IconImage;
