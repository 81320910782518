@import '../../../../styles/utils';

.option {
  @extend %normalize-button;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 20px;
  text-align: left;
  @include defaultTransition(background-color);

  &:hover,
  &:focus,
  &--is-focused {
    background-color: $grey_1;
  }
}
