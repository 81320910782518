import { useCallback } from 'react';
import { useAtom, useAction } from '@reatom/react';

import TaskCreationDialogContainer from './TaskCreatingDialog/TaskCreationDialogContainer';
import UserCreationDialogContainer from './UserCreationDialog/UserCreationDialogContainer';
import UserEditingDialogContainer from './UserEditingDialog/UserEditingDialogContainer';
import TaskEditingDialogContainer from './TaskEditingDialog/TaskEditingDialogContainer';
import CompletedTaskOverviewDialogContainer from './CompletedTaskOverviewDialog/CompletedTaskOverviewDialogContainer';

import * as Account from '../../../models/account';
import * as Dialog from '../../../models/dialog';
import { DIALOG_NAME } from '../../../constants';
import { useTaskOverviewDialog } from '../../../hooks';

const Dialogs = () => {
  const isAuthorized = useAtom(Account.isSucceededAtom);
  const openedDialogName = useAtom(Dialog.openedNameAtom);
  const openedDialogPayload = useAtom(Dialog.openedPayloadAtom);

  const handleClose = useAction(Dialog.closeDialog);

  const { close: closeTaskOverviewDialog } = useTaskOverviewDialog();

  const handleTaskOverviewDialogClose = useCallback(
    () => closeTaskOverviewDialog(),
    [closeTaskOverviewDialog],
  );

  if (!isAuthorized) {
    return null;
  }

  return (
    <>
      <UserCreationDialogContainer
        isOpened={openedDialogName === DIALOG_NAME.USER_CREATION}
        onClose={handleClose}
      />

      <UserEditingDialogContainer
        payload={openedDialogPayload}
        isOpened={openedDialogName === DIALOG_NAME.USER_EDITING}
        onClose={handleClose}
      />

      <TaskCreationDialogContainer
        payload={openedDialogPayload}
        isOpened={openedDialogName === DIALOG_NAME.TASK_CREATION}
        onClose={handleClose}
      />

      <TaskEditingDialogContainer
        payload={openedDialogPayload}
        isOpened={openedDialogName === DIALOG_NAME.TASK_EDITING}
        onClose={handleTaskOverviewDialogClose}
      />

      <CompletedTaskOverviewDialogContainer
        payload={openedDialogPayload}
        isOpened={openedDialogName === DIALOG_NAME.CLOSED_TASK_OVERVIEW}
        onClose={handleTaskOverviewDialogClose}
      />
    </>
  );
};

export default Dialogs;
