import styles from './AdPreviewComposition.module.scss';

const AdPreviewComposition = ({ adPreview, buttonGroup }) => (
  <div className={styles.grid}>
    <div className={styles.adPreviewColumn}>{adPreview}</div>
    <div className={styles.buttonGroupColumn}>{buttonGroup}</div>
  </div>
);

export default AdPreviewComposition;
