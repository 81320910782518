import IconContainer from '../../IconContainer/IconContainer';
import { IconZendesk } from '../../icons';

import styles from './ZendeskLink.module.scss';

const TasksTableZendeskLink = ({ link }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className={styles.root}
  >
    <IconContainer>
      <IconZendesk />
    </IconContainer>
  </a>
);

export default TasksTableZendeskLink;
