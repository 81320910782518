import { useState, useCallback, useEffect } from 'react';
import { useAtom, useAction } from '@reatom/react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import TaskEditingDialog from './TaskEditingDialog';

import * as Tasks from '../../../../models/tasks';
import * as Dialog from '../../../../models/dialog';

import { DIALOG_NAME } from '../../../../constants';
import {
  useNotifications,
  usePagination,
  useExecutorsByTaskType,
  useTaskOverviewDialog,
} from '../../../../hooks';
import { getTaskListPageLink } from '../../../../routes';

const TaskEditingDialogContainer = ({
  payload,
  isOpened,
  onClose,
  ...rest
}) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const { pathname } = useLocation();
  const { showNotification } = useNotifications();
  const [, { goToPage }] = usePagination({ initializePagination: false });
  const { close: closeTaskEditingDialog } = useTaskOverviewDialog();

  const [taskType, setTaskType] = useState('');
  const { executors, isExecutorsLoading } = useExecutorsByTaskType(taskType);
  const handleTaskTypeChange = useCallback(
    (taskType) => setTaskType(taskType),
    [],
  );

  const taskOverview = useAtom(
    Tasks.Overview.makeTaskOverviewAtom(payload?.taskId),
  );

  const isCommentingSucceeded = useAtom(
    Tasks.Comment.isSaveCommentSucceededAtom,
  );
  const isCommentingLoading = useAtom(Tasks.Comment.isSaveCommentLoadingAtom);
  const isCommentingFailed = useAtom(Tasks.Comment.isSaveCommentFailedAtom);
  const handleCommentSend = useAction((taskId, comment) =>
    Tasks.Comment.save({ taskId, comment }),
  );

  const isTaskUpdatingLoading = useAtom(Tasks.Editing.isSaveLoadingAtom);
  const isTaskUpdatingSucceeded = useAtom(Tasks.Editing.isSaveSucceededAtom);
  const isTaskUpdatingFailed = useAtom(Tasks.Editing.isSaveFailedAtom);
  const handleTaskUpdate = useAction((task) => Tasks.Editing.saved({ task }));

  const openedDialogName = useAtom(Dialog.openedNameAtom);

  useEffect(() => {
    if (!isOpened) {
      setTaskType('');
    }
  }, [isOpened]);

  useEffect(() => {
    if (!isTaskUpdatingSucceeded) {
      return;
    }

    showNotification(
      intl.formatMessage({ defaultMessage: 'Task was successfully edited' }),
    );

    const isTaskEditingDialogOpened =
      openedDialogName === DIALOG_NAME.TASK_EDITING;
    if (isTaskEditingDialogOpened) {
      closeTaskEditingDialog();
    }

    if (pathname === getTaskListPageLink()) {
      goToPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTaskUpdatingSucceeded,
    showNotification,
    goToPage,
    closeTaskEditingDialog,
  ]);

  useEffect(() => {
    if (!isCommentingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to add comment. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentingFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isTaskUpdatingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to edit task. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskUpdatingFailed, intl.formatMessage, showErrorNotification]);

  return (
    <TaskEditingDialog
      task={taskOverview}
      executors={executors}
      isEditingLoading={isTaskUpdatingLoading}
      isExecutorsLoading={isExecutorsLoading}
      isCommentingLoading={isCommentingLoading}
      isCommentingSucceeded={isCommentingSucceeded}
      isOpened={isOpened}
      onTaskTypeChange={handleTaskTypeChange}
      onCommentSend={handleCommentSend}
      onUpdate={handleTaskUpdate}
      onClose={onClose}
      {...rest}
    />
  );
};

export default TaskEditingDialogContainer;
