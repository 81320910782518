@import '../../styles/utils';

.root {
  position: relative;
}

.triggerButton {
  width: 224px;
  overflow: hidden;
}

.dropdown {
  width: 264px;
  max-height: 400px;
  overflow: auto;
}

.loader {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
