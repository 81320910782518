import { useIntl } from 'react-intl';

import ChangeMessagePlaceholder from '../ChangeMessagePlaceholder/ChangeMessagePlaceholder';

import {
  CHANGED_FIELD_TYPE,
  TASK_TYPE_INFO,
  TASK_SUBTYPE_INFO,
  PRIORITY_INFO,
} from '../../../constants';
import { checkIsNumber } from '../../../utils';

const ChangeMessage = ({ user, timestamp, fieldName, from, to }) => {
  let action = '';
  let details = '';

  const intl = useIntl();

  switch (fieldName) {
    case CHANGED_FIELD_TYPE.TASK_TYPE:
      action = intl.formatMessage({ defaultMessage: 'changed task type' });
      details = intl.formatMessage(
        {
          defaultMessage: 'from "{from_task_type}" to "{to_task_type}"',
        },
        {
          from_task_type: intl.formatMessage(TASK_TYPE_INFO[from].title),
          to_task_type: intl.formatMessage(TASK_TYPE_INFO[to].title),
        },
      );
      break;

    case CHANGED_FIELD_TYPE.TASK_SUB_TYPE:
      if (!from) {
        action = intl.formatMessage({
          defaultMessage: 'set task subtype',
        });
        details = `"${intl.formatMessage(TASK_SUBTYPE_INFO[to].title)}"`;
        break;
      }

      if (!to) {
        action = intl.formatMessage({
          defaultMessage: 'set task subtype',
        });
        break;
      }

      action = intl.formatMessage({
        defaultMessage: 'changed task subtype',
      });
      details = intl.formatMessage(
        {
          defaultMessage: `from "{from_task_subtype}" to "{to_task_subtype}"`,
        },
        {
          from_task_subtype: intl.formatMessage(TASK_SUBTYPE_INFO[from].title),
          to_task_subtype: intl.formatMessage(TASK_SUBTYPE_INFO[to].title),
        },
      );

      break;

    case CHANGED_FIELD_TYPE.ADDITIONAL_RESOURCES:
      action = intl.formatMessage({
        defaultMessage: 'changed additional resources',
      });
      break;

    case CHANGED_FIELD_TYPE.PRIORITY:
      action = intl.formatMessage({
        defaultMessage: 'changed task priority',
      });
      details = intl.formatMessage(
        {
          defaultMessage: 'from "{from_priority}" to "{to_priority}"',
        },
        {
          from_priority: PRIORITY_INFO[from].title,
          to_priority: PRIORITY_INFO[to].title,
        },
      );
      break;

    case CHANGED_FIELD_TYPE.ZENDESK_URL:
      action = from
        ? intl.formatMessage({
            defaultMessage: 'changed Zendesk link',
          })
        : intl.formatMessage({
            defaultMessage: 'added Zendesk link',
          });

      break;

    case CHANGED_FIELD_TYPE.EXECUTOR:
      const hasPreviousExecutor = checkIsNumber(from?.id);
      const hasCurrentExecutor = checkIsNumber(to?.id);

      if (!hasCurrentExecutor) {
        action = intl.formatMessage({
          defaultMessage: 'reset task assignee',
        });
        break;
      }

      if (!hasPreviousExecutor) {
        action = intl.formatMessage({
          defaultMessage: 'assigned a task',
        });
        details = intl.formatMessage(
          {
            defaultMessage: 'assigned to user {name}',
          },
          { name: to.name },
        );
        break;
      }

      action = 'reassigned task';
      details = intl.formatMessage(
        {
          defaultMessage: 'from user {from_name} to user {to_name}',
        },
        { from_name: from.name, to_name: to.name },
      );
      break;

    default:
      return null;
  }

  return (
    <ChangeMessagePlaceholder
      user={user}
      timestamp={timestamp}
      action={action}
      details={details}
    />
  );
};

export default ChangeMessage;
