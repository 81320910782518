import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Dialog from '../../../../components/Dialog/Dialog';
import Button from '../../../../components/Button/Button';
import Field from '../../../../components/Field/Field';
import Select from '../../../../components/Select/Select';

const serializeOption = ({ id, title }) => ({ value: id, label: title });
const deserializeOption = ({ value, label }) => ({ id: value, title: label });

const AdHouseEditingDialog = ({
  currentHouse,
  houses,
  isOpened,
  isHouseEditingLoading,
  isHousesLoading,
  onQueryChange,
  onHouseChange,
  onClose,
}) => {
  const isFirstRenderRef = useRef(true);
  const [value, setValue] = useState(currentHouse.id || '');

  useEffect(() => setValue(currentHouse.id || ''), [currentHouse.id]);

  const options = houses.map(serializeOption);

  const handleChange = (value) => setValue(value);

  const handleSubmit = () => {
    const fullOption = options.find(
      ({ value: optionValue }) => optionValue === value,
    );
    onHouseChange(deserializeOption(fullOption));
  };

  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);

  const handleInputChange = (inputValue) => {
    if (!inputValue && value) {
      return;
    }
    onQueryChange(inputValue);
  };

  const actions = [
    <Button
      key="cancel"
      title={<FormattedMessage defaultMessage="Cancel" />}
      variant="flat"
      onClick={onClose}
      withLargeSideInnerGaps
    />,
    <Button
      key="submit"
      title={<FormattedMessage defaultMessage="Save" />}
      isDisabled={isHouseEditingLoading}
      onClick={handleSubmit}
      withLargeSideInnerGaps
    />,
  ];

  return (
    <Dialog
      title={
        <FormattedMessage defaultMessage="Editing advertisement details" />
      }
      actions={actions}
      isOpened={isOpened}
      onClose={onClose}
    >
      <Field label={<FormattedMessage defaultMessage="House on the map " />}>
        <Select
          value={value}
          options={options}
          placeholder={<FormattedMessage defaultMessage="Choose house" />}
          isLoading={isHousesLoading}
          loadingMessage={() => {
            <FormattedMessage defaultMessage="Loading..." />;
          }}
          noOptionsMessage={<FormattedMessage defaultMessage="No options" />}
          onChange={handleChange}
          onInputChange={handleInputChange}
        />
      </Field>
    </Dialog>
  );
};

const houseShape = PropTypes.exact({
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

AdHouseEditingDialog.propTypes = {
  houses: PropTypes.arrayOf(houseShape).isRequired,
  currentHouse: houseShape,
  isOpened: PropTypes.bool.isRequired,
  isHouseEditingLoading: PropTypes.bool.isRequired,
  isHousesLoading: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onHouseChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdHouseEditingDialog;
