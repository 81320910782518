import { useCallback } from 'react';
import Cleave from 'cleave.js/react';

import { useEventHandler } from '../../../../hooks';
import useCleave from './useCleave';
import styles from './Input.module.scss';

const DATE_PATTERN = 'dd.mm.yyyy';
const DATE_LENGTH = DATE_PATTERN.length;
const ENTER_KEY_CODE = 13;

const Input = ({ label, value, onFocus, onChange }) => {
  const { setCleaveValue, setCleave } = useCleave(value);

  const handleChange = useEventHandler(
    (cleaveValue) => {
      if (cleaveValue.length !== DATE_LENGTH) {
        setCleaveValue(value);
        return;
      }

      onChange(new Date(cleaveValue));
    },
    [value, onChange],
  );

  const handleBlur = useCallback(
    (e) => handleChange(e.target.value),
    [handleChange],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode !== ENTER_KEY_CODE) {
        return;
      }

      handleChange(e.target.value);
    },
    [handleChange],
  );

  return (
    <div className={styles.container}>
      <Cleave
        value={value} // initial value
        className={styles.input}
        options={{
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        }}
        onInit={setCleave}
        onFocus={onFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />

      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default Input;
