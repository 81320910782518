import { FormattedMessage, useIntl } from 'react-intl';
import { useMemo } from 'react';

import Form from '../../../../components/Form/Form';
import Field from '../../../../components/Field/Field';
import Fieldset from '../../../../components/Fieldset/Fieldset';
import Radio from '../../../../components/Radio/Radio';
import Select from '../../../../components/Select/Select';
import Textarea from '../../../../components/Textarea/Textarea';
import PhoneNumberLabel from './PhoneNumberLabel/PhoneNumberLabel';
import CustomPhoneRadio from './CustomPhoneRadio/CustomPhoneRadio';

import {
  CALL_STATUS_INFO,
  CALL_RESULTS_BY_STATUS,
  CALL_RESULT_INFO,
} from '../../../../constants';

const CALL_STATUS_OPTIONS = Object.values(CALL_STATUS_INFO).map(
  ({ slug, title }) => ({
    value: slug,
    label: <FormattedMessage {...title} />,
  }),
);

const CallTaskResolvePlateFieldsGroup = ({
  phoneList = [],
  callStatus,
  callResult,
  callComment,
  callPhoneError,
  callStatusError,
  callResultError,
  onBlur,
  onChange,
  onFieldValueSet,
  onFieldTouchedSet,
  onSelectChange,
}) => {
  const intl = useIntl();

  const callResultOptions = useMemo(() => {
    if (!callStatus) {
      return [];
    }

    return CALL_RESULTS_BY_STATUS[callStatus]
      .map((slug) => CALL_RESULT_INFO[slug])
      .map(({ slug, title }) => ({
        value: slug,
        label: intl.formatMessage(title),
      }));
  }, [callStatus, intl]);

  return (
    <>
      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Phone number" />}
          error={callPhoneError}
        >
          <Fieldset isInline>
            {phoneList.map((phone, index) => (
              <Fieldset.Item key={index}>
                <Radio
                  name="callPhone"
                  label={<PhoneNumberLabel>{phone}</PhoneNumberLabel>}
                  value={phone}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Fieldset.Item>
            ))}

            <Fieldset.Item>
              <CustomPhoneRadio
                name="callPhone"
                onChange={onChange}
                onFieldValueSet={onFieldValueSet}
                onFieldTouchedSet={onFieldTouchedSet}
                onBlur={onBlur}
              />
            </Fieldset.Item>
          </Fieldset>
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Call status" />}
          error={callStatusError}
        >
          <Select
            placeholder={
              <FormattedMessage defaultMessage="Choose call status" />
            }
            options={CALL_STATUS_OPTIONS}
            value={callStatus}
            name="callStatus"
            onChange={onSelectChange}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field
          label={<FormattedMessage defaultMessage="Call result" />}
          error={callResultError}
        >
          <Select
            placeholder={
              <FormattedMessage defaultMessage="Choose call result" />
            }
            options={callResultOptions}
            value={callResult}
            name="callResult"
            onChange={onSelectChange}
            isDisabled={!callStatus}
          />
        </Field>
      </Form.Row>

      <Form.Row>
        <Field label={<FormattedMessage defaultMessage="Comment" />}>
          <Textarea
            name="callComment"
            value={callComment}
            onChange={onChange}
          />
        </Field>
      </Form.Row>
    </>
  );
};

export default CallTaskResolvePlateFieldsGroup;
