import { FormattedMessage } from 'react-intl';
import Label from '../Label/Label';

const AutomaticTaskLabel = () => (
  <Label
    title={<FormattedMessage defaultMessage="Automatic" />}
    backgroundColor="#222"
  />
);

export default AutomaticTaskLabel;
