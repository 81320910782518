import { useCallback, useMemo } from 'react';
import cx from 'classnames';

import AnimateHeightFromZero from '../../AnimateHeightFromZero/AnimateHeightFromZero';
import IconContainer from '../../IconContainer/IconContainer';
import { IconSort } from '../../icons';

import { SORT_TYPE } from '../../../constants';
import styles from './HeaderCell.module.scss';

const TableTitleCell = ({
  name,
  title,
  action,
  width = 'auto',
  sort,
  minWidth,
  isFlexible,
  isActionShown,
  hasSort,
  onSort,
}) => {
  const Component = hasSort ? 'button' : 'span';

  const handleSort = useCallback(() => {
    if (hasSort) {
      onSort(name);
    }
  }, [name, hasSort, onSort]);

  const highlighted = useMemo(() => {
    if (name !== sort?.fieldName) {
      return;
    }

    return sort?.value === SORT_TYPE.ASC ? 'top' : 'bottom';
  }, [sort, name]);

  return (
    <div
      style={{
        flex: isFlexible ? `1 1 ${width}` : `0 0 ${width}`,
        width: minWidth || width,
        minWidth,
      }}
      className={styles.root}
    >
      <Component
        className={cx(styles.content, {
          [styles.content_interactive]: !hasSort,
        })}
        onClick={handleSort}
      >
        <span className={styles.title}>{title}</span>

        {hasSort && (
          <span className={styles.icon}>
            <IconContainer size="xsmall">
              <IconSort highlighted={highlighted} />
            </IconContainer>
          </span>
        )}
      </Component>

      <AnimateHeightFromZero isOpened={isActionShown && action}>
        <div className={styles.actionContainer}>
          <div className={styles.action}>{action}</div>
        </div>
      </AnimateHeightFromZero>
    </div>
  );
};

export default TableTitleCell;
