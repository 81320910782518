import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useAction } from '@reatom/react';
import { useIntl } from 'react-intl';

import SearchPage from '../../pages/SearchPage/SearchPage';

import { SEARCH_ENTITY_TYPE } from '../../constants';
import { useQueryParams, useNotifications } from '../../hooks';
import { getSearchPageLink } from '../../routes';
import * as Search from '../../models/search';

const decodeQuery = (encodedQuery = '', onError) => {
  try {
    return decodeURIComponent(encodedQuery);
  } catch {
    onError('Не удалось декодировать значение параметра поиска', {
      toastId: 'query-decoding-error',
    });
    return '';
  }
};

const useSearch = () => {
  const intl = useIntl();

  const handleSearch = useAction(Search.shown);
  const handleSearchReset = useAction(Search.stateReset);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [{ query: encodedQuery, type }, { setQueryParams, removeQueryParams }] =
    useQueryParams();
  const { showErrorNotification } = useNotifications();

  const showQueryEncodingErrorNotification = useCallback(
    () =>
      showErrorNotification(
        intl.formatMessage({
          defaultMessage: 'Failed to decode search parameter value',
        }),
        {
          toastId: 'query-decoding-error',
        },
      ),
    [intl, showErrorNotification],
  );

  const query = useMemo(
    () => decodeQuery(encodedQuery, showQueryEncodingErrorNotification),
    [encodedQuery, showQueryEncodingErrorNotification],
  );

  const handleSearchChange = useCallback(
    (search) => {
      if (pathname !== SearchPage.RouteParams.path) {
        navigate({
          pathname: getSearchPageLink(),
          search: `?${createSearchParams({
            query: encodeURIComponent(search),
          }).toString()}`,
        });
        return;
      }

      if (!search) {
        removeQueryParams(['query', 'type']);
        return;
      }

      setQueryParams(
        { query: encodeURIComponent(search) },
        { replaceHistory: true },
      );
      removeQueryParams(['type']);
    },
    [pathname, setQueryParams, removeQueryParams, navigate],
  );

  const handleSearchEntityTypeSelect = (entityType) => {
    handleSearch({ query, entityType });
    setQueryParams({ type: entityType });
  };

  useEffect(() => {
    handleSearchReset();
  }, [query, handleSearchReset]);

  useEffect(() => {
    if (!query || !Object.values(SEARCH_ENTITY_TYPE).includes(type)) {
      return;
    }

    handleSearch({ query, entityType: type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    query || '',
    {
      onSearchChange: handleSearchChange,
      onSearchEntityTypeSelect: handleSearchEntityTypeSelect,
    },
  ];
};

export default useSearch;
