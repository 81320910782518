import styles from './LoadingStatePlaceholder.module.scss';

const LoadingStatePlaceholder = ({
  failed,
  loading,
  children,
  isLoading,
  isSucceeded,
  isFailed,
}) => {
  if (isLoading) {
    return <div className={styles.root}>{loading}</div>;
  }

  if (isSucceeded) {
    return children;
  }

  if (isFailed) {
    return <div className={styles.root}>{failed}</div>;
  }

  return <div className={styles.root} />;
};

export default LoadingStatePlaceholder;
