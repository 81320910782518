import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import useFirebaseAuth from './useFirebaseAuth';
import useAccount from './useAccount';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const {
    isUserLoading: isFirebaseUserLoading,
    setAuthChangeHandler: setFirebaseAuthChangeHandler,
    setIdTokenUpdateHandler: setFirebaseIdTokenUpdateHandler,
    signInWithGoogle: signInWithGoogleViaFirebase,
    signOut: signOutFirebase,
  } = useFirebaseAuth();

  const [{ isLoading }, { logIn, logOut: logOutAccount, updateToken }] =
    useAccount();

  const handleFirebaseIdTokenUpdate = useCallback(
    (firebaseToken) => updateToken({ firebaseToken }),
    [updateToken],
  );

  const handleUserAuth = useCallback(
    ({ email, firebaseToken }) => logIn({ email, firebaseToken }),
    [logIn],
  );

  const logOut = useCallback(() => {
    signOutFirebase();
    logOutAccount();
  }, [signOutFirebase, logOutAccount]);

  useEffect(
    () => setFirebaseAuthChangeHandler(handleUserAuth),
    [setFirebaseAuthChangeHandler, handleUserAuth],
  );

  useEffect(
    () => setFirebaseIdTokenUpdateHandler(handleFirebaseIdTokenUpdate),
    [setFirebaseIdTokenUpdateHandler, handleFirebaseIdTokenUpdate],
  );

  const value = useMemo(
    () => [
      { isLoading: isFirebaseUserLoading || isLoading },
      {
        logInWithGoogle: signInWithGoogleViaFirebase,
        logOut,
      },
    ],
    [isFirebaseUserLoading, isLoading, signInWithGoogleViaFirebase, logOut],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
