import Link from '../../../components/Link/Link';

import { SEARCH_ENTITY_TYPE } from '../../../constants';
import { getAdPageLink, getPhonePageLink } from '../../../routes';
import styles from './SearchResult.module.scss';

const SearchResultSwitch = ({ entityType, payload }) => {
  switch (entityType) {
    case SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK:
    case SEARCH_ENTITY_TYPE.AD_ID:
      return (
        <Link
          title={payload.externalAdLink}
          to={getAdPageLink(payload.adId)}
          isExpanded
        />
      );

    case SEARCH_ENTITY_TYPE.PHONE:
      return (
        <Link
          title={payload.phone}
          to={getPhonePageLink(payload.phone)}
          isExpanded
        />
      );

    default:
      return null;
  }
};

const SearchResult = ({ entityType, payload }) => {
  return (
    <div className={styles.root}>
      <SearchResultSwitch entityType={entityType} payload={payload} />
    </div>
  );
};

export default SearchResult;
