import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { FormattedMessage, useIntl } from 'react-intl';

import Page from '../../components/Page/Page';
import DefaultPageLayout from '../../components/DefaultPageLayout/DefaultPageLayout';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import CalendarDropdown from '../../components/CalendarDropdown/CalendarDropdown';
import UserPlate from '../../components/UserPlate/UserPlate';
import UserProductivityTimeline from '../../components/UserProductivityTimeline/UserProductivityTimeline';
import Section from '../../components/Section/Section';
import StatisticOverviewLoader from './StatisticOverviewLoader/StatisticOverviewLoader';
import PieChartPlate from './PieChartPlate/PieChartPlate';
import UsersProductivityStatisticPageAppended from './Appended/Appended';
import LoadingStatePlaceholder from './LoadingStatePlaceholder/LoadingStatePlaceholder';
import TimelineStatePlaceholder from './TimelineStatePlaceholder/TimelineStatePlaceholder';
import TimelineDisplayConditions from './TimelineDisplayConditions/TimelineDisplayConditions';

import { useNotifications } from '../../hooks';
import { ROLE_TYPE, TASK_TYPE_INFO } from '../../constants';
import withRoles from '../../components/PagesScene/withRoles';
import useCalendar from '../../components/CalendarDropdown/Calendar/useCalendar';
import * as Users from '../../models/users';
import * as UsersStatistic from '../../models/usersStatistic';
import useTimeline from './useTimeline';
import styles from './UsersProductivityStatistic.module.scss';

const prepareCoins = (tasks) =>
  tasks.map(({ taskType, amount }) => ({
    id: taskType,
    amount,
    title: <FormattedMessage {...TASK_TYPE_INFO[taskType].title} />,
    color: TASK_TYPE_INFO[taskType].color,
  }));

const UserOption = ({ title, payload }) => (
  <UserPlate name={title} media={payload} hasReducedOffset />
);

const UserTriggerButtonContent = ({
  generalOption,
  options,
  selectedValue,
}) => {
  if (selectedValue === generalOption.value) {
    return generalOption.title;
  }

  const { title, payload } = options.find(
    ({ value }) => value === selectedValue,
  );
  return <UserPlate name={title} media={payload} hasReducedOffset />;
};

const ALL_USERS_OPTION = {
  value: 'all',
  title: <FormattedMessage defaultMessage="All users" />,
};

const UsersProductivityStatisticPage = () => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const [{ dateRange, minDate, maxDate }, onDateChange] = useCalendar();
  const [selectedUser, setSelectedUser] = useState(ALL_USERS_OPTION.value);

  const users = useAtom(Users.Showing.activeUsersAtom);
  const isUsersLoading = useAtom(Users.Showing.isUsersLoadingAtom);
  const isUsersSucceeded = useAtom(Users.Showing.isUsersSucceededAtom);
  const isUsersFailed = useAtom(Users.Showing.isUsersFailedAtom);
  const handleUsersShow = useAction(Users.Showing.done);

  useEffect(() => {
    if (!isUsersFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load users. Try reloading the page',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUsersFailed, intl.formatMessage, showErrorNotification]);

  const { moderated, created } = useAtom(UsersStatistic.Overview.overviewAtom);
  const isOverviewStatisticLoading = useAtom(
    UsersStatistic.Overview.isDoneLoadingAtom,
  );
  const isOverviewStatisticSucceeded = useAtom(
    UsersStatistic.Overview.isDoneSucceededAtom,
  );
  const isOverviewStatisticFailed = useAtom(
    UsersStatistic.Overview.isDoneFailedAtom,
  );

  const handleUsersStatisticShown = useAction(UsersStatistic.Overview.done);

  useEffect(() => {
    if (!isOverviewStatisticFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({ defaultMessage: 'Failed to load statistics' }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverviewStatisticFailed, intl.formatMessage, showErrorNotification]);

  const [
    {
      timeline,
      earliestTimelineDate,
      latestTimelineDate,
      isTimelineLoading,
      isTimelineFailed,
      isTimelineIdle,
    },
    { onTimelineBarClick },
  ] = useTimeline(dateRange);

  useEffect(() => {
    if (!isUsersSucceeded && !isUsersLoading) {
      handleUsersShow();
    }
  }, [isUsersSucceeded, isUsersLoading, handleUsersShow]);

  const userOptions = useMemo(
    () =>
      users.map(({ id, name, media }) => ({
        value: id,
        title: name,
        payload: media,
      })),
    [users],
  );

  const moderatedCoins = useMemo(() => prepareCoins(moderated), [moderated]);
  const createdCoins = useMemo(() => prepareCoins(created), [created]);

  useEffect(() => {
    if (dateRange.filter(Boolean).length !== 2) {
      return;
    }

    const resultUserId =
      selectedUser === ALL_USERS_OPTION.value ? null : selectedUser;

    handleUsersStatisticShown({
      dateRange,
      userId: resultUserId,
    });
  }, [selectedUser, dateRange, handleUsersStatisticShown]);

  const handleSelectedUserChange = useCallback(
    (value) => setSelectedUser(value),
    [],
  );

  return (
    <FormattedMessage defaultMessage="Work statistics">
      {([helmetTitle]) => (
        <Page helmetTitle={helmetTitle}>
          <DefaultPageLayout>
            <DefaultPageLayout.WideCol>
              <PageMainContentTile
                title={<FormattedMessage defaultMessage="Work statistics" />}
                titleAppended={
                  <UsersProductivityStatisticPageAppended
                    select={
                      <SelectDropdown
                        generalOption={ALL_USERS_OPTION}
                        options={userOptions}
                        selectedValue={selectedUser}
                        triggerButtonContent={UserTriggerButtonContent}
                        optionContent={UserOption}
                        isLoading={isUsersLoading}
                        onChange={handleSelectedUserChange}
                      />
                    }
                    calendar={
                      <CalendarDropdown
                        dateRange={dateRange}
                        minDate={minDate}
                        maxDate={maxDate}
                        onDateChange={onDateChange}
                      />
                    }
                  />
                }
              >
                <Section
                  title={
                    <FormattedMessage defaultMessage="General information" />
                  }
                >
                  <LoadingStatePlaceholder
                    failed={
                      <FormattedMessage defaultMessage="Could not load data" />
                    }
                    loading={<StatisticOverviewLoader />}
                    isLoading={isOverviewStatisticLoading}
                    isSucceeded={isOverviewStatisticSucceeded}
                    isFailed={isOverviewStatisticFailed}
                  >
                    <div className={styles.pieChartGrid}>
                      <div className={styles.pieChartItem}>
                        <PieChartPlate
                          title={
                            <FormattedMessage defaultMessage="Total tasks done" />
                          }
                          coins={moderatedCoins}
                        />
                      </div>

                      <div className={styles.pieChartItem}>
                        <PieChartPlate
                          title={
                            <FormattedMessage defaultMessage="Total tasks created" />
                          }
                          coins={createdCoins}
                        />
                      </div>
                    </div>
                  </LoadingStatePlaceholder>
                </Section>

                <Section
                  title={<FormattedMessage defaultMessage="Users timeline" />}
                >
                  <TimelineStatePlaceholder
                    loader={
                      <div className={styles.timelineLoader}>
                        <StatisticOverviewLoader />
                      </div>
                    }
                    failed={
                      <FormattedMessage defaultMessage="Could not load data" />
                    }
                    empty={
                      <FormattedMessage defaultMessage="Timeline is empty" />
                    }
                    idle={<TimelineDisplayConditions />}
                    isLoading={isTimelineLoading}
                    isFailed={isTimelineFailed}
                    isEmpty={timeline?.length < 1}
                    isIdle={isTimelineIdle}
                  >
                    <UserProductivityTimeline
                      timelineList={timeline}
                      currentDay={dateRange[0]}
                      earliestDate={earliestTimelineDate}
                      latestDate={latestTimelineDate}
                      onRoundedBarClick={onTimelineBarClick}
                    />
                  </TimelineStatePlaceholder>
                </Section>
              </PageMainContentTile>
            </DefaultPageLayout.WideCol>
          </DefaultPageLayout>
        </Page>
      )}
    </FormattedMessage>
  );
};

UsersProductivityStatisticPage.Roles = [ROLE_TYPE.OWNER, ROLE_TYPE.ADMIN];
UsersProductivityStatisticPage.RouteParams = withRoles(
  UsersProductivityStatisticPage.Roles,
)({
  element: <UsersProductivityStatisticPage />,
  path: '/users-productivity-statistic',
});

export default UsersProductivityStatisticPage;
