@import '../../../styles/utils';

.label {
  display: inline-flex;
  align-items: center;
}

.labelIcon {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  color: $grey_3;
}

.labelCount {
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3.5px;
  bottom: 0;
  text-align: center;
  color: $white;
  font-size: 10px;
  @include overflowEllipsis;
}
