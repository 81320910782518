import { LANGUAGE } from '../constants';

export const fetchMessages = async (language) => {
  switch (language) {
    case LANGUAGE.EN:
      return import('../../i18n/messages/json/en.json');

    case LANGUAGE.UK:
      return import('../../i18n/messages/json/uk.json');

    case LANGUAGE.RU:
      return import('../../i18n/messages/json/ru.json');

    default:
      return;
  }
};
