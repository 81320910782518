import { declareAtom, declareAction, combine, map } from '@reatom/core';

import api from '../../services/api';
import { deserializeTaskPreview, serializeSort } from '../../normalizers';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';
import * as TaskQueue from '../taskQueue';
import * as TaskEditing from './editing';
import * as TaskCreation from './creation';
import * as TaskComment from './comment';

const shownSucceeded = declareAction('Tasks/PreviewList/shownSucceeded');
const shownFailed = declareAction('Tasks/PreviewList/shownFailed');

export const shown = declareAction(
  'Tasks/PreviewList/shown',
  async ({ page, perPage, searchQuery, sort, filters }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const {
        tasks,
        page: requestedPage,
        amount,
      } = await api(authorization).tasks.getAll({
        page,
        perPage,
        search: searchQuery,
        sort: serializeSort(sort),
        filters,
      });

      store.dispatch(
        shownSucceeded({
          tasks: tasks.map(deserializeTaskPreview),
          page: requestedPage,
          searchQuery,
          filters,
          amount,
        }),
      );
    } catch (error) {
      console.error(error);
      store.dispatch(shownFailed());
    }
  },
);

export const resetTasks = declareAction('Tasks/PreviewList/resetTasks');

const shownTableInitialState = {
  tasksByPage: {},

  selectedOptions: {
    searchQuery: '',
    filters: [],
  },
};

export const shownTableAtom = declareAtom(
  'Tasks/PreviewList/shownTableAtom',
  shownTableInitialState,
  (on) => [
    on(shown, (state, { page, searchQuery, filters }) => ({
      tasksByPage: {
        ...state.tasksByPage,
        [page]: [],
      },
      selectedOptions: { filters, searchQuery },
    })),

    on(shownSucceeded, (state, { page, tasks, searchQuery, filters }) => {
      if (
        searchQuery !== state.selectedOptions.searchQuery ||
        filters !== state.selectedOptions.filters
      ) {
        return state;
      }

      return { ...state, tasksByPage: { ...state.tasksByPage, [page]: tasks } };
    }),

    on(TaskComment.saveSucceeded, (state, { taskId }) => {
      const byTaskId = Object.entries(state.tasksByPage).find(
        ([page, taskList]) => taskList.map(({ id }) => id).includes(taskId),
      );
      const [page, listByPage] = byTaskId || [];

      if (!page) {
        return state;
      }

      return {
        ...state,
        tasksByPage: {
          ...state.tasksByPage,
          [page]: listByPage.map((task) => {
            if (task.id !== taskId) {
              return task;
            }

            return { ...task, commentsAmount: task.commentsAmount + 1 };
          }),
        },
      };
    }),

    on(resetTasks, () => shownTableInitialState),

    on(TaskEditing.saveSucceeded, () => shownTableInitialState),
    on(TaskCreation.savedSucceeded, () => shownTableInitialState),
    on(Account.logOut, () => shownTableInitialState),

    on(TaskQueue.Resolving.doneSucceeded, () => shownTableInitialState),
    on(TaskQueue.Resuming.doneSucceeded, () => shownTableInitialState),
    on(TaskQueue.Skip.doneSucceeded, () => shownTableInitialState),
    on(TaskQueue.Showing.doneSucceeded, () => shownTableInitialState),
  ],
);

export const makeTaskPreviewListAtom = (page) =>
  map(shownTableAtom, ({ tasksByPage }) => tasksByPage[page] || []);

export const makeTaskPreviewAtom = (taskId) =>
  map(
    shownTableAtom,
    ({ tasksByPage }) =>
      Object.values(tasksByPage)
        .flat()
        .find(({ id }) => id === taskId) || null,
  );

const shownLoadingStateAtom = declareAtom(
  'Tasks/PreviewList/shownLoadingStateAtom',
  {},
  (on) => [
    on(shown, (state, { page }) => ({
      ...state,
      [page]: LOADING_STATE.LOADING,
    })),
    on(shownSucceeded, (state, { page }) => ({
      ...state,
      [page]: LOADING_STATE.SUCCEEDED,
    })),
    on(shownFailed, (state, { page }) => ({
      ...state,
      [page]: LOADING_STATE.FAILED,
    })),
    on(resetTasks, () => ({})),

    on(TaskEditing.saveSucceeded, () => ({})),
    on(TaskCreation.savedSucceeded, () => ({})),
    on(Account.logOut, () => ({})),

    on(TaskQueue.Resuming.doneSucceeded, () => ({})),
    on(TaskQueue.Resolving.doneSucceeded, () => ({})),
    on(TaskQueue.Showing.doneSucceeded, () => ({})),
    on(TaskQueue.Skip.doneSucceeded, () => ({})),
  ],
);

export const makeShownLoadingStateAtom = (page) =>
  map(shownLoadingStateAtom, (ls) => ls[page] || LOADING_STATE.IDLE);

export const makeShownLoadingAtom = (page) =>
  map(makeShownLoadingStateAtom(page), (ls) => ls === LOADING_STATE.LOADING);

export const makeShownSucceededAtom = (page) =>
  map(makeShownLoadingStateAtom(page), (ls) => ls === LOADING_STATE.SUCCEEDED);

export const makeShownFailedAtom = (page) =>
  map(makeShownLoadingStateAtom(page), (ls) => ls === LOADING_STATE.FAILED);

export const makeShownReadyAtom = (page) =>
  map(
    combine([makeShownSucceededAtom(page), makeShownFailedAtom(page)]),
    ([isSucceeded, isFailed]) => isSucceeded || isFailed,
  );

export const shownTotalAmountAtom = declareAtom(
  'Tasks/PreviewList/shownTotalAmountAtom',
  null,
  (on) => [
    on(shown, () => null),
    on(shownSucceeded, (state, { amount }) => amount),
    on(resetTasks, () => null),

    on(TaskEditing.saveSucceeded, () => null),
    on(TaskCreation.savedSucceeded, () => null),
    on(Account.logOut, () => null),

    on(TaskQueue.Resolving.doneSucceeded, () => null),
    on(TaskQueue.Resuming.doneSucceeded, () => null),
    on(TaskQueue.Skip.doneSucceeded, () => null),
    on(TaskQueue.Showing.doneSucceeded, () => null),
  ],
);

export default combine({
  shownTableAtom,
  shownTotalAmountAtom,
  shownLoadingStateAtom,
});
