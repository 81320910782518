import { ACCOUNT_ERROR_TYPE } from '../../models/account';

const getErrorMessage = (errorType, formatMessage) => {
  switch (errorType) {
    case ACCOUNT_ERROR_TYPE.INACTIVE_USER:
      return formatMessage({
        defaultMessage: 'User not activated',
      });

    default:
      return formatMessage({
        defaultMessage: 'Failed to login. Please try again',
      });
  }
};

export default getErrorMessage;
