@import '../../../styles/utils';

.root {
  border-top: 2px solid $green;

  &_broken {
    border-color: $red;
  }
}

.idRow {
  display: flex;
  align-items: center;
  margin: 12px 0;
  font-weight: 700;
}

.isBrokenIcon {
  margin-left: 8px;
  color: $green;
}

.root_broken .isBrokenIcon {
  color: $red;
}

.grid {
  display: flex;
  align-items: center;
}

.imageColumn {
  flex: 0 0 185px;
  height: 185px;
  margin-right: 8px;
  border-radius: $secondaryBorderRadius;
  overflow: hidden;
}

.contentColumn {
  flex: 1 1 auto;
  height: 185px;
}

.linksRow {
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;
  font-size: 12px;
}

.link {
  flex: 1 1 auto;

  &:last-child {
    flex: 0 0 80px;
  }
}

.infoRow {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid $grey_2;

  &:first-child {
    border-top: 1px solid $grey_2;
  }
}

.infoItem {
  flex: 1 1 auto;
  line-height: 1;
  font-size: 11px;

  &:last-child {
    flex: 0 0 80px;
  }
}
