import { FormattedMessage } from 'react-intl';

import AdInfoTileSection from '../TileSection/TileSection';
import AdDetailsTable from '../Table/Table';

const AdGeneralDetailsSection = ({ data, isModerated }) => (
  <AdInfoTileSection
    title={<FormattedMessage defaultMessage="General information" />}
    isModerated={isModerated}
  >
    <AdDetailsTable>
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="property type" />}
        {...data.realtyType}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="deal type" />}
        {...data.contractType}
      />
    </AdDetailsTable>
  </AdInfoTileSection>
);

export default AdGeneralDetailsSection;
