import { Children, cloneElement } from 'react';
import styles from './HeaderRow.module.scss';

const AdPageTableHeaderRow = ({ children, columnSizeByName }) => (
  <div className={styles.root}>
    {Children.map(children, (child) => {
      const { width, minWidth, isFlexible } =
        columnSizeByName[child.props.name] || {};

      return cloneElement(child, {
        width,
        minWidth,
        isFlexible,
      });
    })}
  </div>
);

export default AdPageTableHeaderRow;
