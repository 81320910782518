@import '../../styles/utils';

.header {
  display: flex;
  margin-bottom: 32px;
}

.headerButton {
  @extend %normalize-button;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 56px;

  font-weight: 700;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid $grey_2;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 4px solid transparent;
    @include defaultTransition(border-top);
  }

  &:hover::after {
    border-top: 4px solid $secondaryBlue;
  }

  &:active::after {
    border-top: 4px solid $primaryBlue;
  }

  &_active {
    pointer-events: none;

    &::after {
      border-top: 4px solid $primaryBlue;
    }
  }
}

.headerButtonTitle {
  font-size: 20px;
}

.headerButtonTrailingElement {
  margin-left: 8px;
}
