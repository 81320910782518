import PropTypes from 'prop-types';

import AdDetailsTableEditableField from './EditableField/EditableField';
import AdDetailsTableRow from './Row/Row';

import styles from './Table.module.scss';

const AdDetailsTable = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

AdDetailsTable.propTypes = {
  children: PropTypes.node.isRequired,
};

AdDetailsTable.Row = AdDetailsTableRow;
AdDetailsTable.EditableField = AdDetailsTableEditableField;

export default AdDetailsTable;
