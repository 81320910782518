import { createContext, useContext, useCallback, useMemo } from 'react';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './NotificationsProvider.scss';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const showNotification = useCallback(
    (text, { type = toast.TYPE.DEFAULT, ...rest } = {}) =>
      toast(text, {
        position: toast.POSITION.TOP_CENTER,
        type,
        ...rest,
      }),
    [],
  );

  const showErrorNotification = useCallback(
    (text, options) =>
      showNotification(text, { ...options, type: toast.TYPE.ERROR }),
    [showNotification],
  );

  const actions = useMemo(
    () => ({ showNotification, showErrorNotification }),
    [showNotification, showErrorNotification],
  );

  return (
    <>
      <ToastContainer
        transition={Zoom}
        limit={2}
        autoClose={4000}
        draggable={false}
        closeButton={false}
        closeOnClick={false}
        pauseOnHover
        newestOnTop
        hideProgressBar
      />
      <NotificationsContext.Provider value={actions}>
        {children}
      </NotificationsContext.Provider>
    </>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
export default NotificationsProvider;
