@import '../../styles/utils';

.root {
  margin-bottom: 32px;
  padding: 24px 32px;
  border-radius: $primaryBorderRadius;
  background-color: $white;

  &:last-child {
    margin-bottom: 0;
  }

  &_grey {
    background-color: $grey_1;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  margin: 0;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 700;
}

.titleAppended {
  flex: 1 1 auto;
  margin-left: 16px;
}
