import { useAction, useAtom } from '@reatom/react';
import PropTypes from 'prop-types';

import AdGeneralDetailsSection from './GeneralSection/GeneralSection';
import AdGeographicalDetailsSection from './GeographicalSection/GeographicalSection';
import AdObjectDetailsSection from './ObjectSection/ObjectSection';
import AdHouseDetailsSection from './HouseSection/HouseSection';
import AdAuthorDetailsSection from './AuthorSection/AuthorSection';
import AdMetaDetailsSection from './MetaSection/MetaSection';
import AdHouseEditingDialogContainer from './HouseEditingDialog/HouseEditingDialogContainer';

import * as Ads from '../../../models/ads';
import * as Dialog from '../../../models/dialog';
import { DIALOG_NAME } from '../../../constants';

const AdDetails = ({ adId }) => {
  const adDetails = useAtom(Ads.Overview.makeAdDetailsAtom(adId));

  const openedDialogName = useAtom(Dialog.openedNameAtom);
  const openDialog = useAction(Dialog.openDialog);
  const closeDialog = useAction(Dialog.closeDialog);

  const handleHouseEditClick = () =>
    openDialog({ name: DIALOG_NAME.AD_HOUSE_EDITING });

  const handleHouseEditingClose = () => closeDialog();

  return (
    <>
      <AdGeneralDetailsSection data={adDetails.general} />
      <AdGeographicalDetailsSection
        data={adDetails.geo}
        onHouseEditClick={handleHouseEditClick}
      />
      <AdObjectDetailsSection data={adDetails.object} />
      <AdHouseDetailsSection data={adDetails.house} />
      <AdAuthorDetailsSection data={adDetails.author} />
      <AdMetaDetailsSection data={adDetails.meta} />

      <AdHouseEditingDialogContainer
        adId={adId}
        isOpened={openedDialogName === DIALOG_NAME.AD_HOUSE_EDITING}
        onClose={handleHouseEditingClose}
      />
    </>
  );
};

AdDetails.propTypes = {
  adId: PropTypes.string,
};

export default AdDetails;
