import { useMemo } from 'react';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  useIntl,
} from 'react-intl';

import Table from '../Table/Table';
import UserPlate from '../UserPlate/UserPlate';

import { CALL_RESULT_INFO, CALL_STATUS_INFO } from '../../constants';
import styles from './CallsTable.module.scss';

const getLastCallId = (calls) =>
  [...calls].sort((a, b) => new Date(b.createTime) - new Date(a.createTime))[0]
    ?.id;

const columns = [
  { name: 'createTime', width: '80px', isFlexible: true },
  { name: 'adId', width: '88px', isFlexible: true },
  { name: 'callStatus', width: '128px', isFlexible: true },
  { name: 'callResult', width: '128px', isFlexible: true },
  { name: 'comment', width: '128px', isFlexible: true },
  { name: 'executor', width: '128px', isFlexible: true },
];

const CallsTable = ({ calls }) => {
  const intl = useIntl();
  const lastCallId = useMemo(() => getLastCallId(calls), [calls]);

  return (
    <Table
      headerRow={
        <Table.HeaderRow>
          <Table.HeaderCell
            name="createTime"
            title={<FormattedMessage defaultMessage="Date" />}
          />
          <Table.HeaderCell
            name="adId"
            title={<FormattedMessage defaultMessage="Task id" />}
          />
          <Table.HeaderCell
            name="callStatus"
            title={<FormattedMessage defaultMessage="Call status" />}
          />
          <Table.HeaderCell
            name="callResult"
            title={<FormattedMessage defaultMessage="Ad status" />}
          />
          <Table.HeaderCell
            name="comment"
            title={<FormattedMessage defaultMessage="Comment" />}
          />
          <Table.HeaderCell
            name="executor"
            title={<FormattedMessage defaultMessage="Moderator" />}
          />
        </Table.HeaderRow>
      }
      columns={columns}
      emptyText={<FormattedMessage defaultMessage="No calls" />}
      isEmpty={calls?.length === 0}
    >
      {calls.map(
        ({
          id,
          createTime,
          adId,
          callStatus,
          callResult,
          comment,
          executor,
        }) => (
          <Table.Row
            key={id}
            isHighlighted={calls.length > 1 && id === lastCallId}
          >
            <Table.Cell name="createTime">
              <span className={styles.date}>
                <FormattedDate value={createTime} />
              </span>
              <span className={styles.date}>
                <FormattedTime value={createTime} />
              </span>
            </Table.Cell>

            <Table.Cell name="adId">{adId}</Table.Cell>

            <Table.Cell name="callStatus">
              {intl
                .formatMessage(CALL_STATUS_INFO[callStatus].title)
                .toLowerCase()}
            </Table.Cell>

            <Table.Cell name="callResult">
              {intl
                .formatMessage(CALL_RESULT_INFO[callResult].title)
                .toLowerCase()}
            </Table.Cell>

            <Table.Cell name="comment">
              <div className={styles.comment}>{comment}</div>
            </Table.Cell>

            <Table.Cell name="executor">
              <UserPlate name={executor.name} media={executor.media} />
            </Table.Cell>
          </Table.Row>
        ),
      )}
    </Table>
  );
};

export default CallsTable;
