import { declareAction, declareAtom, combine, map } from '@reatom/core';

import api from '../services/api';
import { deserializeSearchResults } from '../normalizers';
import { LOADING_STATE } from '../constants';
import * as Account from './account';

export const SEARCH_ERROR_TYPE = {
  SEARCH_RESULT_EXCEEDS_MAX_LIMIT: 'SEARCH_RESULT_EXCEEDS_MAX_LIMIT',
  DEFAULT: 'DEFAULT',
};

const shownSucceeded = declareAction('Search/shownSucceeded'); // { results }
const shownFailed = declareAction('Search/shownFailed'); // { errorType }

let currentQuery = '';

export const shown = declareAction(
  'Search/shown',
  async ({ query, entityType }, store) => {
    currentQuery = query;
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const results = await api(authorization).search.getAll({
        query,
        entityType,
      });

      if (query !== currentQuery) {
        return;
      }

      store.dispatch(
        shownSucceeded({ results: deserializeSearchResults(results) }),
      );
    } catch (error) {
      if (query !== currentQuery) {
        return;
      }

      console.error(error);

      store.dispatch(
        shownFailed({
          errorType: SEARCH_ERROR_TYPE[error.type] || SEARCH_ERROR_TYPE.DEFAULT,
        }),
      );
    }
  },
);

export const stateReset = declareAction('Search/stateReset', () => {
  currentQuery = '';
});

export const resultsAtom = declareAtom('Search/resultsAtom', [], (on) => [
  on(shown, () => []),
  on(shownSucceeded, (state, { results }) => results),
  on(stateReset, () => []),
]);

const loadingStateAtom = declareAtom(
  'Search/loadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(shown, () => LOADING_STATE.LOADING),
    on(shownSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(shownFailed, () => LOADING_STATE.FAILED),
    on(stateReset, () => LOADING_STATE.IDLE),
  ],
);

export const isLoadingAtom = map(
  'Search/isLoadingAtom',
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);
export const isSucceededAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.SUCCEEDED,
);
export const isFailedAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export const errorTypeAtom = declareAtom('Search/errorTypeAtom', '', (on) => [
  on(shown, () => ''),
  on(shownFailed, (state, { errorType }) => errorType),
  on(stateReset, () => ''),
]);

export default combine({
  resultsAtom,
  errorTypeAtom,
  loadingStateAtom,
});
