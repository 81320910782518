import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import CounterStatisticGroup from '../../../components/CounterStatisticGroup/CounterStatisticGroup';
import { TASK_TYPE_INFO, TASK_TYPE_LIST } from '../../../constants';

const formTaskStatistic = (users, formatMessage) => {
  const userAmountByTask = users
    .map(({ taskTypes }) => taskTypes)
    .flat()
    .reduce((acc, type) => {
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});

  return TASK_TYPE_LIST.reduce(
    (acc, type) => [
      ...acc,
      {
        value: type,
        title: formatMessage(TASK_TYPE_INFO[type].title),
        color: TASK_TYPE_INFO[type].color,
        id: type,
        number: userAmountByTask[type] || 0,
      },
    ],
    [],
  );
};

const UsersPageStatisticGroup = ({ users = [] }) => {
  const intl = useIntl();

  const primary = useMemo(
    () => ({
      title: intl.formatMessage({ defaultMessage: 'Total users' }),
      number: users.length,
    }),
    [users, intl],
  );

  const tasksStatistic = useMemo(
    () => formTaskStatistic(users, intl.formatMessage),
    [users, intl.formatMessage],
  );

  return <CounterStatisticGroup list={tasksStatistic} primary={primary} />;
};

export default UsersPageStatisticGroup;
