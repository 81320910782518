import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';

export const savedSucceeded = declareAction('Ads/HouseEditing/savedSucceeded'); // { adId }
export const savedFailed = declareAction('Ads/HouseEditing/savedFailed'); // { adId }

export const saved = declareAction(
  'Ads/HouseEditing/saved',
  async ({ adId, house }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      await api(authorization).ads.editAdHouse(adId, house.id);
      store.dispatch(savedSucceeded({ adId }));
    } catch (e) {
      console.error(e);

      store.dispatch(
        savedFailed({
          adId,
        }),
      );
    }
  },
);

const loadingStateAtom = declareAtom(
  'Ads/HouseEditing/loadingStateAtom',
  {},
  (on) => [
    on(saved, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.LOADING,
    })),
    on(savedSucceeded, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.SUCCEEDED,
    })),
    on(savedFailed, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.FAILED,
    })),
  ],
);

const makeLoadingStateAtom = (adId) =>
  map(loadingStateAtom, (table) => table[adId] || LOADING_STATE.IDLE);

export const makeIsLoadingAtom = (adId) =>
  map(makeLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.LOADING);

export const makeIsSucceededAtom = (adId) =>
  map(makeLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.SUCCEEDED);

export const makeIsFailedAtom = (adId) =>
  map(makeLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.FAILED);

export default combine({ loadingStateAtom });
