import { useCallback } from 'react';
import cx from 'classnames';

import CheckMark from '../../CheckMark/CheckMark';

import styles from './GeneralOption.module.scss';

const SelectDropdownGeneralOption = ({ option, isSelected, onClick }) => {
  const handleClick = useCallback(
    () => onClick(option.value),
    [option.value, onClick],
  );

  return (
    <button
      className={cx(styles.root, { [styles.root_selected]: isSelected })}
      onClick={handleClick}
    >
      <span className={styles.mark}>
        <CheckMark isOn={isSelected} />
      </span>

      <span>{option.title}</span>
    </button>
  );
};

export default SelectDropdownGeneralOption;
