@import '../../../styles/utils';

.root {
  display: flex;
  align-items: flex-start;
  margin: -16px;
  font-size: 12px;
}

.mediaColumn {
  flex: 0 0 264px;
  max-width: 264px;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.mediaItem {
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 8px;
  color: $grey_2;
}

.media {
  aspect-ratio: 1;
  border-radius: $secondaryBorderRadius;
  overflow: hidden;
}

.imagePlaceholder {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePlaceholderContent {
  width: 50%;
  height: 50%;
}

.textColumn {
  flex: 1 1 auto;
  padding: 16px;
}

.root_small .textColumn {
  padding: 8px;
}

.address {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}

.agency {
  margin-bottom: 12px;
}

.price {
  margin-bottom: 12px;
  font-size: 16px;
}

.parametersItem {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.description {
  margin-top: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
