import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Dialog from '../../../Dialog/Dialog';
import Form from '../../../Form/Form';
import Field from '../../../Field/Field';
import Input from '../../../Input/Input';
import Fieldset from '../../../Fieldset/Fieldset';
import Radio from '../../../Radio/Radio';
import Button from '../../../Button/Button';
import ZendeskLinkInput from '../../../ZendeskLinkInput/ZendeskLinkInput';
import InputList from '../../../InputList/InputList';
import CommentTextarea from '../../../CommentTextarea/CommentTextarea';
import TaskInteractionHistoryField from '../../../TaskInteractionHistoryField/TaskInteractionHistoryField';
import TaskInteractionHistory from '../../../TaskInteractionHistory/TaskInteractionHistory';
import { IconLink } from '../../../icons';
import TitleAppended from './TitleAppended/TitleAppended';

import { useEventHandler } from '../../../../hooks';
import {
  PRIORITY_INFO,
  SORTED_PRIORITIES,
  TASK_IDENTIFIER_TYPE_INFO,
  TASK_SUBTYPE_INFO,
  TASK_TYPE_INFO,
} from '../../../../constants';
import styles from './CompletedTaskOverviewDialog.module.scss';

const CompletedTaskOverviewDialog = ({
  task,
  hasRecreationButton,
  isRecreationLoading,
  isCommentLoading,
  isCommentSucceeded,
  isOpened,
  onRecreateClick,
  onCommentSubmit,
  onClose,
  ...rest
}) => {
  const intl = useIntl();
  const [comment, setComment] = useState();

  const {
    id,
    type,
    subType,
    priority: selectedPriority,
    zendeskUrl,
    identifier,
    additionalResources,
    executor,
    interactionHistory,
    isAutomatic,
    isComplaint,
  } = task;

  const actions = useMemo(() => {
    const res = [
      <Button
        key="cancel"
        title={<FormattedMessage defaultMessage="Cancel" />}
        variant="flat"
        onClick={onClose}
        withLargeSideInnerGaps
      />,
    ];

    if (hasRecreationButton) {
      res.push(
        <Button
          key="recreation"
          title={<FormattedMessage defaultMessage="Re-create task" />}
          isDisabled={isRecreationLoading}
          onClick={onRecreateClick}
          withLargeSideInnerGaps
        />,
      );
    }
    return res;
  }, [isRecreationLoading, hasRecreationButton, onRecreateClick, onClose]);

  const handleCommentChange = useCallback(
    (e) => setComment(e.target.value),
    [],
  );

  const handleCommentSubmit = useEventHandler(
    () => onCommentSubmit(task.id, comment),
    [task.id, comment],
  );

  useEffect(() => {
    setComment('');
  }, [isOpened]);

  useEffect(() => {
    if (isCommentSucceeded) {
      setComment('');
    }
  }, [isCommentSucceeded]);

  const taskSubtype = useMemo(() => {
    const title = TASK_SUBTYPE_INFO[subType]?.title;
    return title ? intl.formatMessage(title) : '';
  }, [subType, intl]);

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="Completed task view" />}
      titleAppended={
        <TitleAppended
          id={id}
          type={type}
          isAutomatic={isAutomatic}
          isComplaint={isComplaint}
        />
      }
      actions={actions}
      isOpened={isOpened}
      onClose={onClose}
      {...rest}
    >
      <div className={styles.grid}>
        <div className={styles.column}>
          <Form.Row>
            <Field label={<FormattedMessage defaultMessage="Task type" />}>
              <Input
                value={
                  type && <FormattedMessage {...TASK_TYPE_INFO[type].title} />
                }
                isGrey
                isDisabled
              />
            </Field>
          </Form.Row>

          <Form.Row>
            <Field label={<FormattedMessage defaultMessage="Task subtype" />}>
              <Input
                value={
                  subType && (
                    <FormattedMessage {...TASK_SUBTYPE_INFO[subType].title} />
                  )
                }
                isGrey
                isDisabled
              />
            </Field>
          </Form.Row>

          <Form.Row>
            <Field label={<FormattedMessage defaultMessage="Priority" />}>
              <Fieldset>
                {SORTED_PRIORITIES.map((priority) => (
                  <Fieldset.Item key={priority}>
                    <Radio
                      label={PRIORITY_INFO[priority]?.title}
                      checked={priority === selectedPriority}
                      isDisabled
                    />
                  </Fieldset.Item>
                ))}
              </Fieldset>
            </Field>
          </Form.Row>

          <Form.Row>
            <Field label={<FormattedMessage defaultMessage="Zendesk link" />}>
              <ZendeskLinkInput value={zendeskUrl} isDisabled />
            </Field>
          </Form.Row>

          <Form.Row>
            <Field
              label={
                identifier?.type && (
                  <FormattedMessage
                    {...TASK_IDENTIFIER_TYPE_INFO[identifier.type].title}
                  />
                )
              }
            >
              <InputList
                list={identifier?.entities}
                noAppendedIcon
                isDisabled
              />
            </Field>
          </Form.Row>

          <Form.Row>
            <Field label={<FormattedMessage defaultMessage="Assignee" />}>
              <Input value={executor?.name} isGrey isDisabled />
            </Field>
          </Form.Row>

          <Form.Row>
            <Field
              label={<FormattedMessage defaultMessage="Additional resources" />}
            >
              <InputList
                list={additionalResources}
                prependedIcon={<IconLink />}
                noAppendedIcon
                isDisabled
              />
            </Field>
          </Form.Row>

          <Form.Row>
            <Field label={<FormattedMessage defaultMessage="Comment" />}>
              <CommentTextarea
                value={comment}
                isSubmitButtonDisabled={isCommentLoading || !comment}
                onSubmit={handleCommentSubmit}
                onChange={handleCommentChange}
              />
            </Field>
          </Form.Row>
        </div>

        <div className={styles.column}>
          <TaskInteractionHistoryField
            label={<FormattedMessage defaultMessage="Comment history" />}
          >
            <TaskInteractionHistory history={interactionHistory} />
          </TaskInteractionHistoryField>
        </div>
      </div>
    </Dialog>
  );
};

export default CompletedTaskOverviewDialog;
