import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Dropdown from '../../../../components/Dropdown/Dropdown';
import {
  IconAngleArrowUp,
  IconAngleArrowDown,
} from '../../../../components/icons';
import RoundIconButton from '../../../../components/RoundIconButton/RoundIconButton';

import { useToggle } from '../../../../hooks';
import styles from './FinishTaskDropdown.module.scss';

const FinishTaskDropdown = ({ onSkip, onSubmit }) => {
  const { isOn, on, off } = useToggle();

  const handleSkip = useCallback(() => {
    onSkip(true);
    off();
  }, [off, onSkip]);

  const handleSubmit = useCallback(() => {
    onSubmit(true);
    off();
  }, [off, onSubmit]);

  return (
    <Dropdown
      trigger={
        <RoundIconButton
          icon={isOn ? <IconAngleArrowUp /> : <IconAngleArrowDown />}
          onClick={on}
        />
      }
      isOpened={isOn}
      onClose={off}
    >
      <div>
        <button className={styles.option} onClick={handleSubmit}>
          <FormattedMessage defaultMessage="Resolve and pause" />
        </button>
      </div>

      <div>
        <button className={styles.option} onClick={handleSkip}>
          <FormattedMessage defaultMessage="Skip and pause" />
        </button>
      </div>
    </Dropdown>
  );
};

export default FinishTaskDropdown;
