@import '../../../../../styles/utils';

.root {
  margin-right: 18px;
  font-weight: 700;

  &:last-child {
    margin-right: 0;
  }
}
