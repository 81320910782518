import { defineMessage } from 'react-intl';

const SECTION_OPTIONS = [
  {
    title: defineMessage({ defaultMessage: 'Apartment sale' }),
    value: 'apartments_sale',
  },
  {
    title: defineMessage({ defaultMessage: 'Apartment rent' }),
    value: 'apartments_rent',
  },
  {
    title: defineMessage({ defaultMessage: 'House sale' }),
    value: 'houses_sale',
  },
  {
    title: defineMessage({ defaultMessage: 'House rent' }),
    value: 'houses_rent',
  },
  {
    title: defineMessage({ defaultMessage: 'Daily apartment rent' }),
    value: 'daily_apartments_rent',
  },
  {
    title: defineMessage({ defaultMessage: 'Daily house rent' }),
    value: 'daily_houses_rent',
  },
];

export default SECTION_OPTIONS;
