import { defineMessage } from 'react-intl';
import { TASK_TYPE } from './taskType';

export const TASK_SUBTYPE = {
  FAKE_PHOTO_VERIFICATION: 'fake_photo_verification',
  PHOTO_WITH_INSCRIPTIONS: 'photo_with_inscriptions',
  CHECK_GEOGRAPHY: 'geography',
  CHECK_BUILDINGS: 'check_buildings',
  UNBLOCKING_REQUEST: 'unblocking_request',
  DEVELOPER_SUSPICION: 'developer_suspicion',
  BLOCKING_SUSPICION: 'blocking_suspicion',
  SPAMMER_SUSPICION: 'spammer_suspicion',
  UNDER_GROUP: 'under_group',
  OVER_GROUP: 'over_group',
  RELEVANCE_CHECK: 'relevance_check',
  IRRELEVANCE_COMPLAINT: 'irrelevance_complaint',
  SCAMMER_COMPLAINT: 'scammer_complaint',
  WRONG_PRICE: 'wrong_price',
  WRONG_GEO: 'wrong_geo',
  FRAUD: 'fraud',
  SOLD_OUT: 'sold_out',
  FAKE: 'fake',
};

export const TASK_SUBTYPE_INFO = {
  [TASK_SUBTYPE.FAKE_PHOTO_VERIFICATION]: {
    slug: TASK_SUBTYPE.FAKE_PHOTO_VERIFICATION,
    title: defineMessage({
      defaultMessage: 'Spammer check (fake by photo)',
    }),
  },

  [TASK_SUBTYPE.PHOTO_WITH_INSCRIPTIONS]: {
    slug: TASK_SUBTYPE.PHOTO_WITH_INSCRIPTIONS,
    title: defineMessage({
      defaultMessage: 'Captions on the photo',
    }),
  },

  [TASK_SUBTYPE.CHECK_GEOGRAPHY]: {
    slug: TASK_SUBTYPE.CHECK_GEOGRAPHY,
    title: defineMessage({
      defaultMessage: 'Check geography',
    }),
  },

  [TASK_SUBTYPE.CHECK_BUILDINGS]: {
    slug: TASK_SUBTYPE.CHECK_BUILDINGS,
    title: defineMessage({
      defaultMessage: 'House/HCA not recognized',
    }),
  },

  [TASK_SUBTYPE.UNBLOCKING_REQUEST]: {
    slug: TASK_SUBTYPE.UNBLOCKING_REQUEST,
    title: defineMessage({
      defaultMessage: 'Request to unlock',
    }),
  },

  [TASK_SUBTYPE.DEVELOPER_SUSPICION]: {
    slug: TASK_SUBTYPE.DEVELOPER_SUSPICION,
    title: defineMessage({
      defaultMessage: 'Suspicion about the builder',
    }),
  },

  [TASK_SUBTYPE.BLOCKING_SUSPICION]: {
    slug: TASK_SUBTYPE.BLOCKING_SUSPICION,
    title: defineMessage({
      defaultMessage: 'Suspicion of blocking',
    }),
  },

  [TASK_SUBTYPE.SPAMMER_SUSPICION]: {
    slug: TASK_SUBTYPE.SPAMMER_SUSPICION,
    title: defineMessage({
      defaultMessage: 'Suspicion of a spammer',
    }),
  },

  [TASK_SUBTYPE.UNDER_GROUP]: {
    slug: TASK_SUBTYPE.UNDER_GROUP,
    title: defineMessage({
      defaultMessage: 'Undergroup',
    }),
  },

  [TASK_SUBTYPE.OVER_GROUP]: {
    slug: TASK_SUBTYPE.OVER_GROUP,
    title: defineMessage({
      defaultMessage: 'Overgroup',
    }),
  },

  [TASK_SUBTYPE.RELEVANCE_CHECK]: {
    slug: TASK_SUBTYPE.RELEVANCE_CHECK,
    title: defineMessage({
      defaultMessage: 'Relevance check',
    }),
  },

  [TASK_SUBTYPE.IRRELEVANCE_COMPLAINT]: {
    slug: TASK_SUBTYPE.IRRELEVANCE_COMPLAINT,
    title: defineMessage({
      defaultMessage: 'Complaint surrendered/sold',
    }),
  },

  [TASK_SUBTYPE.SCAMMER_COMPLAINT]: {
    slug: TASK_SUBTYPE.SCAMMER_COMPLAINT,
    title: defineMessage({
      defaultMessage: 'Complaint scammer',
    }),
  },

  [TASK_SUBTYPE.WRONG_PRICE]: {
    slug: TASK_SUBTYPE.WRONG_PRICE,
    title: defineMessage({
      defaultMessage: 'Wrong Price',
    }),
  },

  [TASK_SUBTYPE.WRONG_GEO]: {
    slug: TASK_SUBTYPE.WRONG_GEO,
    title: defineMessage({
      defaultMessage: 'Wrong Geo',
    }),
  },

  [TASK_SUBTYPE.FRAUD]: {
    slug: TASK_SUBTYPE.FRAUD,
    title: defineMessage({
      defaultMessage: 'Fraud',
    }),
  },

  [TASK_SUBTYPE.SOLD_OUT]: {
    slug: TASK_SUBTYPE.SOLD_OUT,
    title: defineMessage({
      defaultMessage: 'Sold out',
    }),
  },

  [TASK_SUBTYPE.FAKE]: {
    slug: TASK_SUBTYPE.FAKE,
    title: defineMessage({
      defaultMessage: 'Fake',
    }),
  },
};

export const TASK_SUBTYPES_BY_TYPE = {
  [TASK_TYPE.MODERATION]: [
    TASK_SUBTYPE.FAKE_PHOTO_VERIFICATION,
    TASK_SUBTYPE.PHOTO_WITH_INSCRIPTIONS,
    TASK_SUBTYPE.CHECK_GEOGRAPHY,
    TASK_SUBTYPE.CHECK_BUILDINGS,
    TASK_SUBTYPE.WRONG_GEO,
    TASK_SUBTYPE.WRONG_PRICE,
  ],

  [TASK_TYPE.OTHER]: [
    TASK_SUBTYPE.UNBLOCKING_REQUEST,
    TASK_SUBTYPE.DEVELOPER_SUSPICION,
    TASK_SUBTYPE.BLOCKING_SUSPICION,
    TASK_SUBTYPE.SPAMMER_SUSPICION,
  ],

  [TASK_TYPE.GROUP_VERIFICATION]: [
    TASK_SUBTYPE.UNDER_GROUP,
    TASK_SUBTYPE.OVER_GROUP,
  ],

  [TASK_TYPE.CALL]: [
    TASK_SUBTYPE.RELEVANCE_CHECK,
    TASK_SUBTYPE.IRRELEVANCE_COMPLAINT,
    TASK_SUBTYPE.SCAMMER_COMPLAINT,
  ],

  [TASK_TYPE.USER_COMPLAINT]: [
    TASK_SUBTYPE.FAKE,
    TASK_SUBTYPE.SOLD_OUT,
    TASK_SUBTYPE.FRAUD,
  ],
};
