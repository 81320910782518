import { useMemo } from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import styles from './PieChart.module.scss';

const createCoinsFallback = (coins) => {
  const hasAmount =
    coins.reduce((current, accumulator) => current + accumulator.amount, 0) > 0;

  if (hasAmount) {
    return coins;
  }

  return [{ id: 'fallback', amount: 1, color: '#000' }];
};

const PieChart = ({ coins, width = 144, primaryText, primaryTextCaption }) => {
  const half = width / 2;
  const coinsWithFallback = useMemo(() => createCoinsFallback(coins), [coins]);

  return (
    <svg width={width} height={width} className={styles.root}>
      <Group top={half} left={half}>
        <Pie
          data={coinsWithFallback}
          pieValue={({ amount }) => amount}
          outerRadius={half}
          innerRadius={half - 10}
          fill="red"
        >
          {(pie) =>
            pie.arcs.map((arc) => (
              <g key={arc.data.id}>
                <path d={pie.path(arc)} fill={arc.data.color}></path>
              </g>
            ))
          }
        </Pie>

        {primaryText && (
          <Text textAnchor="middle" fontSize={26} fontWeight="500">
            {primaryText}
          </Text>
        )}

        {primaryTextCaption && (
          <Text textAnchor="middle" fontSize={13} dy={20}>
            {primaryTextCaption}
          </Text>
        )}
      </Group>
    </svg>
  );
};

export default PieChart;
