import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import { deserializeTimeline } from '../../normalizers';
import * as Account from '../account';

const checkIsParamsRelevant = (stateParams, requestParams) =>
  stateParams.dateRange
    .map((date, index) => date === requestParams.dateRange[index])
    .reduce((cur, acc) => cur && acc, true);

const doneSucceeded = declareAction('UsersStatistic/Timeline/doneSucceeded');
const doneFailed = declareAction('UsersStatistic/Timeline/doneFailed');

export const done = declareAction(
  'UsersStatistic/Timeline/done',
  async ({ dateRange }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const timeline = await api(authorization).usersStatistic.timeline({
        from: dateRange[0],
        to: dateRange[1],
      });

      if (
        checkIsParamsRelevant(store.getState(getParamsAtom()), { dateRange })
      ) {
        store.dispatch(doneSucceeded(deserializeTimeline(timeline)));
      }
    } catch (error) {
      if (
        !checkIsParamsRelevant(store.getState(getParamsAtom()), { dateRange })
      ) {
        return;
      }

      console.error(error);
      store.dispatch(doneFailed());
    }
  },
);

export const reset = declareAction('UsersStatistic/Timeline/reset');

export const resetDoneErrorMessage = declareAction(
  'UsersStatistic/Timeline/resetDoneErrorMessage',
);

const paramsAtom = declareAtom(
  'UsersStatistic/Timeline/paramsAtom',
  { dateRange: [] },
  (on) => [
    on(done, (state, payload) => payload),
    on(reset, () => ({
      dateRange: [],
    })),
  ],
);

function getParamsAtom() {
  return paramsAtom;
}

export const timelineAtom = declareAtom(
  'UsersStatistic/Timeline/timelineAtom',
  [],
  (on) => [
    on(done, () => []),
    on(doneSucceeded, (state, { timeline }) => timeline),
    on(reset, () => []),
  ],
);

export const earliestTimelineDateAtom = declareAtom(
  'UsersStatistic/Timeline/earliestTimelineDateAtom',
  '',
  (on) => [
    on(done, () => ''),
    on(doneSucceeded, (state, { earliestDate }) => earliestDate),
    on(reset, () => ''),
  ],
);

export const latestTimelineDateAtom = declareAtom(
  'UsersStatistic/Timeline/latestTimelineDateAtom',
  '',
  (on) => [
    on(done, () => ''),
    on(doneSucceeded, (state, { latestDate }) => latestDate),
    on(reset, () => ''),
  ],
);

const doneLoadingStateAtom = declareAtom(
  'UsersStatistic/Timeline/doneLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
    on(reset, () => LOADING_STATE.IDLE),
  ],
);

export const isDoneLoadingAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);
export const isDoneFailedAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);
export const isDoneIdleAtom = map(
  doneLoadingStateAtom,
  (ls) => ls === LOADING_STATE.IDLE,
);

export default combine({
  timelineAtom,
  doneLoadingStateAtom,
  paramsAtom,
});
