import React, { useRef, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import { Portal } from 'react-portal';

import useScrollBarWidth from './useScrollBarWidth';
import styles from './Dialog.module.scss';

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 25,
};

const Dialog = ({
  title,
  titleAppended,
  children,
  actions = [],
  isOpened,
  onClose,
}) => {
  const dialogWindowElementRef = useRef(null);
  const scrollBarWidth = useScrollBarWidth();

  const transitions = useTransition(isOpened, null, {
    from: { opacity: 0, transform: 'scale(0.8)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.8)' },
    config: springConfig,
  });

  const right = isOpened ? '0' : `-${scrollBarWidth}px`;

  const checkIsOverlay = useCallback(
    (target) => !dialogWindowElementRef.current.contains(target),
    [],
  );

  const handleOverlayClick = useCallback(
    (e) => {
      if (checkIsOverlay(e.target)) {
        onClose();
      }
    },

    [checkIsOverlay, onClose],
  );

  return transitions.map(
    ({ item: opened, key, props }) =>
      opened && (
        <Portal key={key}>
          <animated.div
            className={styles.root}
            style={{ opacity: props.opacity, right }}
            onClick={handleOverlayClick}
          >
            <div className={styles.container}>
              <animated.div
                className={styles.window}
                style={props}
                ref={dialogWindowElementRef}
              >
                {(title || titleAppended) && (
                  <div className={styles.header}>
                    <h2 className={styles.title}>{title}</h2>

                    {titleAppended && (
                      <div className={styles.titleAppended}>
                        {titleAppended}
                      </div>
                    )}
                  </div>
                )}

                <div>{children}</div>

                {actions.length > 0 && (
                  <div className={styles.actionGroup}>
                    {actions.map((action, index) => (
                      <div key={index} className={styles.actionItem}>
                        {action}
                      </div>
                    ))}
                  </div>
                )}
              </animated.div>
            </div>
          </animated.div>
        </Portal>
      ),
  );
};

export default Dialog;
