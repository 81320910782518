import { FormattedMessage } from 'react-intl';
import Button from '../../../components/Button/Button';
import styles from './StoppedTaskQueue.module.scss';

const StoppedTaskQueue = ({ onRestart }) => (
  <div>
    <div className={styles.inscription}>
      <FormattedMessage defaultMessage="Task queue is paused. Press button to continue work:" />
    </div>

    <div>
      <Button
        title={<FormattedMessage defaultMessage="Back to tasks" />}
        onClick={onRestart}
      />
    </div>
  </div>
);

export default StoppedTaskQueue;
