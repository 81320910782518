import styles from './DefaultPageLayout.module.scss';

const DefaultPageLayout = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

DefaultPageLayout.WideCol = ({ children }) => (
  <div className={styles.wideCol}>{children}</div>
);
DefaultPageLayout.NarrowCol = ({ children }) => (
  <div className={styles.narrowCol}>{children}</div>
);

export default DefaultPageLayout;
