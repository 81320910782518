import { TASK_TYPE_LIST } from '../../constants';

const deserializeCountStatistic = (statisticList) => {
  return TASK_TYPE_LIST.map((taskType) => ({
    taskType,
    count:
      statisticList.find(({ taskType: _taskType }) => _taskType === taskType)
        ?.count || 0,
  }));
};

export default deserializeCountStatistic;
