import { FormattedMessage } from 'react-intl';

import Label from '../../../../../components/Label/Label';
import { IconCheck } from '../../../../../components/icons';

const AdDetailsModeratedLabel = () => (
  <Label
    title={<FormattedMessage defaultMessage="Moderated" />}
    backgroundColor="#69c358"
    icon={<IconCheck />}
  />
);

export default AdDetailsModeratedLabel;
