import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { BarRounded, Line } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom } from '@visx/axis';
import { scaleTime } from '@visx/scale';

import Media from '../Media/Media';

import { TASK_TYPE_INFO } from '../../constants';
import styles from './UserProductivityTimeline.module.scss';

const AXIS_WIDTH_PER_HOUR = 10000 / 24;
const AXIS_HEIGHT = 20;
const AXIS_SIDE_INNER_GAP = 16;
const MS_IN_HOUR = 60 * 60 * 1000;
const MS_IN_DAY = 24 * MS_IN_HOUR;
const HEIGHT_PER_USER = 72;
const TICKS_AMOUNT_PER_HOUR = 60 / 5 - 1; // length of a segment is 5 minutes;

const createTimeScale = (currentDay, earliestTime, latestTime) => {
  const startDate = +new Date(earliestTime || currentDay);
  const endDate = latestTime
    ? +new Date(latestTime)
    : +new Date(currentDay) + MS_IN_DAY;
  const differenceInHours = Math.ceil((endDate - startDate) / MS_IN_HOUR);

  return scaleTime({
    domain: [startDate, endDate],
    range: [0, AXIS_WIDTH_PER_HOUR * differenceInHours],
    nice: true,
  });
};

const getTickLabelProps = () => ({ textAnchor: 'middle' });

const UserProductivityTimeline = ({
  currentDay,
  earliestDate,
  latestDate,
  timelineList,
  onRoundedBarClick,
}) => {
  const intl = useIntl();

  const tickFormat = useCallback(
    (date) => intl.formatTime(date),
    [intl.formatDate],
  );

  const timeScale = useMemo(
    () => createTimeScale(currentDay, earliestDate, latestDate),
    [currentDay, earliestDate, latestDate],
  );

  const rangeDifferenceInHours = useMemo(() => {
    const [startDate, endDate] = timeScale.domain();
    return Math.ceil((endDate - startDate) / MS_IN_HOUR);
  }, [timeScale]);

  const ticksAmount = useMemo(
    () => rangeDifferenceInHours * TICKS_AMOUNT_PER_HOUR,
    [rangeDifferenceInHours],
  );

  const chartHeight = useMemo(
    () => timelineList.length * HEIGHT_PER_USER + AXIS_HEIGHT,
    [timelineList],
  );

  const chartWidth = useMemo(
    () =>
      rangeDifferenceInHours * AXIS_WIDTH_PER_HOUR + AXIS_SIDE_INNER_GAP * 2,
    [rangeDifferenceInHours],
  );

  const rangeHoursInMsList = useMemo(() => {
    const startDateInMs = +new Date(timeScale.domain()[0]);

    return Array.from(Array(rangeDifferenceInHours + 1).keys()).map(
      (hour) => startDateInMs + hour * MS_IN_HOUR,
    );
  }, [timeScale, rangeDifferenceInHours]);

  return (
    <div className={styles.root}>
      <div className={styles.userList}>
        {timelineList.map(({ user }) => (
          <div key={user.id} className={styles.userItem}>
            <div className={styles.userMedia}>
              <Media media={user.media} />
            </div>

            <div className={styles.userName}>{user.name}</div>
          </div>
        ))}
      </div>

      <div className={styles.timeline}>
        <svg width={chartWidth} height={chartHeight}>
          <Group left={AXIS_SIDE_INNER_GAP} top={-8}>
            {rangeHoursInMsList.map((hourInMs) => (
              <Line
                key={hourInMs}
                from={{ x: timeScale(hourInMs), y: AXIS_HEIGHT }}
                to={{ x: timeScale(hourInMs), y: chartHeight - AXIS_HEIGHT }}
                stroke="#afb5c4"
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="4"
              />
            ))}
            {timelineList.map(({ user, timeline }, index) => (
              <Group top={index * HEIGHT_PER_USER + 16} key={user.id}>
                {timeline.map(
                  ({ startTime, finishTime, taskType, taskId, wasSkipped }) => (
                    <BarRounded
                      key={startTime}
                      height={16}
                      y={8}
                      radius={12}
                      all="true"
                      className={styles.barRounded}
                      style={{
                        fill: wasSkipped
                          ? '#afb5c4'
                          : TASK_TYPE_INFO[taskType]?.color,
                      }}
                      x={timeScale(+new Date(startTime))}
                      width={Math.round(
                        timeScale(+new Date(finishTime)) -
                          timeScale(+new Date(startTime)),
                      )}
                      onClick={() => onRoundedBarClick(taskId)}
                    />
                  ),
                )}
              </Group>
            ))}
             
            <AxisBottom
              top={chartHeight - AXIS_HEIGHT}
              scale={timeScale}
              tickClassName={styles.axisBottomTrick}
              numTicks={ticksAmount}
              tickLabelProps={getTickLabelProps}
              tickFormat={tickFormat}
            />
          </Group>
        </svg>
      </div>
    </div>
  );
};

export default UserProductivityTimeline;
