import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import { deserializeCountStatistic } from '../../normalizers';
import { LOADING_STATE, TASK_TYPE_LIST } from '../../constants';
import * as Account from '../account';
import * as TaskQueue from '../taskQueue';
import * as TaskEditing from './editing';
import * as TaskCreation from './creation';

const shownSucceeded = declareAction('Tasks/CountStatistic/shownSucceeded');
const shownFailed = declareAction('Tasks/CountStatistic/shownFailed');

export const shown = declareAction(
  'Tasks/CountStatistic/shown',
  async ({ search, filters }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const statistic = await api(authorization).tasks.countStatistic({
        search,
        filters,
      });
      store.dispatch(
        shownSucceeded({
          statistic: deserializeCountStatistic(statistic),
          search,
          filters,
        }),
      );
    } catch (error) {
      console.error(error);

      store.dispatch(shownFailed());
    }
  },
);

const shownTableInitialState = {
  statistic: TASK_TYPE_LIST.map((type) => ({
    taskType: type,
    count: '-',
  })),

  selectedOptions: {
    searchQuery: '',
    filters: [],
  },
};

export const statisticTableAtom = declareAtom(
  'Tasks/CountStatistic/statisticTableAtom',
  shownTableInitialState,
  (on) => [
    on(shown, (state, { searchQuery, filters }) => ({
      statistic: shownTableInitialState.statistic,
      selectedOptions: { filters, searchQuery },
    })),

    on(shownSucceeded, (state, { statistic, searchQuery, filter }) => {
      if (
        searchQuery !== state.selectedOptions.searchQuery ||
        filter !== state.selectedOptions.filter
      ) {
        return state;
      }

      return { ...state, statistic };
    }),

    on(TaskEditing.saveSucceeded, () => shownTableInitialState),
    on(TaskCreation.savedSucceeded, () => shownTableInitialState),

    on(TaskQueue.Resolving.doneSucceeded, () => shownTableInitialState),
    on(TaskQueue.Resuming.doneSucceeded, () => shownTableInitialState),
    on(TaskQueue.Skip.doneSucceeded, () => shownTableInitialState),
    on(TaskQueue.Showing.doneSucceeded, () => shownTableInitialState),
  ],
);

export const statisticListAtom = map(
  statisticTableAtom,
  (table) => table.statistic,
);

const shownLoadingStateAtom = declareAtom(
  'Tasks/CountStatistic/shownLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(shown, () => LOADING_STATE.LOADING),
    on(shownSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(shownFailed, () => LOADING_STATE.FAILED),

    on(TaskEditing.saveSucceeded, () => LOADING_STATE.IDLE),
    on(TaskCreation.savedSucceeded, () => LOADING_STATE.IDLE),

    on(TaskQueue.Resolving.doneSucceeded, () => LOADING_STATE.IDLE),
    on(TaskQueue.Resuming.doneSucceeded, () => LOADING_STATE.IDLE),
    on(TaskQueue.Skip.doneSucceeded, () => LOADING_STATE.IDLE),
    on(TaskQueue.Showing.doneSucceeded, () => LOADING_STATE.IDLE),
  ],
);

export const isShownIdleAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.IDLE,
);

export const isShownFailedAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({
  statisticTableAtom,
  shownLoadingStateAtom,
});
