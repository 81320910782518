import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import { deserializeAdHouseOptions } from '../../normalizers';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';

const checkIsMetaEqual = (meta, payload) =>
  meta.query === payload.query && meta.streetId === payload.streetId;

const shownSucceeded = declareAction('Ads/HouseOptions/shownSucceeded'); // { houses }
const shownFailed = declareAction('Ads/HouseOptions/shownFailed'); // nothing

// { streetId, query }
export const shown = declareAction(
  'Ads/HouseOptions/shown',
  async (payload, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const { options: houses } = await api(
        authorization,
      ).ads.getHousesByStreet(payload.streetId, payload.query);

      const meta = store.getState(getMetaAtom());

      if (checkIsMetaEqual(meta, payload)) {
        store.dispatch(
          shownSucceeded({ houses: deserializeAdHouseOptions(houses) }),
        );
      }
    } catch (e) {
      console.error(e);

      const meta = store.getState(getMetaAtom());

      if (checkIsMetaEqual(meta, payload)) {
        store.dispatch(shownFailed());
      }
    }
  },
);

export const stateReset = declareAction('Ads/HouseOptions/stateReset'); // nothing

const metaAtom = declareAtom(
  'Ads/HouseOptions/metaAtom',
  { streetId: null, query: '' },
  (on) => [on(shown, (state, { streetId, query }) => ({ streetId, query }))],
);

function getMetaAtom() {
  return metaAtom;
}

export const listAtom = declareAtom('Ads/HouseOptions/listAtom', [], (on) => [
  on(shown, () => []),
  on(shownSucceeded, (state, { houses }) => houses),
  on(stateReset, () => []),
]);

const loadingStateAtom = declareAtom(
  'Ads/HouseOptions/loadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(shown, () => LOADING_STATE.LOADING),
    on(shownSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(shownFailed, () => LOADING_STATE.FAILED),
    on(stateReset, () => LOADING_STATE.IDLE),
  ],
);

export const isLoadingAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);

export const isIdleAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.IDLE,
);

export const isFailedAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({
  listAtom,
  metaAtom,
  loadingStateAtom,
});
