export const AD_STATUS = {
  IN_INTERFACE: 'in_interface',
  NOT_IN_INTERFACE: 'not_in_interface',
  FIRST_IN_INTERFACE: 'first_in_interface',
};

export const AD_STATUS_REASON = {
  BANNED: 'banned',
  DELETED: 'deleted',
};

export const BANNED_AD_STATUS = {
  NOT_PASS_FILTERS: 'not_pass_filters',
  NOT_ACTIVE: 'not_active',
  NOT_PASS_POSTFILTERS: 'not_pass_postfilters',
};
