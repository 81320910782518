import { LANGUAGE } from './constants';

const config = {
  sentryClientDns:
    process.env.WEB_APP_SENTRY_CLIENT_DSN ||
    'https://3ab8da817ab183ead55f40695b998529@sentry.bird.rent/10',

  defaultLanguage: process.env.WEB_APP_DEFAULT_LANGUAGE || LANGUAGE.EN,
};

export default config;
