import { Children, cloneElement } from 'react';

import styles from './Row.module.scss';

const AdPageTableRow = ({ columnSizeByName = {}, children }) => (
  <div className={styles.row}>
    {Children.map(children, (child) => {
      const { width, minWidth, isFlexible } =
        columnSizeByName[child.props.name] || {};

      return cloneElement(child, {
        width,
        minWidth,
        isFlexible,
      });
    })}
  </div>
);

export default AdPageTableRow;
