@import '../../styles/utils.scss';

.commentButton {
  @extend %normalize-button;
  color: $primaryBlue;
  @include defaultTransition(color);

  &:hover {
    color: $secondaryBlue;
  }

  &:active {
    color: $primaryBlue;
  }
}
