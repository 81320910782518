@import '../../styles/utils';

.root {
  display: flex;
  overflow: hidden;
}

.userList {
  flex: 0 0 64px;
  overflow: hidden;
}

.userItem {
  margin-bottom: 12px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.userMedia {
  display: inline-block;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 2px;
}

.userName {
  line-height: 1.2;
  font-size: 12px;
  text-align: center;
  @include overflowEllipsis;
}

.axisBottomTrick {
  font-size: 10px;
}

.timeline {
  flex: 1 1 auto;
  overflow: auto;
}

.barRounded {
  @include defaultTransition(opacity);

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  cursor: pointer;
}
