import { defineMessage } from 'react-intl';
import { TASK_IDENTIFIER_TYPE } from './taskIdentifierType';

export const TASK_TYPE = {
  MODERATION: 'moderation',
  AUTHOR_VERIFICATION: 'author_verification',
  GROUP_VERIFICATION: 'group_verification',
  USER_COMPLAINT: 'user_complaint', // not used, but must be saved so that the history of task changes does not break
  CALL: 'call',
  OTHER: 'other',
};

export const TASK_TYPE_INFO = {
  [TASK_TYPE.MODERATION]: {
    type: TASK_TYPE.MODERATION,
    title: defineMessage({
      defaultMessage: 'Moderation',
    }),
    color: '#4880ff',
    identifierType: TASK_IDENTIFIER_TYPE.AD_ID,
  },

  [TASK_TYPE.AUTHOR_VERIFICATION]: {
    type: TASK_TYPE.AUTHOR_VERIFICATION,
    title: defineMessage({
      defaultMessage: 'Author verification',
    }),
    color: '#9868f2',
    identifierType: TASK_IDENTIFIER_TYPE.PHONE_NUMBER,
  },

  [TASK_TYPE.GROUP_VERIFICATION]: {
    type: TASK_TYPE.GROUP_VERIFICATION,
    title: defineMessage({
      defaultMessage: 'Group verification',
    }),
    color: '#f5525c',
    identifierType: TASK_IDENTIFIER_TYPE.GROUP_ID,
  },

  [TASK_TYPE.CALL]: {
    type: TASK_TYPE.CALL,
    title: defineMessage({
      defaultMessage: 'Call',
    }),
    color: '#27cba1',
    identifierType: TASK_IDENTIFIER_TYPE.AD_ID,
  },

  [TASK_TYPE.USER_COMPLAINT]: {
    type: TASK_TYPE.USER_COMPLAINT,
    title: defineMessage({
      defaultMessage: 'Complaint',
    }),
    color: '#fbc000',
  },

  [TASK_TYPE.OTHER]: {
    type: TASK_TYPE.OTHER,
    title: defineMessage({
      defaultMessage: 'Other',
    }),
    color: '#00fbb8',
  },
};

export const TASK_TYPE_LIST = [
  TASK_TYPE.MODERATION,
  TASK_TYPE.AUTHOR_VERIFICATION,
  TASK_TYPE.GROUP_VERIFICATION,
  TASK_TYPE.CALL,
  TASK_TYPE.USER_COMPLAINT,
  TASK_TYPE.OTHER,
];
