import TaskLabel from '../../../../TaskLabel/TaskLabel';
import AutomaticTaskLabel from '../../../../AutomaticTaskLabel/AutomaticTaskLabel';
import ComplaintTaskLabel from '../../../../ComplaintTaskLabel/ComplaintTaskLabel';

import styles from './TitleAppended.module.scss';

const TaskEditingTitleAppended = ({
  taskId,
  taskType,
  isTaskAutomatic,
  isTaskComplaint,
}) => (
  <div className={styles.root}>
    <span>#{taskId}</span>

    <span>
      {isTaskAutomatic && (
        <span className={styles.labelItem}>
          <AutomaticTaskLabel />
        </span>
      )}

      {isTaskComplaint && (
        <span className={styles.labelItem}>
          <ComplaintTaskLabel />
        </span>
      )}

      <span className={styles.labelItem}>
        <TaskLabel type={taskType} />
      </span>
    </span>
  </div>
);

export default TaskEditingTitleAppended;
