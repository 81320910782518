import { combine, declareAction, declareAtom, map } from '@reatom/core';

import api from '../../services/api';
import { deserializeAdStatus } from '../../normalizers';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';

export const doneSucceeded = declareAction('Ads/Removing/doneSucceeded');
const doneFailed = declareAction('Ads/Removing/doneFailed');

export const done = declareAction(
  'Ads/Removing/done',
  async ({ adId, reason }, store) => {
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const adStatus = await api(authorization).ads.removeAd(adId, reason);
      store.dispatch(
        doneSucceeded({ adId, adStatus: deserializeAdStatus(adStatus) }),
      );
    } catch (e) {
      console.error(e);

      store.dispatch(
        doneFailed({
          adId,
        }),
      );
    }
  },
);

const loadingStateAtom = declareAtom(
  'Ads/Reprocessing/loadingStateAtom',
  {},
  (on) => [
    on(done, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.LOADING,
    })),
    on(doneSucceeded, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.SUCCEEDED,
    })),
    on(doneFailed, (state, { adId }) => ({
      ...state,
      [adId]: LOADING_STATE.FAILED,
    })),
  ],
);

export const makeLoadingStateAtom = (adId) =>
  map(loadingStateAtom, (lsTable) => lsTable[adId] || LOADING_STATE.IDLE);

export const makeIsLoadingAtom = (adId) =>
  map(makeLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.LOADING);

export const makeIsFailedAtom = (adId) =>
  map(makeLoadingStateAtom(adId), (ls) => ls === LOADING_STATE.FAILED);

export default combine({ loadingStateAtom });
