import { combine } from '@reatom/core';

import entityIdAtom from './entityId';
import resolvingAtom from './resolving';
import resumingAtom from './resuming';
import showingAtom from './showing';
import skipAtom from './skip';
import isStoppedAtom from './isStopped';

export * as EntityId from './entityId';
export * as Resolving from './resolving';
export * as Resuming from './resuming';
export * as Showing from './showing';
export * as Skip from './skip';
export * as IsStopped from './isStopped';

export default combine({
  entityIdAtom,
  resolvingAtom,
  resumingAtom,
  showingAtom,
  skipAtom,
  isStoppedAtom,
});
