import PagesScene from '../PagesScene/PagesScene';
import Dialogs from './Dialogs/Dialogs';

const App = () => (
  <>
    <PagesScene />
    <Dialogs />
  </>
);

export default App;
