import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Label from '../Label/Label';

import { TASK_TYPE_INFO } from '../../constants';

const TaskTypeLabel = ({ type }) => {
  const taskTypeInfo = useMemo(() => TASK_TYPE_INFO[type], [type]);

  if (!taskTypeInfo) {
    return null;
  }

  return (
    <Label
      title={<FormattedMessage {...taskTypeInfo.title} />}
      backgroundColor={taskTypeInfo.color}
    />
  );
};

export default TaskTypeLabel;
