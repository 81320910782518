import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { fetchMessages } from '../services/messages';
import { LOADING_STATE } from '../constants';
import config from '../config';

const getLanguageInitialState = () =>
  window.localStorage.getItem('language') || config.defaultLanguage;

const messagesLoadedFailed = declareAction('Localization/messagesLoaded'); // { language }

// { language, messages }
const messagesLoadedSucceeded = declareAction(
  'Localization/messagesLoadedSucceeded',
);

// { language }
export const messagesLoaded = declareAction(
  'Localization/messagesLoaded',
  async ({ language }, store) => {
    try {
      const messages = await fetchMessages(language);
      store.dispatch(messagesLoadedSucceeded({ language, messages }));
    } catch (error) {
      console.error(error);
      store.dispatch(messagesLoadedFailed({ language }));
    }
  },
);

// { language }
const languageChangedSucceeded = declareAction(
  'Localization/languageChangedSucceeded',
);

// { language }
const languageChangedFailed = declareAction(
  'Localization/languageChangedFailed',
);

// { language }
export const languageChanged = declareAction(
  'Localization/languageChangedSucceeded',
  async ({ language }, store) => {
    const isMessagesLoading = store.getState(
      makeIsMessagesLoadingAtom(language),
    );

    const isMessagesReady = store.getState(
      makeIsMessagesSucceededAtom(language),
    );

    if (isMessagesLoading || isMessagesReady) {
      store.dispatch(languageChangedSucceeded({ language }));
      window.localStorage.setItem('language', language);
      return;
    }

    try {
      const proposalLanguage = store.getState(getProposalLanguageAtom());

      if (proposalLanguage !== language) {
        return;
      }

      const messages = await fetchMessages(language);
      store.dispatch(messagesLoadedSucceeded({ language, messages }));
      store.dispatch(languageChangedSucceeded({ language }));
      window.localStorage.setItem('language', language);
    } catch (error) {
      const proposalLanguage = store.getState(getProposalLanguageAtom());

      if (proposalLanguage !== language) {
        return;
      }

      console.error(error);
      store.dispatch(messagesLoadedFailed({ language }));
      store.dispatch(languageChangedFailed({ language }));
    }
  },
);

export const messagesAtom = declareAtom('Messages/tableAtom', {}, (on) => [
  on(messagesLoaded, (state, { language }) => ({ ...state, [language]: {} })),
  on(messagesLoadedSucceeded, (state, { language, messages }) => ({
    ...state,
    [language]: messages,
  })),
]);

export const makeMessagesAtom = (language) =>
  map(messagesAtom, (table) => table[language] || {});

const messagesLoadingStateAtom = declareAtom(
  'Messages/loadingStateAtom',
  {},
  (on) => [
    on(messagesLoaded, (state, { language }) => ({
      ...state,
      [language]: LOADING_STATE.LOADING,
    })),
    on(messagesLoadedSucceeded, (state, { language }) => ({
      ...state,
      [language]: LOADING_STATE.SUCCEEDED,
    })),
    on(messagesLoadedFailed, (state, { language }) => ({
      ...state,
      [language]: LOADING_STATE.FAILED,
    })),
  ],
);

const makeMessageLoadingStateAtom = (language) =>
  map(
    messagesLoadingStateAtom,
    (table) => table[language] || LOADING_STATE.IDLE,
  );

function makeIsMessagesLoadingAtom(language) {
  return map(
    makeMessageLoadingStateAtom(language),
    (ls) => ls === LOADING_STATE.LOADING,
  );
}

function makeIsMessagesSucceededAtom(language) {
  return map(
    makeMessageLoadingStateAtom(language),
    (ls) => ls === LOADING_STATE.SUCCEEDED,
  );
}

export const makeIsMessagesFailedAtom = (language) =>
  map(
    makeMessageLoadingStateAtom(language),
    (ls) => ls === LOADING_STATE.FAILED,
  );

export const languageAtom = declareAtom(
  'Localization/languageAtom',
  getLanguageInitialState(),
  (on) => [on(languageChangedSucceeded, (state, { language }) => language)],
);

export const proposalLanguageAtom = declareAtom(
  'Localization/proposalLanguageAtom',
  '',
  (on) => [
    on(messagesLoaded, (state, { language }) => language),
    on(messagesLoadedSucceeded, () => ''),
    on(messagesLoadedFailed, () => ''),
    on(languageChanged, (state, { language }) => language),
    on(languageChangedSucceeded, () => ''),
    on(languageChangedFailed, () => ''),
  ],
);

function getProposalLanguageAtom() {
  return proposalLanguageAtom;
}

export default combine({
  messagesAtom,
  messagesLoadingStateAtom,
  languageAtom,
});
