import styles from './Appended.module.scss';

const DashboardPageAppended = ({
  siteSelect,
  sectionSelect,
  regionSelect,
  calendar,
}) => (
  <div className={styles.root}>
    <div className={styles.item}>{siteSelect}</div>
    <div className={styles.item}>{sectionSelect}</div>
    <div className={styles.item}>{regionSelect}</div>
    <div className={styles.item}>{calendar}</div>
  </div>
);

export default DashboardPageAppended;
