import { Children, cloneElement } from 'react';

import AccordionItem from './Item/Item';

const Accordion = ({
  children = [],
  openedList,
  onToggle,
  onOpeningAnimationEnd,
  ...rest
}) =>
  Children.map(children, (child) =>
    cloneElement(child, {
      openedList,
      onToggle,
      onOpeningAnimationEnd,
      ...rest,
    }),
  );

Accordion.Item = AccordionItem;
export default Accordion;
