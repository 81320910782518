import { useCallback, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Page from '../../components/Page/Page';
import DefaultPageLayout from '../../components/DefaultPageLayout/DefaultPageLayout';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import CalendarDropdown from '../../components/CalendarDropdown/CalendarDropdown';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import DashboardPageAppended from './Appended/Appended';
import AreaClosedPlate from './AreaClosedPlate/AreaClosedPlate';
import LoadingStatePlaceholder from './LoadingStatePlaceholder/LoadingStatePlaceholder';
import StatisticLoader from './StatisticLoader/StatisticLoader';
import DashboardPageContentGrid from './ContentGrid/ContentGrid';

import { ROLE_TYPE } from '../../constants';
import withRoles from '../../components/PagesScene/withRoles';
import useCalendar from '../../components/CalendarDropdown/Calendar/useCalendar';
import useCities from './useCities';
import useSites from './useSites';
import useStatistic from './useStatistic';
import useGeneralOptions from './useGeneralOptions';
import SECTION_OPTIONS from './sectionOptions';

const DATA_COLLECTION_START_DATE = new Date('2021-09-09');

const DashboardPage = () => {
  const intl = useIntl();

  const { locationGeneralOption, siteGeneralOption, sectionGeneralOption } =
    useGeneralOptions();

  const sectionOptions = useMemo(
    () =>
      SECTION_OPTIONS.map(({ value, title }) => ({
        value,
        title: intl.formatMessage(title),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage],
  );

  const [{ dateRange, minDate, maxDate }, onDateChange] = useCalendar({
    minDate: DATA_COLLECTION_START_DATE,
  });

  const { cities, isCitiesLoading } = useCities();
  const [cityId, setCityId] = useState(locationGeneralOption.value);
  const cityOptions = cities.map(({ id, title }) => ({ value: id, title }));
  const handleCityIdChange = useCallback((cityId) => setCityId(cityId), []);

  const { sites, isSitesLoading } = useSites();
  const [siteId, setSiteId] = useState(siteGeneralOption.value);
  const sitesOptions = useMemo(
    () =>
      sites
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(({ id, title }) => ({ value: id, title })),
    [sites],
  );
  const handleSiteIdChange = useCallback((siteId) => setSiteId(siteId), []);

  const [section, setSection] = useState(sectionGeneralOption.value);
  const handleSectionChange = useCallback((section) => setSection(section), []);

  const {
    statistic,
    isStatisticSucceeded,
    isStatisticLoading,
    isStatisticFailed,
  } = useStatistic({
    dateRange,
    siteId,
    cityId,
    section,
  });

  return (
    <FormattedMessage defaultMessage="Dashboard">
      {([helmetTitle]) => (
        <Page helmetTitle={helmetTitle}>
          <DefaultPageLayout>
            <DefaultPageLayout.WideCol>
              <PageMainContentTile
                title={<FormattedMessage defaultMessage="Dashboard" />}
                titleAppended={
                  <DashboardPageAppended
                    sectionSelect={
                      <SelectDropdown
                        generalOption={sectionGeneralOption}
                        options={sectionOptions}
                        selectedValue={section}
                        onChange={handleSectionChange}
                      />
                    }
                    siteSelect={
                      <SelectDropdown
                        generalOption={siteGeneralOption}
                        options={sitesOptions}
                        selectedValue={siteId}
                        isLoading={isSitesLoading}
                        onChange={handleSiteIdChange}
                      />
                    }
                    regionSelect={
                      <SelectDropdown
                        generalOption={locationGeneralOption}
                        options={cityOptions}
                        selectedValue={cityId}
                        isLoading={isCitiesLoading}
                        onChange={handleCityIdChange}
                      />
                    }
                    calendar={
                      <CalendarDropdown
                        dateRange={dateRange}
                        minDate={minDate}
                        maxDate={maxDate}
                        onDateChange={onDateChange}
                      />
                    }
                  />
                }
              >
                <LoadingStatePlaceholder
                  isLoading={isStatisticLoading}
                  isSucceeded={isStatisticSucceeded}
                  isFailed={isStatisticFailed}
                  loading={<StatisticLoader />}
                  failed={
                    <FormattedMessage defaultMessage="Statistics is unavailable" />
                  }
                >
                  <DashboardPageContentGrid>
                    <DashboardPageContentGrid.Column
                      title={
                        <FormattedMessage defaultMessage="Advertisements" />
                      }
                    >
                      <DashboardPageContentGrid.Item>
                        <AreaClosedPlate
                          title={<FormattedMessage defaultMessage="Total" />}
                          stock={statistic.ads}
                        />
                      </DashboardPageContentGrid.Item>

                      <DashboardPageContentGrid.Item>
                        <AreaClosedPlate
                          title={
                            <FormattedMessage defaultMessage="House/HCA not recognized" />
                          }
                          stock={statistic.notSpecifiedBuildingAds}
                        />
                      </DashboardPageContentGrid.Item>

                      <DashboardPageContentGrid.Item>
                        <AreaClosedPlate
                          title={
                            <FormattedMessage defaultMessage="Settlement is not identified" />
                          }
                          stock={statistic.notSpecifiedLocalityAds}
                        />
                      </DashboardPageContentGrid.Item>
                    </DashboardPageContentGrid.Column>

                    <DashboardPageContentGrid.Column
                      title={<FormattedMessage defaultMessage="Groups" />}
                    >
                      <DashboardPageContentGrid.Item>
                        <AreaClosedPlate
                          title={<FormattedMessage defaultMessage="Total" />}
                          stock={statistic.adGroups}
                        />
                      </DashboardPageContentGrid.Item>

                      <DashboardPageContentGrid.Item>
                        <AreaClosedPlate
                          title={
                            <FormattedMessage defaultMessage="House/HCA not recognized" />
                          }
                          stock={statistic.notSpecifiedBuildingAdGroups}
                        />
                      </DashboardPageContentGrid.Item>

                      <DashboardPageContentGrid.Item>
                        <AreaClosedPlate
                          title={
                            <FormattedMessage defaultMessage="Settlement is not identified" />
                          }
                          stock={statistic.notSpecifiedLocalityAdGroups}
                        />
                      </DashboardPageContentGrid.Item>
                    </DashboardPageContentGrid.Column>
                  </DashboardPageContentGrid>
                </LoadingStatePlaceholder>
              </PageMainContentTile>
            </DefaultPageLayout.WideCol>
          </DefaultPageLayout>
        </Page>
      )}
    </FormattedMessage>
  );
};

DashboardPage.Roles = [ROLE_TYPE.OWNER];
DashboardPage.RouteParams = withRoles(DashboardPage.Roles)({
  element: <DashboardPage />,
  path: '/dashboard',
});

export default DashboardPage;
