import { useEffect, useMemo } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Users from '../../models/users';
import { useExecutorsByTaskType, useNotifications } from '../../hooks';

const useExecutors = (taskType) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const activeUsers = useAtom(Users.Showing.activeUsersAtom);
  const isAllUsersSucceeded = useAtom(Users.Showing.isUsersSucceededAtom);
  const isAllUsersLoading = useAtom(Users.Showing.isUsersLoadingAtom);
  const isAllUsersFailed = useAtom(Users.Showing.isUsersFailedAtom);
  const handleUsersShow = useAction(Users.Showing.done);

  useEffect(() => {
    if (!isAllUsersFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load assignees. Try reloading the page',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllUsersFailed, intl.formatMessage, showErrorNotification]);

  const {
    executors: executorsByType,
    isExecutorsLoading: isExecutorsByTypeLoading,
  } = useExecutorsByTaskType(taskType);

  useEffect(() => {
    if (!isAllUsersSucceeded && !isAllUsersLoading) {
      handleUsersShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUsersShow]);

  const executorsOptions = useMemo(() => {
    const executors = taskType ? executorsByType : activeUsers;
    return executors.map(({ id, name }) => ({ value: id, label: name }));
  }, [taskType, executorsByType, activeUsers]);

  return {
    executorsOptions,
    isExecutorOptionsLoading: taskType
      ? isExecutorsByTypeLoading
      : isAllUsersLoading,
  };
};

export default useExecutors;
