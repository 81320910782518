import styles from './TitleAppended.module.scss';

const AdPageTitleAppended = ({ adId, relatedResourcesLinks }) => (
  <div className={styles.root}>
    <div className={styles.adId}>#{adId}</div>

    {relatedResourcesLinks && (
      <div className={styles.relatedResources}>{relatedResourcesLinks}</div>
    )}
  </div>
);

export default AdPageTitleAppended;
