import Loader from '../../../components/Loader/Loader';
import styles from './StatisticLoader.module.scss';

const StatisticOverviewLoader = () => (
  <div className={styles.root}>
    <Loader />
  </div>
);

export default StatisticOverviewLoader;
