import { Link } from 'react-router-dom';
import IconContainer from '../../../../components/IconContainer/IconContainer';

import styles from './Link.module.scss';

const AdRelatedResourceLinkTemplate = ({ icon, title, ...rest }) => {
  const LinkComponent = rest.to ? Link : 'a';
  const derivativeProps = rest.to ? {} : { target: '_blank' };

  return (
    <LinkComponent className={styles.root} {...rest} {...derivativeProps}>
      {icon && (
        <span className={styles.icon}>
          <IconContainer size="small">{icon}</IconContainer>
        </span>
      )}

      <span className={styles.title}>{title}</span>
    </LinkComponent>
  );
};

export default AdRelatedResourceLinkTemplate;
