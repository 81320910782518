import IconContainer from '../IconContainer/IconContainer';
import NoResultsPlaceholderImage from './Image/Image';

import styles from './NoResultsPlaceholder.module.scss';

const NoResultsPlaceholder = ({ title, description }) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>

      {description && <div className={styles.description}>{description}</div>}

      <div className={styles.image}>
        <IconContainer size="inline">
          <NoResultsPlaceholderImage />
        </IconContainer>
      </div>
    </div>
  </div>
);

export default NoResultsPlaceholder;
