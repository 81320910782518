.root {
  height: 220px; // height of charts
}

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
