import Svg from '../Svg/Svg';

const IconDelete = () => (
  <Svg>
    <path fill="none" fillRule="evenodd" d="M0 0h24v24H0z" />
    <path
      fillRule="nonzero"
      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"
    />
  </Svg>
);

export default IconDelete;
