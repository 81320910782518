@import '../../styles/utils';
@import '../Field/Field.module.scss';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  flex: 0 0 auto;
  margin-bottom: $labelBottom;
  color: $grey_3;
}

.content {
  flex: 1 1 auto;
}
