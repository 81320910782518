import { useRef, useEffect } from 'react';

const useScrollToBottomOnHistoryChange = (messageList) => {
  const listRef = useRef();
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    if (!messageList.length) {
      return;
    }

    if (!listRef.current || isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [messageList.length]);

  return listRef;
};

export default useScrollToBottomOnHistoryChange;
