import { defineMessage } from 'react-intl';

export const SEARCH_ENTITY_TYPE = {
  AD_EXTERNAL_LINK: 'ad_link',
  AD_ID: 'ad_id',
  AD_GROUP_ID: 'ad_group_id',
  PHONE: 'phone',
};

export const SEARCH_ENTITY_TYPE_INFO = {
  [SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK]: {
    slug: SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK,
    title: defineMessage({
      defaultMessage: 'External link',
    }),
  },

  [SEARCH_ENTITY_TYPE.AD_ID]: {
    slug: SEARCH_ENTITY_TYPE.AD_ID,
    title: defineMessage({
      defaultMessage: 'Ad id',
    }),
  },

  [SEARCH_ENTITY_TYPE.AD_GROUP_ID]: {
    slug: SEARCH_ENTITY_TYPE.AD_GROUP_ID,
    title: defineMessage({
      defaultMessage: 'Group id',
    }),
  },

  [SEARCH_ENTITY_TYPE.PHONE]: {
    slug: SEARCH_ENTITY_TYPE.PHONE,
    title: defineMessage({
      defaultMessage: 'Phone number',
    }),
  },
};

export default SEARCH_ENTITY_TYPE;
