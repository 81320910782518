import { BrowserRouter } from 'react-router-dom';
import { context as ReatomContext } from '@reatom/react';

import NotificationsProvider from './providers/NotificationsProvider/NotificationsProvider';
import AuthProvider from './providers/AuthProvider/AuthProvider';
import TaskOverviewDialogsProvider from './providers/TaskOverviewDialogsProvider';
import QueryParamsProvider from './providers/QueryParamsProvider';
import IntlProvider from './providers/IntlProvider';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import DefaultErrorFallback from './components/DefaultErrorFallback/DefaultErrorFallback';

const AppProviders = ({ App, store }) => (
  <ReatomContext.Provider value={store}>
    <BrowserRouter>
      <NotificationsProvider>
        <IntlProvider>
          <QueryParamsProvider>
            <AuthProvider>
              <TaskOverviewDialogsProvider>
                <ErrorBoundary fallback={<DefaultErrorFallback />}>
                  <App />
                </ErrorBoundary>
              </TaskOverviewDialogsProvider>
            </AuthProvider>
          </QueryParamsProvider>
        </IntlProvider>
      </NotificationsProvider>
    </BrowserRouter>
  </ReatomContext.Provider>
);

export default AppProviders;
