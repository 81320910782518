.root {
  display: inline-block;
  width: 100%;

  &_adjustable {
    height: 100%;
  }
}

.element {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.root_adjustable {
  .element {
    height: 100%;
    object-fit: cover;
  }
}
