@import '../../../../styles/utils';

.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  border-radius: $primaryBorderRadius;
  background-color: #f2f2f7;
  text-decoration: none;
  outline: none;
  @include defaultTransition(background-color);

  &:hover,
  &:focus {
    background-color: $primaryBlue;
  }

  &:active {
    background-color: #f2f2f7;
  }
}

.icon {
  margin-right: 6px;
  color: $primaryBlue;
  @include defaultTransition(color);
}

.root:hover,
.root:focus {
  .icon {
    color: $white;
  }
}

.root:active {
  .icon {
    color: $primaryBlue;
  }
}

.title {
  color: $black;
  font-size: 12px;
  line-height: 1;
  @include defaultTransition(color);
}

.root:hover,
.root:focus {
  .title {
    color: $white;
  }
}

.root:active {
  .title {
    color: $black;
  }
}
