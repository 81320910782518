@import '../../styles/utils';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 46px;
  padding: 0 24px;
  padding-right: 8px;
  padding-left: 16px;
  border-radius: $primaryBorderRadius;
  background-color: $white;
  @include defaultTransition(background-color);

  &_grey {
    background-color: $grey_1;
  }

  &_highlighted {
    background-color: rgba($primaryBlue, 0.2);
  }
}

.element {
  @extend %normalize-input;
  flex: 1 1 auto;
  padding: 0 8px;

  &::placeholder {
    color: $grey_3;
  }
}

.prepended {
  flex: 0 0 auto;
  max-height: 100%;
}

.appended {
  flex: 0 0 auto;
  max-height: 100%;
}
