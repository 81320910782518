@import '../../styles/utils';

.root {
  @extend %normalize-button;
  text-decoration: underline;
  color: $primaryBlue;
  word-break: break-word;
  text-align: left;
  @include defaultTransition(color);

  &:hover {
    color: $secondaryBlue;
  }

  &_expanded {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
}
