import TileSection from '../../../../components/TileSection/TileSection';
import AdDetailsModeratedLabel from './ModeratedLabel/ModeratedLabel';

import styles from './TileSection.module.scss';

const AdDetailsTileSection = ({ children, isModerated, ...rest }) => (
  <TileSection
    {...rest}
    titleAppended={
      isModerated && (
        <div className={styles.titleAppended}>
          <AdDetailsModeratedLabel />
        </div>
      )
    }
    isGrey
  >
    <div className={styles.root}>{children}</div>
  </TileSection>
);

export default AdDetailsTileSection;
