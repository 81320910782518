.item {
  display: flex;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
}

.itemTitle {
  flex: 0 0 auto;
  margin-right: 4px;
  font-weight: 700;
}
