import { combine } from '@reatom/core';

import overviewAtom from './overview';
import citiesAtom from './cities';
import sitesAtom from './sites';

export * as Overview from './overview';
export * as Cities from './cities';
export * as Sites from './sites';

export default combine({
  overviewAtom,
  citiesAtom,
  sitesAtom,
});
