import { useMemo } from 'react';
import { nanoid } from 'nanoid';

import AnimateHeightFromZero from '../../AnimateHeightFromZero/AnimateHeightFromZero';
import RoundIconButton from '../../RoundIconButton/RoundIconButton';
import { IconAngleArrowDown, IconAngleArrowUp } from '../../icons';

import { useEventHandler } from '../../../hooks';
import styles from './Item.module.scss';

const AccordionItem = ({
  name,
  label,
  number,
  children,
  openedList,
  openingAnimationDuration = 400,
  onToggle,
  onOpeningAnimationEnd,
}) => {
  const isOpened = useMemo(() => openedList.includes(name), [openedList, name]);
  const ariaControlsId = useMemo(() => `accordion-item-${nanoid()}`, []);

  const handleClick = useEventHandler(() => onToggle(name), [name, onToggle]);

  const handleAnimationEnd = useEventHandler(() => {
    if (isOpened && typeof onOpeningAnimationEnd === 'function') {
      onOpeningAnimationEnd(name);
    }
  }, [name, isOpened, onOpeningAnimationEnd]);

  return (
    <div id={name} className={styles.root}>
      <div>
        <button
          className={styles.button}
          aria-expanded={isOpened}
          aria-controls={ariaControlsId}
          onClick={handleClick}
        >
          <span className={styles.buttonLabel}>{label}</span>

          <span>
            <RoundIconButton
              component="span"
              icon={
                <span className={styles.hoverSpreader}>
                  {isOpened ? <IconAngleArrowUp /> : <IconAngleArrowDown />}
                </span>
              }
              isSmall
            />
          </span>
        </button>
      </div>

      <AnimateHeightFromZero
        duration={openingAnimationDuration}
        id={ariaControlsId}
        isOpened={isOpened}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className={styles.content}>{children}</div>
      </AnimateHeightFromZero>
    </div>
  );
};

export default AccordionItem;
