export const HISTORY_INTERACTION_TYPE = {
  CREATION: 'CREATION',
  FIELD_CHANGE: 'FIELD_CHANGE',
  COMMENT: 'COMMENT',
  SKIP: 'SKIP',
  RESOLVING: 'RESOLVING',
};

export const CHANGED_FIELD_TYPE = {
  TASK_TYPE: 'taskType',
  TASK_SUB_TYPE: 'taskSubType',
  ZENDESK_URL: 'zendeskUrl',
  EXECUTOR: 'executor',
  PRIORITY: 'priority',
  SOLUTION: 'solution',
  ADDITIONAL_RESOURCES: 'additionalResources',
};
