import { forwardRef } from 'react';
import cx from 'classnames';
import IconContainer from '../IconContainer/IconContainer';
import styles from './RoundIconButton.module.scss';

const RoundIconButton = forwardRef(
  (
    {
      component: Component = 'button',
      variant,
      icon,
      iconSize = 'xsmall',
      isSmall,
      isDisabled,
      disabled,
      ...rest
    },
    ref,
  ) => (
    <Component
      type="button"
      className={cx(styles.root, styles[`root_variant--${variant}`], {
        [styles.root_small]: isSmall,
      })}
      disabled={isDisabled || disabled}
      ref={ref}
      {...rest}
    >
      <IconContainer size={iconSize}>{icon}</IconContainer>
    </Component>
  ),
);

RoundIconButton.displayName = 'RoundIconButton';

export default RoundIconButton;
