import { declareAtom, map, combine } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import * as Account from '../account';
import * as TaskEditing from '../tasks/editing';
import * as TaskCreation from '../tasks/creation';
import * as TaskRecreation from '../tasks/recreation';
import * as TaskQueueResolving from './resolving';
import * as TaskQueueResuming from './resuming';
import * as TaskQueueShowing from './showing';
import * as TaskQueueSkip from './skip';

export const TASK_QUEUE_ERROR_TYPE = {
  EMPTY_QUEUE: 'EMPTY_QUEUE',
  STOPPED_QUEUE: 'STOPPED_QUEUE',
  NEED_TASK_TYPE_OR_EXECUTOR_CHANGES: 'NEED_TASKTYPE_OR_EXECUTOR_CHANGES',
  DEFAULT: 'DEFAULT',
};

export const valueAtom = declareAtom('TaskQueue/EntityId/value', null, (on) => [
  on(TaskQueueShowing.done, () => null),
  on(TaskQueueShowing.doneSucceeded, (state, { task }) => task?.id || null),

  on(TaskQueueResolving.done, () => null),
  on(TaskQueueResolving.doneSucceeded, (state, { task }) => task?.id || null),

  on(TaskQueueResuming.done, () => null),
  on(TaskQueueResuming.doneSucceeded, (state, { task }) => task?.id || null),

  on(TaskQueueSkip.done, () => null),
  on(TaskQueueSkip.doneSucceeded, (state, { task }) => task?.id || null),

  on(TaskEditing.saveSucceeded, () => null),
  on(TaskCreation.savedSucceeded, () => null),
  on(TaskRecreation.doneSucceeded, () => null),
  on(Account.logOut, () => null),
]);

export const loadingStateAtom = declareAtom(
  'TaskQueue/EntityId/loadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(TaskQueueResolving.done, () => LOADING_STATE.LOADING),
    on(TaskQueueResolving.doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(TaskQueueResolving.doneFailed, () => LOADING_STATE.FAILED),

    on(TaskQueueResuming.done, () => LOADING_STATE.LOADING),
    on(TaskQueueResuming.doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(TaskQueueResuming.doneFailed, () => LOADING_STATE.FAILED),

    on(TaskQueueShowing.done, () => LOADING_STATE.LOADING),
    on(TaskQueueShowing.doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(TaskQueueShowing.doneFailed, () => LOADING_STATE.FAILED),

    on(TaskQueueSkip.done, () => LOADING_STATE.LOADING),
    on(TaskQueueSkip.doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(TaskQueueSkip.doneFailed, () => LOADING_STATE.FAILED),

    on(TaskEditing.saveSucceeded, () => LOADING_STATE.IDLE),
    on(TaskCreation.savedSucceeded, () => LOADING_STATE.IDLE),
    on(TaskRecreation.doneSucceeded, () => LOADING_STATE.IDLE),
    on(Account.logOut, () => LOADING_STATE.IDLE),
  ],
);

export const isLoadingAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);

export const isSucceededAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.SUCCEEDED,
);

export const isFailedAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export const isReadyAtom = map(
  combine([isSucceededAtom, isFailedAtom]),
  ([isSucceeded, isFailed]) => isSucceeded || isFailed,
);

export default combine({ valueAtom, loadingStateAtom });
