import cx from 'classnames';
import { useRef, useEffect, cloneElement } from 'react';
import { useTransition, animated } from 'react-spring';
import contains from 'dom-helpers/contains';

import { useEventHandler } from '../../hooks';
import styles from './Dropdown.module.scss';

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 25,
  duration: 250,
};

const Dropdown = ({
  trigger,
  children,
  horizontalAlignment = 'left',
  verticalPosition = 'bottom',
  isOpened,
  onClose,
}) => {
  const transitions = useTransition(isOpened, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: springConfig,
  });

  const rootNodeRef = useRef();
  const triggerRef = useRef();

  const handleWindowClick = useEventHandler(
    (e) => {
      if (!isOpened) {
        return;
      }

      if (
        !contains(document.body, e.target) ||
        contains(rootNodeRef.current, e.target) ||
        contains(triggerRef.current, e.target)
      ) {
        return;
      }

      onClose();
    },
    [isOpened, onClose],
  );

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);

    return () => window.removeEventListener('click', handleWindowClick);
  }, [handleWindowClick]);

  return (
    <div className={styles.root}>
      {cloneElement(trigger, { ref: triggerRef })}

      {transitions.map(
        ({ item: opened, key, props }) =>
          opened && (
            <animated.div
              key={key}
              className={cx(
                styles.dropdown,
                styles[`dropdown__horizontalAlignment_${horizontalAlignment}`],
                styles[`dropdown__verticalPosition_${verticalPosition}`],
              )}
              style={{ opacity: props.opacity }}
              ref={rootNodeRef}
            >
              {children}
            </animated.div>
          ),
      )}
    </div>
  );
};

export default Dropdown;
