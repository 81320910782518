import { combine, declareAction, declareAtom, map } from '@reatom/core';
import { nanoid } from 'nanoid';

import { DEFAULT_AVATAR_MEDIA, LOADING_STATE } from '../../constants';
import api from '../../services/api';
import * as Account from '../account';

export const handleSaved = declareAction('Users/Creation/handleSaved');
export const savedSucceeded = declareAction('Users/Creation/savedSucceeded');
export const savedFailed = declareAction('Users/Creation/savedFailed');

export const saved = declareAction(
  'Users/Creation/saved',
  async (creatingUser, store) => {
    const dummyId = nanoid();
    store.dispatch(
      handleSaved({
        ...creatingUser,
        dummyId,
        media: DEFAULT_AVATAR_MEDIA,
      }),
    );

    const { name, email, isActive, role, taskTypes } = creatingUser;
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const { userId } = await api(authorization).users.add({
        name,
        email,
        isActive,
        role,
        taskTypes,
      });

      store.dispatch(savedSucceeded({ userId, dummyId }));
    } catch (error) {
      console.error(error);

      store.dispatch(
        savedFailed({
          dummyId,
        }),
      );
    }
  },
);

const savedLoadingStateAtom = declareAtom(
  'Users/Creation/savedLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(saved, () => LOADING_STATE.LOADING),
    on(savedSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(savedFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isSavedFailedAtom = map(
  savedLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({ savedLoadingStateAtom });
