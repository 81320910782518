import cx from 'classnames';
import styles from './IconContainer.module.scss';

const IconContainer = ({ children, size = 'medium', icon, ...rest }) => (
  <span className={cx(styles.root, styles[`root_size--${size}`])} {...rest}>
    {icon || children}
  </span>
);

export default IconContainer;
