import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button/Button';
import AstronautImage from './AstronautImage';

import styles from './DefaultErrorFallback.module.scss';

const DefaultErrorFallback = () => {
  const handleReloadButtonClick = useCallback(() => {
    document.location.reload();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>
          <FormattedMessage defaultMessage="Oops!" />
        </div>

        <div className={styles.subtitle}>
          <FormattedMessage defaultMessage="Something went wrong(" />
        </div>

        <div className={styles.image}>
          <AstronautImage />
        </div>

        <div>
          <Button
            title={<FormattedMessage defaultMessage="Refresh page" />}
            onClick={handleReloadButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultErrorFallback;
