import IconContainer from '../IconContainer/IconContainer';
import styles from './Label.module.scss';

const Label = ({
  title,
  backgroundColor = '#afb5c4',
  color = '#fff',
  icon,
}) => (
  <div className={styles.root} style={{ backgroundColor, color }}>
    {icon && (
      <div className={styles.icon}>
        <IconContainer size="xsmall" icon={icon} />
      </div>
    )}

    <div className={styles.title}>{title}</div>
  </div>
);

export default Label;
