import { defineMessage } from 'react-intl';

export const TASK_IDENTIFIER_TYPE = {
  AD_ID: 'page',
  GROUP_ID: 'group',
  PHONE_NUMBER: 'phone',
};

export const TASK_IDENTIFIER_TYPE_INFO = {
  [TASK_IDENTIFIER_TYPE.AD_ID]: {
    slug: TASK_IDENTIFIER_TYPE.AD_ID,
    title: defineMessage({
      defaultMessage: 'Ad id',
    }),
    minNumbersAmount: 9,
    maxNumbersAmount: 10,
  },

  [TASK_IDENTIFIER_TYPE.GROUP_ID]: {
    slug: TASK_IDENTIFIER_TYPE.GROUP_ID,
    title: defineMessage({
      defaultMessage: 'Group id',
    }),
    minNumbersAmount: 17,
    maxNumbersAmount: 18,
  },

  [TASK_IDENTIFIER_TYPE.PHONE_NUMBER]: {
    slug: TASK_IDENTIFIER_TYPE.PHONE_NUMBER,
    title: defineMessage({
      defaultMessage: 'Phone number',
    }),
  },
};
