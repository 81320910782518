import {
  TASK_TYPE,
  CHANGED_FIELD_TYPE,
  HISTORY_INTERACTION_TYPE,
} from '../../constants';
import { checkIsUndefined } from '../../utils';
import { deserializeUserPreview } from '../common';
import { deserializeTaskOverviewPayload } from './overviewPayload';
import { deserializeTaskComment } from './comment';

export * from './comment';
export { default as deserializeCountStatistic } from './deserializeCountStatistic';

export const serializeUpdatingTask = ({
  id,
  type,
  subType,
  zendeskUrl,
  identifiers,
  priority,
  executorId,
  comment,
  additionalResources,
  identifiersType,
  isSingleTask,
}) => {
  const res = {
    id,
    taskType: type,
    taskSubType: subType || null,
    zendeskUrl,
    priority,
    entityIds: identifiers,
    entityType: identifiersType,
    additionalResources,
  };

  if (executorId) {
    res.executorId = executorId;
  }

  if (!checkIsUndefined(isSingleTask)) {
    res.isSingleTask = isSingleTask;
  }

  if (comment) {
    res.comment = { text: comment, mediaUrls: [] };
  }

  return res;
};

export const deserializeTaskChange = ({ author, type, ...rest }) => {
  const res = {
    author: deserializeUserPreview(author),
    type: HISTORY_INTERACTION_TYPE.FIELD_CHANGE,
    fieldType: type,
    ...rest,
  };

  if (type === CHANGED_FIELD_TYPE.EXECUTOR) {
    res.from = deserializeUserPreview(res.from);
    res.to = deserializeUserPreview(res.to);
  }

  return res;
};

export const deserializeTaskPreview = ({
  taskId,
  taskType,
  taskSubType,
  executor,
  zendeskUrl,
  createdBy,
  ...rest
}) => ({
  id: taskId,
  type: taskType,
  subType: taskSubType || '',
  zendeskUrl: zendeskUrl || '',
  executor: executor ? deserializeUserPreview(executor) : null,
  creator: deserializeUserPreview(createdBy),
  ...rest,
});

const deserializeTaskHistory = (history) => {
  const creation = {
    ...history.creation,
    user: deserializeUserPreview(history.creation.user),
    type: HISTORY_INTERACTION_TYPE.CREATION,
  };

  const comments = history.comments.map(deserializeTaskComment);

  const fieldChanges = history.changes.map(({ user, ...rest }) => ({
    ...rest,
    user: deserializeUserPreview(user),
    type: HISTORY_INTERACTION_TYPE.FIELD_CHANGE,
  }));

  const solutions = history.solutions.map(({ user, value, ...rest }) => ({
    ...rest,
    user: deserializeUserPreview(user),
    value,
    type: value
      ? HISTORY_INTERACTION_TYPE.RESOLVING
      : HISTORY_INTERACTION_TYPE.SKIP,
  }));

  return { creation, comments, fieldChanges, solutions };
};

export const deserializeTaskOverview = ({
  taskId,
  taskType,
  taskSubType,
  executor,
  zendeskUrl,
  changes,
  comments,
  history,
  entityType,
  entityIds,
  preload,
  createdBy,
  ...rest
}) => ({
  ...deserializeTaskPreview({
    taskId,
    taskType,
    taskSubType,
    executor,
    createdBy,
    zendeskUrl,
  }),
  identifier: {
    type: entityType,
    entities: entityIds,
  },
  payload: deserializeTaskOverviewPayload(taskType, preload),
  interactionHistory: deserializeTaskHistory(history),
  ...rest,
});

export const serializeTaskResolvedParams = ({
  task,
  solution,
  callStatus,
  callResult,
  callPhone,
  callComment,
  ...rest
}) => {
  if (task?.type !== TASK_TYPE.CALL) {
    return { task: serializeUpdatingTask(task), solution, ...rest };
  }

  return {
    task: serializeUpdatingTask(task),
    solution: callResult,
    solutionPayload: {
      callStatus,
      phone: callPhone,
      comment: callComment,
      ...rest,
    },
  };
};

export const deserializeTaskPreviewStatistic = (statistic) =>
  statistic.map(({ taskType, count }) => ({ taskType, amount: count }));

export { serializeSort } from './serializeSort';
