import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Account from '../../models/account';
import { useNotifications } from '../../hooks';
import getErrorMessage from './getErrorMessage';

const useAccount = () => {
  const intl = useIntl();

  const { showErrorNotification } = useNotifications();

  const accountErrorType = useAtom(Account.errorTypeAtom);
  const isLoading = useAtom(Account.isLoadingAtom);

  const logIn = useAction(Account.logIn);
  const logOut = useAction(Account.logOut);
  const updateToken = useAction(Account.tokenUpdated);

  useEffect(() => {
    if (!accountErrorType) {
      return;
    }

    showErrorNotification(
      getErrorMessage(accountErrorType, intl.formatMessage),
    );
  }, [accountErrorType, intl.formatMessage, showErrorNotification]);

  return [{ isLoading }, { logIn, logOut, updateToken }];
};

export default useAccount;
