import { FormattedMessage } from 'react-intl';

import Accordion from '../../../components/Accordion/Accordion';
import IconContainer from '../../../components/IconContainer/IconContainer';
import { IconComment } from '../../../components/icons';
import useAccordion from '../../../components/Accordion/useAccordion';

import styles from './QueuedTaskOverviewAccordion.module.scss';

const QueuedTaskOverviewAccordionLabel = ({ commentsAmount }) => (
  <span className={styles.label}>
    <span>
      <FormattedMessage defaultMessage="Task view" />
    </span>

    <span className={styles.labelIcon}>
      <IconContainer>
        <IconComment />
      </IconContainer>

      <span className={styles.labelCount}>{commentsAmount}</span>
    </span>
  </span>
);

const QueuedTaskOverviewAccordion = ({
  children,
  commentsAmount,
  hasAccordion,
}) => {
  const [openedList, { toggle }] = useAccordion(['task-overview']);

  if (!hasAccordion) {
    return children;
  }

  return (
    <Accordion openedList={openedList} onToggle={toggle}>
      <Accordion.Item
        name="task-overview"
        label={
          <QueuedTaskOverviewAccordionLabel commentsAmount={commentsAmount} />
        }
      >
        {children}
      </Accordion.Item>
    </Accordion>
  );
};

export default QueuedTaskOverviewAccordion;
