const createTasksService = (initRequest) => ({
  getAll: ({ page, perPage, search, sort, filters }) =>
    initRequest('/tasks/all', {
      method: 'POST',
      data: { page, perPage, search, sort, filters },
    }),

  create: (task) =>
    initRequest('/tasks/create', {
      method: 'POST',
      data: task,
    }),

  update: (task, taskId) =>
    initRequest(`/tasks/${taskId}`, {
      method: 'PUT',
      data: task,
    }),

  getExecutors: (taskType) =>
    initRequest('/tasks/executors', { params: { taskType } }),

  comment: (comment) =>
    initRequest('/tasks/comments/create', { method: 'POST', data: comment }),

  overview: (taskId) => initRequest(`/tasks/${taskId}`),

  getQueuedTask: () => initRequest('/tasks/queue'),

  skipQueuedTask: (task, taskId, shouldQueueStop) =>
    initRequest(`/tasks/skip/${taskId}`, {
      method: 'POST',
      data: { taskData: task, shouldQueueStop },
    }),

  resolveQueuedTask: ({ task, ...rest }) =>
    initRequest(`/tasks/moderate/${task.id}`, {
      method: 'POST',
      data: { taskData: task, ...rest },
    }),

  restartQueue: () => initRequest('/tasks/queue_restart'),

  recreate: (callId) =>
    initRequest(`/calls/create_call_task`, {
      method: 'POST',
      data: { callId },
    }),

  countStatistic: ({ search, filters }) =>
    initRequest('/tasks/statistic-preview', {
      method: 'POST',
      data: { search, filters },
    }),
});

export default createTasksService;
