.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorCode {
  margin-bottom: -48px;
  font-size: 240px;
  font-weight: 700;
}

.blackHoleIcon {
  display: inline-block;
  margin: 0 16px;
  font-size: 180px;
}

.caption {
  margin-bottom: 56px;
  font-size: 26px;
}

.button {
}
