import cx from 'classnames';
import styles from './PageMainContentTile.module.scss';

const PageMainContentTile = ({
  title,
  titleAppended,
  subtitleElement,
  isStretched = true,
  children,
}) => (
  <section
    className={cx(styles.root, { [styles.root_stretched]: isStretched })}
  >
    <header className={styles.header}>
      <div className={styles.titleRow}>
        <h1 className={styles.title}>{title}</h1>
        {titleAppended && (
          <div className={styles.titleAppended}>{titleAppended}</div>
        )}
      </div>

      {subtitleElement && (
        <div className={styles.subtitleElement}>{subtitleElement}</div>
      )}
    </header>

    <div className={styles.content}>{children}</div>
  </section>
);

export default PageMainContentTile;
