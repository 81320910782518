import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../Button/Button';
import CalendarInput from './Input/Input';
import DatePicker from './DatePicker/DatePicker';

import { useEventHandler } from '../../../hooks';
import useDatePicker from './DatePicker/useDatePicker';
import styles from './Calendar.module.scss';

const getCleaveValue = (date) => {
  if (!date) {
    return ' ';
  }

  return date.toLocaleDateString(); // dd/mm/yyyy
};

const Calendar = ({
  dateRange: selectedDateRange,
  minDate,
  maxDate,
  onDateChange,
}) => {
  const [dateRange, setDateRange] = useState(selectedDateRange);
  const [datePickerRef, { selectRange, jumpToDate }] = useDatePicker();

  const handleDateRangeSet = useCallback(
    (dateRange) =>
      setDateRange((setDateRange) => [
        dateRange[0] || setDateRange[0],
        dateRange[1] || setDateRange[1],
      ]),
    [],
  );

  useEffect(() => {
    handleDateRangeSet(selectedDateRange);
  }, [selectedDateRange, handleDateRangeSet]);

  useEffect(() => {
    const [from, to] = dateRange;

    if (from > to) {
      handleDateRangeSet([to, from]);
    }
  }, [dateRange, handleDateRangeSet]);

  useEffect(() => {
    const [from, to] = dateRange;

    if (!from || !to) {
      return;
    }

    const _from = Math.max(from, minDate);
    const _to = Math.min(to, maxDate);

    if (_from !== Number(from) || _to !== Number(to)) {
      handleDateRangeSet([new Date(_from), new Date(_to)]);
    }
  }, [dateRange, minDate, maxDate, handleDateRangeSet]);

  useEffect(() => {
    if (dateRange[0]) {
      jumpToDate(dateRange[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange[0], jumpToDate]);

  useEffect(() => {
    if (dateRange[0]) {
      jumpToDate(dateRange[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange[1], jumpToDate]);

  useLayoutEffect(() => {
    selectRange(dateRange);
  }, [dateRange, selectRange]);

  const handleFromChange = useCallback(
    (date) => handleDateRangeSet([date]),
    [handleDateRangeSet],
  );

  const handleToChange = useCallback(
    (date) => handleDateRangeSet([null, date]),
    [handleDateRangeSet],
  );

  const handleFromFocus = useEventHandler(() => {
    jumpToDate(dateRange[0]);
  }, [dateRange[0], jumpToDate]);

  const handleToFocus = useEventHandler(() => {
    jumpToDate(dateRange[1]);
  }, [dateRange[1], jumpToDate]);

  const handleDatePickerChange = useCallback(
    (dateRange) => handleDateRangeSet(dateRange),
    [handleDateRangeSet],
  );

  const handleApplyClick = useEventHandler(
    () => onDateChange(dateRange),
    [dateRange, onDateChange],
  );

  return (
    <div className={styles.root}>
      <div className={styles.inputGroup}>
        <div className={styles.inputItem}>
          <CalendarInput
            label={<FormattedMessage defaultMessage="Date from" />}
            value={getCleaveValue(dateRange[0])}
            onChange={handleFromChange}
            onFocus={handleFromFocus}
          />
        </div>

        <div className={styles.inputItem}>
          <CalendarInput
            label={<FormattedMessage defaultMessage="Date to" />}
            value={getCleaveValue(dateRange[1])}
            onChange={handleToChange}
            onFocus={handleToFocus}
          />
        </div>
      </div>

      <div className={styles.datePicker}>
        <DatePicker
          maxDate={maxDate}
          minDate={minDate}
          ref={datePickerRef}
          onChange={handleDatePickerChange}
        />
      </div>

      <div className={styles.applyButton}>
        <Button
          title={<FormattedMessage defaultMessage="Apply" />}
          onClick={handleApplyClick}
          isExpanded
        />
      </div>
    </div>
  );
};

export default Calendar;
