import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useEventHandler } from '../../hooks';

const scrollToItem = (name = '') => {
  const item = document.getElementById(name);

  if (item) {
    scrollIntoView(item, {
      behavior: 'smooth',
    });
  }
};

const useAccordion = (defaultOpenedList = [], withHash) => {
  const [openedList, setOpenedList] = useState(defaultOpenedList);
  const location = useLocation();
  const navigate = useNavigate();
  const nameByHash = location.hash.split('#')[1];

  const open = useEventHandler(
    (name) => {
      if (openedList.includes(name)) {
        return;
      }
      setOpenedList([...openedList, name]);
    },

    [openedList],
  );

  const close = useEventHandler(
    (name) => {
      if (!openedList.includes(name)) {
        return;
      }

      setOpenedList((openedList) =>
        openedList.filter((_name) => _name !== name),
      );

      if (withHash) {
        navigate({ ...location, hash: '' }, { replace: true });
      }
    },
    [openedList, location, withHash],
  );

  const toggle = useEventHandler(
    (name) => {
      const isOpened = openedList.includes(name);

      if (isOpened) {
        close(name);
        return;
      }

      open(name);

      if (withHash && !isOpened) {
        navigate({ ...location, hash: name }, { replace: true });
      }
    },
    [openedList, location, withHash, navigate, open, close],
  );

  useEffect(() => {
    if (!withHash) {
      return;
    }

    if (nameByHash) {
      open(nameByHash);
    }
  }, [openedList, nameByHash, withHash, open]);

  return [openedList, { toggle, scrollToItem }];
};

export default useAccordion;
