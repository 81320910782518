import { useAtom } from '@reatom/react';
import PropTypes from 'prop-types';

import AdHouseEditingDialog from './HouseEditingDialog';

import * as Ads from '../../../../models/ads';
import useHouseOptions from './useHouseOptions';
import useHouseEditing from './useHouseEditing';

const formCurrentHouse = ({ id, recognized }) => ({ id, title: recognized });

const AdHouseEditingDialogContainer = ({ adId, isOpened, onClose }) => {
  const adDetails = useAtom(Ads.Overview.makeAdDetailsAtom(adId));

  const [{ isLoading: isAdHouseEditingLoading }, { edit: editAdHouse }] =
    useHouseEditing(adId);

  const [{ list: houses, isLoading: isHousesLoading }, { onQueryChange }] =
    useHouseOptions(adDetails.geo.street.id, isOpened);

  const handleHouseChange = (house) => {
    editAdHouse(house);
    onClose();
  };

  return (
    <AdHouseEditingDialog
      currentHouse={formCurrentHouse(adDetails.geo.house)}
      houses={houses}
      isHousesLoading={isHousesLoading}
      isHouseEditingLoading={isAdHouseEditingLoading}
      isOpened={isOpened}
      onQueryChange={onQueryChange}
      onHouseChange={handleHouseChange}
      onClose={onClose}
    />
  );
};

AdHouseEditingDialogContainer.propTypes = {
  adId: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdHouseEditingDialogContainer;
