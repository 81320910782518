import { useCallback, useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import UserEditingDialog from './UserEditingDialog';

import * as Users from '../../../../models/users';
import { useNotifications } from '../../../../hooks';

const UserEditingDialogContainer = ({ payload, ...rest }) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const currentUpdatingUser = useAtom(
    Users.Showing.makeUserByIdAtom(payload?.userId),
  );
  const allUsersEmails = useAtom(Users.Showing.allUsersEmails);
  const isUserEditingFailed = useAtom(Users.Editing.isSavedFailedAtom);
  const handleUserEdit = useAction(Users.Editing.saved);

  useEffect(() => {
    if (!isUserEditingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to edit user',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserEditingFailed, intl.formatMessage, showErrorNotification]);

  const handleSubmit = useCallback(
    (user) => handleUserEdit(user),
    [handleUserEdit],
  );

  return (
    <UserEditingDialog
      user={currentUpdatingUser}
      allUsersEmails={allUsersEmails}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
};

export default UserEditingDialogContainer;
