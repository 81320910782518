import styles from './PhotoPreviewCardGrid.module.scss';

const PhotoPreviewCardGrid = ({ children }) => (
  <div className={styles.root}>{children}</div>
);
PhotoPreviewCardGrid.Item = ({ children }) => (
  <div className={styles.item}>{children}</div>
);

export default PhotoPreviewCardGrid;
