import { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.WEB_APP_FIREBASE_API_KEY,
  authDomain: process.env.WEB_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.WEB_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.WEB_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.WEB_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.WEB_APP_FIREBASE_APP_ID,
  measurementId: process.env.WEB_APP_FIREBASE_MEASUREMENT_ID,
};

const useFirebaseAuth = () => {
  const [isUserLoading, setIsUserLoading] = useState(true);

  const setAuthChangeHandler = useCallback(
    (callback) =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          setIsUserLoading(false);
          return;
        }

        const firebaseToken = await user.getIdToken();

        callback({
          email: user.email,
          firebaseToken,
        });
        setIsUserLoading(false);
      }),
    [],
  );

  const signInWithGoogle = useCallback(() => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.addScope('profile');
    googleProvider.addScope('email');

    return firebase.auth().signInWithPopup(googleProvider);
  }, []);

  const signOut = useCallback(() => firebase.auth().signOut(), []);

  const setIdTokenUpdateHandler = useCallback(
    (callback) =>
      firebase.auth().onIdTokenChanged(async (user) => {
        if (user) {
          const firebaseToken = await user.getIdToken();
          callback(firebaseToken);
        }
      }),
    [],
  );

  useEffect(() => {
    if (firebase.apps.length > 0) {
      return;
    }

    firebase.initializeApp(firebaseConfig);
  }, []);

  return {
    isUserLoading,
    signInWithGoogle,
    signOut,
    setAuthChangeHandler,
    setIdTokenUpdateHandler,
  };
};

export default useFirebaseAuth;
