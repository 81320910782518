.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.labelItem {
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}
