import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import CommentMessage from './CommentMessage/CommentMessage';
import ChangeMessage from './ChangeMessage/ChangeMessage';
import ChangeMessagePlaceholder from './ChangeMessagePlaceholder/ChangeMessagePlaceholder';

import {
  HISTORY_INTERACTION_TYPE,
  TASK_SOLUTION_INFO,
  CALL_RESULT_INFO,
} from '../../constants';
import useScrollToBottomOnHistoryChange from './useScrollToBottomOnHistoryChange';
import styles from './TaskInteractionHistory.module.scss';

const TaskInteractionHistoryMessage = ({ interaction }) => {
  const intl = useIntl();

  switch (interaction.type) {
    case HISTORY_INTERACTION_TYPE.CREATION:
      return (
        <ChangeMessagePlaceholder
          user={interaction.user}
          timestamp={interaction.timestamp}
          action={<FormattedMessage defaultMessage="created task" />}
        />
      );

    case HISTORY_INTERACTION_TYPE.COMMENT:
      return (
        <CommentMessage
          user={interaction.user}
          timestamp={interaction.timestamp}
          text={interaction.text}
        />
      );

    case HISTORY_INTERACTION_TYPE.FIELD_CHANGE:
      return (
        <ChangeMessage
          user={interaction.user}
          timestamp={interaction.timestamp}
          fieldName={interaction.fieldName}
          from={interaction.from}
          to={interaction.to}
        />
      );

    case HISTORY_INTERACTION_TYPE.SKIP:
      return (
        <ChangeMessagePlaceholder
          user={interaction.user}
          timestamp={interaction.timestamp}
          action={<FormattedMessage defaultMessage="skipped task" />}
        />
      );

    case HISTORY_INTERACTION_TYPE.RESOLVING:
      const resolvingTypeTitle = intl.formatMessage(
        TASK_SOLUTION_INFO[interaction.value]?.title ||
          CALL_RESULT_INFO[interaction.value]?.title,
      );

      const details = intl.formatMessage(
        {
          defaultMessage: 'with solution type "{type}"',
        },
        { type: resolvingTypeTitle },
      );

      return (
        <ChangeMessagePlaceholder
          user={interaction.user}
          timestamp={interaction.timestamp}
          action={<FormattedMessage defaultMessage="resolved task" />}
          details={details}
          isHighlighted
        />
      );

    default:
      return null;
  }
};

const TaskInteractionHistory = ({ history = [] }) => {
  const listRef = useScrollToBottomOnHistoryChange(history);

  const isEmpty = history.length === 0;

  if (isEmpty) {
    return (
      <div className={styles.root}>
        <div
          className={cx(styles.list, styles.list_centerAligned)}
          ref={listRef}
        >
          <FormattedMessage defaultMessage="No changes or comments" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.list} ref={listRef}>
        {history.map((interaction, index) => (
          <div className={styles.message} key={index}>
            <TaskInteractionHistoryMessage interaction={interaction} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskInteractionHistory;
