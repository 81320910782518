import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/Page/Page';
import Button from '../../components/Button/Button';
import IconContainer from '../../components/IconContainer/IconContainer';
import { IconAlien } from '../../components/icons';

import { getTaskListPageLink } from '../../routes';
import styles from './Error404Page.module.scss';

const Error404Page = () => (
  <FormattedMessage defaultMessage="Page not found">
    {([helmetTitle]) => (
      <Page helmetTitle={helmetTitle}>
        <div className={styles.root}>
          <div className={styles.errorCode}>
            4
            <span className={styles.blackHoleIcon}>
              <IconContainer size="inline">
                <IconAlien />
              </IconContainer>
            </span>
            4
          </div>

          <div className={styles.caption}>
            <FormattedMessage defaultMessage="We have not discovered this galaxy yet..." />
          </div>

          <div className={styles.button}>
            <Button
              component={Link}
              to={getTaskListPageLink()}
              title={<FormattedMessage defaultMessage="Go to task list" />}
            />
          </div>
        </div>
      </Page>
    )}
  </FormattedMessage>
);

export default Error404Page;
