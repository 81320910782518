import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Ads from '../../models/ads';
import { useNotifications } from '../../hooks';

const useAdStatus = (adId) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const adStatus = useAtom(Ads.Status.makeAdStatusAtom(adId));
  const isAdStatusLoading = useAtom(Ads.Status.makeIsLoadingAtom(adId));
  const isPublishingFailed = useAtom(Ads.Publishing.makeIsFailedAtom(adId));
  const isRemovingFailed = useAtom(Ads.Removing.makeIsFailedAtom(adId));
  const isReprocessingFailed = useAtom(Ads.Reprocessing.makeIsFailedAtom(adId));

  const onAdRemove = useAction(Ads.Removing.done);
  const onAdPublish = useAction(Ads.Publishing.done);
  const onAdReprocess = useAction(Ads.Reprocessing.done);

  useEffect(() => {
    if (!isPublishingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to publish ad',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishingFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isRemovingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to delete ad',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemovingFailed, intl.formatMessage, showErrorNotification]);

  useEffect(() => {
    if (!isReprocessingFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to reprocess ad',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReprocessingFailed, intl.formatMessage, showErrorNotification]);

  return [
    { adStatus, isAdStatusLoading },
    { onAdRemove, onAdPublish, onAdReprocess },
  ];
};

export default useAdStatus;
