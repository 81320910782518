import { useEffect, useMemo } from 'react';

import { useEventHandler, useQueryParams } from '../../hooks';
import { toggleSortType } from '../../utils';
import { SORT_TYPE } from '../../constants';

const DEFAULT_SORT_TYPE = SORT_TYPE.ASC;

const useSort = ({ fieldName, value } = {}) => {
  const [params, { setQueryParams }] = useQueryParams();

  useEffect(() => {
    if (!fieldName || !value) {
      return;
    }

    setQueryParams({ sortField: fieldName, sortValue: value });
  }, [fieldName, value, setQueryParams]);

  const toggleSort = useEventHandler(
    (fieldName) => {
      if (fieldName === params.sortField) {
        setQueryParams({
          sortValue: toggleSortType(params.sortValue) || DEFAULT_SORT_TYPE,
        });
        return;
      }

      setQueryParams({
        sortField: fieldName,
        sortValue: DEFAULT_SORT_TYPE,
      });
    },
    [params.sortValue, setQueryParams],
  );

  const sort = useMemo(
    () => ({ fieldName: params.sortField, value: params.sortValue }),
    [params.sortField, params.sortValue],
  );

  return [sort, toggleSort];
};

export default useSort;
