@import '../../styles/utils.scss';

.root {
  position: relative;
  height: 100%;
}

.list {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 12px;
  border-radius: $primaryBorderRadius;
  background-color: $grey_1;
  color: $grey_3;
  font-size: 16px;
  overflow: auto;
  scroll-behavior: smooth;

  &_centerAligned {
    align-items: center;
    justify-content: center;
  }
}

.message {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
