import TaskLabel from '../../../../components/TaskLabel/TaskLabel';
import styles from './TaskTypes.module.scss';

const UsersTableTaskTypes = ({ list }) => (
  <div className={styles.root}>
    <div className={styles.list}>
      {list.map((taskType) => (
        <div key={taskType} className={styles.item}>
          <TaskLabel type={taskType} />
        </div>
      ))}
    </div>
  </div>
);

export default UsersTableTaskTypes;
