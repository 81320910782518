import { defineMessage } from 'react-intl';

export const LANGUAGE = {
  EN: 'en',
  RU: 'ru',
  UK: 'uk',
};

export const LANGUAGE_INFO = {
  [LANGUAGE.UK]: {
    slug: LANGUAGE.UK,
    title: defineMessage({
      defaultMessage: 'Ukrainian',
    }),
  },

  [LANGUAGE.EN]: {
    slug: LANGUAGE.EN,
    title: defineMessage({
      defaultMessage: 'English',
    }),
  },

  [LANGUAGE.RU]: {
    slug: LANGUAGE.RU,
    title: defineMessage({
      defaultMessage: 'Russian',
    }),
  },
};
