%item {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 8px;
}

.root {
  display: flex;
  align-items: center;
  margin: -8px;
}

.narrowItem {
  @extend %item;
  flex: 0 0 auto;
}

.wideItem {
  @extend %item;
  flex: 1 1 auto;
}

.halfItem {
  @extend %item;
}
