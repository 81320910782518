import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl, FormattedDate } from 'react-intl';

import Table from '../Table/Table';
import TaskLabel from '../TaskLabel/TaskLabel';
import UserPlate from '../UserPlate/UserPlate';
import ButtonEdit from '../ButtonEdit/ButtonEdit';
import RoundIconButton from '../RoundIconButton/RoundIconButton';
import { IconDelete } from '../icons';
import CalendarDropdown from '../CalendarDropdown/CalendarDropdown';
import TasksTableZendeskLink from './ZendeskLink/ZendeskLink';
import TasksTableTaskCreator from './TaskCreator/TaskCreator';

import {
  TASK_SUBTYPE_INFO,
  TASK_TYPE_INFO,
  TASK_TYPE_LIST,
  TASK_SUBTYPES_BY_TYPE,
  TASK_SUBTYPE,
} from '../../constants';
import { trimISOTime, dateToISOInCurrentTimezone } from '../../utils';
import useCalendar from '../../components/CalendarDropdown/Calendar/useCalendar';
import styles from './TasksTable.module.scss';

const COLUMNS = [
  { name: 'id', width: '80px', isFlexible: false },
  { name: 'createTime', width: '112px', isFlexible: true, hasSort: true },
  { name: 'taskType', width: '120px', isFlexible: true },
  { name: 'taskSubType', width: '96px', isFlexible: true },
  { name: 'creator', width: '120px', minWidth: '100px', isFlexible: true },
  { name: 'zendeskUrl', width: '56px', isFlexible: false },
  { name: 'executor', width: '120px', minWidth: '100px', isFlexible: true },
  { name: 'edit', width: '48px', isFlexible: false },
];

const TASK_TYPE_OPTIONS = [
  ...TASK_TYPE_LIST.map((type) => ({
    value: type,
    label: <FormattedMessage {...TASK_TYPE_INFO[type].title} />,
  })),
];

const CALENDAR_BUTTON_PROPS = {
  isSmall: true,
};

const CALENDAR_DROPDOWN_PROPS = {
  horizontalAlignment: 'right',
};

const TasksTable = ({
  tasks = [],
  filters = {},
  executorsOptions = [],
  creatorFilterOptions = [],
  isExecutorOptionsLoading,
  isCreatorFilterOptionsLoading,
  loadingOverviewTaskIdsAtom,
  onTaskEditClick,
  onFilter,
  onFilterReset,
  ...rest
}) => {
  const intl = useIntl();

  const taskSubTypeOptions = useMemo(() => {
    const subTypes =
      TASK_SUBTYPES_BY_TYPE[filters.taskType] || Object.values(TASK_SUBTYPE);
    return subTypes.map((subType) => ({
      value: subType,
      label: <FormattedMessage {...TASK_SUBTYPE_INFO[subType].title} />,
    }));
  }, [filters.taskType]);

  const isResetButtonDisabled = useMemo(
    () => Object.values(filters).filter(Boolean).length === 0,
    [filters],
  );

  const handleEditClick = (id) => () => onTaskEditClick(id);
  const handleDateFilter = useCallback(
    (date) =>
      onFilter(
        'date',
        date.map((dateISO) =>
          trimISOTime(dateToISOInCurrentTimezone(new Date(dateISO))),
        ),
      ),
    [onFilter],
  );

  const [{ dateRange: defaultDateRange, minDate, maxDate }] = useCalendar();

  return (
    <Table
      headerRow={
        <Table.HeaderRow>
          <Table.HeaderCell
            name="id"
            title={<FormattedMessage defaultMessage="Task id" />}
          />
          <Table.HeaderCell
            name="createTime"
            title={<FormattedMessage defaultMessage="Date" />}
            action={
              <CalendarDropdown
                dateRange={filters.date || defaultDateRange}
                minDate={minDate}
                maxDate={maxDate}
                buttonProps={CALENDAR_BUTTON_PROPS}
                dropdownProps={CALENDAR_DROPDOWN_PROPS}
                onDateChange={handleDateFilter}
                isDisplayAbbreviated
              />
            }
            hasSort
          />
          <Table.HeaderCell
            name="taskType"
            title={<FormattedMessage defaultMessage="Type" />}
            action={
              <Table.FilterSelect
                name="taskType"
                placeholder={<FormattedMessage defaultMessage="Choose" />}
                value={filters.taskType}
                options={TASK_TYPE_OPTIONS}
                onChange={onFilter}
              />
            }
          />
          <Table.HeaderCell
            name="taskSubType"
            title={<FormattedMessage defaultMessage="Subtype" />}
            action={
              <Table.FilterSelect
                name="taskSubType"
                placeholder={<FormattedMessage defaultMessage="Choose" />}
                value={filters.taskSubType}
                options={taskSubTypeOptions}
                onChange={onFilter}
              />
            }
          />
          <Table.HeaderCell
            name="creator"
            title={<FormattedMessage defaultMessage="Creator" />}
            action={
              <Table.FilterSelect
                name="creator"
                placeholder={<FormattedMessage defaultMessage="Choose" />}
                value={filters.creator}
                options={creatorFilterOptions}
                isLoading={isCreatorFilterOptionsLoading}
                onChange={onFilter}
              />
            }
          />
          <Table.HeaderCell
            name="zendeskUrl"
            title={<FormattedMessage defaultMessage="Zendesk" />}
          />
          <Table.HeaderCell
            name="executor"
            title={<FormattedMessage defaultMessage="Assignee" />}
            action={
              <Table.FilterSelect
                name="executorId"
                placeholder={<FormattedMessage defaultMessage="Choose" />}
                value={filters.executorId}
                options={executorsOptions}
                isLoading={isExecutorOptionsLoading}
                onChange={onFilter}
              />
            }
          />
          <Table.HeaderCell
            name="edit"
            action={
              <div className={styles.resetFilterButton}>
                <RoundIconButton
                  variant="flat"
                  iconSize="small"
                  icon={<IconDelete />}
                  isDisabled={isResetButtonDisabled}
                  onClick={onFilterReset}
                  isSmall
                />
              </div>
            }
          />
        </Table.HeaderRow>
      }
      emptyText={<FormattedMessage defaultMessage="No tasks" />}
      isEmpty={tasks.length < 1}
      columns={COLUMNS}
      {...rest}
    >
      {tasks.map(
        ({
          id,
          createTime,
          type,
          subType,
          zendeskUrl,
          executor,
          commentsAmount,
          creator,
          isAutomatic,
          isComplaint,
          isCompleted,
        }) => (
          <Table.Row key={id} isTranslucent={isCompleted}>
            <Table.Cell name="id">{id}</Table.Cell>

            <Table.Cell name="createTime">
              <FormattedDate value={createTime} />
            </Table.Cell>

            <Table.Cell name="taskType">
              <TaskLabel type={type} />
            </Table.Cell>

            <Table.Cell name="taskSubType">
              {subType &&
                intl
                  .formatMessage(TASK_SUBTYPE_INFO[subType].title)
                  .toLowerCase()}
            </Table.Cell>

            <Table.Cell name="creator">
              <TasksTableTaskCreator
                creator={creator}
                isAutomatic={isAutomatic}
                isComplaint={isComplaint}
              />
            </Table.Cell>

            <Table.Cell name="zendeskUrl">
              {zendeskUrl && <TasksTableZendeskLink link={zendeskUrl} />}
            </Table.Cell>

            <Table.Cell name="executor">
              {executor && (
                <UserPlate name={executor.name} media={executor.media} />
              )}
            </Table.Cell>

            <Table.Cell
              name="edit"
              horizontalAlignment="center"
              isVisibleWhenRowTranslucent
            >
              {loadingOverviewTaskIdsAtom.includes(String(id)) ? (
                <Table.CellLoader />
              ) : (
                <ButtonEdit
                  counter={commentsAmount}
                  onClick={handleEditClick(id)}
                />
              )}
            </Table.Cell>
          </Table.Row>
        ),
      )}
    </Table>
  );
};

export default TasksTable;
