import { Navigate, useRoutes } from 'react-router-dom';

import ContentPagesLayout from '../ContentPagesLayout/ContentPagesLayout';
import Error404Page from '../../pages/Error404Page/Error404Page';
import DashboardPage from '../../pages/DashboardPage/DashboardPage';
import UsersProductivityStatisticPage from '../../pages/UsersProductivityStatisticPage/UsersProductivityStatisticPage';
import TaskListPage from '../../pages/TaskListPage/TaskListPage';
import CallTaskListPage from '../../pages/CallHistoryPage/CallHistoryPage';
import TaskQueuePage from '../../pages/TasksQueuePage/TasksQueuePage';
import UsersPage from '../../pages/UsersPage/UsersPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import SearchPage from '../../pages/SearchPage/SearchPage';
import AdPage from '../../pages/AdPage/AdPage';
import PhonePage from '../../pages/PhonePage/PhonePage';

import withAuth from './withAuth';
import { DEFAULT_PAGE_PATH } from '../../constants';

const PagesScene = () =>
  useRoutes([
    withAuth({
      element: <ContentPagesLayout />,
      children: [
        DashboardPage.RouteParams,
        UsersProductivityStatisticPage.RouteParams,
        TaskListPage.RouteParams,
        CallTaskListPage.RouteParams,
        TaskQueuePage.RouteParams,
        UsersPage.RouteParams,
        SearchPage.RouteParams,
        AdPage.RouteParams,
        PhonePage.RouteParams,
      ],
    }),
    { path: '/', element: <Navigate to={DEFAULT_PAGE_PATH} replace /> },
    { path: '/login', element: <LoginPage /> },
    { path: '*', element: <Error404Page /> },
  ]);

export default PagesScene;
