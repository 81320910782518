@import '../../styles/utils';

.root {
  display: flex;
  align-items: center;
}

.media {
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 16px;
  overflow: hidden;
}

.root_withReducedOffset .media {
  margin-right: 8px;
}

.name {
  overflow: hidden;
  @include overflowEllipsis;
}
