const createUsersStatisticService = (initRequest) => ({
  overview: ({ date, userId }) =>
    initRequest('/users-statistic/overview', {
      method: 'POST',
      data: { date, userId },
    }),

  timeline: (date) =>
    initRequest('/users-statistic/timeline-preview', {
      method: 'POST',
      data: { date },
    }),
});

export default createUsersStatisticService;
