import { declareAtom, declareAction } from '@reatom/core';
import * as TaskQueueResuming from './resuming';

export const stopped = declareAction('TaskQueue/isStopped/stopped');

export const value = declareAtom('TaskQueue/isStopped/value', false, (on) => [
  on(TaskQueueResuming.doneSucceeded, () => false),
  on(stopped, () => true),
]);

export default value;
