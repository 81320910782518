import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import DashboardPage from '../../../pages/DashboardPage/DashboardPage';
import TaskListPage from '../../../pages/TaskListPage/TaskListPage';
import TasksQueuePage from '../../../pages/TasksQueuePage/TasksQueuePage';
import UsersProductivityStatisticPage from '../../../pages/UsersProductivityStatisticPage/UsersProductivityStatisticPage';
import UsersPage from '../../../pages/UsersPage/UsersPage';
import CallTaskListPage from '../../../pages/CallHistoryPage/CallHistoryPage';
import SearchPage from '../../../pages/SearchPage/SearchPage';
import Logo from '../../Logo/Logo';
import MainNavigationPrivateLink from './PrivateLink/PrivateLink';
import {
  IconDashboard,
  IconList,
  IconListCard,
  IconStatistic,
  IconUsers,
  IconPhone,
  IconSearch,
} from '../../icons';

import { DEFAULT_PAGE_PATH } from '../../../constants';
import styles from './MainNavigation.module.scss';

const MainNavigation = () => (
  <div>
    <div className={styles.logo}>
      <Link to={DEFAULT_PAGE_PATH} className={styles.logoLink}>
        <Logo />
      </Link>
    </div>

    <div className={styles.routeList}>
      <MainNavigationPrivateLink
        title={<FormattedMessage defaultMessage="Search" />}
        icon={<IconSearch />}
        page={SearchPage}
      />
      <MainNavigationPrivateLink
        title={<FormattedMessage defaultMessage="Dashboard" />}
        icon={<IconDashboard />}
        page={DashboardPage}
      />
      <MainNavigationPrivateLink
        title={<FormattedMessage defaultMessage="Task list" />}
        icon={<IconList />}
        page={TaskListPage}
      />

      <MainNavigationPrivateLink
        title={<FormattedMessage defaultMessage="Task queue" />}
        icon={<IconListCard />}
        page={TasksQueuePage}
      />

      <MainNavigationPrivateLink
        title={<FormattedMessage defaultMessage="Call history" />}
        icon={<IconPhone />}
        page={CallTaskListPage}
      />

      <MainNavigationPrivateLink
        title={<FormattedMessage defaultMessage="Users" />}
        icon={<IconUsers />}
        page={UsersPage}
      />

      <MainNavigationPrivateLink
        title={<FormattedMessage defaultMessage="Work statistics" />}
        icon={<IconStatistic />}
        page={UsersProductivityStatisticPage}
      />
    </div>
  </div>
);

export default MainNavigation;
