import { useCallback, useMemo } from 'react';
import { AreaClosed as CoreAreaClosed, Line, Bar } from '@visx/shape';
import { scaleLinear, scaleTime } from '@visx/scale';
import { curveLinear } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { localPoint } from '@visx/event';
import { useTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { withParentSize } from '@visx/responsive';
import { max, extent, bisector } from 'd3-array';
import styles from './AreaClosed.module.scss';

const getDate = (d) => new Date(d.date);
const getStockValue = (d) => d?.amount;
const bisectDate = bisector((d) => new Date(d.date)).left;

const TOP_INNER_GAP = 24;
const BOTTOM_INNER_GAP = 24;

// parentWidth and parentHeight are passed withing withParentSize HOC
const AreaClosed = ({
  width: propsWidth,
  height: propsHeight,
  parentWidth,
  parentHeight,
  stock,
}) => {
  const width = propsWidth || parentWidth;
  const height = (propsHeight || parentHeight) - TOP_INNER_GAP;
  const {
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [0, width],
        domain: extent(stock, getDate),
      }),
    [stock, width],
  );

  const stockValueScale = useMemo(
    () =>
      scaleLinear({
        range: [height + TOP_INNER_GAP, TOP_INNER_GAP],
        domain: [
          -BOTTOM_INNER_GAP,
          (max(stock, getStockValue) || 0) + height / 3,
        ],
        nice: true,
      }),
    [stock, height],
  );

  const handleTooltipShow = useCallback(
    (event) => {
      const { x = 0 } = localPoint(event) || {};
      const x0 = dateScale.invert(x);
      const index = bisectDate(stock, x0, 1);
      const d0 = stock[index - 1];
      const d1 = stock[index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d =
          x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: stockValueScale(getStockValue(d)),
      });
    },
    [stock, stockValueScale, dateScale, showTooltip],
  );

  const handleBarTouchStart = useCallback(
    (e) => handleTooltipShow(e),
    [handleTooltipShow],
  );
  const handleBarTouchMove = useCallback(
    (e) => handleTooltipShow(e),
    [handleTooltipShow],
  );
  const handleBarMouseMove = useCallback(
    (e) => handleTooltipShow(e),
    [handleTooltipShow],
  );
  const handleBarMouseLeave = useCallback((e) => hideTooltip(e), [hideTooltip]);

  return (
    <div className={styles.root}>
      <svg width={width} height={height} className={styles.element}>
        <rect width={width} height={height} fill="#f2f2f7" />

        <LinearGradient id="area-gradient" from="#3e7eff" to="#99bbff" />

        <CoreAreaClosed
          data={stock}
          x={(d) => dateScale(getDate(d)) ?? 0}
          y={(d) => stockValueScale(getStockValue(d)) ?? 0}
          yScale={stockValueScale}
          strokeWidth={1}
          stroke="url(#area-gradient)"
          fill="url(#area-gradient)"
          curve={curveLinear}
        />

        <Bar
          width={width}
          height={height}
          fill="transparent"
          onTouchStart={handleBarTouchStart}
          onTouchMove={handleBarTouchMove}
          onMouseMove={handleBarMouseMove}
          onMouseLeave={handleBarMouseLeave}
        />

        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft }}
              to={{ x: tooltipLeft, y: height }}
              stroke="#3e7eff"
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="4"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop + 1}
              r={6}
              fill="#fff"
              stroke="#3e7eff"
              strokeWidth={1}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>

      {tooltipData && (
        <>
          <Tooltip
            className={styles.amountTooltip}
            style={defaultStyles}
            top={tooltipTop}
            left={tooltipLeft}
          >
            {new Intl.NumberFormat('ru-RU').format(getStockValue(tooltipData))}
          </Tooltip>

          <Tooltip
            className={styles.dateTooltip}
            style={defaultStyles}
            top={height}
            left={tooltipLeft}
          >
            {new Intl.DateTimeFormat('ru', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }).format(new Date(getDate(tooltipData)))}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default withParentSize(AreaClosed);
