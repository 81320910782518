import { useEffect, useLayoutEffect } from 'react';
import { useAtom, useAction } from '@reatom/react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/Page/Page';
import DefaultPageLayout from '../../components/DefaultPageLayout/DefaultPageLayout';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import TaskInteractionHistoryField from '../../components/TaskInteractionHistoryField/TaskInteractionHistoryField';
import TaskInteractionHistory from '../../components/TaskInteractionHistory/TaskInteractionHistory';
import Section from '../../components/Section/Section';
import TileSection from '../../components/TileSection/TileSection';
import CallsTable from '../../components/CallsTable/CallsTable';

import QueuedTaskAdPreview from './AdPreview/AdPreview';
import TaskResolvePlate from './TaskResolvePlate/TaskResolvePlate';
import TasksQueuePageLoader from './Loader/Loader';
import TasksQueuePagePlaceholder from './Placeholder/Placeholder';
import QueuedTaskOverview from './QueuedTaskOverview/QueuedTaskOverview';
import QueuedTaskMetaInfo from './QueuedTaskMetaInfo/QueuedTaskMetaInfo';
import QueuedTaskOverviewAccordion from './QueuedTaskOverviewAccordion/QueuedTaskOverviewAccordion';
import StoppedTaskQueue from './StoppedTaskQueue/StoppedTaskQueue';

import { useExecutorsByTaskType } from '../../hooks';
import * as Tasks from '../../models/tasks';
import * as TaskQueue from '../../models/taskQueue';
import { TASK_TYPE } from '../../constants';
import withRoles from '../../components/PagesScene/withRoles';
import { formatPhoneNumber } from '../../utils';
import useFrom from './useForm';
import useComment from './useComment';
import useTaskQueueLoadingErrorMessage from './useLoadingErrorMessage';
import styles from './TasksQueuePage.module.scss';

const TasksQueuePage = () => {
  const queuedTaskId = useAtom(TaskQueue.EntityId.valueAtom);
  const isQueuedTaskLoading = useAtom(TaskQueue.EntityId.isLoadingAtom);
  const isQueuedTaskSucceeded = useAtom(TaskQueue.EntityId.isSucceededAtom);
  const isQueuedTaskFailed = useAtom(TaskQueue.EntityId.isFailedAtom);
  const isQueuedTaskReady = useAtom(TaskQueue.EntityId.isReadyAtom);
  const isTaskQueueStopped = useAtom(TaskQueue.IsStopped.value);
  const isNoQueuedTask = isQueuedTaskSucceeded && !queuedTaskId;

  const showTask = useAction(TaskQueue.Showing.done);
  const resumeTask = useAction(TaskQueue.Resuming.done);
  const resolveTask = useAction(TaskQueue.Resolving.done);
  const skipTask = useAction(TaskQueue.Skip.done);

  useTaskQueueLoadingErrorMessage();

  const queuedTask = useAtom(Tasks.Overview.makeTaskOverviewAtom(queuedTaskId));
  const commentsAmount = useAtom(
    Tasks.Overview.makeCommentsAmount(queuedTask?.id),
  );

  const [{ comment, isCommentLoading }, { onCommentChange, onCommentSubmit }] =
    useComment();

  const [formik, { onSelectChange, onFormSubmit, onFormSkip, onFormReset }] =
    useFrom({
      queuedTask,
      onSubmit: resolveTask,
      onSkip: skipTask,
    });

  const { executors, isExecutorsLoading } = useExecutorsByTaskType(
    formik.values.type,
  );

  useLayoutEffect(() => {
    onFormReset(queuedTask);
    onCommentChange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuedTask?.id, onFormReset]);

  // Upload queued task
  useEffect(() => {
    if (!isQueuedTaskLoading && !isQueuedTaskSucceeded) {
      showTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    ad,
    phones,
    adCallsHistory,
    callsHistoryByPhoneList = [],
    sourceAdUrlByAdId,
  } = queuedTask?.payload || {};
  const isCallTaskType = formik.values.type === TASK_TYPE.CALL;
  const isAdOverviewShown = isCallTaskType && ad;
  const isTitleAppendedShown = queuedTask && !isTaskQueueStopped;

  return (
    <FormattedMessage defaultMessage="Task queue">
      {([helmetTitle]) => (
        <Page helmetTitle={helmetTitle}>
          <Section isVerticallyGrowing>
            <DefaultPageLayout>
              <DefaultPageLayout.WideCol>
                <PageMainContentTile
                  title={<FormattedMessage defaultMessage="Task queue" />}
                  titleAppended={
                    isTitleAppendedShown && (
                      <QueuedTaskMetaInfo
                        taskId={queuedTask?.id}
                        taskType={formik.values.type}
                        isTaskAutomatic={queuedTask?.isAutomatic}
                        isTaskComplaint={queuedTask?.isComplaint}
                      />
                    )
                  }
                  isStretched={
                    !isCallTaskType ||
                    !isQueuedTaskSucceeded ||
                    isTaskQueueStopped
                  }
                >
                  <TasksQueuePagePlaceholder
                    loadingFallback={<TasksQueuePageLoader />}
                    stoppedQueueFallback={
                      <StoppedTaskQueue onRestart={resumeTask} />
                    }
                    failedFallback={
                      <FormattedMessage defaultMessage="Information is unavailable" />
                    }
                    noQueuedTaskFallback={
                      <FormattedMessage defaultMessage="No tasks for you right now" />
                    }
                    isLoading={isQueuedTaskLoading}
                    isFailed={isQueuedTaskFailed}
                    isNoQueuedTask={isNoQueuedTask}
                    isQueueStopped={isTaskQueueStopped}
                  >
                    <QueuedTaskOverviewAccordion
                      commentsAmount={commentsAmount}
                      hasAccordion={isAdOverviewShown}
                    >
                      <div className={styles.grid}>
                        <div className={styles.column}>
                          <QueuedTaskOverview
                            sourceAdUrlByAdId={sourceAdUrlByAdId}
                            task={queuedTask}
                            values={formik.values}
                            touched={formik.touched}
                            errors={formik.errors}
                            comment={comment}
                            executors={executors}
                            setFieldValue={formik.setFieldValue}
                            isExecutorsLoading={isExecutorsLoading}
                            isCommentDisabled={isCommentLoading}
                            onCommentSubmit={onCommentSubmit}
                            onCommentChange={onCommentChange}
                            onChange={formik.handleChange}
                            onSelectChange={onSelectChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>

                        <div className={styles.column}>
                          <TaskInteractionHistoryField
                            label={
                              <FormattedMessage defaultMessage="Comment history" />
                            }
                          >
                            <TaskInteractionHistory
                              history={queuedTask?.interactionHistory}
                              isLoading={isQueuedTaskLoading}
                              isReady={isQueuedTaskReady}
                            />
                          </TaskInteractionHistoryField>
                        </div>
                      </div>
                    </QueuedTaskOverviewAccordion>

                    {isAdOverviewShown && (
                      <div className={styles.adOverview}>
                        <QueuedTaskAdPreview ad={ad} />
                      </div>
                    )}
                  </TasksQueuePagePlaceholder>
                </PageMainContentTile>
              </DefaultPageLayout.WideCol>

              <DefaultPageLayout.NarrowCol>
                <TaskResolvePlate
                  values={formik.values}
                  errors={formik.errors}
                  touched={formik.touched}
                  phoneList={phones}
                  isLoading={isQueuedTaskLoading}
                  isFailed={isQueuedTaskFailed}
                  isQueueEmpty={isNoQueuedTask}
                  isQueueStopped={isTaskQueueStopped}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFieldValueSet={formik.setFieldValue}
                  onFieldTouchedSet={formik.setFieldTouched}
                  onSelectChange={onSelectChange}
                  onSubmit={onFormSubmit}
                  onSkip={onFormSkip}
                />
              </DefaultPageLayout.NarrowCol>
            </DefaultPageLayout>
          </Section>

          {isCallTaskType &&
            callsHistoryByPhoneList.map(({ phone, history }) => (
              <TileSection
                key={phone}
                title={
                  <FormattedMessage defaultMessage="Call history by number" />
                }
                titleAppended={formatPhoneNumber(phone)}
              >
                <CallsTable calls={history} />
              </TileSection>
            ))}

          {isCallTaskType && adCallsHistory && (
            <TileSection
              title={
                <FormattedMessage defaultMessage="Call history by advertisement" />
              }
              titleAppended={adCallsHistory.adId}
            >
              <CallsTable calls={adCallsHistory.list} />
            </TileSection>
          )}
        </Page>
      )}
    </FormattedMessage>
  );
};

TasksQueuePage.RouteParams = withRoles()({
  element: <TasksQueuePage />,
  path: '/task-queue',
});

export default TasksQueuePage;
