import { useMemo } from 'react';
import cx from 'classnames';

import { checkIsString } from '../../utils';
import styles from './Media.module.scss';

const formSource = (source) => {
  if (checkIsString(source)) {
    return { normal: source, double: source };
  }

  return source;
};

const Media = ({ media, isAdjustable }) => {
  const source = useMemo(() => formSource(media.source), [media.source]);

  return (
    <span
      className={cx(styles.root, { [styles.root_adjustable]: isAdjustable })}
    >
      <img
        className={styles.element}
        src={source.normal}
        srcSet={`${source.double} 2x`}
        alt={media.title}
      />
    </span>
  );
};

export default Media;
