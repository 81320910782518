const TasksQueuePagePlaceholder = ({
  children,
  loadingFallback,
  failedFallback,
  noQueuedTaskFallback,
  stoppedQueueFallback,
  isLoading,
  isFailed,
  isNoQueuedTask,
  isQueueStopped,
}) => {
  if (isLoading) {
    return loadingFallback;
  }

  if (isFailed) {
    return failedFallback;
  }

  if (isQueueStopped) {
    return stoppedQueueFallback;
  }

  if (isNoQueuedTask) {
    return noQueuedTaskFallback;
  }

  return children;
};

export default TasksQueuePagePlaceholder;
