import { useAtom } from '@reatom/react';
import { Navigate } from 'react-router-dom';

import * as Account from '../../../models/account';
import { ROLE_TYPE, DEFAULT_PAGE_PATH } from '../../../constants';

const allRoleTypes = Object.values(ROLE_TYPE);

const RoleBarrier = ({ roles = allRoleTypes, children }) => {
  const accountRole = useAtom(Account.roleAtom);

  if (!roles.includes(accountRole)) {
    return <Navigate to={DEFAULT_PAGE_PATH} replace />;
  }

  return children;
};

export default RoleBarrier;
