@import '../../styles/utils';

.root {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid $textDark;
  border-radius: 2px;
  box-sizing: border-box;
  vertical-align: top;
  @include defaultTransition(border, background-color, opacity);

  &::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 1px;
    display: inline-block;
    width: 4px;
    height: 10px;
    border-radius: 1px;
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotate(45deg);
    opacity: 0;
    @include defaultTransition(opacity);
  }
}

.root_active {
  border: 2px solid $textDark;
  background-color: $textDark;

  &::before {
    opacity: 1;
  }
}
