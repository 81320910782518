import IconContainer from '../IconContainer/IconContainer';
import { IconEdit } from '../icons';
import styles from './ButtonEdit.module.scss';

const ButtonEdit = ({ counter, isDisabled, disabled, ...rest }) => {
  const preparedCounter =
    counter < 100 ? counter : `${String(counter).slice(0, 2)}...`;

  return (
    <button
      className={styles.element}
      disabled={isDisabled || disabled}
      {...rest}
    >
      <IconContainer icon={<IconEdit />} />

      {counter > 0 && <span className={styles.counter}>{preparedCounter}</span>}
    </button>
  );
};

export default ButtonEdit;
