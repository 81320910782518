import { NavLink } from 'react-router-dom';
import { useAtom } from '@reatom/react';
import cx from 'classnames';

import IconContainer from '../../../IconContainer/IconContainer';

import * as Account from '../../../../models/account';
import { ROLE_TYPE } from '../../../../constants';
import styles from './PrivateLink.module.scss';

const allRoleTypes = Object.values(ROLE_TYPE);

const MainNavigationPrivateLink = ({ title, icon, page: Page }) => {
  const accountRole = useAtom(Account.roleAtom);

  if (!(Page.Roles || allRoleTypes).includes(accountRole)) {
    return null;
  }

  return (
    <NavLink
      className={({ isActive }) =>
        cx(styles.root, { [styles.root_active]: isActive })
      }
      to={Page.RouteParams.path}
    >
      <span className={styles.icon}>
        <IconContainer icon={icon} />
      </span>

      <span className={styles.title}>{title}</span>
    </NavLink>
  );
};

export default MainNavigationPrivateLink;
