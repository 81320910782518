import { useState, useCallback, useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import TaskCreationDialog from './TaskCreationDialog';

import * as Tasks from '../../../../models/tasks';
import {
  useNotifications,
  usePagination,
  useExecutorsByTaskType,
} from '../../../../hooks';
import { getTaskListPageLink } from '../../../../routes';
import useOnModerationRoutParamOpening from './useOnModerationRouteParamOpening';

const formSuccessMessage = (taskIds, formatMessage) => {
  if (taskIds?.length < 1) {
    return formatMessage({
      defaultMessage: 'Task has been successfully created',
    });
  }

  if (taskIds?.length === 1) {
    return formatMessage(
      {
        defaultMessage: `Task has been successfully created. Id: {id}`,
      },
      { id: taskIds[0] },
    );
  }

  if (taskIds?.length > 3) {
    return formatMessage(
      {
        defaultMessage: `Tasks have been successfully created. IDs: {ids}...`,
      },
      { ids: [...taskIds].join(', ').slice(0, 3) },
    );
  }

  return formatMessage(
    {
      defaultMessage: `Tasks have been successfully created. Ids: {ids}`,
    },
    { ids: taskIds.join(', ') },
  );
};

const TaskCreationDialogContainer = ({ payload, isOpened, ...rest }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { showNotification, showErrorNotification } = useNotifications();
  const [creatingTaskType, setCreatingTaskType] = useState('');
  const [, { goToPage }] = usePagination({ initializePagination: false });

  const createdTaskIds = useAtom(Tasks.Creation.createdTaskIdsAtom);
  const isTaskCreationLoading = useAtom(Tasks.Creation.isSavedLoadingAtom);
  const isTaskCreationSucceeded = useAtom(Tasks.Creation.isSavedSucceededAtom);
  const isTaskCreationFailed = useAtom(Tasks.Creation.isSavedFailedAtom);
  const { executors, isExecutorsLoading } =
    useExecutorsByTaskType(creatingTaskType);

  const handleTaskCreate = useAction((task) => Tasks.Creation.saved({ task }));

  const handleTaskTypeChange = useCallback(
    (taskType) => setCreatingTaskType(taskType),
    [],
  );

  useEffect(() => {
    if (!isOpened) {
      setCreatingTaskType('');
    }
  }, [isOpened]);

  useEffect(() => {
    if (!isTaskCreationSucceeded) {
      return;
    }

    showNotification(formSuccessMessage(createdTaskIds, intl.formatMessage));

    if (pathname === getTaskListPageLink()) {
      goToPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskCreationSucceeded, showNotification, createdTaskIds, goToPage]);

  useOnModerationRoutParamOpening();

  useEffect(() => {
    if (!isTaskCreationFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to create task. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskCreationFailed, intl.formatMessage, showErrorNotification]);

  return (
    <TaskCreationDialog
      moderatingAdId={payload?.moderatingAdId}
      executors={executors}
      isLoading={isTaskCreationLoading}
      isExecutorsLoading={isExecutorsLoading}
      isOpened={isOpened}
      onCreate={handleTaskCreate}
      onTaskTypeChange={handleTaskTypeChange}
      {...rest}
    />
  );
};

export default TaskCreationDialogContainer;
