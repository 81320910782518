import { FormattedMessage } from 'react-intl';

import AdInfoTileSection from '../TileSection/TileSection';
import AdDetailsTable from '../Table/Table';

const AdAuthorDetailsSection = ({ data, isModerated }) => (
  <AdInfoTileSection
    title={<FormattedMessage defaultMessage="Author" />}
    isModerated={isModerated}
  >
    <AdDetailsTable>
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="phone(s)" />}
        {...data.phoneNumbers}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="name" />}
        {...data.name}
      />
      <AdDetailsTable.Row
        title={<FormattedMessage defaultMessage="agency" />}
        {...data.agency}
      />
    </AdDetailsTable>
  </AdInfoTileSection>
);

export default AdAuthorDetailsSection;
