@import '../../styles/utils.scss';

.adOverview {
  margin-top: 24px;
}

.separator {
  margin: 24px 0;
  border-top: 1px solid $grey_2;
}

.grid {
  display: flex;
  margin: -4px;
}

.column {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 4px;
}
