import { combine } from '@reatom/core';

import creationAtom from './creation';
import editingAtom from './editing';
import overviewAtom from './overview';
import commentAtom from './comment';
import executorsAtom from './executors';
import previewListAtom from './previewList';
import recreation from './recreation';
import countStatistic from './countStatistic';

export * as Creation from './creation';
export * as Editing from './editing';
export * as Overview from './overview';
export * as Comment from './comment';
export * as Executors from './executors';
export * as PreviewList from './previewList';
export * as Recreation from './recreation';
export * as CountStatistic from './countStatistic';

export default combine({
  executorsAtom,
  creationAtom,
  editingAtom,
  overviewAtom,
  commentAtom,
  previewListAtom,
  recreation,
  countStatistic,
});
