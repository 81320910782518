import Svg from '../Svg/Svg';

const BandedArrowLeft = () => (
  <Svg>
    <g fillRule="evenodd">
      <path d="M9.745 7.671V6.034c0-.917-1.105-1.38-1.75-.731L3.3 10.03a1.03 1.03 0 000 1.452l4.697 4.727c.644.649 1.75.185 1.75-.731v-1.73c5.115 0 8.696 1.647 11.254 5.252-1.023-5.15-4.093-10.299-11.255-11.329z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </g>
  </Svg>
);

export default BandedArrowLeft;
