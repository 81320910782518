import Svg from '../Svg/Svg';

const IconPlus = () => (
  <Svg>
    <path d="M21 13.5h-7.5V21c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-7.5H3c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h7.5V3c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v7.5H21c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
    <path d="M1.5 1.5h21v21h-21v-21z" fill="none" />
  </Svg>
);

export default IconPlus;
