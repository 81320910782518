import { SEARCH_ENTITY_TYPE } from '../constants';

const deserializeExternalAdLink = ({ pageId, sourcePageLink }) => ({
  adId: pageId,
  externalAdLink: sourcePageLink,
});

const deserializeSearchResult = ({ entityType, payload }) => {
  switch (entityType) {
    case SEARCH_ENTITY_TYPE.AD_EXTERNAL_LINK:
    case SEARCH_ENTITY_TYPE.AD_ID:
      return { entityType, payload: deserializeExternalAdLink(payload) };

    default:
      return { entityType, payload };
  }
};

export const deserializeSearchResults = (searchResults) =>
  searchResults.map((result) => deserializeSearchResult(result));
