import { useEffect, useMemo, useRef } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Tasks from '../../models/tasks';

import { TASK_TYPE_INFO } from '../../constants';
import { useNotifications } from '../../hooks';

const useTaskCountStatistic = ({ search, filters }, tasksAmount) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();
  const isFirstRenderRef = useRef(true);

  const statistic = useAtom(Tasks.CountStatistic.statisticListAtom);
  const isStatisticIdle = useAtom(Tasks.CountStatistic.isShownIdleAtom);
  const isStatisticFailed = useAtom(Tasks.CountStatistic.isShownFailedAtom);
  const handleStatisticRequest = useAction(Tasks.CountStatistic.shown);

  useEffect(() => {
    if (!isStatisticFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load task statistics. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatisticFailed, intl.formatMessage, showErrorNotification]);

  const primary = useMemo(
    () => ({
      number: tasksAmount,
      title: intl.formatMessage({ defaultMessage: 'Total tasks' }),
    }),
    [tasksAmount, intl],
  );
  const preparedStatistic = useMemo(
    () =>
      statistic.map(({ taskType, count }) => ({
        id: taskType,
        title: intl.formatMessage(TASK_TYPE_INFO[taskType].title),
        number: count,
        color: TASK_TYPE_INFO[taskType].color,
      })),
    [statistic, intl],
  );

  useEffect(() => {
    handleStatisticRequest({
      search,
      filters: Object.values(filters),
    });
  }, [search, filters, handleStatisticRequest]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      return;
    }

    if (!isStatisticIdle) {
      return;
    }

    handleStatisticRequest({
      search,
      filters: Object.values(filters),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatisticIdle, handleStatisticRequest]);

  useEffect(() => {
    isFirstRenderRef.current = false;
  });

  return { primary, list: preparedStatistic };
};

export default useTaskCountStatistic;
