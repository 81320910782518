import { forwardRef } from 'react';
import cx from 'classnames';
import styles from './Radio.module.scss';

const Radio = forwardRef(
  (
    {
      label,
      value,
      disabled,
      isDisabled,
      hasTextSelectionPrevented = true,
      ...rest
    },
    ref,
  ) => {
    const handleMouseDown = (e) => {
      if (hasTextSelectionPrevented) {
        e.preventDefault(); // disable text selection on dblclick;
      }
    };

    return (
      <div>
        <label
          className={cx(styles.wrapper, {
            [styles.wrapper_disabled]: disabled || isDisabled,
          })}
        >
          <input
            className={styles.element}
            type="radio"
            value={value}
            disabled={disabled || isDisabled}
            ref={ref}
            {...rest}
          />

          <span className={styles.mark} />

          <span className={styles.label} onMouseDown={handleMouseDown}>
            {label}
          </span>
        </label>
      </div>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;
