import { useEffect, useCallback } from 'react';
import { IntlProvider as OriginalIntlProvider } from 'react-intl';
import { useAction, useAtom } from '@reatom/react';
import PropTypes from 'prop-types';

import * as Localization from '../models/localization';

// const MessagesError = () => {
//   const intl = useIntl();
//   const { showErrorNotification } = useNotifications();
//   const language = useAtom(Language.entityAtom);
//   const isFailed = useAtom(Messages.makeIsFailed(language));

//   useEffect(() => {
//     if (!isFailed) {
//       return;
//     }

//     showErrorNotification(
//       intl.formatMessage({ defaultMessage: 'Failed to load translation' }),
//     );
//   }, [intl, isFailed, showErrorNotification]);

//   return null;
// };

const IntlProvider = ({ children, ...intlProps }) => {
  const language = useAtom(Localization.languageAtom);
  const messages = useAtom(Localization.makeMessagesAtom(language));

  const loadMessages = useAction(Localization.messagesLoaded);

  const handleIntlError = useCallback((error) => {
    if (error.code === 'MISSING_TRANSLATION') {
      return;
    }

    console.error('Intl error:', error);
  }, []);

  useEffect(() => {
    if (!language) {
      return;
    }

    loadMessages({ language });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMessages]);

  return (
    <OriginalIntlProvider
      locale={language}
      messages={messages}
      onError={handleIntlError}
      {...intlProps}
    >
      {/* <MessagesError /> */}
      {children}
    </OriginalIntlProvider>
  );
};

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntlProvider;
