import { useCallback, useMemo } from 'react';
import { useQueryParams, useToggle } from '../../hooks';

const TABLE_FILTERS = ['callResult'];

const useTableFilters = () => {
  const [params, { setQueryParams, removeQueryParams }] = useQueryParams();

  const hasTableFilters = useMemo(
    () =>
      TABLE_FILTERS.filter((filter) => Object.keys(params).includes(filter))
        .length > 0,
    [params],
  );

  const { isOn, toggle } = useToggle(hasTableFilters);

  const tableFilters = useMemo(
    () => ({
      callResult: params.callResult,
    }),
    [params.callResult],
  );

  const handleTableFiltersReset = useCallback(
    () => removeQueryParams(TABLE_FILTERS),
    [removeQueryParams],
  );

  const onTableFilterChange = useCallback(
    (name, value) => setQueryParams({ [name]: value }),
    [setQueryParams],
  );

  const handleTableFiltersShownToggle = useCallback(() => {
    toggle();
    handleTableFiltersReset();
  }, [toggle, handleTableFiltersReset]);

  return [
    { tableFilters, isTableFiltersShown: isOn },
    {
      onIsTableFiltersShownToggle: handleTableFiltersShownToggle,
      onTableFiltersReset: handleTableFiltersReset,
      onTableFilterChange: onTableFilterChange,
    },
  ];
};

const useFilters = () => {
  const { isOn, toggle } = useToggle();

  const [
    { tableFilters, isTableFiltersShown },
    { onTableFilterChange, onTableFiltersReset, onIsTableFiltersShownToggle },
  ] = useTableFilters();

  return [
    { tableFilters, isTableFiltersShown, isOnlyMy: isOn },
    {
      onTableFilterChange,
      onTableFiltersReset,
      onIsTableFiltersShownToggle,
      onIsOnlyMyChange: toggle,
    },
  ];
};

export default useFilters;
