.root {
  height: 100%;
  background-image: url('../../assets/images/login-background.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .root {
    background-image: url('../../assets/images/login-background@2x.png');
  }
}

.contentHolder {
  display: flex;
  justify-content: center;
  max-width: 712px;
}

.content {
  max-width: 360px;
  padding-top: 160px;
}

.logo {
  width: 224px;
  margin: 0 auto;
  margin-bottom: 96px;
}

.caption {
  margin-bottom: 48px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.button {
  align-self: stretch;
  margin-bottom: 16px;
}
