import { combine, declareAction, declareAtom, map } from '@reatom/core';

import { LOADING_STATE } from '../../constants';
import api from '../../services/api';
import {
  deserializeAdsStatisticOverview,
  serializeDateRange,
} from '../../normalizers';
import * as Account from '../account';

const shownSucceeded = declareAction('AdsStatistic/Overview/shownSucceeded');
const shownFailed = declareAction('AdsStatistic/Overview/shownFailed');

const checkIsParamsRelevant = (stateParams, requestParams) =>
  stateParams.siteId === requestParams.siteId &&
  stateParams.location === requestParams.location &&
  stateParams.section === requestParams.section &&
  stateParams.dateRange
    .map((date, index) => date === requestParams.dateRange[index])
    .reduce((cur, acc) => cur && acc, true);

const formOverviewPayload = (siteId, date, cityId, section) => {
  const payload = {
    date,
    siteId,
  };

  if (cityId) {
    payload.cityId = cityId;
  }

  if (section) {
    payload.section = section;
  }

  return payload;
};

export const shown = declareAction(
  'AdsStatistic/Overview/shown',
  async (payload, store) => {
    const { siteId, dateRange, cityId, section } = payload;
    const authorization = store.getState(Account.authorizationAtom);
    const date = serializeDateRange(dateRange);

    try {
      const overview = await api(authorization).adsStatistic.overview(
        formOverviewPayload(siteId, date, cityId, section),
      );

      if (checkIsParamsRelevant(store.getState(getParamsAtom()), payload)) {
        store.dispatch(
          shownSucceeded({
            overview: deserializeAdsStatisticOverview(overview, date),
          }),
        );
      }
    } catch (error) {
      if (!checkIsParamsRelevant(store.getState(getParamsAtom()), payload)) {
        return;
      }

      console.error(error);
      store.dispatch(shownFailed());
    }
  },
);

const paramsAtom = declareAtom(
  'AdsStatistic/Overview/paramsAtom',
  { siteId: null, dateRange: [], section: null, location: null },
  (on) => [on(shown, (state, payload) => payload)],
);

function getParamsAtom() {
  return paramsAtom;
}

const tableAtomInitialState = {
  ads: [],
  adGroups: [],
  notSpecifiedBuildingAds: [],
  notSpecifiedBuildingAdGroups: [],
  notSpecifiedLocalityAds: [],
  notSpecifiedLocalityAdGroups: [],
};

export const tableAtom = declareAtom(
  'AdsStatistic/Overview/tableAtom',
  tableAtomInitialState,
  (on) => [
    on(shown, () => tableAtomInitialState),
    on(shownSucceeded, (state, { overview }) => overview),
  ],
);

const shownLoadingStateAtom = declareAtom(
  'AdsStatistic/Overview/shownLoadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(shown, () => LOADING_STATE.LOADING),
    on(shownSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(shownFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isShownLoadingAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.LOADING,
);
export const isShownFailedAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);
export const isShownSucceededAtom = map(
  shownLoadingStateAtom,
  (ls) => ls === LOADING_STATE.SUCCEEDED,
);

export default combine({
  paramsAtom,
  tableAtom,
  shownLoadingStateAtom,
});
