import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';
import { useIntl } from 'react-intl';

import * as Ads from '../../../../models/ads';
import { useNotifications } from '../../../../hooks';

const useHouseEditing = (adId) => {
  const intl = useIntl();
  const { showNotification, showErrorNotification } = useNotifications();

  const isLoading = useAtom(Ads.HouseEditing.makeIsLoadingAtom(adId));
  const isSucceeded = useAtom(Ads.HouseEditing.makeIsSucceededAtom(adId));
  const isFailed = useAtom(Ads.HouseEditing.makeIsFailedAtom(adId));

  const edit = useAction(Ads.HouseEditing.saved);

  useEffect(() => {
    if (!isFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to change house',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFailed, intl.formatMessage, showErrorNotification]);

  const handleEdit = (house) => edit({ adId, house });

  useEffect(() => {
    if (isSucceeded) {
      showNotification(
        intl.formatMessage({
          defaultMessage: 'House was successfully edited',
        }),
      );
    }
  }, [intl, isSucceeded, showNotification]);

  return [{ isLoading, isSucceeded }, { edit: handleEdit }];
};

export default useHouseEditing;
