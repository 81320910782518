import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';

import IconContainer from '../IconContainer/IconContainer';

import { checkIsFunction } from '../../utils';
import styles from './Tabs.module.scss';

const Tabs = ({ children, defaultValue, onChange }) => {
  const [activeValue, setActiveValue] = useState(() => {
    const controlGroupElement = children[0];
    return Children.map(
      controlGroupElement.props.children,
      ({ props }) => props.value,
    ).filter(Boolean)[0];
  });

  const handleHeaderButtonClick = useCallback(
    (value) => {
      setActiveValue(value);

      if (checkIsFunction(onChange)) {
        onChange(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setActiveValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      {Children.map(children, (child) =>
        cloneElement(child, { activeValue, onClick: handleHeaderButtonClick }),
      )}
    </div>
  );
};

const ControlItem = ({
  title,
  value,
  activeValue,
  trailingElement,
  isDisabled,
  onClick,
}) => {
  const handleClick = useCallback(() => onClick(value), [value, onClick]);

  return (
    <div className={styles.headerItem}>
      <button
        className={cx(styles.headerButton, {
          [styles.headerButton_active]: value === activeValue,
        })}
        disabled={isDisabled}
        onClick={handleClick}
      >
        <span className={styles.headerButtonTitle}>{title}</span>

        {trailingElement && (
          <span className={styles.headerButtonTrailingElement}>
            {trailingElement}
          </span>
        )}
      </button>
    </div>
  );
};

Tabs.ControlGroup = ({ children, ...rest }) => (
  <div className={styles.header}>
    {Children.map(children, (child) => cloneElement(child, { ...rest }))}
  </div>
);

Tabs.ControlItem = ControlItem;

Tabs.ControlItemIcon = ({ icon }) => <IconContainer icon={icon} size="small" />;

Tabs.PanelGroup = ({ activeValue, children }) => (
  <div>{children.find(({ props }) => props.value === activeValue)}</div>
);

Tabs.PanelItem = ({ children }) => <div>{children}</div>;

export default Tabs;
