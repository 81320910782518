import { declareAction, declareAtom, combine, map } from '@reatom/core';

import {
  serializeTaskResolvedParams,
  deserializeTaskOverview,
} from '../../normalizers';
import api from '../../services/api';
import { LOADING_STATE } from '../../constants';
import * as Account from '../account';
import TaskQueueIsStopped from './isStopped';
import { TASK_QUEUE_ERROR_TYPE } from './entityId';

export const doneSucceeded = declareAction('TaskQueue/Resolving/doneSucceeded');
export const doneFailed = declareAction('TaskQueue/Resolving/doneFailed');

export const done = declareAction(
  'TaskQueue/Resolving/done',
  async (resolvedParams, store) => {
    const {
      task,
      solution,
      callStatus,
      callResult,
      callPhone,
      callComment,
      shouldQueueStop,
    } = resolvedParams;
    const authorization = store.getState(Account.authorizationAtom);

    try {
      const queuedTask = await api(authorization).tasks.resolveQueuedTask(
        serializeTaskResolvedParams({
          task,
          solution,
          callStatus,
          callResult,
          callPhone,
          callComment,
          shouldQueueStop,
        }),
      );
      store.dispatch(
        doneSucceeded({
          finishedTaskId: task.id,
          task: deserializeTaskOverview(queuedTask),
        }),
      );
    } catch (error) {
      const dispatchDoneSucceeded = () =>
        store.dispatch(
          doneSucceeded({
            finishedTaskId: task.id,
            task: null,
          }),
        );

      switch (error.type) {
        case TASK_QUEUE_ERROR_TYPE.EMPTY_QUEUE:
          dispatchDoneSucceeded();
          return;

        case TASK_QUEUE_ERROR_TYPE.STOPPED_QUEUE:
          dispatchDoneSucceeded();
          store.dispatch(TaskQueueIsStopped.stopped());
          return;

        default:
          console.error(error);
          store.dispatch(doneFailed());
      }
    }
  },
);

const loadingStateAtom = declareAtom(
  'TaskQueue/Resolving/loadingStateAtom',
  LOADING_STATE.IDLE,
  (on) => [
    on(done, () => LOADING_STATE.LOADING),
    on(doneSucceeded, () => LOADING_STATE.SUCCEEDED),
    on(doneFailed, () => LOADING_STATE.FAILED),
  ],
);

export const isFailedAtom = map(
  loadingStateAtom,
  (ls) => ls === LOADING_STATE.FAILED,
);

export default combine({ loadingStateAtom });
