import cx from 'classnames';
import Media from '../Media/Media';
import styles from './UserPlate.module.scss';

const UserPlate = ({ name, media, hasReducedOffset }) => (
  <div
    className={cx(styles.root, {
      [styles.root_withReducedOffset]: hasReducedOffset,
    })}
  >
    <div className={styles.media}>
      <Media media={media} isAdjustable />
    </div>

    <div className={styles.name}>{name}</div>
  </div>
);

export default UserPlate;
