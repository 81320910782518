@import '../../../styles/utils';

.root {
  width: 324px;
  min-height: 212px;
  box-sizing: border-box;
  padding: 18px;
  border-radius: $primaryBorderRadius;
  background-color: $white;

  &_centerAligned {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.taskTypeRow {
  font-weight: 700;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonItem {
  flex: 1 1 auto;
  margin-right: 8px;
}
