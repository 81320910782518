import { useCallback, useMemo, useRef } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { checkIsString } from '../../utils';
import {
  PHONE_NUMBER_FORMAT,
  PHONE_NUMBER_REG_EXP,
  PRIORITY,
  TASK_IDENTIFIER_TYPE,
  TASK_TYPE,
} from '../../constants';
import { useEventHandler } from '../../hooks';

const initValues = (task) => ({
  type: task?.type || '',
  subType: task?.subType || '',
  priority: task?.priority || PRIORITY.MEDIUM,
  zendeskUrl: task?.zendeskUrl || '',
  executorId: task?.executor?.id || '',
  additionalResources: task?.additionalResources || [],
  comment: '',
  callStatus: '',
  callResult: '',
  callPhone: '',
  solution: '',
  callComment: '',
});

const createValidationSchema = (task, isSkippingRef, formatMessage) =>
  Yup.object().shape({
    type: Yup.string()
      .test(
        'call-task-has-only-one-identifier',
        formatMessage({
          defaultMessage:
            'Task type "Call" can have only one identifier - ad id',
        }),
        (taskType) => {
          if (taskType !== TASK_TYPE.CALL) {
            return true;
          }

          return (
            task?.identifier?.type === TASK_IDENTIFIER_TYPE.AD_ID &&
            task?.identifier?.entities?.length === 1
          );
        },
      )
      .test(
        'skipping-task',
        formatMessage({
          defaultMessage:
            'You need to assign a task to another user or change its type',
        }),
        (value, context) => {
          if (!isSkippingRef.current) {
            return true;
          }

          const wasTypeChanged = value !== task.type;
          const wasUserChanged =
            String(context.parent.executorId) !== String(task.executor?.id);

          return wasTypeChanged || wasUserChanged;
        },
      ),
    zendeskUrl: Yup.string().url(
      formatMessage({ defaultMessage: 'Link is not valid' }),
    ),
    solution: Yup.string().test(
      'required',
      formatMessage({ defaultMessage: 'Choose solution type' }),
      (value, context) =>
        isSkippingRef.current ||
        context.parent.type === TASK_TYPE.CALL ||
        (checkIsString(value) && value.length > 0),
    ),
    executorId: Yup.string().test(
      'skipping-task',
      formatMessage({
        defaultMessage:
          'You need to assign a task to another user or change its type',
      }),
      (value, context) => {
        if (!isSkippingRef.current) {
          return true;
        }

        const wasTypeChanged = context.parent.type !== task.type;
        const wasUserChanged = String(value) !== String(task.executor?.id);

        return wasTypeChanged || wasUserChanged;
      },
    ),
    callStatus: Yup.string().test(
      'required',
      formatMessage({
        defaultMessage: 'Call status must be specified',
      }),
      (value, context) => {
        if (isSkippingRef.current || context.parent.type !== TASK_TYPE.CALL) {
          return true;
        }

        return checkIsString(value) && value.length > 0;
      },
    ),
    callResult: Yup.string().test(
      'required',
      formatMessage({
        defaultMessage: 'Call result must be specified',
      }),
      (value, context) => {
        if (
          isSkippingRef.current ||
          context.parent.type !== TASK_TYPE.CALL ||
          !context.parent.callStatus
        ) {
          return true;
        }

        return checkIsString(value) && value.length > 0;
      },
    ),
    callPhone: Yup.string()
      .test(
        'required',
        formatMessage({
          defaultMessage: 'Phone number must be specified',
        }),
        (value, context) => {
          if (isSkippingRef.current || context.parent.type !== TASK_TYPE.CALL) {
            return true;
          }

          return checkIsString(value) && value.length > 0;
        },
      )
      .test(
        'correctness',
        formatMessage(
          {
            defaultMessage:
              'The number does not match the format {phone_number_format}',
          },
          { phone_number_format: PHONE_NUMBER_FORMAT },
        ),
        (value, context) =>
          isSkippingRef.current ||
          context.parent.type !== TASK_TYPE.CALL ||
          PHONE_NUMBER_REG_EXP.test(value),
      ),
  });

const useForm = ({ queuedTask, onSkip, onSubmit }) => {
  const intl = useIntl();

  const isSkippingRef = useRef(false);
  const shouldQueueStopRef = useRef(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: initValues(),
    validationSchema: createValidationSchema(
      queuedTask,
      isSkippingRef,
      intl.formatMessage,
    ),
    onSubmit: (values) => {
      const {
        solution,
        callStatus,
        callResult,
        callPhone,
        callComment,
        comment,
        ...taskFields
      } = values;

      const updatedTask = {
        id: queuedTask.id,
        identifiers: queuedTask.identifier.entities,
        identifiersType: queuedTask.identifier.type,
        ...taskFields,
      };

      if (isSkippingRef.current) {
        onSkip({
          task: updatedTask,
          shouldQueueStop: shouldQueueStopRef.current,
        });
        return;
      }

      onSubmit({
        task: updatedTask,
        solution,
        callStatus,
        callResult,
        callPhone,
        callComment,
        shouldQueueStop: shouldQueueStopRef.current,
      });
    },
  });

  const resetSolutionError = useCallback(() => {
    setFieldError('solution', '');
    setFieldTouched('solution', false);
  }, [setFieldError, setFieldTouched]);

  const handleFormikChange = useCallback(
    (e) => {
      resetSolutionError();
      handleChange(e);
    },
    [handleChange, resetSolutionError],
  );

  const formik = useMemo(
    () => ({
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      handleChange: handleFormikChange,
      handleBlur,
    }),
    [
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      handleFormikChange,
      handleBlur,
    ],
  );

  const onFormSubmit = useEventHandler(
    (shouldQueueStop) => {
      isSkippingRef.current = false;
      shouldQueueStopRef.current = shouldQueueStop;
      handleSubmit();
    },
    [handleSubmit],
  );

  const onFormSkip = useEventHandler(
    (shouldQueueStop) => {
      isSkippingRef.current = true;
      shouldQueueStopRef.current = shouldQueueStop;
      handleSubmit();
    },
    [handleSubmit],
  );

  const onFormReset = useCallback(
    (queuedTask) =>
      resetForm({
        values: initValues(queuedTask),
      }),
    [resetForm],
  );

  const onSelectChange = useEventHandler(
    (value, name) => {
      resetSolutionError();
      setFieldValue(name, value);

      if (name === 'type' && value !== formik.values.type) {
        setFieldValue('executorId', '');
        setFieldValue('subType', '');
      }
    },
    [setFieldValue, resetSolutionError],
  );

  return [formik, { onSelectChange, onFormSubmit, onFormSkip, onFormReset }];
};

export default useForm;
