import TextareaAutosize from 'react-textarea-autosize';
import styles from './Textarea.module.scss';

const Textarea = ({
  appendedItem,
  minRows,
  maxRows,
  isDisabled,
  disabled,
  ...rest
}) => (
  <div className={styles.root}>
    <TextareaAutosize
      className={styles.element}
      minRows={3}
      maxRows={5}
      disabled={isDisabled || disabled}
      {...rest}
    />

    {appendedItem && <div className={styles.appended}>{appendedItem}</div>}
  </div>
);

export default Textarea;
