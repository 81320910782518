import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
import AppProviders from './AppProviders';

import initSentry from './initSentry';
import store from './store';
import './styles/index.scss';

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <AppProviders App={App} store={store} />
  </React.StrictMode>,
  document.getElementById('root'),
);
