import { DEFAULT_AVATAR_MEDIA } from '../constants';

export const deserializeMedia = (media) =>
  media
    ? { title: 'Avatar', source: { normal: media, double: media } }
    : DEFAULT_AVATAR_MEDIA;

export const deserializeUserPreview = ({ userId, name, media }) => ({
  id: userId,
  name,
  media: deserializeMedia(media),
});

export const deserializeUserOverview = (user) => {
  const _user = { ...user };
  delete _user.id;

  const { userId, media, ...rest } = _user;

  return {
    id: userId,
    media: deserializeMedia(media),
    ...rest,
  };
};

const getEndOfCurrentDay = (date) => {
  const endOfCurrentDay = new Date(date.getTime());
  endOfCurrentDay.setHours(23, 59, 59, 999);
  return endOfCurrentDay;
};

export const serializeDateRange = (dateRange) => ({
  from: dateRange[0].toISOString(),
  to: getEndOfCurrentDay(dateRange[1]).toISOString(),
});
